import React from "react";
import LogoIcon from "../Logo/Logo";
import { FaArrowRight } from "react-icons/fa";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import { Link, useParams } from "react-router-dom";
import "./homepage.scss";
import { CUST_URL } from "../../utils/constants";

const HomePage = () => {
  const { name } = useParams();
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  // //console.log(name);
  return (
    <>
      <Header_inner />
      <section className="homeBanner">
        <div className="container">
          <div className="Box">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-5">
                <div className="content lightBg">
                  <h3>Guards</h3>
                  <Link to={`/guard/` + name} className="proceedLeft">
                    Proceed <FaArrowRight className="arrowRight" />
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                {/* <div className="content lightBg"> */}
                <div className="content darkBg">
                  <h3>Customers</h3>
                  {/* <Link  to="/contact_us" className="proceedLeft">Proceed <FaArrowRight className="arrowRight" /></Link> */}
                  <a href={CUST_URL + name} className="proceedLeft">
                    Proceed <FaArrowRight className="arrowRight" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
