import React, { useEffect, useState } from "react";
import mapImg from "../../images/mapImg.png";
import {
  FaStar,
  FaCalendar,
  FaStarHalf,
  FaArrowLeft,
  FaTimesCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./job_detail.scss";
import Navbar from "../../Pages/Header/Navbar";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

import {
  applyLeave,
  getLeaveList,
  deleteAppliedLeave,
  getParticularGuardJobDetail,
} from "../../utils/request";
import { toast } from "react-toastify";
import Map from "../Map/Map";

const JobDetail = () => {
  const item = useLocation();
  const cookie = new Cookies();
  const shiftId = cookie.get("shiftId");
  const jobId = cookie.get("jobId");
  const guardId = cookie.get("guardId");
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [ratingValue, setRatingValue] = useState(0);
  let [upcoming, setUpComing] = useState(false);
  let [prev, setPrev] = useState(false);
  const [jobDetail, setJobDetails] = useState("");

  const [shiftDetails, setShiftDetails] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [dataSaved, setDataSaved] = useState(false);

  useEffect(() => {
    setUpComing(true);
    //console.log(moment.utc(item?.state?.user?.startTime).format("h:mm, A"));
    setRatingValue(item?.state?.user?.clientRating);
    setLat(item?.state?.user.location?.location?.coordinates[0]);
    setLong(item?.state?.user.location?.location?.coordinates[1]);
    navigator.geolocation.getCurrentPosition(function (position) {
      //console.log("Latitude is :", position.coords.latitude);
      //console.log("Longitude is :", position.coords.longitude);
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });
    setTimeout(() => {
      // handleRating();
      getParticularGuardJobDetails();
    }, 2000);
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Function for apply leave
  const applyForLeave = async (sdate) => {
    const requestData = {
      jobId: item?.state?.user?.jobId,
      shiftId: item?.state?.user?.shiftId,
      shiftDate: sdate,
      leaveTitle: title,
    };
    if (title == 3) {
      if (desc === "") {
        ////console.log(desc, title, "testyyy");

        toast.error("Description should not be blank");
        setDataSaved(false);
        return;
      }
      requestData["leaveDescription"] = desc;
    }
    const response = await applyLeave(requestData);
    if (response?.data?.code === 200) {
      setDataSaved(true);
      setTitle("");
      if (title == 3) {
        setDesc("");
      }
      toast.success(response?.data?.message);
      ////console.log(response?.data?.message, "mssg");
    } else {
      setDataSaved(false);
    }
  };

  // Function for get job details
  const getParticularGuardJobDetails = async () => {
    ////console.log("getParticularGuard ");
    const latitude = 0;
    const longitude = 0;
    navigator.geolocation.getCurrentPosition(function (position) {
      //console.log("Latitude is :", position?.coords?.latitude);
      //console.log("Longitude is :", position?.coords?.longitude);
      latitude = position?.coords?.latitude;
      longitude = position?.coords?.longitude;
    });
    const requestData = {
      jobId: item?.state?.user?.jobId,
      userId: guardId,
      shiftId: item?.state?.user?.jobId,
      lat: latitude,
      long: longitude,
    };
    const response = await getParticularGuardJobDetail(requestData);
    ////console.log("getParticularGuardJobDetails response", response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response?.data?.code === 200) {
      setJobDetails(response?.data?.data);
    }
  };

  // Function for set title
  const selectedTitle = (v) => {
    ////console.log(v);
    setTitle(v);
  };
  //console.log(title, "test");
  // Function for set description
  const selectedDesc = (v) => {
    //console.log(v);
    setDesc(v);
  };

  // const handleRating = () => { };

  return (
    <>
      <Navbar />
      <section className="job_detail">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="arrow_icon">
                <Link to={`/guard/myprofile/${guardId}`}>
                  <FaArrowLeft />{" "}
                </Link>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-7">
              <div className="mainBox">
                <div className="detailBox">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title">
                        <div className="heading">
                          <h1>Job Page</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5> {jobDetail?.jobTitle}</h5>
                    <p className="adderess">
                      {jobDetail?.location?.locationName}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="lBox">
                      <p className="para">
                        Description: {jobDetail?.description}
                      </p>
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <p className="calendar_para">
                            <FaCalendar />
                            {moment
                              .utc(item?.state?.user?.jobStartDate)
                              .format("DD/MM/YYYY")}
                          </p>
                        </div>
                        <div className="col-md-6">
                          {jobDetail?.clientRating == 0 ? (
                            "--"
                          ) : (
                            <div className="starRate">
                              <p className="star">
                                <span>Client Rating</span>
                                <div className="starRate">
                                  <Rating
                                    // onClick={handleRating}
                                    initialValue={jobDetail?.clientRating}
                                    readonly
                                  />
                                </div>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="rBox">
                        {lat !== "" && long !== "" && (
                          <Map
                            lat={long}
                            long={lat}
                            name={item?.state?.user.location?.locationName}
                          />
                        )}
                      </div>
                      <div className="desc">
                        <div className="flexBox">
                          <p>
                            <span>Distance From Base</span> :{" "}
                            {jobDetail?.distanceFromBase} Kms
                          </p>
                          <p>
                            <span>Dress Code</span> :{" "}
                            {jobDetail?.dressCode === 1
                              ? "HV Vest"
                              : item?.state?.user?.dressCode === 2
                              ? "Casual"
                              : "Suited"}
                          </p>
                          <p>
                            <span>Transport</span> :{" "}
                            {jobDetail?.transportation
                              ? "Own Vehicle"
                              : "Doesn't matter"}
                          </p>
                          <p>
                            <span>Status</span> :{" "}
                            {item?.state?.user?.jobStatus == 0
                              ? "Awaiting Approval"
                              : item?.state?.user?.jobStatus == 1
                              ? "Approved"
                              : item?.state?.user?.jobStatus == 2
                              ? "De-Activate(Reject)"
                              : item?.state?.user?.jobStatus == 3
                              ? "Cancel"
                              : item?.state?.user?.jobStatus == 4
                              ? "Ongoing"
                              : item?.state?.user?.jobStatus == 5
                              ? "Closed"
                              : item?.state?.user?.jobStatus == 6
                              ? "Upcoming"
                              : item?.state?.user?.jobStatus == 7
                              ? "Previous"
                              : "N/A"}
                          </p>
                        </div>
                        <div className="flexBox spaceRight">
                          <p>
                            <span>Hourly Rate</span> : $
                            {item?.state?.user?.hourlyRate} /hr
                          </p>
                          <p>
                            <span>Speciality</span> :{" "}
                            {item?.state?.user?.speciality?.name}
                          </p>
                          <p>
                            <span>Tot.Hours</span> :{" "}
                            {item?.state?.user?.shiftHrs} Hrs
                          </p>
                        </div>
                      </div>
                      <div className="earningBox">
                        <div className="flexBox">
                          <h6>Earning</h6>
                          <p>{jobDetail?.earning}</p>
                        </div>
                        <div className="flexBox">
                          <h6>Upcoming Earning</h6>
                          <p>${jobDetail?.upcomingEarning}</p>
                        </div>
                        <div className="totEarn">
                          <h6>Total Earning</h6>
                          <p>${jobDetail?.totalEarning?.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="shiftBox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="imgBtn">
                      <a
                        type="button"
                        className={upcoming ? "detailBtn" : ""}
                        onClick={() => {
                          setPrev(prev == true ? false : true);
                          setUpComing((upcoming = !upcoming));
                        }}
                      >
                        Upcoming Shifts
                      </a>
                      <a
                        type="button"
                        className={prev ? "detailBtn" : ""}
                        onClick={() => {
                          setUpComing(upcoming == true ? false : true);
                          setPrev((prev = !prev));
                        }}
                      >
                        Previous Shifts
                      </a>
                    </div>
                  </div>
                  {upcoming ? (
                    <>
                      {" "}
                      {jobDetail?.upcomingShifts?.length !== 0 ? (
                        jobDetail?.upcomingShifts?.map((res, i) => {
                          return (
                            <div className="col-md-12">
                              <div className="borderBox">
                                <div className="row">
                                  {item?.state?.user?.jobStatus !== 4 ||
                                    (item?.state?.user?.jobStatus !== 5 && (
                                      <div className="col-12">
                                        <div className="startBtn">
                                          <button
                                            onClick={() =>
                                              // applyForLeave(res?.shiftDate)
                                              setShiftDetails(res?.shiftDate)
                                            }
                                            className="leaveBtn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#applyLeave"
                                          >
                                            Apply Leave
                                          </button>
                                        </div>
                                      </div>
                                    ))}

                                  <div className="col-12">
                                    <div className="dateBox">
                                      <h6>Date</h6>
                                      <p>
                                        {moment
                                          .utc(res?.shiftDate)
                                          .format("DD/MM/YYYY")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="startBox">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <h6>Start Time</h6>
                                      <p>
                                        {moment
                                          .utc(res?.startTime)
                                          .format("hh:mm A")}{" "}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <h6>End Time</h6>
                                      <p>
                                        {moment
                                          .utc(res?.endTime)
                                          .format("hh:mm A")}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <h6>Rate</h6>
                                      <p>${res?.customerRate}/hr</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-md-12">
                          <div className="borderBox">
                            <div className="row"></div>
                            <div className="startBox">
                              <p>No Data</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {prev ? (
                    <>
                      {prev && jobDetail?.previoushifts?.length !== 0 ? (
                        jobDetail?.previoushifts?.map((res, i) => {
                          return (
                            <div className="col-md-12">
                              <div className="borderBox">
                                <div className="row">
                                  <div className="col-12">
                                    {/* <div className="startBtn">
                                      <button
                                        onClick={() => {
                                          //applyForLeave(res?.shiftDate)
                                          setShiftDetails(res?.shiftDate);
                                        }}
                                        className="leaveBtn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#applyLeave"
                                      >
                                        Apply Leave
                                      </button>
                                    </div> */}
                                  </div>
                                  <div className="col-12">
                                    <div className="dateBox">
                                      <h6>Date</h6>
                                      <p>
                                        {moment
                                          .utc(res?.shiftDate)
                                          .format("DD/MM/YYYY")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="startBox">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <h6>Start Time</h6>
                                      <p>
                                        {moment
                                          .utc(res?.startTime)
                                          .format("hh:mm A")}{" "}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <h6>End Time</h6>
                                      <p>
                                        {moment
                                          .utc(res?.endTime)
                                          .format("hh:mm A")}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <h6>Rate</h6>
                                      <p>${res?.customerRate}/hr</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-md-12">
                          <div className="borderBox">
                            <div className="row"></div>
                            <div className="startBox">
                              <p>No Data</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade applyLeave"
        id="applyLeave"
        tabIndex="-1"
        aria-labelledby="applyLeaveLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="applyLeaveLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <FaTimesCircle />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <select
                      className="form-select"
                      id="title"
                      aria-label="Default select example"
                      value={title}
                      onChange={(e) => {
                        selectedTitle(e.target.value);
                      }}
                    >
                      <option selected value="">
                        Select Leave Title
                      </option>
                      <option value="1">Sick</option>
                      <option value="2">Personal Emergency </option>
                      <option value="3">Other </option>
                    </select>
                  </div>
                  {title == 3 && (
                    <div className="form-group">
                      <textarea
                        className="form-control textArea"
                        rows="4"
                        id="description"
                        placeholder="Details"
                        onKeyUp={(e) => {
                          selectedDesc(e.target.value);
                        }}
                      >
                        {desc}
                      </textarea>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => applyForLeave(shiftDetails)}
                type="button"
                className="btn submitBtn"
                data-bs-dismiss={dataSaved ? "" : "modal"}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetail;
