import React, { useEffect } from "react";
import './career.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Dollarimg from '../../images/dollar.png';
import Balimg from '../../images/balance.png';
import Teamimg from '../../images/team.png';
import GrowthImg from '../../images/growth.png';
import PlaystoreIcon from '../../images/welcomePage/playStore.png';
import AppStoreIcon from '../../images/welcomePage/appStore.png';
import MobImg from '../../images/welcomePage/mobImg.png';
import WelcomeHeader from '../../Pages/Wel_Header/WelcomeHeader';
import WelcomeFooter from '../../Pages/Wel_Footer/WelcomeFooter';
import { FaStar,FaPaperclip } from "react-icons/fa";
import { Link } from 'react-router-dom';
import SubscribeSection from '../../shared/subscribe';
// import $ from 'jquery';

const Career = () => {
    useEffect(async () => {
        window.scroll(0, 0)
        // $('.owl-carousel').owlCarousel({
        //     navigation : true,
        //     loop: true,
        //     margin: 10,
        //     nav: true,
        //     dots: true,
        //     responsive: {
        //         0: {
        //             items: 1
        //         },
        //         600: {
        //             items: 1
        //         },
        //         1000: {
        //             items: 1
        //         }
        //     }
        // })
    }, []);
    return (
        <>
            <WelcomeHeader />
            <section className="career">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="careerBox">
                                <div className="row justify-content-center">
                                    <div className="col-md-11">
                                        <h1>Career @ GoGuard</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="lifeSec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <h5>Life At GoGuard</h5>
                            <h4>"Pleasure in the job puts perfection in the work.”</h4>
                            <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon. Muffin chocolate bar pastry chupa chups pie oat cake croissant. Dragée carrot cake chocolate bear claw caramels sesame snaps sweet chocolate bar. Jelly-o apple pie wafer tootsie roll dragée.Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon. Muffin chocolate bar pastry chupa chups pie oat cake croissant. Dragée carrot cake chocolate bear claw caramels sesame snaps sweet chocolate bar. Jelly-o apple pie wafer tootsie roll dragée.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why_joinUs">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h5>Why Join Us?</h5>
                        </div>
                        <div className="col-md-12">
                            <ul>
                                <li>
                                    <img src={Dollarimg} className="img-fluid" />
                                    <p>Industry Best Remuneration</p>
                                </li>
                                <li>
                                    <img src={Balimg} className="img-fluid" />
                                    <p>Perfect Work- Life Balance</p>
                                </li>
                                <li>
                                    <img src={Teamimg} className="img-fluid" />
                                    <p>World Class Working Atmosphere</p>
                                </li>
                                <li>
                                    <img src={GrowthImg} className="img-fluid" />
                                    <p>Career Growth Opportunities</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="opening">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h5>Openings</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="opening_box">
                                <h4>Security Guard For Shopping Mall</h4>
                                <span><FaStar /> Experience :  1 -2 yrs</span>
                                <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread
                                    ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon.
                                </p>
                                <a href="#">Apply</a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="opening_box">
                                <h4>Security Guard For Shopping Mall</h4>
                                <span><FaStar /> Experience :  1 -2 yrs</span>
                                <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread
                                    ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon.
                                </p>
                                <a href="#">Apply</a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="opening_box">
                                <h4>Security Guard For Shopping Mall</h4>
                                <span><FaStar /> Experience :  1 -2 yrs</span>
                                <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread
                                    ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon.
                                </p>
                                <a href="#">Apply</a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="opening_box">
                                <h4>Security Guard For Shopping Mall</h4>
                                <span><FaStar /> Experience :  1 -2 yrs</span>
                                <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread
                                    ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon.
                                </p>
                                <a href="#">Apply</a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="opening_box">
                                <h4>Security Guard For Shopping Mall</h4>
                                <span><FaStar /> Experience :  1 -2 yrs</span>
                                <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread
                                    ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon.
                                </p>
                                <a href="#">Apply</a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="opening_box">
                                <h4>Security Guard For Shopping Mall</h4>
                                <span><FaStar /> Experience :  1 -2 yrs</span>
                                <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread
                                    ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon.
                                </p>
                                <a href="#">Apply</a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="opening_box">
                                <h4>Security Guard For Shopping Mall</h4>
                                <span><FaStar /> Experience :  1 -2 yrs</span>
                                <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread
                                    ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon.
                                </p>
                                <a href="#">Apply</a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="opening_box">
                                <h4>Security Guard For Shopping Mall</h4>
                                <span><FaStar /> Experience :  1 -2 yrs</span>
                                <p>Chocolate caramels muffin brownie candy. Lemon drops biscuit dragée gingerbread
                                    ice cream tootsie roll sugar plum. Fruitcake halvah danish cake jelly beans lollipop. Tootsie roll chocolate cake wafer jelly-o bonbon.
                                </p>
                                <a href="#">Apply</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="apply_now">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h5>Apply Now</h5>
                        </div>
                    </div>
                    <div className="apply_form">
                        <div className="row justify-content-center">
                            <div className="col-md-5">
                                <div className="form-group">
                                    <input className="form-control" type="text" placeholder="Name" aria-label="default input example" />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <input className="form-control" type="email" placeholder="Email" aria-label="default input example" />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <input className="form-control" type="text" placeholder="Phone" aria-label="default input example" />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className='form-group'>
                                    <select class="form-select" aria-label="Default select example" defaultValue="Experiance">
                                    <option selected>Experiance</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className='form-group'>
                                    <select class="form-select" aria-label="Default select example" defaultValue="Speciality">
                                    <option selected>Speciality</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <input
                                        type="file"
                                        className="form-control"
                                        htmlFor="exampleInputtext"
                                        aria-describedby="textHelp"
                                        name="fileName"
                                        placeholder="Upload Identification"
                                    />
                                    <span>
                                        <FaPaperclip />
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div class="form-group">
                                    <textarea class="form-control textarea" placeholder="Other Details" id="floatingTextarea2" style={{height: '100px'}}></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="sub_det_btn">
                                    <a href="#">Submit Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SubscribeSection />
            <WelcomeFooter />
        </>
    )
}

export default Career;
