import React, { useEffect, useState } from "react";
import LogoIcon from "../Logo/Logo";
// import Google from "../../images/google.png";
import Cookies from "universal-cookie";
import { FaArrowRight, FaEnvelope } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import "./forgot-password.scss";
import { forgotPasswordAPI } from "../../utils/request";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailIsRequiredError, setEmailIsRequiredlError] = useState(false);
  const [emailValidError, setEmailValidError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [issuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [error, setError] = useState("");
  const [basicToken, setbasicToken] = useState(
    "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
  );

  const cookie = new Cookies();
  cookie.set("session", basicToken);
  const token = cookie.get("token");

  const navigate = useNavigate();

  // Function For Forget Password
  const handleSubmit = async (e) => {
    e.preventDefault();
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,3})*$/;
    if (email === "") {
      setEmailIsRequiredlError(true);
    }
    if (email && !emailIsRequiredError && !emailValidError) {
      if (regex.test(email) === false) {
        setEmailValidError(true);
        setEmailIsRequiredlError(false);
      }
      let requestData = {
        email: email,
      };
      setIsLoading(true);
      await forgotPasswordAPI(requestData)
        .then((response) => {
          setIsLoading(false);
          if (response.data.code !== 200) {
            setError(response.data.message);
            setIsError(true);
            setTimeout(() => {
              setError("");
              setIsError(false);
            }, 5000);
          } else {
            setIsError(false);
            setIsSuccess(true);
            toast.success(response.data.message);
            // setSuccessMsg(response.data.message);
            setTimeout(() => {
              setIsSuccess(false);
              setSuccessMsg("");
              navigate(`/guard/login`);
            }, 1000);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
          if (err.response) {
            setError(err.response.data.userMessage);
          }
          setTimeout(() => {
            setError("");
            setIsError(false);
          }, 5000);
        });
    }
  };
  // Email Validator Function
  const handleEmailChange = (value) => {
    setEmail(value);
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,3})*$/;
    // const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value === "") {
      setEmailValidError(false);
      setEmailIsRequiredlError(true);
    } else if (regex.test(value) === false) {
      setEmailValidError(true);
      setEmailIsRequiredlError(false);
    } else {
      setEmailValidError(false);
      setEmailIsRequiredlError(false);
    }
  };

  return (
    <>
      <Header_inner />
      <section className="forgotPasswordPage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="loginForm">
                <form>
                  <h4>Forgot Password</h4>
                  {iserror ? (
                    <div className="alert alert-danger">{error}</div>
                  ) : null}
                  {/* {issuccess ? (
                    <div className="alert alert-success">{successMsg}</div>
                  ) : null} */}
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      maxLength={180}
                      onChange={(e) => handleEmailChange(e.target.value)}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email Address"
                      required
                    />
                    <span>
                      <FaEnvelope />
                    </span>
                  </div>
                  {emailIsRequiredError && (
                    <div className="invalid-feedback">Email is required</div>
                  )}
                  {emailValidError && (
                    <div className="invalid-feedback">
                      Please enter valid email
                    </div>
                  )}
                  <div className="loginBtn">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={(e) => handleSubmit(e)}
                    >
                      {isLoading ? "Loding..." : "Submit"}{" "}
                      {isLoading ? "" : <FaArrowRight className="arrowRight" />}
                    </button>
                  </div>
                  <div className="notReg mt-2">
                    <p>
                      Back To Login{" "}
                      <Link to="/guard/login" className="signupBtn">
                        Click Here
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </form> */}
    </>
  );
};

export default ForgotPassword;
