import React, { useEffect, useState } from "react";
import { FaFacebookF, FaEye, FaEyeSlash } from "react-icons/fa";
import "./settings.scss";
import Navbar from "../../Pages/Header/Navbar";
// import Footer from "../../Pages/Footer/Footer";
import {
  changePassword,
  getStaticContent,
  updateNotificationType,
  loginWithFacebook,
  getGuardProfile,
} from "../../utils/request";
// import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { googleclientIdKey } from "../../utils/constants";

const Settings = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const oldPassword = cookie.get("password");
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [termDescription, setTermDescription] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPasswordIsRequiredError, setOldPasswordIsRequiredError] =
    useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordIsRequiredError, setNewPasswordIsRequiredError] =
    useState(false);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  let [newJobSms, setNewJobSms] = useState(false);
  let [newJobEmail, setNewJobEmail] = useState(false);
  let [newJobNotification, setNewJobNotification] = useState(false);

  let [chatSms, setChatSms] = useState(false);
  let [chatEmail, setChatEmail] = useState(false);
  let [chatNotification, setChatNotification] = useState(false);

  let [jobAwardSms, setJobAwardSms] = useState(false);
  let [jobAwardEmail, setJobAwardEmail] = useState(false);
  let [jobAwardNotification, setJobAwardNotification] = useState(false);

  let [jobCancelSms, setJobCancelSms] = useState(false);
  let [jobCancelEmail, setJobCancelEmail] = useState(false);
  let [jobCancelNotification, setJobCancelNotification] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [guard, setGuard] = useState("");
  const [isShowOldPassword, setIsShowOldPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowCnfPassword, setIsShowCnfPassword] = useState(false);
  let [notification, setNotification] = useState(false);

  useEffect(() => {
    if (token) {
      getPrivacyPolicy();
      getTermsAndCondition();
      getGuardProfilef();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
    // setToken(sessionStorage.getItem("token"))
  }, []);
  // Function for get profile photo
  const getGuardProfilef = async () => {
    const id = cookie.get("guardId");
    const response = await getGuardProfile(id);
    // //console.log("guard profile response", response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response?.data?.code === 200) {
      //   setGuardOtherInfo(response.data.data);
      setGuard(response?.data?.data?.guard);
      setChatSms(response?.data?.data?.guard?.chat?.isSMS);
      setChatEmail(response?.data?.data?.guard?.chat?.isEmail);
      setChatNotification(response?.data?.data?.guard?.chat?.isNotification);
      setNewJobSms(response?.data?.data?.guard?.newJob?.isSMS);
      setNewJobEmail(response?.data?.data?.guard?.newJob?.isEmail);
      setNewJobNotification(
        response?.data?.data?.guard?.newJob?.isNotification
      );
      setJobAwardSms(response?.data?.data?.guard?.jobAward?.isSMS);
      setJobAwardEmail(response?.data?.data?.guard?.jobAward?.isEmail);
      setJobAwardNotification(
        response?.data?.data?.guard?.jobAward?.isNotification
      );
      setJobCancelNotification(
        response?.data?.data?.guard?.jobCancel?.isNotification
      );
      setJobCancelEmail(response?.data?.data?.guard?.jobCancel?.isEmail);
      setJobCancelSms(response?.data?.data?.guard?.jobCancel?.isSMS);
      setNotification(response?.data?.data?.guard?.isNotification);
    }
  };
  // Function for get privacy policy
  const getPrivacyPolicy = async () => {
    const response = await getStaticContent({ type: "privacy" });
    if (response.data.code === 200) {
      setDescription(response.data.data.description);
    }
  };
  // Function for get T&C
  const getTermsAndCondition = async () => {
    const response = await getStaticContent({ type: "terms" });
    if (response.data.code === 200) {
      setTermDescription(response.data.data.description);
    }
  };
  // Function for handle Validation
  const handleOldPass = (value) => {
    if (oldPassword !== value) {
      setOldPasswordError(true);
    } else {
      setOldPasswordError(false);
    }
  };

  // Check the length of the input
  const checkPasswordLength = (password) => {
    //console.log("password=======", password);
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  };

  const passwordFunction = () => {
    if (numberValid && uppercaseValid && charNumberValid && specialCharValid) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const handleNewPass = (value) => {
    //console.log("passwordError=======", value);
    setPassword(value);
    if (value === "") {
      //   setPasswordIsRequiredError(true);
      setPasswordError(false);
      setNumberValid(true);
      setUppercaseValid(true);
      setSpecialCharValid(true);
      setCharNumberValid(true);
    } else {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/;
      const isValidPassword = passwordRegex.test(value);
      setPasswordError(!isValidPassword);
      setPasswordIsRequiredError(false);
    }
  };

  const handlePushNotification = (value) => {
    setNotification((notification = !notification));
  };
  // Functions for handle Notification Settings
  const handleNewJobNotification = (value) => {
    //console.log("handlePushNotification value", JSON.stringify(value));
    if (value === "newJobSms") {
      setNewJobSms((newJobSms = !newJobSms));
    } else if (value === "newJobEmail") {
      setNewJobEmail((newJobEmail = !newJobEmail));
    } else if (value === "newJobNotification") {
      setNewJobNotification((newJobNotification = !newJobNotification));
    } else {
      setNewJobSms(false);
      setNewJobEmail(false);
      setNewJobNotification(false);
    }
  };

  const handleChatNotification = (value) => {
    //console.log("handleChatNotification value", JSON.stringify(value));
    if (value === "chatSms") {
      setChatSms((chatSms = !chatSms));
    } else if (value === "chatEmail") {
      setChatEmail((chatEmail = !chatEmail));
    } else if (value === "chatNotification") {
      setChatNotification((chatNotification = !chatNotification));
    } else {
      setChatSms(false);
      setChatEmail(false);
      setChatNotification(false);
    }
  };

  const handleJobAwardNotification = (value) => {
    //console.log("handleJobAwardNotification value", JSON.stringify(value));
    if (value === "jobAwardSms") {
      setJobAwardSms((jobAwardSms = !jobAwardSms));
    } else if (value === "jobAwardEmail") {
      setJobAwardEmail((jobAwardEmail = !jobAwardEmail));
    } else if (value === "jobAwardNotification") {
      setJobAwardNotification((jobAwardNotification = !jobAwardNotification));
    } else {
      setJobAwardSms(false);
      setJobAwardEmail(false);
      setJobAwardNotification(false);
    }
  };

  const handleJobCancelNotification = (value) => {
    //console.log("handleJobCancelNotification value", JSON.stringify(value));
    if (value === "jobCancelSms") {
      setJobCancelSms((jobCancelSms = !jobCancelSms));
    } else if (value === "jobCancelEmail") {
      setJobCancelEmail((jobCancelEmail = !jobCancelEmail));
    } else if (value === "jobCancelNotification") {
      setJobCancelNotification(
        (jobCancelNotification = !jobCancelNotification)
      );
      //console.log(jobCancelNotification);
    } else {
      setJobCancelSms(false);
      setJobCancelEmail(false);
      setJobCancelNotification(false);
    }
  };
  // Function for update notification setting
  const handleUpdateNotification = async () => {
    const requestedData = {
      newJob: {
        isSMS: newJobSms,
        isEmail: newJobEmail,
        isNotification: newJobNotification,
      },
      chat: {
        isSMS: chatSms,
        isEmail: chatEmail,
        isNotification: chatNotification,
      },
      jobAward: {
        isSMS: jobAwardSms,
        isEmail: jobAwardEmail,
        isNotification: jobAwardNotification,
      },
      jobCancel: {
        isSMS: jobCancelSms,
        isEmail: jobCancelEmail,
        isNotification: jobCancelNotification,
      },
      isNotification: notification,
    };
    const response = await updateNotificationType(requestedData);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response?.data?.code === 200) {
      navigate(`/setting`);
      // window.location.href = "/setting";
      toast.success(response?.data?.message);
    }
    // //console.log("updateNotificationType", JSON.stringify(response));
  };
  // Function for handle update password
  const handlePassword = async () => {
    if (oldPassword === "" || password === "") {
      setOldPasswordError(true);
      setPasswordError(true);
      setConfirmPasswordError(true);
      return;
    }

    if (confirmPasswordError) {
      setConfirmPasswordError(true);
      return;
    }

    const requestedData = {
      oldPassword: oldPassword,
      password: password,
    };

    const response = await changePassword(requestedData);
    //console.log("handlePassword response", JSON.stringify(response));
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response?.data?.code === 200) {
      navigate(`/guard/login`);
      toast.success(response?.data?.message);
    } else {
      toast.error("Something Went wrong!");
    }
  };
  // Function for handle validation
  const handleConfirmPass = (value) => {
    if (password !== value) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPassword(value);
      setConfirmPasswordError(false);
    }
  };
  // Function for handle facebook login
  const responseFacebook = async (response) => {
    let requestData = {
      deviceToken: "web",
      deviceId: "web",
      deviceType: 3,
      email: response.email,
      socialId: response.id,
      socialType: 1,
    };
    await loginWithFacebook(requestData)
      .then((apiResponse) => {
        if (apiResponse.data.code !== 200) {
          setError(apiResponse.data.message);
          setIsError(true);
          setTimeout(() => {
            setError("");
            setIsError(false);
          }, 5000);
        } else {
          setIsError(false);
          const { guard, tokens } = response.data.data;
          const cookie = new Cookies();
          if (tokens && tokens.refresh && tokens.refresh.token) {
            cookie.set("token", tokens.refresh.token);
            cookie.set("session", tokens.refresh.token);
          }
          cookie.set("guardId", guard.id);
          if (guard && guard.isPrivacyAgreement === false) {
            navigate(`/privacy/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse === false
          ) {
            navigate(`/termsUse/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isIndependentContractor === false
          ) {
            navigate(`/guard-agreement/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse &&
            guard.isVerifyOtp === false
          ) {
            navigate(`/guard/otp/${guard.id}`);
          } else if (
            guard &&
            guard.identityDocuments &&
            guard.identityDocuments.length === 0
          ) {
            navigate(`/guard/registration/${guard.id}`);
          } else {
            navigate(`/thankYou?isLicense=` + "full");
          }
          if (
            guard &&
            guard.isVerifyOtp &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse &&
            guard.identityDocuments.length !== 0
          ) {
            navigate(`/thankYou?isLicense=` + "full");
          }
        }
      })
      .catch((err) => {
        setIsError(true);
        if (err.response) {
          setError(err.response.data.userMessage);
        }
        setTimeout(() => {
          setError("");
          setIsError(false);
        }, 5000);
      });
  };

  const socialLogin = async (requestData) => {
    // // e.preventDefault();
    // let requestData = {
    //   deviceToken: "web",
    //   deviceId: "web",
    //   deviceType: 3,
    //   email: email,
    //   socialId: socialId,
    //   socialType: 1,
    // };
    // //console.log(requestData);
    await loginWithFacebook(requestData)
      .then((response) => {
        //console.log(response);
        if (response.data.code === 200) {
          setIsError(false);
          // const { guard, tokens } = response.data.data;
          // const cookie = new Cookies();
          // cookie.set("token", tokens.refresh.token);

          // cookie.set("guardId", guard.id);
          // // cookie.set("session", guard);
          const { guard, tokens } = response.data.data;
          const cookie = new Cookies();
          cookie.set("token", response?.data?.data?.tokens?.refresh?.token);
          cookie.set("session", response?.data?.data?.tokens?.refresh?.token);
          navigate(`/guard/myprofile/${guard.id}`);
          // if (tokens && tokens.refresh && tokens.refresh.token) {
          //   cookie.set("token", tokens.refresh.token);
          //   cookie.set("session", tokens.refresh.token);
          // }
          cookie.set("guardId", guard.id);
          cookie.set("guardInfo", guard);
          if (guard && guard.isPrivacyAgreement === false) {
            navigate(`/privacy/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse === false
          ) {
            navigate(`/termsUse/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isIndependentContractor === false
          ) {
            navigate(`/guard-agreement/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse &&
            guard.isVerifyOtp === false
          ) {
            navigate(`/guard/otp/${guard.id}`);
          } else if (
            guard &&
            guard.identityDocuments &&
            guard.identityDocuments.length === 0
          ) {
            navigate(`/guard/registration/${guard.id}`);
          } else {
            navigate(`/thankYou?isLicense=` + "full");
          }
          if (
            guard &&
            guard.isVerifyOtp &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse &&
            guard.identityDocuments.length !== 0
          ) {
            //console.log("i am called");
            navigate(`/guard/myprofile/${guard.id}`);
          }
        } else {
          setError(response.data.message);
          setIsError(true);
          setTimeout(() => {
            setError("");
            setIsError(false);
          }, 5000);
        }
      })
      .catch((err) => {
        setIsError(true);
        if (err.response) {
          setError(err.response.data.userMessage);
        }
        setTimeout(() => {
          setError("");
          setIsError(false);
        }, 5000);
      });
  };
  // Function for handle google login
  const responseGoogle = (response) => {
    //console.log(response);
    let requestData = {
      deviceToken: "web",
      deviceId: "web",
      deviceType: 3,
      email: response?.profileObj?.email,
      socialId: response?.profileObj?.googleId,
      socialType: 2,
    };
    //console.log(requestData);
    socialLogin(requestData);
    // if (response?.profileObj?.googleId && response?.profileObj.email) {
    //   // setSocialId(response.profileObj.googleId);
    //   socialId = response.profileObj.googleId;
    //   setEmail(response.profileObj.email);
    // }
  };
  // Functions for handle validation
  const handleOldPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowOldPassword(true);
    } else {
      setIsShowOldPassword(false);
    }
  };

  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };

  const handleShowCnfPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowCnfPassword(true);
    } else {
      setIsShowCnfPassword(false);
    }
  };

  return (
    <>
      <Navbar />
      <section className="settingBox">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="row justify-content-center">
                <div className="col-md-5 col-lg-4">
                  {/* <div className='alertOuter'>
                                        <h6>Connect Social Media</h6>
                                        <div className='social_btn'>
                                            <a href="#" className='faceBtn'><FaFacebookF />Connect With Facebook</a>
                                            <a href="#" className='googleBtn'><FaGoogle />Connect With Google</a>
                                        </div>
                                    </div> */}
                  <div className="alertOuter">
                    <div className="social_login">
                      <h6>LogIn with Social Media</h6>
                      <div className="fbBtn">
                        <FacebookLogin
                          className="facebookButton"
                          cssClass="button"
                          isDisabled={guard?.socialFacebookId !== ""}
                          textButton={
                            guard?.socialFacebookId !== ""
                              ? "Connected"
                              : "Login With Facebook"
                          }
                          appId="705391504029824" //APP ID NOT CREATED YET
                          fields="name,email,picture"
                          icon={<FaFacebookF className="icon-fb" />}
                          callback={responseFacebook}
                        />
                        {/* <button type="button">
                            {" "}
                            <span>
                              <FaFacebookF />
                            </span>
                            Login With Facebook
                          </button> */}
                      </div>
                      <div className="googleBtn">
                        {/* <button type="button" onClick={(e) => socialLogin(e)}>
                            {" "}
                            <span>
                              <img src={Google} className="img-fluid" />
                            </span>{" "}
                            LogIn With Google
                          </button> */}
                        <GoogleLogin
                          className="googleButton"
                          clientId={googleclientIdKey} //CLIENTID NOT CREATED YET
                          isDisabled={guard?.socialGmailId !== ""}
                          buttonText={
                            guard?.socialGmailId !== ""
                              ? "Connected"
                              : "Login With Google"
                          }
                          // textButton={
                          //   guard?.socialGmailId !== ""
                          //     ? "Connected"
                          //     : "Login With Google"
                          // }
                          cssClass="button"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="alertOuter mt-3">
                    <h6>Change Password</h6>
                    <div className="form-group">
                      <input
                        onChange={(event) => handleOldPass(event.target.value)}
                        type={!isShowOldPassword ? "password" : "text"}
                        className="form-control"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="Old Password"
                      />
                      <span>
                        {!isShowOldPassword ? (
                          <FaEye
                            onClick={(e) => handleOldPassword(e, "show")}
                          />
                        ) : (
                          <FaEyeSlash
                            onClick={(e) => handleOldPassword(e, "hide")}
                          />
                        )}
                      </span>
                    </div>
                    {oldPasswordError ? (
                      <div className="invalid-feedback">
                        Password not match to your current password
                      </div>
                    ) : (
                      ""
                    )}
                    {oldPasswordIsRequiredError ? (
                      <div className="invalid-feedback">
                        Password is required
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <input
                        onChange={(event) => handleNewPass(event.target.value)}
                        type={!isShowPassword ? "password" : "text"}
                        className="form-control"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="New Password"
                      />
                      <span>
                        {!isShowPassword ? (
                          <FaEye
                            onClick={(e) => handleShowPassword(e, "show")}
                          />
                        ) : (
                          <FaEyeSlash
                            onClick={(e) => handleShowPassword(e, "hide")}
                          />
                        )}
                      </span>
                    </div>
                    {passwordError ? (
                      <div className="invalid-feedback">
                        Password at least 8 characters in length.
                        <br />
                        Lowercase letters (a-z)
                        <br />
                        Uppercase letters (A-Z)
                        <br />
                        Numbers (0-9)
                        <br />
                        Special characters ($@$!%*?&) <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {passwordIsRequiredError ? (
                      <div className="invalid-feedback">
                        Password is required
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <input
                        onChange={(event) =>
                          handleConfirmPass(event.target.value)
                        }
                        type={!isShowCnfPassword ? "password" : "text"}
                        className="form-control"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="Confirm Password"
                      />
                      <span>
                        {!isShowCnfPassword ? (
                          <FaEye
                            onClick={(e) => handleShowCnfPassword(e, "show")}
                          />
                        ) : (
                          <FaEyeSlash
                            onClick={(e) => handleShowCnfPassword(e, "hide")}
                          />
                        )}
                      </span>
                    </div>
                    {confirmPasswordError ? (
                      <div className="invalid-feedback">Password mismatch</div>
                    ) : (
                      ""
                    )}
                    {confirmPasswordIsRequiredError ? (
                      <div className="invalid-feedback">
                        Password is required
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="submitBtn">
                      <a type="button" onClick={handlePassword}>
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-5">
                  <div className="notiFi">
                    <h6>Push Notifications</h6>
                    <div className="toggle-button-cover">
                      <div className="button-cover">
                        <div className="button r" id="button-1">
                          <input
                            type="checkbox"
                            checked={notification === true}
                            onChange={(e) =>
                              handlePushNotification(e.target.value)
                            }
                            value="notification"
                            className="checkbox"
                          />
                          <div className="knobs"></div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="alertOuter mt-3">
                    <h6>Notification Alert Type</h6>
                    <div className="alertInner">
                      <div className="alertBox">
                        <div className="row mb-3">
                          <div className="col-4 col-md-3"></div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <p>SMS</p>
                              </div>
                              <div className="col-3 col-md-3">
                                <p>Email</p>
                              </div>
                              <div className="col-6 col-md-6">
                                <p>Notification</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4 col-md-3">
                            <h6>New Job</h6>
                          </div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={newJobSms === true}
                                    onChange={(e) =>
                                      handleNewJobNotification(e.target.value)
                                    }
                                    type="checkbox"
                                    value="newJobSms"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={newJobEmail === true}
                                    onChange={(e) =>
                                      handleNewJobNotification(e.target.value)
                                    }
                                    type="checkbox"
                                    value="newJobEmail"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={newJobNotification === true}
                                    onChange={(e) =>
                                      handleNewJobNotification(e.target.value)
                                    }
                                    type="checkbox"
                                    value="newJobNotification"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4 col-md-3">
                            <h6>Chat</h6>
                          </div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    checked={chatSms === true}
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleChatNotification(e.target.value)
                                    }
                                    value="chatSms"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    checked={chatEmail === true}
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleChatNotification(e.target.value)
                                    }
                                    value="chatEmail"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={chatNotification === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleChatNotification(e.target.value)
                                    }
                                    value="chatNotification"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4 col-md-3">
                            <h6>Job Award</h6>
                          </div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobAwardSms === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobAwardNotification(e.target.value)
                                    }
                                    value="jobAwardSms"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobAwardEmail === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobAwardNotification(e.target.value)
                                    }
                                    value="jobAwardEmail"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobAwardNotification === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobAwardNotification(e.target.value)
                                    }
                                    value="jobAwardNotification"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4 col-md-3">
                            <h6>Job Cancel</h6>
                          </div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobCancelSms === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobCancelNotification(
                                        e.target.value
                                      )
                                    }
                                    value="jobCancelSms"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobCancelEmail === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobCancelNotification(
                                        e.target.value
                                      )
                                    }
                                    value="jobCancelEmail"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobCancelNotification === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobCancelNotification(
                                        e.target.value
                                      )
                                    }
                                    value="jobCancelNotification"
                                    id="jobCancelNotification"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="submitBtn">
                              <a
                                type="button"
                                onClick={() => handleUpdateNotification()}
                              >
                                Submit
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Settings;
