import React from "react";
import './our_speciality.scss';
import OntarioImg from '../../images/Speciality/VIP.jpg';
import CusImg1 from '../../images/how_work/client-1.png';
import CusImg2 from '../../images/how_work/client-2.png';
import CusImg3 from '../../images/how_work/client-3.png';
import CusImg4 from '../../images/how_work/client-4.png';
import { FaCaretRight } from "react-icons/fa";
import { useParams } from "react-router-dom";
import ReachUSForm from '../../shared/reachUs';
import GuardsignupSection from "../../shared/guardSignup";
import { Link } from "react-router-dom";
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';

const VIP = () => {
    const { type } = useParams();
    window.scroll(0, 0)
    return (
        <>
            <section className="banner-speciality vip">
                <div className="container">
                    <div className='workBox'>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 text-center">
                                <div className="work">
                                    <h1>VIP Security</h1>
									<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
										<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
										<Breadcrumb.Item active>VIP</Breadcrumb.Item>
									</Breadcrumb>
                                </div>
                            </div>
		{/*<ReachUSForm pageType="Our Specialities" />*/}
                        </div>
                    </div>
                </div>
            </section>
            <section className="airpoSec">
                <div className="workGuard">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                    <h2>Hire <strong>VIP Security Guards</strong></h2>
                                    <p>Meet the VIP protection security guards of the future. Empowered with technology, VIP protection professionals can perform better with great client experiences. No matter what kind of space it is, our professionals will ensure that it is not only secure but the VIP also don’t feel the intrusion of security.</p>
                                    <p>With live tracking of Guards, group chat and several other enhanced technology enabled features GoGuard gives you VIP protection like never before. Why get into the hassles of hiring VIP protection security guards when you can get GoGuard and leave all the worries to us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="specialitySec text-center">
                <div className="workGuard">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <div className="content">
                                    <h2>Why hire a <strong>GoGuard for VIP close protection?</strong></h2>
                                    <p>GoGuards allows you the freedom like never before</p>
                                    <ul class="why-hire d-flex mt-5 flex-wrap justify-content-center">
                                        <li><p><FaCaretRight /> Live Chat with Guards for quick updates</p></li>
                                        <li><p><FaCaretRight /> Immediate guard availability within hours or sooner</p></li>
                                        <li><p><FaCaretRight /> Online Tracking, shift monitoring</p></li>
                                        <li><p><FaCaretRight /> Experience tailored to meet your demands.</p></li>
                                        <li><p><FaCaretRight /> E-Notes on mobile app for your team to track guard activities.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="other-services py-6">
		<div class="container">
		<div class="row align-items-center">
		<div class="col-lg-5 pe-lg-5">
		<h2>Discover our <strong>Other Security Services</strong></h2>
		<p>GoGuard, provides security solutions across multiple fields and sectors. Whether looking to protect your commercial or residential interest, with Go Guard you can do so at budget prices.</p>
		</div>
		<div class="col-lg-7">
		<ul class="list-unstyled mb-0 d-flex flex-wrap">
		<li><Link to="/our-speciality/car-lots">Car Lots</Link></li>
		<li><Link to="/our-speciality/concierge">Concierges</Link></li>
		<li><Link to="/our-speciality/loss-prevention">Loss Prevention</Link></li>
		<li><Link to="/our-speciality/protests">Protests</Link></li>
		<li><Link to="/our-speciality/access-control">Access Control</Link></li>
		<li><Link to="/our-speciality/events">Events</Link></li>
		<li><Link to="/our-speciality/residential">Residential</Link></li>
		<li><Link to="/our-speciality/construction">Construction</Link></li>
		<li><Link to="/our-speciality/bank">Banks</Link></li>
		</ul>
		</div>
		</div>
		</div>
		</section>
		<GuardsignupSection />
		{/*<section className="needAssistance">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="content">
                                <p>Need assistance in Setting up your GoGuard Account, our Customer Success Expert will set it up for you.</p>
                                <a href="#">Get In Touch Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}
        </>
    )
}

export default VIP;
