import React, { useState } from "react";
import { useParams } from "react-router";
import "../Component/Training/training.scss";
import { reachUsAPI } from "../utils/reachUsRequest";
import { toast } from "react-toastify";

const ReachUSForm = (props) => {
  let { type } = useParams();
  //console.log("props====", props, type);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [query, setQuery] = useState("");
  const [nameError, setNameError] = useState(false);
  const [isNameBlankError, setIsNameBlankError] = useState(false);
  const [isEmailBlankError, setIsEmailBlankError] = useState(false);
  const [isPhoneBlankError, setIsPhoneBlankError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [queryError, setQueryError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Functions for handle validation
  const handleName = (value) => {
    if (value === "") {
      setIsNameBlankError(true);
      setEmailError(false);
      return false;
    }
    setName(value);
    //console.log(name);
    const regex = /^[a-zA-Z-. ]+$/;
    if (regex.test(value) === false) {
      setNameError(true);
      setIsNameBlankError(false);
      return true;
    } else {
      setNameError(false);
      setIsNameBlankError(false);
      return true;
    }
  };
  const handleEmailChange = (value) => {
    if (value === "") {
      setIsEmailBlankError(true);
      setEmailError(false);
      return false;
    }
    setEmail(value);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    // const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (regex.test(value) === false) {
      setEmailError(true);
      setIsEmailBlankError(false);
      return true;
    } else {
      setEmailError(false);
      setIsEmailBlankError(false);
      return true;
    }
  };
  const handleMobileChange = (value) => {
    if (value === "") {
      setIsPhoneBlankError(true);
      setEmailError(false);
      return false;
    }
    setPhone(value);
    const regex = /^[0-9]*$/;
    if (regex.test(value) === false) {
      setPhoneError(true);
      setIsPhoneBlankError(false);
      return false;
    } else {
      setPhoneError(false);
      setIsPhoneBlankError(false);
      return true;
    }
  };
  const handleQueryChange = (value) => {
    setQuery(value);
    if (value === "") {
      setQueryError(true);
      return false;
    } else {
      setQueryError(false);
      return true;
    }
  };
  // Function for send reach us data to server
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      handleQueryChange(query) ||
      handleMobileChange(phone) ||
      handleEmailChange(email) ||
      handleName(name)
    ) {
      var pageT = "";
      if (type) {
        pageT = type.replace(/\-/g, " ");
      } else {
        pageT = props.pageType;
      }
      var reqObj = {
        name: name,
        email: email,
        phone: phone,
        query: query,
        pageId: pageT.toLocaleUpperCase(),
      };
      setIsLoading(true);
      await reachUsAPI(reqObj).then((response) => {
        setIsLoading(false);
        if (response.data.code === 200) {
          document.getElementById("name").value = "";
          setName("");
          document.getElementById("email").value = "";
          setEmail("");
          document.getElementById("phone").value = "";
          setPhone("");
          document.getElementById("message").value = "";
          setQuery("");
          toast.success(response.data.message, "green");
        } else {
          setIsLoading(false);
          toast.error(response.data.message, "red");
        }
      });
    }
  };
  return (
    <>
      <div class="col-md-6 col-lg-5 order-1 order-md-2 mb-5 mb-md-0">
        <div class="form-bg training-form">
          <form>
            <h3>Reach Us</h3>
            <div class="mb-3 form-name input-icon">
              <input
                className={
                  nameError ? "form-control is-invalid" : "form-control"
                }
                type="text"
                // value={name}
                id="name"
                // defaultValue=""
                placeholder="Name"
                aria-label="default input example"
                onChangeCapture={(e) => handleName(e.target.value)}
                required
              />
              {nameError && (
                <div className="invalid-feedback">Please enter valid name</div>
              )}
              {isNameBlankError && (
                <div className="invalid-feedback mt-1">Name is required</div>
              )}
            </div>
            <div class="mb-3 form-mail input-icon">
              <input
                className={
                  emailError ? "form-control is-invalid" : "form-control"
                }
                type="email"
                id="email"
                // value={email}
                placeholder="Email"
                aria-label="default input example"
                onChange={(e) => handleEmailChange(e.target.value)}
                required
              />
              {emailError && (
                <div className="invalid-feedback">Please enter valid email</div>
              )}
              {isEmailBlankError && (
                <div className="invalid-feedback mt-1">Email is required</div>
              )}
            </div>
            <div class="mb-3 form-tel input-icon">
              <input
                className={
                  phoneError ? "form-control is-invalid" : "form-control"
                }
                type="text"
                placeholder="Phone"
                id="phone"
                maxLength={10}
                // value={phone}
                aria-label="default input example"
                onChange={(e) => handleMobileChange(e.target.value)}
                required
              />
              {phoneError && !isPhoneBlankError && (
                <div className="invalid-feedback">
                  Please enter valid phone number
                </div>
              )}
              {isPhoneBlankError && (
                <div className="invalid-feedback">Phone number is required</div>
              )}
            </div>
            <div class="mb-3 form-message input-icon">
              <textarea
                className="form-control textArea"
                rows="3"
                placeholder="Message"
                id="message"
                // value={query}
                onChange={(e) => handleQueryChange(e.target.value)}
              ></textarea>
              {queryError && (
                <div className="invalid-feedback mt-1">
                  Please enter your message
                </div>
              )}
            </div>
            <div className="submitBtn">
              <a
                type="button"
                className="btn btn-black w-100"
                onClick={(e) =>
                  nameError ||
                  isNameBlankError ||
                  emailError ||
                  isEmailBlankError ||
                  phoneError ||
                  isPhoneBlankError ||
                  queryError ||
                  isLoading
                    ? ""
                    : handleSubmit(e)
                }
              >
                {isLoading ? "Please Wait..." : "Submit"}
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReachUSForm;
