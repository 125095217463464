import React, { useEffect, useState } from "react";
import Navbar from "./Pages/Header/Navbar";
// import Footer from './Pages/Footer/Footer';
import AddDocu from "./Component/Add Document/AddDocu";
import HomePage from "./Component/LandingPage/HomePage";
import Login from "./Component/Login/Login";
import ForgotPassword from "./Component/forgot-password/forgot-password";
import ResetPassword from "./Component/reset-password/reset-password";
import Signup from "./Component/Signup/Signup";
import License from "./Component/License/License";
import AgreeProceed from "./Component/Agree/Agree";
import Registration from "./Component/Registration/Registration";
import SecurityLicense from "./Component/SecurityLicense/SecurityLicense";
import AddIdentification from "./Component/AddIdentification/AddIdentification";
import Review from "./Component/Review/Review";
import MyDocument from "./Component/MyDocument/MyDocument";
import WorkDistance from "./Component/WorkDistance/WorkDistance";
import OTP from "./Component/Otp/Otp";
import ProfilePic from "./Component/ProfilePic/ProfilePic";
import PreEmployee from "./Component/PreEmployee/PreEmployee";
import WorkProfile from "./Component/WorkProfile/WorkPro";
import WorkProfileInner from "./Component/Work_Profile_Inner/WorkInner";
import UserProfile from "./Component/UserProfile/Profile";
import MyProfile from "./Component/MyProfile/MyProfile";
import UpdateDocument from "./Component/Update Documents/updateDocuments";
import MyAccount from "./Component/MyAccount/MyAccount";
import MyRating from "./Component/MyRating/Rating";
// import RateDetails from "./Component/RatingDetail/RateDetail";
import AllJob from "./Component/AllJob/AllJob";
import MyEarning from "./Component/MyEarning/MyEarning";
import JobRange from "./Component/JobRange/JobRange";
import JobPage from "./Component/JobPage/JobPage";
import JobDetail from "./Component/JobDetail/JobDetail";
import ENote from "./Component/ENotes/ENote";
import Withdraw from "./Component/Withdraw/Withdraw";
import JobHistory from "./Component/JobHistory/JobHistory";
import ENotes from "./Component/ENote/ENote";
import TaskReview from "./Component/TaskReview/TaskReview";
import Chat from "./Component/chats/chat";
import Settings from "./Component/Settings/Settings";
import Support from "./Component/SupportNeed/Support";
import ReportIssue from "./Component/ReportIssue/ReportIssue";
import PreviousTicket from "./Component/PreviousTicket/PreviousTicket";
import TicketDetail from "./Component/TicketDetail/TicketDetail";
import ReportInjury from "./Component/ReportInjury/ReportInjury";
import Notification from "./Component/Notification/Notification";
import PreviousInjury from "./Component/PreviousInjury/PreviousInjury";
import InjuryDetail from "./Component/InjuryDetail/InjuryDetail";
import OnJob from "./Component/OnJob/OnJob";
import Privacy from "./Component/Privacy/Privacy";
import TermsUse from "./Component/TermsUse/TermsUse";
import GuardContractorAgreement from "./Component/guardAgreement/guardAgreement";
import JobRating from "./Component/JobRating/JobRating";
import JobPage2 from "./Component/JobPage2/JobPage2";
import WelcomePage from "./Component/WelcomePage/WelcomePage";
import ThankYou from "./Component/ThankYou/thankYou";
// import WelcomeHeader from './Pages/Wel_Header/WelcomeHeader';
// import WelcomeFooter from './Pages/Wel_Footer/WelcomeFooter';
import HowWork from "./Component/How_it_work/HowWork";
import Faq from "./Component/Faq/Faq";
import AboutUs from "./Component/aboutUs/aboutUs";
import Servicespage from "./Component/Services/services";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./Component/termsAndCondition/termsAndCondition";
import OurSpec from "./Component/OurSpeciality/OurSpec";
import ContactUs from "./Component/Contact/Contact";
import Ontario from "./Component/Ontario/Ontario";
import Training from "./Component/Training/Training";
import JobDetail2 from "./Component/JobDetail2/JobDetail2";
import ContractAgreement from "./Component/ContractAgree/ContractAgree";
import EmplDetails from "./Component/Employee_Details/Empl_Details";
import Header_inner from "./Pages/Sec_Header/Header_inner";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Page404 from "./Component/ErrorPage/Page404";
import Career from "./Component/Career/Career";
import "./App.css";
import { fetchToken, onMessageListener } from "./firebase";
import { toast } from "react-toastify";
function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setShow(true);
      //console.log(payload);
      toast.success(payload?.notification?.body);
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <>				
      <Router>
        {/* <Navbar />  */}
        {/* <WelcomeHeader /> */}
        <Routes>
          <Route exact path="/" element={<WelcomePage />} />
          <Route exact path="/select-navigation/:name" element={<HomePage />} />
          <Route path="/guard/registration/:id" element={<Registration />} />
          <Route path="/guard/login" element={<Login />} />
          <Route path="/guard/forgot-password" element={<ForgotPassword />} />
          <Route path="/guard/reset-password" element={<ResetPassword />} />
          <Route path="/guard/myprofile/:id" element={<MyProfile />} />
          <Route path="/guard/signup" element={<Signup />} />
          <Route path="/guard/documents" element={<MyDocument />} />
          <Route path="/guard/documents/:id" element={<UpdateDocument />} />
          <Route path="/privacy/:id" element={<Privacy />} />
          <Route path="/termsUse/:id" element={<TermsUse />} />
          <Route path="/guard-agreement/:id" element={<GuardContractorAgreement />} />
          <Route path="/guard/otp/:id" element={<OTP />} />
          <Route path="/guard/myAccount" element={<MyAccount />} />
          <Route path="/guard/editProfile" element={<ProfilePic />} />
          <Route path="/myRating" element={<MyRating />} />
          <Route path="/job-history" element={<JobHistory />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/search-job" element={<JobRange />} />
          <Route path="/all-job" element={<AllJob />} />
          <Route path="/review/:id" element={<Review />} />
          <Route path="/work-profile/:id" element={<UserProfile />} />
          <Route path="/myEarning" element={<MyEarning />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/report-injury" element={<ReportInjury />} />
          <Route path="/setting" element={<Settings />} />
          <Route path="/support" element={<Support />} />
          <Route path="/addDocs" element={<AddDocu />} />
          <Route path="/jobDetail" element={<JobDetail />} />
          <Route path="/eNote" element={<ENote />} />
          <Route path="/onJob" element={<OnJob />} />
          <Route path="/previousTicket" element={<PreviousTicket />} />
          <Route path="/reportIssue" element={<ReportIssue />} />
          <Route path="/ticketDetail/:id" element={<TicketDetail />} />
          <Route path="/previousInjury" element={<PreviousInjury />} />
          <Route path="/injuryDetail/:id" element={<InjuryDetail />} />
          <Route path="/jobRating" element={<JobRating />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/jobPage" element={<JobPage />} />
          <Route path="/eNotes" element={<ENotes />} />
          <Route path="/jobPage2" element={<JobPage2 />} />
          <Route path="/taskReview" element={<TaskReview />} />
          <Route path="/jobDetail2" element={<JobDetail2 />} />
          {/* <Route path="/preEmployee" element={<PreEmployee />} /> */}
          {/* <Route path="/addIdentity" element={<AddIdentification />} /> */}
          {/* <Route path="/agreeProceed" element={<AgreeProceed />} /> */}
          {/* <Route path="/workProfile" element={<WorkProfile />} /> */}
          {/* <Route path="/workProInner" element={<WorkProfileInner />} /> */}
          {/* <Route path="/rateDetails" element={<RateDetails />} /> */}
          {/* <Route path="/workDistance" element={<WorkDistance />} /> */}
          {/* PUBLIC ROUTES */}

          <Route path="/how-it-work" element={<HowWork />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/about-us" element={<AboutUs />} />
		<Route path="/services" element={<Servicespage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/thankYou" element={<ThankYou />} />
          <Route path="/our-speciality/:type" element={<OurSpec />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/ontario" element={<Ontario />} />
          <Route path="/training" element={<Training />} />
          <Route path="/contract_agreement" element={<ContractAgreement />} />
          {/* <Route path="/inner_header" element={<Header_inner />} /> */}
          <Route path="/career" element={<Career />} />
          <Route path="/emp_details/:id" element={<EmplDetails />} />
          <Route path="/404" element={<Page404 />} />
          {/* <Route path="*" element={<Navigate to="/404" />} /> */}
          <Route path="*" element={<Page404 />} />
        </Routes>
        {/* <Footer /> */}
        {/* <WelcomeFooter /> */}
      </Router>
    </>
  );
}

export default App;
