import React, { useEffect, useState } from "react";
import Navbar from "../../Pages/Header/Navbar";
import { FaCaretDown } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./my_account.scss";
import Cookies from "universal-cookie";
import { updateMyAccount } from "../../utils/request";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const MyAccount = () => {
  const cookie = new Cookies();
  const guardId = cookie.get("guardId");
  const token = cookie.get("token");
  const navigate = useNavigate();
  const profileData = useLocation();

  const [paypalId, setPaypalId] = useState(profileData.state.guard?.paypalId);
  const [paypalIdError, setPaypalIdError] = useState(false);
  const [paypalIdValidError, setPaypalIdValidError] = useState(false);
  const [bankTransitNumber, setBankTransitNumber] = useState(
    profileData.state.guard?.bankTransitNumber
  );
  const [bankTransitNumberError, setBankTransitNumberError] = useState(false);
  const [bankTransitNumberValidError, setBankTransitNumberValidError] =
    useState(false);
  const [bankTransitNumErr, setBankTransitNumErr] = useState(false);

  const [accHolderName, setAccHolderName] = useState(
    profileData.state.guard?.accHolderName
  );
  const [accHolderNameError, setAccHolderNameError] = useState(false);
  const [accHolderNameValidError, setAccHolderNameValidError] = useState(false);
  const [maxAccHolderErr, setmaxAccHolderErr] = useState(false);

  const [bankInstitution, setBankInstitution] = useState(
    profileData.state.guard?.bankInstitution
  );
  const [bankInstitutionError, setBankInstitutionError] = useState(false);
  const [bankInstitutionValidError, setBankInstitutionValidError] =
    useState(false);
  const [maxBankInstErr, setMaxBankInstErr] = useState(false);
  const [bankAccountNumber, setBankAccountNumber] = useState(
    profileData.state.guard?.bankAccountNumber
  );
  const [bankAccountNumberError, setBankAccountNumberError] = useState(false);
  const [bankAccountNumberValidError, setBankAccountNumberValidError] =
    useState(false);
  const [maxAccNumberErr, setMaxAccNumberErr] = useState(false);
  // const [frequency, setFrequency] = useState("");
  // const [frequencyError, setFrequencyError] = useState(false);
  const [paidThrough, setPaidThrough] = useState(
    profileData?.state?.guard?.wantTopay === 1 ? "1" : "2"
  );
  const [paidThroughError, setPaidThroughError] = useState(false);
  const [submitEnable, setSubmitEnable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Validation Functions
  const handleBankTransitNumber = (value) => {
    if (value === "") {
      setBankTransitNumberError(true);
      setBankTransitNumberValidError(false);
    }
    if (value.length < 6) {
      setBankTransitNumber(value);
    }

    if (value.length < 5 || value.length > 5) {
      if (value.length > 5) {
        setBankTransitNumberValidError(false);
        setBankTransitNumErr(true);
      } else {
        setBankTransitNumberValidError(true);
        setBankTransitNumberError(false);
      }
    } else {
      setBankTransitNumberValidError(false);
      setBankTransitNumberError(false);
    }
  };

  const handleAccHolderName = (value) => {
    if (value === "") {
      setAccHolderNameError(true);
      setAccHolderNameValidError(false);
    }
    if (value.length < 16) {
      setAccHolderName(value);
    }

    if (value.length < 3 || value.length > 15) {
      if (value.length > 15) {
        setAccHolderNameValidError(false);

        setmaxAccHolderErr(true);
      } else {
        setAccHolderNameValidError(true);
        setAccHolderNameError(false);
      }
    } else {
      setAccHolderNameValidError(false);
      setAccHolderNameError(false);
    }
  };

  const handleBankInstitution = (value) => {
    // setBankInstitution(value);
    if (value === "") {
      setBankInstitutionError(true);
      setBankInstitutionValidError(false);
    }
    if (value.length < 4) {
      setBankInstitution(value);
    }

    if (value?.length < 3 || value?.length > 3) {
      if (value.length > 3) {
        setBankInstitutionValidError(false);

        setMaxBankInstErr(true);
      } else {
        setBankInstitutionValidError(true);
        setBankInstitutionError(false);
      }
    } else {
      setBankInstitutionValidError(false);
      setBankInstitutionError(false);
    }
  };
  const handleBankAccountNumber = (value) => {
    // setBankAccountNumber(value);
    if (value === "") {
      setBankAccountNumberError(true);
      setBankAccountNumberValidError(false);
      setMaxAccNumberErr(false);
    }
    if (value.length < 13) {
      setBankAccountNumber(value);
    }

    if (value?.length < 7 || value?.length > 12) {
      if (value.length > 12) {
        setBankAccountNumberValidError(false);

        setMaxAccNumberErr(true);
      } else {
        setBankAccountNumberValidError(true);
        setBankAccountNumberError(false);
        setMaxAccNumberErr(false);
      }
    } else {
      setBankAccountNumberValidError(false);
      setBankAccountNumberError(false);
      setMaxAccNumberErr(false);
    }
  };

  const handlePaidThrough = (e) => {
    setPaidThrough(e);
  };

  // Function for update my account
  const updatemyAccount = async () => {
    setBankInstitutionError(true);
    setBankTransitNumberError(true);
    setBankAccountNumberError(true);
    setAccHolderNameError(true);
    setPaidThroughError(true);
    // setFrequencyError(true);
    // setPaypalIdError(true);
    const requestedData = {
      // paypalId: paypalId,
      bankTransitNumber: bankTransitNumber,
      bankInstitution: bankInstitution,
      bankAccountNumber: bankAccountNumber,
      wantTopay: paidThrough,
      accHolderName: accHolderName,
      // payoutFrequency: frequency,
    };
    //console.log(requestedData);

    if (accHolderName === "" || accHolderName === undefined) {
      // toast.warning("Transit Number is required");
      setAccHolderNameError(true);
      setSubmitEnable(false);
      return;
    }
    if (accHolderName.length < 3) {
      setAccHolderNameError(true);
      setSubmitEnable(false);
      return;
    } else {
      setAccHolderNameError(false);
    }

    if (bankTransitNumber === "" || bankTransitNumber === undefined) {
      // toast.warning("Transit Number is required");
      setBankTransitNumberError(true);
      setSubmitEnable(false);
      return;
    } else {
      setBankTransitNumberError(false);
    }
    if (bankTransitNumber.length < 5) {
      setBankTransitNumberError(true);
      setSubmitEnable(false);
      return;
    } else {
      setBankInstitutionError(false);
    }

    if (bankInstitution === "" || bankInstitution === undefined) {
      setBankInstitutionError(true);
      setSubmitEnable(false);
      return;
    }
    if (bankInstitution.length < 3) {
      setBankInstitutionError(true);
      setSubmitEnable(false);
      return;
    } else {
      setBankInstitutionError(false);
    }

    if (bankAccountNumber === "" || bankAccountNumber === undefined) {
      setBankAccountNumberError(true);
      setSubmitEnable(false);
      return;
    }
    if (bankAccountNumber.length < 7) {
      setBankAccountNumberError(true);
      setSubmitEnable(false);
      return;
    } else {
      setBankAccountNumberError(false);
    }

    if (paidThrough === "" || paidThrough === undefined) {
      setPaidThroughError(true);
      setSubmitEnable(false);
      return;
    } else {
      setPaidThroughError(false);
    }
    setIsLoading(true);
    const response = await updateMyAccount(requestedData);
    setIsLoading(false);
    if (response.data.code === 200) {
      navigate(`/guard/myprofile/${guardId}`);
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Navbar />
      <section className="my_Account">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <div className="myBox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="editDetail">
                      <a
                        className="btn addBtn"
                        type="button"
                        onClick={(e) => {
                          navigate(`/guard/myprofile/${guardId}`);
                        }}
                      >
                        <span>
                          <i class="fa-solid fa-arrow-left"></i>
                        </span>
                        Back
                      </a>
                    </div>
                    <h4>My Accounts</h4>
                    <h5 className="d-flex align-item-center">
                      Bank Details{"    "}
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div className="handle_tool_box ms-2">
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="If you want to change payout option then create ticket in support section"
                          >
                            <span
                              onClick={handleTooltipOpen}
                              className="handle_tooltip_span"
                            >
                              {" "}
                              <i class="fa fa-info-circle"></i>
                            </span>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </h5>
                  </div>
                </div>

                <div className="vertical_line_box">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="leftBox">
                        <p>A/c Holder Name</p>
                        <div className="form-group">
                          <input
                            onChange={(e) =>
                              handleAccHolderName(e.target.value)
                            }
                            type="text"
                            className="form-control"
                            id="exampleInputtext"
                            aria-describedby="textHelp"
                            placeholder="A/c Holder Name"
                            value={accHolderName}
                          />
                        </div>
                        {accHolderNameError === true ? (
                          <div className="invalid-feedback">
                            Acc holder Name is required.
                          </div>
                        ) : (
                          ""
                        )}
                        {accHolderNameValidError === true ? (
                          <div className="invalid-feedback">
                            Acc holder Name (must be 3 to 15 Alphabet).
                          </div>
                        ) : (
                          ""
                        )}

                        {maxAccHolderErr === true ? (
                          <div className="invalid-feedback">
                            Max Should be 15 Alphabet.
                          </div>
                        ) : (
                          ""
                        )}

                        <p>A/c No.</p>
                        <div className="form-group">
                          <input
                            onChange={(e) =>
                              handleBankAccountNumber(e.target.value)
                            }
                            type="number"
                            className="form-control"
                            id="exampleInputtext"
                            aria-describedby="textHelp"
                            placeholder="A/c No."
                            value={bankAccountNumber}
                            // maxLength={12}
                          />
                        </div>
                        {bankAccountNumberError === true ? (
                          <div className="invalid-feedback">
                            Account Number is required.
                          </div>
                        ) : (
                          ""
                        )}
                        {bankAccountNumberValidError === true ? (
                          <div className="invalid-feedback">
                            Enter valid account number(must be 7 to 12 digit).
                          </div>
                        ) : (
                          ""
                        )}
                        {maxAccNumberErr === true ? (
                          <div className="invalid-feedback">
                            Max digit should be 12.
                          </div>
                        ) : (
                          ""
                        )}

                        <p>Institution Number</p>
                        <div className="form-group">
                          <input
                            onChange={(e) =>
                              handleBankInstitution(e.target.value)
                            }
                            type="number"
                            className="form-control"
                            id="exampleInputtext"
                            aria-describedby="textHelp"
                            placeholder="Institution Number."
                            value={bankInstitution}
                          />
                        </div>
                        {bankInstitutionError === true ? (
                          <div className="invalid-feedback">
                            Institution Number is required.
                          </div>
                        ) : (
                          ""
                        )}
                        {bankInstitutionValidError === true ? (
                          <div className="invalid-feedback">
                            Enter Valid Institution Number(must be 3 digit).
                          </div>
                        ) : (
                          ""
                        )}

                        {maxBankInstErr === true ? (
                          <div className="invalid-feedback">
                            Max digit should be 3.
                          </div>
                        ) : (
                          ""
                        )}

                        <p>Transit Number</p>
                        <div className="form-group">
                          <input
                            onChange={(e) =>
                              handleBankTransitNumber(e.target.value)
                            }
                            type="number"
                            className="form-control"
                            id="exampleInputtext"
                            aria-describedby="textHelp"
                            placeholder="Transit Name"
                            value={bankTransitNumber}
                            // maxLength={5}
                          />
                        </div>
                        {bankTransitNumberError === true ? (
                          <div className="invalid-feedback">
                            Transit Number is required.
                          </div>
                        ) : (
                          ""
                        )}
                        {bankTransitNumberValidError === true ? (
                          <div className="invalid-feedback">
                            Enter valid transit number(must be 5 digit).
                          </div>
                        ) : (
                          ""
                        )}
                        {maxBankInstErr === true ? (
                          <div className="invalid-feedback">
                            Max digit should be 5.
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="rightBox">
                        {/* <p>I want to get paid through</p> */}
                        <p>Account Type</p>
                        <div className="form-group">
                          <select
                            value={paidThrough}
                            onChange={(e) => handlePaidThrough(e.target.value)}
                            className="form-control"
                          >
                            <option value="2">Individual</option>
                            <option value="1">Company</option>
                            {/* <option value="2">Company</option> */}
                          </select>
                          <span>
                            <FaCaretDown />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-5">
                    <div className="submitDetails">
                      <a onClick={() => (isLoading ? "" : updatemyAccount())}>
                        {isLoading ? "Loading..." : "Submit Details"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyAccount;
