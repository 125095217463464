import React from 'react';
import './inner_header.scss';
import InnerHead_img from '../../../src/images/inner_header.png'; 

const Header_inner = () => {
  return (
    <>
    <section className='inner_header_section fixed-top'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 test-col'>
                    <div className='inner_imgbox'>
                        <a href="/">
                        <img src={InnerHead_img} className="img-fluid" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Header_inner;
