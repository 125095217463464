// export const API_URL = "http://localhost:3000/v1"; // Local
// export const SOCKET_URL = "http://localhost:3002/"; // Omnist Testing Server

// export const API_URL = "https://go-guard.apikart.co/v1/"; // For Staging
// export const SOCKET_URL = "https://guardsocket.apikart.co";

// export const CUST_URL = "https://customer-guard.apikart.co/"; // Omnist staging Server
// export const CUST_URL_LOGIN = "https://customer-guard.apikart.co/signup"; // Omnist staging Server

// export const API_URL = "https://go-guard.omnisttechhub.com/v1"; // Omnist Testing Server
// export const SOCKET_URL = "https://guardsocket.omnisttechhub.com"; // Omnist Testing Server

// export const CUST_URL = "https://guard-customer.web.app/"; // Omnist Testing Server
// export const CUST_URL_LOGIN = "https://guard-customer.web.app/signup"; // Omnist Testing Server
// export const S3PREVIEW_URL = `${API_URL}/media/preview?filename=`; // Omnist Testing Server
export const vapidKey =
  "BK0x2yhw8eydVUehpfuJEOMPfhVgR4EQX_moBF-qqr65OHk_W4Sx-dLX4j6GNmXBGu3maL70AcIpQLUrRei128M";
export const apiKey = "AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY";
export const authDomain = "iconic-being-340812.firebaseapp.com";
export const projectId = "iconic-being-340812";
export const messagingSenderId = "858285694999";
export const storageBucket = "iconic-being-340812.appspot.com";
export const appId = "1:858285694999:web:027b1f54d0d4509e107c52";
export const measurementId = "G-EGZTTZ1ED4";

export const googleclientIdKey =
  "858285694999-bf5681k3kebfdvfobiaructldlk17fod.apps.googleusercontent.com";

export const API_URL = "https://go-guard-api.go-guard.com/v1"; // Live Server
export const SOCKET_URL = "https://go-guard-api.go-guard.com/"; // Live Server
export const CUST_URL = "https://customer.go-guard.com/"; // Live Server
export const CUST_URL_LOGIN = "https://customer.go-guard.com/signup";
export const S3PREVIEW_URL = `${API_URL}/media/preview?filename=`;
// //console.log('=======Base URL========2==', process.env.NODE_ENV, API_URL);
