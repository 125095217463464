// import Cookies from "universal-cookie";
import axios from "axios";
import { API_URL } from "./constants";
var basicToken = "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"

export async function reachUsAPI(requestData) {
    axios.defaults.headers.common["Authorization"] = basicToken

    var response = await axios.post(
        `${API_URL}/reach-us/public/add`,
        requestData
    );
    if (response) {
        return response;
    } else {
        return [];
    }
}

export async function newsLetterAPI(requestData) {
    axios.defaults.headers.common["Authorization"] = basicToken

    var response = await axios.post(
        `${API_URL}/news-letter/public/add`,
        requestData
    );
    if (response) {
        return response;
    } else {
        return [];
    }
}