import React, { useEffect } from "react";
import './contact_us.scss';
import WelcomeHeader from '../../Pages/Wel_Header/WelcomeHeader';
import WelcomeFooter from '../../Pages/Wel_Footer/WelcomeFooter';
import FbIcon from "../../images/welcomePage/facebook.png";
import TwIcon from "../../images/welcomePage/insta.png";
import LiIcon from "../../images/welcomePage/linkedin.png";
import ReachUSForm from '../../shared/reachUs';
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <WelcomeHeader />
            <section className="banner-contact">
                <div className="container">
                    <div className='workBox'>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-12">
                                <div className="work">
                                    <h1>Contact Us</h1>
									<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
										<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
										<Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
									</Breadcrumb>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact-us" className='contactForm'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div class="col-md-6 col-lg-7 order-2 order-md-1">
						<h2>Register And <strong>Get Trained!!</strong></h2>
						<p class="sec-sub-heading">Looking to get trained as a security guard, Register Now!!</p>
						<p>Register your interest to train as a security guard and our training team will connect you with our training partners. We have partnered with best guard training agencies to provide you with the best training courses which help you quickly qualify and get your licensed security guard registration.</p>
						<div class="d-inline-flex taining-contact">
						<div class="flex-shrink-0">
						<i class="gg-icon envelope-icon"></i>
						</div>
						<div class="flex-grow-1 ms-3">
						<h3>Contact Us Via</h3>
							<label class="mb-2">Email: <a href="mailto:support@go-guard.com">support@go-guard.com</a></label>
						<a href="#">Read More</a>
						</div>
						</div>
						<div class="d-flex taining-contact">
						<div class="flex-shrink-0">
						<i class="gg-icon like-icon"></i>
						</div>
						<div class="flex-grow-1 ms-3">
						<h3>Follow Us On</h3>
						<ul class="list-unstyled footer-social mb-0 d-flex">
						<li><a href="https://www.facebook.com/Guardondemand" target="_blank"><i class="gg-icon fb-grey-icon"></i></a></li>
						<li><a href="https://www.instagram.com/goguard.inc/" target="_blank"><i class="gg-icon instagram-grey-icon"></i></a></li>
						<li><a href="https://www.linkedin.com/in/goguardinc-2305/" target="_blank"><i class="gg-icon linkedin-grey-icon"></i></a></li>
						</ul>
						</div>
						</div>
						</div>
                        <ReachUSForm pageType="Contact Us" />
                    </div>
                </div>
            </section>
            {/* <section className="needAssistance">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="content">
                        <p>Need assistance in Setting up your Go-Guard Account, our Customer Success Expert will set it up for you.</p>
                        <a href="#">Get In Touch Now</a>
                    </div>
                </div>
            </div>
        </div>
      </section> */}
            <WelcomeFooter />
        </>
    )
}

export default ContactUs;
