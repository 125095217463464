import React, { useEffect } from "react";
import '../../css/style.css';
import WelcomeHeader from '../../Pages/Wel_Header/WelcomeHeader';
import WelcomeFooter from '../../Pages/Wel_Footer/WelcomeFooter';
import SubscribeSection from '../../shared/subscribe';
import go_guard_icon from '../../images/go-guard-icon.png';
import service_guard from '../../images/service-guard.png';
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';


const Servicespage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <WelcomeHeader />
            <section class="page-title py-6">
	<div class="container">
		<div class="row">
			<div class="col-12 text-center">
			<h1><strong>GoGuard Security Services</strong></h1>
			<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
				<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>Services</Breadcrumb.Item>
			</Breadcrumb>	
			</div>
		</div>
	</div>
</section>
		<section class="inner-intro text-center">
<div class="container">
<div class="inner-intro-inner py-6">
<h2><strong>Leading Security Provider</strong> Your Safety is Our Only Priority</h2>
<p>GoGuard, provides excellent security services for all major sectors in Canada. Our affordable services detect any possible threat and are trained to deal with any breach. Through our app, you can easily communicate and keep track of the activities of your GoGuard. Whether a large corporation or a small business, with us you are assured of the best.</p>
<p>At GoGuard, we take the stress away from hiring a professional security guard to give you a seamless experience. Ready to get the most experienced personal security guard without any hassle? Sign up now to get started.</p>
</div>
</div>
</section>
		<section class="inner-services py-6">
<div class="container">
<div class="row">
<h2 class="text-center"><strong>Dedicated Services</strong> Across the Board</h2>
<p class="text-center sec-sub-heading">Our team of trained, licensed, and professional security guards work tirelessly in the following areas.</p>
<ul class="mb-0 list-unstyled d-flex flex-wrap">
	<li><a href="/our-speciality/bank">
		<i class="gg-icon bank-icon"></i><h3>Banks</h3>
		<p>We cater to multiple services in the banking sector such as ATMs, branches, and other financial institutions.</p></a>
	</li>
	<li><a href="/our-speciality/car-lots">
		<i class="gg-icon car-lot-icon"></i><h3>Car Lots</h3>
		<p>Easily manage your car lots with security personnel. With the GoGuard app, you will always be in the loop.</p></a>
	</li>
	<li><a href="/our-speciality/concierge">
		<i class="gg-icon concierge-icon"></i><h3>Concierge</h3>
		<p>Keep your guests secure and happy with our Concierge security guard services in Canada.</p></a>
	</li>
	<li><a href="/our-speciality/loss-prevention">
		<i class="gg-icon loss-prevention-icon"></i><h3>Loss Prevention</h3>
		<p>Minimize risks with loss prevention guards who will identify and deter any possible threats.</p></a>
	</li>
	<li><a href="/our-speciality/protests">
		<i class="gg-icon protests-icon"></i><h3>Protests</h3>
		<p>Be aware of all the minute developments during any protests or demonstrations.</p></a>
	</li>
	<li><a href="/our-speciality/access-control">
		<i class="gg-icon access-control-icon"></i><h3>Access Control</h3>
		<p>With access control guards you can become fully in charge of who enters your premises, whether it's for any event or otherwise.&nbsp;</p></a>
	</li>
	<li><a href="/our-speciality/events">
		<i class="gg-icon events-icon"></i><h3>Events</h3>
		<p>Hire a security guard in Canada for any family functions or office gatherings.</p></a>
	</li>
	<li><a href="/our-speciality/residential">
		<i class="gg-icon residential-icon"></i><h3>Residential</h3>
		<p>Affordable and trusted home security solutions with GoGuard in Ontario. So that you sleep carefree.</p></a>
	</li>
	<li><a href="/our-speciality/construction">
		<i class="gg-icon construction-icon"></i><h3>Construction</h3>
		<p>Protect your valuable materials and workforce with construction site guards for hire.</p></a>
	</li>
	<li><a href="/our-speciality/vip">
		<i class="gg-icon vip-icon"></i><h3>VIP</h3>
		<p>With our premium security services, you can be assured of the best without any intrusions on your privacy.</p></a>
	</li>
</ul>
</div>
</div>
</section>
		<section class="inner-loc-benefit py-6">
<div class="container">
<h2><strong>GoGuard</strong> In Ontario</h2>
<div class="row">
<div class="col-md-6 mb-4 mb-md-0">
<p class="sec-sub-heading">GoGuard is a fully licensed, insured, and bonded personal security guard service, with operations primarily in Ontario, Canada</p>
<img src={service_guard} alt="Service Guard" class="img-fluid app-mobile-img rounded-3" width="652px" height="233px" />
</div>
<div class="col-md-6">
<ul class="list-unstyled">
<li class="d-flex">
<div class="flex-shrink-0">
<i class="gg-icon sheild-icon"></i>
</div>
<div class="flex-grow-1 ms-3">
<h3>Secure Services</h3>
<p>Trusted and secure GoGuard is known to provide the best security solutions.</p>
</div>
</li>
<li class="d-flex">
<div class="flex-shrink-0">
<i class="gg-icon mobile-icon"></i>
</div>
<div class="flex-grow-1 ms-3">
<h3>App</h3>
<p>Download the GoGuard App and open up to the future of security.</p>
</div>
</li>
<li class="d-flex">
<div class="flex-shrink-0">
<i class="gg-icon track-icon"></i>
</div>
<div class="flex-grow-1 ms-3">
<h3>Tracking</h3>
<p>Always be in the loop with 24/7 tracking of your personnel.</p>
</div>
</li>
</ul>
<p>Know More About <a href="#">GoGuard Security in Ontario</a></p>
</div>
</div>
</div>
</section>
            <SubscribeSection />
            <WelcomeFooter />
        </>
    )
}

export default Servicespage;
