import React from 'react';
import { FaArrowLeft, FaMapMarkerAlt, FaClock, FaPlay, FaCalendar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './task_review.scss';
import Navbar from '../../Pages/Header/Navbar';
import Footer from '../../Pages/Footer/Footer';


const TaskReview = () => {
    return (
        <>
            <Navbar />
            <section className="task_review">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-9">
                            <div className="content">
                                <div className="title">
                                    <div className="backArrow"><Link to="/eNotes"><FaArrowLeft /></Link></div>
                                    <div className="heading">
                                        <h1>Mall Security Job</h1>
                                        <p><span><FaMapMarkerAlt /></span> 123,Street Mall, City</p>
                                        <p><span><FaCalendar /></span> 12/12/2021</p>
                                        <p><span><FaClock /></span> 10:00 AM <small style={{margin:'0 5px',fontSize:'14px'}}>To</small> <span> <FaClock /> </span> 09:00 PM</p>
                                    </div>
                                </div>
                                <div className='historyBox'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='box'>
                                                <div className='descBox'>
                                                    <h6>Reached office on time.Job Tasks reviewed</h6>
                                                    <p><FaClock /> <span>01:30 PM, 12/12/2021</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='box'>
                                                <div className='descBox'>
                                                    <h6>Reached office on time.Job Tasks reviewed</h6>
                                                    <p><FaClock /> <span>01:30 PM, 12/12/2021</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='box'>
                                                <div className='descBox'>
                                                    <h6>Walked around building</h6>
                                                    <a href='#'><FaPlay /><span></span></a>
                                                    <p><FaClock /> <span>01:30 PM, 12/12/2021</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='box'>
                                                <div className='descBox'>
                                                    <h6>Reached office on time.Job Tasks reviewed</h6>
                                                    <p><FaClock /> <span>01:30 PM, 12/12/2021</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='box'>
                                                <div className='descBox'>
                                                    <h6>Reached office on time.Job Tasks reviewed</h6>
                                                    <p><FaClock /> <span>01:30 PM, 12/12/2021</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='box'>
                                                <div className='descBox'>
                                                    <h6>Walked around building</h6>
                                                    <a href='#'><FaPlay /><span></span></a>
                                                    <p><FaClock /> <span>01:30 PM, 12/12/2021</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TaskReview;
