import React, { useEffect } from "react";
import "./welcome_page.scss";
import '../../css/style.css';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WorkImg from "../../images/welcomePage/work_img.png";
import HireImg from "../../images/welcomePage/hire_img.png";
import PlaystoreIcon from "../../images/welcomePage/playStore.png";
import AppStoreIcon from "../../images/welcomePage/appStore.png";
import MobImg from "../../images/welcomePage/mobImg.png";
import WelcomeHeader from "../../Pages/Wel_Header/WelcomeHeader";
import WelcomeFooter from "../../Pages/Wel_Footer/WelcomeFooter";
import Welimg from "../../images/welcomePage/banner.jpg";
import Sceduleimg from "../../images/welcomePage/schedule.jpg";
import Fewclickimg from "../../images/welcomePage/Fewclick.jpg";
import AsGoGuard_Img from "../../images/welcomePage/as_goguard.jpg";
import Hire_guard_img from "../../images/welcomePage/hire_goguard.jpg";

import app_screen from "../../images/welcomePage/app-screen.png";
import ontario_service from "../../images/welcomePage/ontario-service.png";
import guard_info from "../../images/welcomePage/guard-info.png";
import app_store from "../../images/welcomePage/en_app_store.svg";
import google_play from "../../images/welcomePage/en_google_play.svg";

import { Link } from "react-router-dom";
import GuardsignupSection from "../../shared/guardSignup";
// import $ from 'jquery';
import { CUST_URL ,CUST_URL_LOGIN} from "../../utils/constants";
const WelcomePage = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  useEffect(async () => {
    // $('.owl-carousel').owlCarousel({
    //     navigation : true,
    //     loop: true,
    //     margin: 10,
    //     nav: true,
    //     dots: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // })
  }, []);
  return (
    <>
      <WelcomeHeader />
      <section className="wel_banner">
        <div className="outerBox">
          <div className="owl-carousel owl-theme owl-loaded owl-drag" id="testimonial">
            <div className="item active">
              <img src={Welimg} className="img-fluid" />
              <div className="home-banner-text">
	  			<div class="container">
	  			<div class="home-banner-text-inner">
                <h1>Professional, Efficient &amp; Reliable <span>Security Services in Canada</span></h1>
	  			<p>Safeguard your interests & assets with GoGuard.</p>
	  			<ul class="mb-0 list-unstyled">
	  			<li><Link to="/services" class="btn-black">Explore Services</Link></li>
                <li><a href="https://customer.go-guard.com/signup" class="btn-white">Sign Up</a></li>
	  			</ul>
	  			</div>
	  			</div>
              </div>
            </div>
	  		{/*<div className="item">
              <img src={Welimg} className="img-fluid" />
              <div className="mainBox">
                <h1>Welcome to the Future of Security </h1>
                <Link to="/select-navigation/signup">Sign Up</Link>
              </div>
            </div>
            <div className="item">
              <img src={Fewclickimg} className="img-fluid" />
              <div className="mainBox">
                <h1>Hire Security Guards in a few Clicks!! </h1>
                <Link to="/select-navigation/signup">Sign Up</Link>
              </div>
            </div>
            <div className="item">
              <img src={Sceduleimg} className="img-fluid" />
              <div className="mainBox">
                <h1>Work a schedule that suits you!! </h1>
                <Link to="/select-navigation/signup">Sign Up</Link>
              </div>
            </div>*/}
          </div>
        </div>
      </section>
      <section class="home-solutions text-center py-6">
		<div class="container">
		<h2><strong>Security Solutions</strong> for Home, Workplace, and Much More</h2>
		<p class="sec-sub-heading">GoGuard, brings you affordable and competent security services for multiple sectors.</p>
		<ul class="list-unstyled mb-0 mt-5 d-flex flex-wrap justify-content-center">
		<li><i class="gg-icon bank-icon"></i>Banks</li>
		<li><i class="gg-icon car-lot-icon"></i>Car Lots</li>
		<li><i class="gg-icon concierge-icon"></i>Concierge</li>
		<li><i class="gg-icon loss-prevention-icon"></i>Loss Prevention</li>
		<li><i class="gg-icon protests-icon"></i>Protests</li>
		<li><i class="gg-icon access-control-icon"></i>Access Control</li>
		<li><i class="gg-icon events-icon"></i>Events</li>
		<li><i class="gg-icon residential-icon"></i>Residential</li>
		<li><i class="gg-icon construction-icon"></i>Construction</li>
		<li><i class="gg-icon vip-icon"></i>VIP</li>
		</ul>
		</div>
	  </section>
      <section class="home-fingertips py-6">
		<div class="container">
		<div class="row">
		<div class="col-md-5">
		<h2><strong>Best Security Services </strong> at Your Fingertips</h2>
		</div>
		<div class="col-md-7">
		<p>GoGuard is a registered security service provider legally authorized to provide security services in Ontario Canada. We are an online security provider who provide guards for a wide range of security needs.</p>
		<p>We offer security services to Banks, Car Lots, Events, Strikes, Estate, Loss, Residential, Protection, Private &amp; VIP’s. Our security guards are licensed and trained to protect you and your property with utmost care, confidentiality and commitment.</p>
		</div>
		</div>
		</div>
		</section>
	  <section class="home-why py-6">
<div class="container">
<div class="row">
<div class="col-md-12 text-center">
<h2>Why <strong>GoGuard?</strong></h2>
</div>
<div class="col-lg-4 text-lg-end d-flex flex-column justify-content-between">
<div class="d-flex count-left">
<div class="why-tips flex-grow-1 ms-4 ms-lg-0 me-lg-4 order-2 order-lg-0">
<h3>Physical Safety</h3>
<p>Full protection from all possible threats.</p>
</div>
<div class="flex-shrink-0">
<span class="why-count first">01</span>
</div>
</div>
<div class="d-flex count-left">
<div class="why-tips flex-grow-1 ms-4 ms-lg-0 me-lg-4 order-2 order-lg-0">
<h3>24/7 Dispatch Center</h3>
<p>Availability of services 365 days.</p>
</div>
<div class="flex-shrink-0">
<span class="why-count second">02</span>
</div>
</div>
</div>
<div class="col-lg-4 text-center d-none d-lg-block">
<img class="img-fluid" src={guard_info} alt="Why GoGuard?" />
</div>
<div class="col-lg-4 text-lg-start d-flex flex-column justify-content-between">
<div class="d-flex count-right">
<div class="flex-shrink-0">
<span class="why-count third">03</span>
</div>
<div class="why-tips flex-grow-1 ms-4 me-lg-0">
<h3>GPS Satellite Based Tracking</h3>
<p>Keep track of your GoGuard from your mobile phone.</p>
</div>
</div>	
<div class="d-flex count-right">
<div class="flex-shrink-0">
<span class="why-count fourth">04</span>
</div>
<div class="why-tips flex-grow-1 ms-4 me-lg-0">
<h3>Comprehensive Security Rules</h3>
<p>Our guards follow a strict regime of discipline and alertness.</p>
</div>
</div>
</div>
</div>
</div>
</section>
	  <section class="home-app-sec">
<div class="container">
<div class="row">
<div class="col-12 col-lg-8 p-3 mx-auto">
<div class="row align-items-center home-app-sec-inner">
<div class="col-5 ps-0">
<img src={app_screen} alt="GoGuard App" class="img-fluid app-mobile-img" width="352px" height="317px" />
</div>
<div class="col-7 ps-md-5">
<h3><strong>The GoGuard App <span class="d-block">Coming Soon</span></strong></h3>
<ul class="list-inline mb-0">
<li class="list-inline-item">
<img alt="App Store" src={app_store} width="122" height="37" />
</li>
<li class="list-inline-item">
<img alt="Google Play" src={google_play} width="122" height="37" />
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</section>
	  <section class="service-areas py-6">
<div class="container">
<div class="row">
<div class="col-lg-5 mb-4 mb-lg-0">
<img src={ontario_service} alt="Services Areas In Ontario" class="img-fluid rounded-3" width="593px" height="359px" />
</div>
<div class="col-lg-7">
<h2><strong>Services Areas</strong> In Ontario</h2>
<p class="sec-sub-heading">GoGuard offers on demand security guards in major cities of Ontario.</p>
<p>We plan to expand and offer our services across Ontario in the coming months. Currently, we are serving the following regions.</p>
<ul class="list-unstyled d-flex flex-wrap">
<li>Toronto</li>
<li>Ottawa</li>          
<li>Mississauga</li>
<li>Brampton</li>             
<li>Hamilton</li>                
<li>London</li>
<li>Markham</li>
<li>Vaughan</li>
<li>Kitchener</li>
</ul>
</div>
</div>
</div>
</section>
	  <section class="home-faqs py-6">
<div class="container">
<div class="row">
<div class="col-md-12">
<div class="d-flex justify-content-between flex-wrap align-items-center">	
<h2>Frequently Asked <strong>Questions</strong></h2>
<a href="/faq" class="text-decoration-none ms-auto">View More</a>
</div>
</div>
</div>
<div class="row" id="accordionFaqs">
<div class="col-md-6">
<div class="accordion d-flex flex-wrap">
  <div class="accordion-item">
    <h3 class="accordion-header" id="heading1">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
        What is GoGuard?
      </button>
    </h3>
    <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionFaqs">
      <div class="accordion-body">
        In a nut shell, GoGuard enables clients in need of security guards to hire qualified and vetted professionals through our platform. A platform that enables licensed security professionals to freelance their expertise. 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="heading2">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
        Can anyone register?
      </button>
    </h3>
    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionFaqs">
      <div class="accordion-body">
        In a nut shell, GoGuard enables clients in need of security guards to hire qualified and vetted professionals through our platform. A platform that enables licensed security professionals to freelance their expertise.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="heading3">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
        How does the guard app work?
      </button>
    </h3>
    <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionFaqs">
      <div class="accordion-body">
        In a nut shell, GoGuard enables clients in need of security guards to hire qualified and vetted professionals through our platform. A platform that enables licensed security professionals to freelance their expertise.
      </div>
    </div>
  </div>
</div>
</div>
<div class="col-md-6">
<div class="accordion d-flex flex-wrap">
	<div class="accordion-item">
    <h3 class="accordion-header" id="heading4">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
        How often do I get paid?
      </button>
    </h3>
    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionFaqs">
      <div class="accordion-body">
        In a nut shell, GoGuard enables clients in need of security guards to hire qualified and vetted professionals through our platform. A platform that enables licensed security professionals to freelance their expertise.
      </div>
    </div>
  </div>
	<div class="accordion-item">
    <h3 class="accordion-header" id="heading5">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
        What type of Payment methods do you accept?
      </button>
    </h3>
    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionFaqs">
      <div class="accordion-body">
        In a nut shell, GoGuard enables clients in need of security guards to hire qualified and vetted professionals through our platform. A platform that enables licensed security professionals to freelance their expertise.
      </div>
    </div>
  </div>
	<div class="accordion-item">
    <h3 class="accordion-header" id="heading6">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
        Are you licensed to provide security services?
      </button>
    </h3>
    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionFaqs">
      <div class="accordion-body">
        In a nut shell, GoGuard enables clients in need of security guards to hire qualified and vetted professionals through our platform. A platform that enables licensed security professionals to freelance their expertise.
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
</section>
      <GuardsignupSection />
      <WelcomeFooter />
    </>
  );
};

export default WelcomePage;
