import React, { useEffect, useState } from "react";
// import { slide as Menu } from "react-burger-menu";
import "./navbar.scss";
import {
  FaBriefcase,
  FaSearch,
  FaBook,
  FaBell,
  // FaBars,
  FaUserAlt,
  FaCoins,
  FaStar,
  // FaBox,
  FaCalendar,
  // FaFile,
  FaCog,
  FaPhone,
  FaBriefcaseMedical,
  FaComment,
} from "react-icons/fa";
import LogoIcon from "../../images/logo.png";
import userIcon from "../../images/user.png";
import { Link, useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { logout } from "../../utils/request";
import { toast } from "react-toastify";
import { API_URL } from "../../utils/constants";
import Swal from "sweetalert2";

const Navbar = () => {
  let [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const cookie = new Cookies();
  const guardId = cookie.get("guardId");
  const img = cookie.get("profileImage");
  // Function for handle logout warning
  const handleLogout = async (id) => {
    Swal.fire({
      title: "Are you sure",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogoutApi();
      }
    });
  };
  // Function for handle logout
  const handleLogoutApi = async (id) => {
    await logout().then((response) => {
      //console.log(response);
      if (response.data.code === 200) {
        toast.success(response.data.message);
        localStorage.clear();
        navigate(`/guard/login`);
      }
    });
  };

  // const showSettings = (event)=> {
  //   // event.preventDefault();
  // }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand">
            <img src={LogoIcon} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to={`/guard/myprofile/${guardId}`}>
                  <span>
                    <FaBriefcase />
                  </span>{" "}
                  My Jobs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/search-job">
                  <span>
                    <FaSearch />
                  </span>{" "}
                  Search Jobs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/eNotes">
                  <span>
                    <FaBook />
                  </span>{" "}
                  eNotes
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/chat">
                  <span>
                    <FaComment />
                  </span>{" "}
                  Chats
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link notifi_icon" to="/notification">
                  <span className="left">
                    <FaBell />{" "}
                  </span>
                  <span className="alert_message"></span>
                </Link>
              </li>

              <li className="nav-item">
                {/* <button
                  className="nav-link"
                  data-bs-target="#first"
                  id="sidebar-toggle"
                  type="button"
                  data-bs-toggle="toggle"
                  onClick={(e) => {
                    setMenu((menu = !menu));
                  }}
                  aria-expanded="false"
                >
                  <span>
                    <FaBars />
                  </span>
                </button> */}

                <a
                  className={
                    "nav-link hamburger" + (menu === true ? " active" : "")
                  }
                  onClick={(e) => {
                    setMenu((menu = !menu));
                  }}
                  id="sidebar-toggle"
                  type="button"
                  data-bs-toggle="toggle"
                  aria-expanded="false"
                >
                  <span></span>
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/guard/myprofile/${guardId}`}>
                  <span className="userImg">
                    <img
                      src={
                        img === ""
                          ? userIcon
                          : `${API_URL}/media/preview?filename=${img}`
                      }
                    />
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={"right_sidebar" + (menu === true ? " add_sidebar" : "")}
          id="sidebar_wrapper"
        >
          <div className="sidebar_box">
            <ul className="sidebar_menu">
              <li>
                <Link
                  className="sidebar-item"
                  to={`/guard/myprofile/${guardId}`}
                >
                  <span>
                    <FaUserAlt />
                  </span>
                  My Profile
                </Link>
              </li>
              <li>
                <Link
                  className="sidebar-item"
                  to={`/work-profile/${guardId}?isShow=with_login`}
                >
                  <span>
                    <FaBriefcase />
                  </span>
                  Work Profile
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/withdraw">
                  <span>
                    <FaCoins />
                  </span>
                  My Earning
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/jobRating">
                  <span>
                    <FaStar />
                  </span>
                  My Ratings
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/notification">
                  <span>
                    <FaBell />
                  </span>
                  Alerts
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/job-history">
                  <span>
                    <FaCalendar />
                  </span>
                  Job History
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/report-injury">
                  <span>
                    <FaBriefcaseMedical />
                  </span>
                  Report Injury
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/setting">
                  <span>
                    <FaCog />
                  </span>
                  Settings
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/reportIssue">
                  <span>
                    <FaPhone />
                  </span>
                  Support
                </Link>
              </li>
              <div className="logout">
                <button onClick={(e) => handleLogout(e)}>Logout</button>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
