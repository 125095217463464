import React, { useEffect, useState } from "react";
import "./PrivacyPolicy.scss";
import WelcomeHeader from "../../Pages/Wel_Header/WelcomeHeader";
import WelcomeFooter from "../../Pages/Wel_Footer/WelcomeFooter";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { FaArrowRight } from "react-icons/fa";
import { getStaticContent } from "../../utils/request";
import parse from "html-react-parser";
import SubscribeSection from "../../shared/subscribe";
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';

const PrivacyPolicy = () => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getPrivacyPolicyData();
  }, []);
  // Function for get privacy policy
  const getPrivacyPolicyData = async () => {
    const response = await getStaticContent({ type: "privacy" });
    if (response.data.code === 200) {
      setDescription(response.data.data.description);
    }
  };
  return (
    <>
      <WelcomeHeader />
      <section className="privacyBanner">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="work">
                <h1>Privacy Policy</h1>
	  			<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
				</Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="term-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="para-A">
                <p
                  className="cls-maxheight-scroll"
                  dangerouslySetInnerHTML={{ __html: parse(description) }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SubscribeSection />
      <WelcomeFooter />
    </>
  );
};

export default PrivacyPolicy;
