import React from "react";
import './our_speciality.scss';
import WelcomeHeader from '../../Pages/Wel_Header/WelcomeHeader';
import WelcomeFooter from '../../Pages/Wel_Footer/WelcomeFooter';
// import OntarioImg from '../../images/how_work/ontario-img1.png';
// import CusImg1 from '../../images/how_work/client-1.png';
// import CusImg2 from '../../images/how_work/client-2.png';
// import CusImg3 from '../../images/how_work/client-3.png';
// import CusImg4 from '../../images/how_work/client-4.png';
// import { FaCaretRight } from "react-icons/fa";
import { useParams } from "react-router-dom";
// import ReachUSForm from '../../shared/reachUs';
import VIP from './VIP';
import Airport from './airports';
import CarLots from './carlots';
import Bank from './bank';
import Concierge from './concierge';
import LossPrevention from './lossPrevention';
import Protests from './protests';
import AccessControl from './accessControl';
import Events from './events';
import Residential from './residential';
import Construction from './construction';

const OurSpec = () => {
    const { type } = useParams();
    window.scroll(0, 0)
    return (
        <>
            <WelcomeHeader />
            {type === 'vip' ? <VIP /> : ''}
            {type === 'airports' ? <Airport /> : ''}
            {type === 'car-lots' ? <CarLots /> : ''}
            {type === 'bank' ? <Bank /> : ''}
            {type === 'concierge' ? <Concierge /> : ''}
            {type === 'loss-prevention' ? <LossPrevention /> : ''}
            {type === 'protests' ? <Protests /> : ''}
            {type === 'access-control' ? <AccessControl /> : ''}
            {type === 'events' ? <Events /> : ''}
            {type === 'residential' ? <Residential /> : ''}
            {type === 'construction' ? <Construction /> : ''}
            <WelcomeFooter />
        </>
    )
}

export default OurSpec;
