import React, { useEffect, useState, useRef } from "react";
import {
  FaArrowRight,
  FaUserAlt,
  FaEnvelope,
  FaPhone,
  FaSearch,
  FaHome,
  FaMapMarked,
  FaMap,
  FaCode,
  FaHandHolding,
} from "react-icons/fa";
// import Box from '@mui/material/Box';
// import { Link } from 'react-router-dom';
// import Slider from '@mui/material/Slider';
import "./user_profile.scss";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  getGuardProfile,
  getSpecialityList,
  updateWorkProfile,
} from "../../utils/request";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
// import moment from "moment";
import { API_URL } from "../../utils/constants";
import { Oval } from "react-loader-spinner";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";

let autoComplete;

const UserProfile = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isShowType = searchParams.get("isShow");
  const { id } = useParams();
  const [query, setQuery] = useState("");
  const [mapUrl, setMapUrl] = useState("");
  const [latlngUrl, setLatLngUrl] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [fullAddress2, setFullAddress2] = useState("");
  const [landMark, setLandMark] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const autoCompleteRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [guard, setGuard] = useState({});
  const [isGoWith, setIsGoWith] = useState("");
  const [vestSize, setVestSize] = useState("");
  const [isTravelTo, setTravelTo] = useState(0);
  const [specialityList, setSpecialityList] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [selectedSpecialityIds, setSelectedIds] = useState([]);
  // const [address, setAddress] = useState("");
  var selectedIds = [];
  const cookie = new Cookies();
  const token = cookie.get("token");

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };
  // Function for handle google address autocomplete
  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "ca" } }
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = autoComplete.getPlace();
    const latitude = addressObject.geometry.location.lat();
    setLatitude(latitude);
    const longitude = addressObject.geometry.location.lng();
    setLongitude(longitude);
    const query = addressObject.formatted_address;
    setMapUrl(
      `https://www.google.com/maps/embed/v1/place?key=AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY&q=${latitude},${longitude}`
    );
    updateQuery(query);
    setFullAddress(addressObject.formatted_address);
    for (let i = 0; i < addressObject.address_components.length; i++) {
      const element = addressObject.address_components[i];
      if (element.types[0] === "country") {
        const country = element.long_name;
        setCountry(country);
      } else if (element.types[0] === "administrative_area_level_1") {
        const state = element.long_name;
        setState(state);
      } else if (
        element.types[0] === "locality" ||
        element.types[0] === "sublocality_level_1"
      ) {
        const city = element.long_name;
        setCity(city);
      } else if (element.types[0] === "postal_code") {
        const zip = element.long_name;
        zip.replace(/ /g, "");
        setPostalCode(zip.replace(/ /g, ""));
      }
    }
  };

  useEffect(async () => {
    if (token) {
      // getSpeciality();
      await getGuardProfilef();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
    loadScript(
      // `https://maps.googleapis.com/maps/api/js?key=AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY&libraries=places`,
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAPS-SckWYi0UK8lbHK_fbQqUVBJKoL91U&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);
  // Function for get guard profile data
  const getGuardProfilef = async () => {
    cookie.set("guardId", id);
    setLoading(true);
    const response = await getGuardProfile(id);
    //console.log(response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      setLoading(false);
      if (response.data && response.data.data && response.data.data.guard) {
        setGuard(response?.data?.data?.guard);
        let emtArry = [];
        emtArry.push(response?.data?.data?.guard.lat);
        emtArry.push(response?.data?.data?.guard.lng);

        setLatLngUrl(emtArry);
      } else {
        setGuard(response?.data?.data);
      }
      setIsGoWith(
        guard?.isGoWith
          ? guard?.isGoWith?.toString()
          : response?.data?.data?.guard && response?.data?.data?.guard?.isGoWith
          ? response?.data.data.guard.isGoWith.toString()
          : ""
      );
      setVestSize(
        guard?.vestSize
          ? guard?.vestSize.toString()
          : response?.data.data.guard && response?.data?.data?.guard?.vestSize
          ? response?.data?.data?.guard?.vestSize.toString()
          : ""
      );
      setTravelTo(
        guard?.isTravelTo
          ? Number(guard.isTravelTo)
          : response.data.data.guard && response.data.data.guard.isTravelTo
          ? Number(response.data.data.guard.isTravelTo)
          : 0
      );

      setQuery(
        guard?.fullAddress
          ? guard.fullAddress
          : response.data.data.guard && response.data.data.guard.fullAddress
          ? response.data.data.guard.fullAddress
          : ""
      );
      setFullAddress(
        guard?.fullAddress
          ? guard.fullAddress
          : response.data.data.guard && response.data.data.guard.fullAddress
          ? response.data.data.guard.fullAddress
          : ""
      );
      setFullAddress2(
        guard?.fullAddress2
          ? guard.fullAddress2
          : response.data.data.guard && response.data.data.guard.fullAddress2
          ? response.data.data.guard.fullAddress2
          : ""
      );
      setPostalCode(
        guard?.postalCode
          ? guard.postalCode
          : response.data.data.guard && response.data.data.guard.postalCode
          ? response.data.data.guard.postalCode
          : ""
      );
      setCountry(
        guard?.country
          ? guard.country
          : response.data.data.guard && response.data.data.guard.country
          ? response.data.data.guard.country
          : ""
      );
      setCity(
        guard?.city
          ? guard.city
          : response.data.data.guard && response.data.data.guard.city
          ? response.data.data.guard.city
          : ""
      );
      setState(
        guard?.state
          ? guard.state
          : response.data.data.guard && response.data.data.guard.state
          ? response.data.data.guard.state
          : ""
      );
      setLandMark(
        guard?.landMark
          ? guard.landMark
          : response.data.data.guard && response.data.data.guard.landMark
          ? response.data.data.guard.landMark
          : ""
      );
      setLatitude(
        guard?.lat
          ? guard.lat
          : response.data.data.guard && response.data.data.guard.lat
          ? response.data.data.guard.lat
          : ""
      );
      setLongitude(
        guard?.lng
          ? guard.lng
          : response.data.data.guard && response.data.data.guard.lng
          ? response.data.data.guard.lng
          : ""
      );
      // const guardListRes = await getSpecialityList();
      // //console.log(guardListRes);
      // setSpecialityList(guardListRes.data.data);
      // for (let i = 0; i < response.data.data.guard.workSpecialities; i++) {
      //   for (let j = 0; j < guardListRes.data.data.length; j++) {
      //     const sDtaa = {
      //       name: guardListRes.data.data[j].name,
      //       id: guardListRes.data.data[j].id,
      //       isTrue:
      //         guardListRes.data.data[j].id ==
      //         response.data.data.guard.workSpecialities[i]._id
      //           ? true
      //           : false,
      //     };
      //     setSpecialityList(sDtaa);
      //     //console.log(sDtaa);
      //   }
      // }
      const guardListRes = await getSpecialityList();
      if (guardListRes.data.code === 200) {
        // setSpecialityList(guardListRes.data.data);
        if (
          response.data.data.guard &&
          response.data.data.guard.workSpecialities
        ) {
          setSelectedIds(response.data.data.guard.workSpecialities);
          for (
            let i = 0;
            i < response.data.data.guard.workSpecialities.length;
            i++
          ) {
            for (let j = 0; j < guardListRes.data.data.length; j++) {
              if (
                guardListRes.data.data[j].id ===
                response.data.data.guard.workSpecialities[i]._id
              ) {
                guardListRes.data.data[j]["isChecked"] = true;
                const sDtaa = {
                  name: guardListRes.data.data[j].name,
                  id: guardListRes.data.data[j].id,
                  isTrue:
                    guardListRes.data.data[j].id ==
                    response.data.data.guard.workSpecialities[i]._id
                      ? true
                      : false,
                };
                selectedIds.push(
                  response.data.data.guard.workSpecialities[i]._id
                );
                selectedSpecialityIds.push(
                  response.data.data.guard.workSpecialities[i]._id
                );
              }
              // else {
              //   guardListRes.data.data[j]["isChecked"] = false;
              // }
            }
          }
          setSpecialityList(guardListRes.data.data);
        }
      }
    } else {
      setLoading(false);
    }
  };

  const getSpeciality = async () => {
    // const guardListRes = await getSpecialityList();
    // //console.log(guardListRes);
    // setSpecialityList(guardListRes.data.data);
    // for (let i = 0; i < guard?.workSpecialities?.length; i++) {
    //   for (let j = 0; j < guardListRes?.data?.data?.length; j++) {
    //     const sDtaa = {
    //       name: guardListRes.data.data[j].name,
    //       id: guardListRes.data.data[j].id,
    //       isTrue:
    //         guardListRes.data.data[j].id == guard?.workSpecialities[i]._id
    //           ? true
    //           : false,
    //     };
    //     setSpecialityList(sDtaa);
    //   }
    // }
  };
  // Function for handle speciality change
  const onSpeciality = async (e, id) => {
    let value = [...specialityList];
    //console.log(value);
    //console.log(selectedSpecialityIds);
    if (e.target.checked) {
      const index = selectedSpecialityIds.findIndex(
        (x) => x._id === e.target.value
      );
      if (index > -1) {
        return;
      } else {
        //console.log("t", e.target.name);
        if (e.target.name === "Any") {
          //console.log("any clicked");

          let filter = value.map((r, i) => {
            return {
              name: r.name,
              id: r.id,
              isChecked: r.name === "Any" ? true : false,
            };
          });
          //console.log(filter);
          value = filter;
          while (selectedSpecialityIds.length > 0) {
            selectedSpecialityIds.pop();
          }
          selectedSpecialityIds.push(e.target.value);
        } else {
          const anyindex = value.findIndex((x) => x.name === "Any");
          const idindex = selectedSpecialityIds.findIndex(
            (x) => x === value[anyindex].id || x._id === value[anyindex].id
          );
          if (idindex > -1 && anyindex > -1) {
            let filter2 = value.map((r, i) => {
              return {
                name: r.name,
                id: r.id,
                isChecked: r.name === "Any" ? false : r?.isChecked,
              };
            });
            value = filter2;
            selectedIds.splice(idindex, 1);
            selectedSpecialityIds.splice(idindex, 1);
            //console.log(selectedSpecialityIds);
            //console.log(value);
          }
          //console.log("anyindex", idindex);
          selectedSpecialityIds.push(e.target.value);
          // //console.log(selectedIds);
          value[id]["isChecked"] = true;
        }
      }
      // selectedIds.push(e.target.value);
    } else {
      //console.log("id", selectedSpecialityIds);
      const index = selectedSpecialityIds.findIndex(
        (x) => x._id === e.target.value
      );
      //console.log(index);
      //console.log(e.target.value);
      if (index > -1) {
        selectedIds.splice(index, 1); // 2nd parameter means remove one item only
        selectedSpecialityIds.splice(index, 1);
      }
      //console.log("val", value);
      value[id]["isChecked"] = false;
    }
    //console.log("ids", selectedSpecialityIds);
    setSpecialityList(value);
  };
  // Function for update profile
  const handleSubmit = async () => {
    if (isTravelTo === 0) {
      toast.warning("Please select willing to travel");
      return;
    }

    if (isGoWith === "") {
      toast.warning("Please select tranpotation");
      return;
    }

    if (vestSize === "") {
      toast.warning("Please select vest size");
      return;
    }

    if (fullAddress === "") {
      toast.warning("Please select Address");
      return;
    }

    if (postalCode === "") {
      toast.warning("Please enter postal code");
      return;
    }

    if (city === "") {
      toast.warning("Please enter city");
      return;
    }

    if (state === "") {
      toast.warning("Please enter state");
      return;
    }

    //console.log(selectedIds);

    if (selectedSpecialityIds.length < 1) {
      toast.warning("Please select specialities");
      return;
    }
    // //console.log(event);
    // event.preventDefault();

    var specialityIds = selectedSpecialityIds.map((a) => a.id);
    selectedIds = [...selectedIds];
    const requestData = {
      id: id,
      isTravelTo: isTravelTo,
      workSpecialities: selectedSpecialityIds,
      isGoWith: isGoWith,
      vestSize: vestSize,
      lat: latitude,
      lng: longitude,
      fullAddress: fullAddress,
      fullAddress2: fullAddress2,
      postalCode: postalCode,
      country: country,
      city: city,
      state: state,
      landMark: landMark,
      isType: isShowType,
    };
    //console.log(requestData);
    setIsLoading(true);
    const res = await updateWorkProfile(requestData);
    //console.log(res);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/guard/login`);
    }
    if (res.data.code === 200) {
      setIsLoading(false);
      setAllCheck(false);
      setIsLoading(false);
      toast.success(res?.data?.message);
      if (isShowType === "with_login") {
        navigate(`/guard/myprofile/${id}`);
      } else {
        navigate(`/thankYou?isLicense=` + "full");
      }
    } else {
      setIsLoading(false);
      // setAllCheck(true);
    }
  };

  // const handleChange = address => {
  //     // this.setState({ address });
  //     setAddress(address);
  //   };

  // const handleSelect = address => {
  //     geocodeByAddress(address)
  //         .then(results => getLatLng(results[0]))
  //         .then(latLng => //console.log('Success', latLng))
  //         .catch(error => console.error('Error', error));
  // };

  return (
    <>
      <div>
        <Modal
          open={Loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Header_inner />
      {isShowType === "with_login" ? <Navbar /> : ""}
      {!Loading && (
        <section className="userProfile">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-11">
                <div className="userBox">
                  <div className="row">
                    {
                      allCheck && toast.error("Please select all values")
                      // <div className="alert alert-danger" role="alert">
                      //   Please select all values
                      // </div>
                    }
                    <div className="col-md-6 col-lg-5">
                      <div className="mapBox">
                        <h4>Work Profile</h4>
                        <div className="profileBox">
                          <div className="imgBox">
                            <span>
                              {guard?.profileImage ? (
                                <img
                                  src={
                                    API_URL +
                                    "/media/preview?filename=" +
                                    guard?.profileImage
                                  }
                                  width={40}
                                />
                              ) : (
                                <FaUserAlt />
                              )}
                            </span>
                          </div>
                          <div className="content">
                            <h4>{guard?.firstName + " " + guard?.lastName}</h4>
                            <p>
                              <FaEnvelope />
                              {guard?.email}
                            </p>
                            <p>
                              <FaPhone />
                              {guard?.mobileNumber}
                            </p>
                          </div>
                        </div>
                        <div className="otpForm">
                          <form>
                            <p>I am willing to travel</p>
                            <InputRange
                              maxValue={75}
                              minValue={0}
                              formatLabel={(value) => `${value} km`}
                              // disabled
                              value={isTravelTo}
                              onChange={(e) => setTravelTo(e)}
                            />
                            <div className="transport">
                              <p>How will you commute?</p>
                              <div className="row justify-content-center">
                                <div className="col-lg-5 col-xl-6">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      checked={isGoWith === "2"}
                                      name="isGoWith"
                                      type="checkbox"
                                      value="2"
                                      onChange={(e) =>
                                        setIsGoWith(e.target.value)
                                      }
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {" "}
                                      Public Transport{" "}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-7 col-xl-6">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      checked={isGoWith === "1"}
                                      name="isGoWith"
                                      type="checkbox"
                                      value="1"
                                      onChange={(e) =>
                                        setIsGoWith(e.target.value)
                                      }
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {" "}
                                      My Own Vehicle{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="transport">
                              <p>Choose Preferred Reflective Vest Size</p>
                              <div className="row justify-content-center">
                                {/* <div className="col-md-6 col-xl-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={vestSize === "1"}
                                    name="vestSize"
                                    type="radio"
                                    value="1"
                                    onChange={(e) =>
                                      setVestSize(e.target.value)
                                    }
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {" "}
                                    Small{" "}
                                  </label>
                                </div>
                              </div> */}
                                <div className="col-md-6 col-xl-3">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      checked={vestSize === "2"}
                                      name="vestSize"
                                      type="checkbox"
                                      value="2"
                                      onChange={(e) =>
                                        setVestSize(e.target.value)
                                      }
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {" "}
                                      Medium{" "}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6 col-xl-3">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      checked={vestSize === "3"}
                                      name="vestSize"
                                      type="checkbox"
                                      value="3"
                                      onChange={(e) =>
                                        setVestSize(e.target.value)
                                      }
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {" "}
                                      Large{" "}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6 col-xl-3">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      checked={vestSize === "4"}
                                      name="vestSize"
                                      type="checkbox"
                                      value="4"
                                      onChange={(e) =>
                                        setVestSize(e.target.value)
                                      }
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {" "}
                                      XL{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-7">
                      <div className="mapBox">
                        <h5>My Base Location</h5>
                        <div className="mapNav">
                          {mapUrl ? (
                            <iframe
                              src={mapUrl}
                              width={"100%"}
                              height={"100px"}
                              frameBorder={"0"}
                              style={{ color: "red" }}
                            ></iframe>
                          ) : (
                            <iframe
                              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY&q=${latlngUrl[0]},${latlngUrl[1]}`}
                              width={"100%"}
                              height={"100px"}
                              frameBorder={"0"}
                              style={{ color: "red" }}
                            ></iframe>
                          )}
                        </div>
                        <div className="contentBox">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  ref={autoCompleteRef}
                                  value={query}
                                  onChange={(event) =>
                                    setQuery(event.target.value)
                                  }
                                  className="form-control"
                                  required
                                  placeholder="Search For Address"
                                />
                                {/* <span>
                                <FaSearch />
                              </span> */}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  disabled
                                  value={city}
                                  className="form-control"
                                  placeholder="Add City"
                                />
                                <span>
                                  <FaMap />
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  disabled
                                  value={state}
                                  className="form-control"
                                  placeholder="Add Province"
                                />
                                <span>
                                  <FaHandHolding />
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={postalCode}
                                  className="form-control"
                                  placeholder="Add Postal Code"
                                  maxLength={7}
                                  onChange={(e) => {
                                    setPostalCode(e.target.value);
                                  }}
                                />
                                <span>
                                  <FaCode />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={landMark}
                                  onChange={(event) =>
                                    setLandMark(event.target.value)
                                  }
                                  className="form-control"
                                  placeholder="Add Landmark"
                                />
                                <span>
                                  <FaMapMarked />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  disabled
                                  value={fullAddress}
                                  className="form-control"
                                  placeholder="Add Address Line 1"
                                />
                                <span>
                                  <FaHome />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={fullAddress2}
                                  onChange={(event) =>
                                    setFullAddress2(event.target.value)
                                  }
                                  className="form-control"
                                  placeholder="Add Address Line 2"
                                />
                                <span>
                                  <FaHome />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="workProfileForm">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        {/* <h4>Work Profile</h4> */}
                        <p>I wish to work in these specialties</p>
                      </div>
                    </div>
                    <div className="row">
                      {specialityList?.map((item, i) => {
                        // //console.log(item);
                        let flexCheckDefault = `${item.name}`;
                        return (
                          <>
                            <div className="col-sm-6 col-md-4 col-lg-3" key={i}>
                              <div className="form-check" key={i}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={item.id}
                                  checked={item.isChecked}
                                  name={flexCheckDefault}
                                  id={flexCheckDefault}
                                  onClick={(e) => onSpeciality(e, i)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexCheckDefault-${i}`}
                                >
                                  {item.name}
                                </label>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="nextBtn">
                    {/* <Link to="/myprofile">Submit<span><FaArrowRight /></span></Link> */}
                    <div className="nextBtn">
                      <button
                        onClick={(e) => handleSubmit()}
                        disabled={isLoading}
                      >
                        Submit{" "}
                        <span>
                          {isLoading ? (
                            <Oval
                              color="white"
                              height={20}
                              width={20}
                              className="Loadingstyle"
                            />
                          ) : (
                            <FaArrowRight />
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default UserProfile;
