import React, { useEffect } from 'react';
import { FaMapMarkerAlt, FaDollarSign } from 'react-icons/fa';
import './earning.scss';
import Navbar from '../../Pages/Header/Navbar';
import Footer from '../../Pages/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const MyEarning = () => {

    const navigate = useNavigate();
    const cookie = new Cookies();
    const token = cookie.get("token");

    useEffect(() => {
        if (!token) {
            toast.warning(
              "your session has been expired ...kindly login again.",
              "yellow"
            );
            navigate(`/guard/login`);
          }
    },[])

    return (
        <>
            <Navbar />
            <section className='my_earning'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <div className='detailsBox'>
                                <div className='rate_title'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-4'>
                                            <h4>My Earnings</h4>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='earningBtn'>
                                                <a href='#' className='allBtn'>All Jobs</a>
                                                <a href='#' className='updomeBtn'>Upcoming</a>
                                                <a href='#' className='withdrawBtn'>Withdrawn</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-lg-4'>
                                        <div className='flexBox'>
                                            <h6>Mall Security Job</h6>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <p className='mapText'><FaMapMarkerAlt />123, Street Name,City Name</p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='starDate'>Start Date : <span>12/09/2021</span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Hourly Rate:<span><FaDollarSign />8/hr </span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Hours : <span>8 Hrs</span> </p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Amount :<span><FaDollarSign />40 </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-4'>
                                        <div className='flexBox'>
                                            <h6>Mall Security Job</h6>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <p className='mapText'><FaMapMarkerAlt />123, Street Name,City Name</p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='starDate'>Start Date : <span>12/09/2021</span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Hourly Rate:<span><FaDollarSign />8/hr </span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Hours : <span>8 Hrs</span> </p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Amount :<span><FaDollarSign />40 </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-4'>
                                        <div className='flexBox'>
                                            <h6>Mall Security Job</h6>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <p className='mapText'><FaMapMarkerAlt />123, Street Name,City Name</p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='starDate'>Start Date : <span>12/09/2021</span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Hourly Rate:<span><FaDollarSign />8/hr </span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Hours : <span>8 Hrs</span> </p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Amount :<span><FaDollarSign />40 </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-4'>
                                        <div className='flexBox'>
                                            <h6>Mall Security Job</h6>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <p className='mapText'><FaMapMarkerAlt />123, Street Name,City Name</p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='starDate'>Start Date : <span>12/09/2021</span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Hourly Rate:<span><FaDollarSign />8/hr </span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Hours : <span>8 Hrs</span> </p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Amount :<span><FaDollarSign />40 </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-4'>
                                        <div className='flexBox'>
                                            <h6>Mall Security Job</h6>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <p className='mapText'><FaMapMarkerAlt />123, Street Name,City Name</p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='starDate'>Start Date : <span>12/09/2021</span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Hourly Rate:<span><FaDollarSign />8/hr </span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Hours : <span>8 Hrs</span> </p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Amount :<span><FaDollarSign />40 </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-lg-4'>
                                        <div className='flexBox'>
                                            <h6>Mall Security Job</h6>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <p className='mapText'><FaMapMarkerAlt />123, Street Name,City Name</p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='starDate'>Start Date : <span>12/09/2021</span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Hourly Rate:<span><FaDollarSign />8/hr </span></p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Hours : <span>8 Hrs</span> </p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <p className='hourRate'>Total Amount :<span><FaDollarSign />40 </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default MyEarning;
