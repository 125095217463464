import React , {useState, useEffect} from 'react';
import Navbar from '../../Pages/Header/Navbar';
import mapIcon from '../../images/map.png';
import mapImg from '../../images/mapImg.png';
import {FaStar,FaCalendar,FaStarHalf,FaArrowLeft,FaTimesCircle} from 'react-icons/fa';
import './job_page2.scss';

const JobPage2 = () => {
    
  return (
    <>
    <Navbar />
    <section className='job_page2'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='arrow_icon'>
                        <a href="#"><FaArrowLeft /></a>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <div className='mainBox'>
                        <div className='row mb-3'>
                            <div className='col-md-6'>
                                <h4>Job Page</h4>
                            </div>
                            <div className='col-md-6'>
                                <div className='date_title'>
                                    <p><FaCalendar /> 12/09/2021</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='lBox'>
                                    <h5>Mall Security Job</h5>
                                    <p className='adderess'><img src={mapIcon}  className='img-fluid' alt="mapIcon"/> 123,street Name, City Name</p>
                                    <p style={{color:'#4a4a4a'}}>Tart fruitcake brownie chupa chups candy jujubes biscuit. Oat cake biscuit donut jelly-o toffee croissant croissant. Bear claw tootsie roll pudding chocolate bar shortbread donut cookie. Halvah chocolate cake bonbon caramels </p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-4'>
                                <div className='rBox'>
                                    <div className='imgBox'>
                                        <img src={mapImg} className='img-fluid' alt="map Image" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='starDate'>
                                    <p className='star'>
                                        <span>Job Rating</span>
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStarHalf />
                                    </p>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='desc'>
                                            <div className='flexBox'>
                                                <p><span>Start Time</span> : 09:30 AM</p>
                                                <p><span>End Time</span> : 06:30 AM</p>
                                                <p><span>Job Type</span> : One-Time</p>
                                                <p><span>Transport</span> : Own Vehicle</p>
                                            </div>
                                            <div className='flexBox spaceRight'>
                                                <p><span>Hourly Rate</span> : $8/hr</p>
                                                <p><span>Speciality</span> : Mall Security</p>
                                                <p><span>Tot.Hours</span> : 8 Hrs</p>
                                                <p><span>Dress Code</span> : HV Vest</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='imgBtn'>
                                    <a href="#" className='cancel_btn'  data-bs-toggle="modal" data-bs-target="#cancelDetails">Cancel</a>
                                    <a href="#" className='view_btn'> View Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div className="modal fade cancelDetails" id="cancelDetails" tabIndex="-1" aria-labelledby="cancelDetailsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="cancelDetailsLabel"></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><FaTimesCircle /></button>
                </div>
                <div className="modal-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Cancellation Reason</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <textarea className="form-control textArea" rows="4" id="validationTextarea" placeholder="Add Comment"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn submitBtn" data-bs-dismiss="modal">Submit</button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default JobPage2;
