import React, { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { guardAgreement, getStaticContent } from "../../utils/request";
import "./contract_agreement.scss";
import parse from 'html-react-parser';

const ContractAgreement = () => {
  /*const { id } = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");

  useEffect(() => {
    getTermsAndCondition();
  }, [id]);

  const getTermsAndCondition = async () => {
    const response = await getStaticContent({ type: 'terms' });
    if (response.data.code === 200) {
      setDescription(response.data.data.description);
    }
  };

  const handleAgreement = async (event) => {
    event.preventDefault();
    const requestData = {
      id: id,
      isTermsOfUse: true,
    };
    const updatedResponse = await guardAgreement(requestData);
    if (updatedResponse.data.code === 200) {
      navigate(`/guard-agreement/${updatedResponse.data.data.id}`);
    }
  };
  */


  return (
    <>
      <section className="contract_agreement">
        <div className="container">
          <Logo />
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="content">
                <div className="privacyTitle">
                  <div className="row align-items-lg-center">
                    <div className="col-md-12 text-center">
                      <h4>Independent Contractor Agreement</h4>
                    </div>
                  </div>
                </div>
                <div className="notifyBox">
                <p className="cls-maxheight-scroll">
                Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.
                Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.
                Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.
                Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.
                </p>
                  <div className="time">
                    <a href="#">
                      Agree & Continue <FaArrowRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContractAgreement;
