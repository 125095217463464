import { Row } from "antd";
import React, { useEffect, useState } from "react";
import { FaBook, FaBriefcase, FaClock, FaPlay, FaStar } from "react-icons/fa";
import "./on_job.scss";
import {
  breakReasonsList,
  takeBreak,
  endBreak,
  endTakeBreak,
} from "../../utils/request";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const OnJob = () => {
  const [reasonList, setReasonList] = useState([]);
  const [breakReasonId, setBreakReasonId] = useState([]);
  const cookie = new Cookies();
  const guardId = cookie.get("guardId");
  const jobId = cookie.get("jobId");
  const shiftId = cookie.get("shiftId");
  const token = cookie.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      breakReasonList();
      // takeBreaks();
      // endBreaks();
      // endTakeBreaks();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Function for get break reason list
  const breakReasonList = async () => {
    const response = await breakReasonsList();
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      setReasonList(response.data.data);
    }
  };
  // Function for take break
  const takeBreaks = async () => {
    const requestedData = {
      jobId: jobId,
      shiftId: shiftId,
      guardId: guardId,
      breakReasonId: breakReasonId,
      description: "Testing Take Break",
    };
    const response = await takeBreak(requestedData);
    // if (response.data.code === 200) {
    // }
  };

  const handleList = (e) => {
    setBreakReasonId(e);
  };

  return (
    <>
      <section className="on_job">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="content">
                <div className="title">
                  <div className="heading">
                    <h1>On Job</h1>
                  </div>
                </div>
                <div className="job_box">
                  <div className="row">
                    <div className="col-md-12">
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-eNotes-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-eNotes"
                            type="button"
                            role="tab"
                            aria-controls="pills-eNotes"
                            aria-selected="true"
                          >
                            <FaBook />
                            E-Notes
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-endJob-tab"
                            data-bs-toggle="modal"
                            data-bs-target="#endJob"
                            type="button"
                            role="tab"
                          >
                            <FaBriefcase />
                            End Job
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-sos-tab"
                            data-bs-toggle="modal"
                            data-bs-target="#sos"
                            type="button"
                            role="tab"
                          >
                            <span
                              style={{
                                fontSize: "13px",
                                display: "inline-block",
                                lineHeight: "0",
                                marginRight: "5px",
                              }}
                            >
                              SOS
                            </span>
                            Energency
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-break-tab"
                            data-bs-toggle="modal"
                            data-bs-target="#break"
                            type="button"
                            role="tab"
                          >
                            <FaClock />
                            Take Break
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control timeInput"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="Time on Job :"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control timeInput"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="Total Hours :"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control timeInput"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="Total Remaining :"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control timeInput"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="Total Earning :"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-eNotes"
                        role="tabpanel"
                        aria-labelledby="pills-eNotes-tab"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <h4>eNotes Entries</h4>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-md-5">
                            <div className="box">
                              <div className="descBox">
                                <h6>Report on Duty, meeting at office</h6>
                                <p>
                                  <FaClock /> <span>01:30 PM, 12/12/2021</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="box">
                              <div className="descBox">
                                <h6>Report on Duty, meeting at office</h6>
                                <a href="#">
                                  <FaPlay />
                                  <span></span>
                                </a>
                                <p>
                                  <FaClock /> <span>01:30 PM, 12/12/2021</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="box">
                              <div className="descBox">
                                <h6>Report on Duty, meeting at office</h6>
                                <p>
                                  <FaClock /> <span>01:30 PM, 12/12/2021</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="box">
                              <div className="descBox">
                                <h6>Report on Duty, meeting at office</h6>
                                <a href="#">
                                  <FaPlay />
                                  <span></span>
                                </a>
                                <p>
                                  <FaClock /> <span>01:30 PM, 12/12/2021</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade takeBreak"
        id="break"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Take Break
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <select
                      onChange={(e) => handleList(e.target.value)}
                      className="form-select"
                      aria-label="Default select example"
                      name="Select Reason"
                      defaultValue="Select Reason"
                    >
                      <option selected>Select Reason</option>
                      {reasonList.map((user, index) => {
                        return <option value={user.id}>{user.name}</option>;
                      })}

                      {/* <option value="1">${reason1}c</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn submitBtn"
                data-bs-dismiss="modal"
                onClick={() => takeBreaks()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade endJob"
        id="endJob"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Security Guard Job
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>End Job Reason</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control textArea"
                      rows="8"
                      placeholder="Add Comment"
                      id="floatingTextarea"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control rating"
                      type="text"
                      placeholder="Rate Client :"
                      aria-label="default input example"
                    />
                    <div className="starIcon">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn submitBtn"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade sos"
        id="sos"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Alert Team Now!
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="notiFi">
                      <label className="switch btn-color-mode-switch">
                        <input
                          type="checkbox"
                          name="color_mode"
                          id="color_mode"
                          value="1"
                        />
                        <label
                          htmlFor="color_mode"
                          data-on="SOS"
                          data-off="OFF"
                          className="btn-color-mode-switch-inner"
                        ></label>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnJob;
