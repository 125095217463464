import React, { useEffect, useState } from "react";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import DocsImg from "../../images/imge placeholder.png";
import Cookies from "universal-cookie";
import { Oval } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  FaUserAlt,
  FaBirthdayCake,
  FaArrowRight,
  FaInfoCircle,
  // FaArrowLeft,
  FaEnvelope,
  FaPhone,
  // FaPen,
  // FaCamera,
  // FaUserCircle,
  FaPaperclip,
  // FaBriefcase,
  FaPlusCircle,
  FaEdit,
  FaTimes,
  FaCalendar,
  FaPen,
  FaCaretDown,
} from "react-icons/fa";
// import LogoIcon from "../Logo/Logo";
// import { Link } from "react-router-dom";
import { API_URL } from "../../utils/constants";
import "./Registration.scss";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  getGuardProfile,
  updateGuard,
  getSpecialityList,
} from "../../utils/request";
// import ApiClient from "../";

import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled } from "@mui/material/styles";

const Registration = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const loginStatus = searchParams.get("status");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditInfo, setIsEditInfo] = useState(false);
  const [token, setToken] = useState("");
  const [guard, setGuard] = useState({});
  const [specialityList, setSpecialityList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [error, seterror] = useState("");
  const [securityRegistrationExpiryDate, setSecurityRegistrationExpiryDate] =
    useState("");
  const [
    errorSecurityRegistrationExpiryDate,
    setErrorSecurityRegistrationExpiryDate,
  ] = useState(false);
  const [errorSecurityRegistrationImage, setErrorSecurityRegistrationImage] =
    useState(false);
  const [max, setMax] = useState(moment.utc().format("yyyy-MM-DD"));
  const [min, setMin] = useState(moment.utc().format("yyyy-MM-DD"));
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState(new Date());
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");

  const [dobError, setdobError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [documentErrors, setDocumentErrors] = useState([{}]);
  const [employerErrors, setEmployerErrors] = useState([{}]);
  const string = /^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}$/;

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [documentFields, setDocumentFields] = useState([
    {
      idType: "",
      idNumber: "",
      fileName: "",
      idProof: "",
      tempPreview: "",
    },
  ]);
  const [employerFields, setEmployerFields] = useState([
    {
      employerName: "",
      projectName: "",
      from: "",
      to: "",
      speciality: "",
    },
  ]);
  const [identityFile, setIdentityFile] = useState([]);
  const [licenseFile, setLicenseFile] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [licensePreviewUrl, setLicensePreviewUrl] = useState("");
  const cookie = new Cookies();
  const Token = cookie.get("token");
  const years = range(1900, 3000, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var img = [];

  useEffect(() => {
    if (Token) {
      getGuardProfileData();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
    // setToken(sessionStorage.getItem("token"))
  }, [id]);
  // Function for get profile data
  const getGuardProfileData = async () => {
    const response = await getGuardProfile(id);
    //console.log(response);
    if (response?.data?.code === 200) {
      localStorage.setItem(
        "employment",
        JSON.stringify(response?.data?.data?.guard?.employmentDetails)
      );
      //console.log(response?.data?.data?.guard?.employmentDetails);
      if (response.data && response.data.data && response.data.data.guard) {
        setGuard(response.data.data.guard);
        setFirstName(
          guard.firstName ? guard.firstName : response.data.data.guard.firstName
        );
        setMiddleName(
          guard.middleName
            ? guard.middleName
            : response.data.data.guard.middleName
        );
        setLastName(
          guard.lastName ? guard.lastName : response.data.data.guard.lastName
        );
        setDOB(
          guard.dob
            ? new Date(guard.dob)
            : new Date(response.data.data.guard.dob)
        );
        // setDOB(guard.dob ? (moment.utc((new Date(guard.dob)).format("yyyy-MM-DD"))) : (moment.utc((new Date(response.data.data.guard.dob)).format("yyyy-MM-DD"))));
        setemail(guard.email ? guard.email : response.data.data.guard.email);
        setPhone(
          guard.mobileNumber
            ? guard.mobileNumber
            : response.data.data.guard.mobileNumber
        );
        if (
          guard.securityRegistrationDocument ||
          response.data.data.guard.securityRegistrationDocument
        ) {
          setLicenseFile(
            guard.securityRegistrationDocument
              ? guard.securityRegistrationDocument
              : response.data.data.guard.securityRegistrationDocument
          );
        }

        if (
          response.data.data.guard.securityRegistrationDocument !== undefined &&
          response.data.data.guard.securityRegistrationDocument !== ""
        ) {
          setLicensePreviewUrl(
            API_URL +
              "/media/preview?filename=" +
              response.data.data.guard.securityRegistrationDocument
          );
        } else {
          setLicensePreviewUrl(DocsImg);
        }

        if (response.data.data.guard.profileImage) {
          setImagePreviewUrl(
            API_URL +
              "/media/preview?filename=" +
              response.data.data.guard.profileImage
          );
          setProfilePhoto(response.data.data.guard.profileImage);
        }
        if (
          response.data.data.guard.securityRegistrationExpiryDate !== undefined
        ) {
          setSecurityRegistrationExpiryDate(
            new Date(response.data.data.guard.securityRegistrationExpiryDate)
          );
        }
        // setSecurityRegistrationExpiryDate(
        //   guard.securityRegistrationExpiryDate !== undefined
        //     ? new Date(guard.securityRegistrationExpiryDate)
        //     : new Date(response.data.data.guard.securityRegistrationExpiryDate)
        // );
        if (
          response.data.data.guard.identityDocuments &&
          response.data.data.guard.identityDocuments.length != 0
        ) {
          setDocumentFields(response.data.data.guard.identityDocuments);
        }
        if (
          response.data.data.guard.employmentDetails &&
          response.data.data.guard.employmentDetails.length != 0
        ) {
          for (
            let i = 0;
            i < response.data.data.guard.employmentDetails.length;
            i++
          ) {
            response.data.data.guard.employmentDetails[i].speciality =
              response.data.data.guard.employmentDetails[i]?.speciality?.id;
            response.data.data.guard.employmentDetails[i].from = moment
              .utc(new Date(response.data.data.guard.employmentDetails[i].from))
              .format("yyyy-MM-DD");
            response.data.data.guard.employmentDetails[i].to = moment
              .utc(new Date(response.data.data.guard.employmentDetails[i].to))
              .format("yyyy-MM-DD");
          }
          setEmployerFields(JSON.parse(localStorage.getItem("employment")));
          // //console.log(guard);
        }
      }
    }
    const guardListRes = await getSpecialityList();
    if (guardListRes.data.code === 200) {
      setSpecialityList(guardListRes.data.data);
    }
  };

  const isEditDetails = async (e) => {
    setIsEditInfo(!isEditInfo);
  };

  // const handleUploadFile = async () => {
  //   if (documentType !== "" && documentNo !== "" && documentFile !== "") {
  //     var bodyFormData = new FormData();
  //     bodyFormData.append("documentType", documentType);
  //     bodyFormData.append("documentNumber", documentNo);
  //     bodyFormData.append("file", documentFile);
  //     const res = await uploadDocument(bodyFormData);

  //   } else {
  //     alert("Fill All Details");
  //   }
  // };
  // Function for handle add employer field
  const handleAddEmployerFields = async () => {
    setEmployerFields([
      ...employerFields,
      { employerName: "", projectName: "", from: "", to: "", speciality: "" },
    ]);
  };
  // Function for remove employer field
  const removeEmployerRow = async (index) => {
    if (employerFields.length > 1) {
      employerFields.splice(index, 1);
      setEmployerFields([...employerFields]);
    }
  };
  // Function for handle add document field
  const handleAddDocumentFields = async () => {
    setDocumentFields([
      ...documentFields,
      { idType: "", idNumber: "", idProof: "", fileName: "", tempPreview: "" },
    ]);
  };
  // Function for handle licence file
  const handleLicenseFile = async (e) => {
    const allowedBytes = 2 * 1024 * 1024;
    if (e.target.files[0].size > allowedBytes) {
      alert("Size is more than 2 MB");
      return;
    }
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setLicenseFile(file);
      setLicensePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    setErrorSecurityRegistrationImage(false);
  };
  // Function for handle profile photo
  const handleProfilePhoto = async (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      // this.setState({
      //   file: file,
      //   imagePreviewUrl: reader.result
      // });
      setProfilePhoto(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  // Function for remove identification row
  const removeIdentificationRow = async (index, val) => {
    const value = [...documentFields];
    const file = [...identityFile];
    if (value.length > 1) {
      //console.log("value====", value);
      value.splice(index, 1);
      setDocumentFields(value);
    }
    if (file.length > 1) {
      file.splice(index, 1);
      setIdentityFile(file);
    }
  };
  // Function for handle file change
  const handleChange = async (index, e) => {
    const value = [...documentFields];
    if (e.target.type === "text" || e.target.type == "select-one") {
      value[index][e.target.name] = e.target.value;
      setDocumentFields(value);
      checkDocumentValidationOnSubmit();
    } else {
      const allowedBytes = 2 * 1024 * 1024;
      if (e.target.files[0].size > allowedBytes) {
        alert("Size is more than 2 MB");
        return;
      }
      let reader = new FileReader();
      const file = [...identityFile];
      file[index] = e.target.files[0];
      // //console.log('file-----', file)
      setIdentityFile(file);
      value[index].fileName = e.target.files[0].name;
      // //console.log('value[index][e.target.name]===', value[index][e.target.name], e.target.files[0].name)
      reader.onloadend = () => {
        // img.push(reader.result);
        // localStorage.setItem(`file${index}`, reader.result);
        // //console.log(index);
        // //console.log(img);
        // //console.log('reader====',reader.result)
        value[index].idProof = "";
        value[index].tempPreview = reader.result;
        setDocumentFields(value);
        checkDocumentValidationOnSubmit();
      };
      reader.readAsDataURL(file[index]);
    }
  };
  // Function for handle employe change
  const handleEmployChange = async (index, e) => {
    const value = [...employerFields];
    value[index][e.target.name] = e.target.value;

    setEmployerFields(value);
    checkEmployerValidationOnSubmit();
  };
  // Functions for handle validation
  const handleCheckDOB = (value) => {
    setDOB(value);
    var today = new Date();
    var birthDate = new Date(value);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age >= 18) {
      setdobError(false);
    } else {
      setdobError(true);
    }
  };

  const checkDocumentValidationOnSubmit = async () => {
    var valid = true;
    const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
    // //console.log('documentFields===',documentFields)
    let errorMsg = documentFields.map((data, key) => {
      let error = {};
      if (data.idNumber === "") {
        error.errorIdNumber = "Identification number is required";
        valid = false;
      } else if (alphanumericRegex.test(data.idNumber) === false) {
        error.errorIdNumber = "Please enter valid identification number";
        valid = false;
      } else {
        error.errorIdNumber = "";
      }
      if (data.idType === "") {
        error.errorIdType = "Identification type is required";
        valid = false;
      } else {
        error.errorIdType = "";
      }
      if (data.idProof === "" && data.tempPreview === "") {
        error.errorFileName = "Identification file is required";
        valid = false;
      } else {
        error.errorFileName = "";
      }
      return error;
    });
    setDocumentErrors(errorMsg);
    return valid;
  };

  const checkEmployerValidationOnSubmit = async () => {
    var valid = true;
    let errorMsg = employerFields.map((data, key) => {
      let error = {};

      if (!string.test(data.projectName)) {
        error.errorprojectName = "Please enter valid position held name";
        valid = false;
      } else {
        error.errorprojectName = "";
      }
      if (data.to !== "" && data.from === "") {
        error.errorfrom = "From date is required";
        valid = false;
      } else {
        error.errorfrom = "";
      }

      if (data.to === "" && data.from !== "") {
        error.errorto = "To date is required";
        valid = false;
      } else {
        error.errorto = "";
      }

      if (data.to && data.from) {
        var mStart = moment.utc(data.to);
        var mEnd = moment.utc(data.from);
        // mStart.isBefore(mEnd)
        if (mStart.isBefore(mEnd)) {
          error.errorto = "To Date should be greater than From Date";
          valid = false;
        } else {
          error.errorfrom = "";
        }
      }
      return error;
    });
    setEmployerErrors(errorMsg);
    return valid;
  };
  // Function for handle date validation
  const TDate = (value) => {
    var UserDate = value;
    var ToDate = new Date();
    if (new Date(UserDate).getTime() <= ToDate.getTime()) {
      // alert("The Date must be Bigger or Equal to today date");
      return false;
    }
    return true;
  };
  // Function for signup
  const handleSave = async (e) => {
    e.preventDefault();
    // var isEmptys = false;
    var isDocument = await checkDocumentValidationOnSubmit();
    // var isEmployer = await checkEmployerValidationOnSubmit();;
    // if (employerFields.length === 1) {
    //   isEmptys = Object.values(employerFields[0]).every(x => x === null || x === '');
    //   if (!isEmptys) {
    //     isEmployer = await checkEmployerValidationOnSubmit();
    //   }
    // }

    // if (profilePhoto === "") {
    //   toast.warning("Please select profile photo ");
    //   return;
    // }

    if (
      securityRegistrationExpiryDate === "" ||
      securityRegistrationExpiryDate === "Invalid date"
    ) {
      setErrorSecurityRegistrationExpiryDate(true);
      return;
    } else {
      setErrorSecurityRegistrationExpiryDate(false);
    }
    if (licenseFile === "") {
      setErrorSecurityRegistrationImage(true);
      return;
    } else {
      setErrorSecurityRegistrationImage(false);
    }
    if (isDocument) {
      // if (!isEmptys && isEmployer) {
      var bodyFormData = new FormData();

      bodyFormData.append("firstName", firstName);
      bodyFormData.append("middleName", middleName);
      bodyFormData.append("lastName", lastName);
      bodyFormData.append("mobileNumber", phone);
      bodyFormData.append("dob", dob);
      bodyFormData.append("email", email);
      bodyFormData.append(
        "securityRegistrationExpiryDate",
        moment.utc(securityRegistrationExpiryDate).format()
      );
      bodyFormData.append("securityRegistrationDocument", licenseFile);
      bodyFormData.append("securityRegistrationNumber", guard.licenseNumber);
      bodyFormData.append("profileImage", profilePhoto);

      if (loginStatus !== "loggedin") {
        bodyFormData.append("profileUpdate", "first");
      } else {
        bodyFormData.append("profileUpdate", "second");
      }
      // for (let i = 0; i < documentFields.length; i++) {
      //  delete documentFields[i].tempPreview;
      // }
      bodyFormData.append("identityDocuments", JSON.stringify(documentFields));
      if (employerFields.length >= 1) {
        var isEmptys = Object.values(employerFields[0]).every(
          (x) => x === null || x === ""
        );
        if (!isEmptys) {
          var isEmployer = await checkEmployerValidationOnSubmit();
          if (isEmployer) {
            bodyFormData.append(
              "employmentDetails",
              JSON.stringify(employerFields)
            );
          } else {
            return;
          }
        }
      }
      identityFile.map((item, i) => {
        bodyFormData.append(`identityFile${i}`, item);
      });
      setIsLoading(true);
      const res = await updateGuard(id, bodyFormData);
      //console.log(res);
      if (res.data !== undefined) {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          // navigate(`/review/${id}`);
          // cookie.set("guardId", id);
          localStorage.removeItem("employment");
          navigate(
            loginStatus !== "loggedin"
              ? `/work-profile/${id}?isShow=with_out_login`
              : `/emp_details/${id}`
          );
          setIsLoading(false);
          setIsError(false);
        }
      } else {
        setIsLoading(false);
        setIsError(true);
        seterror(res.error);
      }
      // }
    }
  };
  // Functions for validation
  const handleEmailChange = (value) => {
    setemail(value);
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (regex.test(value) === false) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  const handleMobileChange = (value) => {
    setPhone(value);
    const regex = /^\d{10}$/;
    if (regex.test(value) === false) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const _secDateHandle = (value) => {
    setSecurityRegistrationExpiryDate(value);
    if (value === "") {
      setErrorSecurityRegistrationExpiryDate(true);
    } else {
      setErrorSecurityRegistrationExpiryDate(false);
    }
  };

  return (
    <>
      <Header_inner />
      <section className="registrationPage">
        <div className="container">
          {/* <LogoIcon /> */}
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="regForm">
                <form>
                  <div className="regBox">
                    <div className="row">
                      <div className="col-md-12">
                        <h4>Registration</h4>
                        <div className="editDetail">
                          <a
                            type="button"
                            onClick={(e) =>
                              navigate(
                                loginStatus !== "loggedin"
                                  ? (window.location.href = "/")
                                  : `/emp_details/${id}`
                              )
                            }
                          >
                            <i class="fa-solid fa-arrow-left"></i>
                            <span>
                              {loginStatus !== "loggedin"
                                ? "Back To Home"
                                : "Back"}
                            </span>
                          </a>
                        </div>
                        {isError && (
                          <div className="alert alert-danger">
                            Please Fill Valid Data
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="identityBox profileBox">
                    <div className="col-md-12">
                      <div className="profileHeading">
                        <h5 className="previous_emp_title">
                          Personal Information
                        </h5>
                        <div className="editDetail">
                          <button
                            type="button"
                            onClick={(e) => isEditDetails(e)}
                          >
                            <span>
                              {!isEditInfo ? <FaEdit /> : <FaTimes />}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="avatar">
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <input
                            type="file"
                            id="uploadPhoto"
                            className="form-control profile-select d-none"
                            htmlFor="exampleInputtext"
                            aria-describedby="textHelp"
                            // capture="environment"
                            name="profilePhoto"
                            onChange={(e) => handleProfilePhoto(e)}
                            accept="image/*"
                          />

                          <label htmlFor="uploadPhoto" className="profileImg">
                            {imagePreviewUrl ? (
                              <img src={imagePreviewUrl} />
                            ) : (
                              <span>
                                <FaPen />
                              </span>
                            )}
                            <div className="icon">
                              <div className="camIcon">
                                <label htmlFor="uploadPhoto">
                                  <span>
                                    <FaPen />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="col-md-12">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputtext"
                                  aria-describedby="textHelp"
                                  value={firstName}
                                  placeholder="First Name"
                                  disabled={!isEditInfo}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                                <span>
                                  <FaUserAlt />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputtext"
                                  aria-describedby="textHelp"
                                  value={middleName}
                                  placeholder="Middle Name"
                                  disabled={!isEditInfo}
                                  onChange={(e) =>
                                    setMiddleName(e.target.value)
                                  }
                                />
                                <span>
                                  <FaUserAlt />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputtext"
                                  aria-describedby="textHelp"
                                  value={lastName}
                                  placeholder="Last Name"
                                  disabled={!isEditInfo}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                                <span>
                                  <FaUserAlt />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="exampleInputtext"
                                  aria-describedby="textHelp"
                                  value={email}
                                  placeholder="example@gmail.com"
                                  disabled={!isEditInfo}
                                  onChange={(e) =>
                                    handleEmailChange(e.target.value)
                                  }
                                />
                                <span>
                                  <FaEnvelope />
                                </span>
                              </div>
                              {emailError && (
                                <div className="invalid-feedback">
                                  Please enter valid email
                                </div>
                              )}
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={
                                    phoneError
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="exampleInputtext"
                                  aria-describedby="textHelp"
                                  value={phone}
                                  maxLength={10}
                                  placeholder="Mobile No"
                                  disabled={!isEditInfo}
                                  onChange={(e) =>
                                    handleMobileChange(e.target.value)
                                  }
                                />
                                <span>
                                  <FaPhone />
                                </span>
                              </div>
                              {phoneError ? (
                                <div className="invalid-feedback">
                                  Please enter valid Phone Number
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <DatePicker
                                  renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                  }) => (
                                    <div
                                      style={{
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decreaseMonth();
                                        }}
                                        disabled={prevMonthButtonDisabled}
                                      >
                                        {"<"}{" "}
                                      </button>
                                      <select
                                        value={getYear(date)}
                                        onChange={({ target: { value } }) =>
                                          changeYear(value)
                                        }
                                      >
                                        {years.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <select
                                        value={months[getMonth(date)]}
                                        onChange={({ target: { value } }) =>
                                          changeMonth(months.indexOf(value))
                                        }
                                      >
                                        {months.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          increaseMonth();
                                        }}
                                        disabled={nextMonthButtonDisabled}
                                      >
                                        {" "}
                                        {">"}
                                      </button>
                                    </div>
                                  )}
                                  className={
                                    dobError
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  placeholderText="dd/mm/yyyy"
                                  selected={dob}
                                  disabled={!isEditInfo}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(e) => handleCheckDOB(e)}
                                />
                                {/* <input
                                  type="date"
                                  className="form-control"
                                  id="exampleInputtext"
                                  aria-describedby="textHelp"
                                  value={dob}
                                  placeholder="DOB"
                                  disabled={!isEditInfo}
                                  onChange={(e) =>
                                    handleCheckDOB(e.target.value)
                                  }
                                /> */}
                                <span>
                                  <FaBirthdayCake />
                                </span>
                              </div>
                              {dobError && (
                                <div className="invalid-feedback">
                                  Please valid date of birth
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        {/* <div className="preTitle">
                          <h5>Previous Employer</h5>
                        </div> */}
                        <div className="identityTitle  mt-3 mb-2">
                          <h5 className="previous_emp_title">
                            Previous Employer
                          </h5>
                          <div className="editDetail">
                            <a
                              className="btn addBtn"
                              type="button"
                              onClick={handleAddEmployerFields}
                            >
                              <span>
                                <FaPlusCircle />
                              </span>
                              Add
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="identificationDocument mt-2">
                        <div className="table-responsive border-1">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Employer Name</th>
                                <th>Position Held</th>
                                <th>Speciality</th>
                                <th>From Date</th>
                                <th>To Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {employerFields.map((inputField, index) => {
                                // //console.log(inputField);
                                let empName = `empName-${index}`,
                                  pName = `pName-${index}`,
                                  tDate = `tDate-${index}`,
                                  fDate = `fDate-${index}`,
                                  sSelect = `sSelect-${index}`;
                                return (
                                  <>
                                    <tr key={index}>
                                      <td>
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id={empName}
                                            aria-describedby="textHelp"
                                            name="employerName"
                                            placeholder="Employer Name"
                                            maxLength={160}
                                            value={inputField.employerName}
                                            onChange={(e) =>
                                              handleEmployChange(index, e)
                                            }
                                          />
                                          {/* <span>
                                          <FaBriefcase />
                                        </span> */}
                                        </div>
                                        {/* <div className="invalid-feedback mt-1">
                                          {employerErrors.length - 1 >= index &&
                                            employerErrors[index]
                                              .erroremployerName}
                                        </div> */}
                                      </td>
                                      <td>
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id={pName}
                                            aria-describedby="textHelp"
                                            name="projectName"
                                            maxLength={160}
                                            value={inputField.projectName}
                                            placeholder="Position Held"
                                            onChange={(e) =>
                                              handleEmployChange(index, e)
                                            }
                                          />
                                        </div>
                                        <div className="invalid-feedback mt-1">
                                          {employerErrors.length - 1 >= index &&
                                            employerErrors[index]
                                              .errorprojectName}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-group">
                                          <select
                                            className="form-select"
                                            name="speciality"
                                            id={sSelect}
                                            aria-label="Default select example"
                                            defaultValue="Select Speciality"
                                            value={inputField.speciality?._id}
                                            onChange={(e) =>
                                              handleEmployChange(index, e)
                                            }
                                          >
                                            <option selected value="">
                                              Select Speciality
                                            </option>
                                            {specialityList.map((item, i) => {
                                              return (
                                                <>
                                                  <option
                                                    key={i}
                                                    value={item.id}
                                                  >
                                                    {item.name}
                                                  </option>
                                                </>
                                              );
                                            })}
                                          </select>
                                          <span>
                                            <FaCaretDown />
                                          </span>
                                        </div>
                                        {/* <div className="invalid-feedback mt-1">
                                          {employerErrors.length - 1 >= index &&
                                            employerErrors[index]
                                              .errorspeciality}
                                        </div> */}
                                      </td>
                                      <td>
                                        <div className="dateFormat">
                                          <div className="form-group">
                                            {/* <DatePicker
                                              renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <button
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      decreaseMonth();
                                                    }}
                                                    disabled={
                                                      prevMonthButtonDisabled
                                                    }
                                                  >
                                                    {"<"}{" "}
                                                  </button>
                                                  <select
                                                    value={getYear(date)}
                                                    onChange={({
                                                      target: { value },
                                                    }) => changeYear(value)}
                                                  >
                                                    {years.map((option) => (
                                                      <option
                                                        key={option}
                                                        value={option}
                                                      >
                                                        {option}
                                                      </option>
                                                    ))}
                                                  </select>

                                                  <select
                                                    value={
                                                      months[getMonth(date)]
                                                    }
                                                    onChange={({
                                                      target: { value },
                                                    }) =>
                                                      changeMonth(
                                                        months.indexOf(value)
                                                      )
                                                    }
                                                  >
                                                    {months.map((option) => (
                                                      <option
                                                        key={option}
                                                        value={option}
                                                      >
                                                        {option}
                                                      </option>
                                                    ))}
                                                  </select>

                                                  <button
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      increaseMonth();
                                                    }}
                                                    disabled={
                                                      nextMonthButtonDisabled
                                                    }
                                                  >
                                                    {" "}
                                                    {">"}
                                                  </button>
                                                </div>
                                              )}
                                              className="form-control"
                                              id={fDate}
                                              placeholderText="dd/mm/yyyy"
                                              selected={inputField.from}
                                              value={inputField.from}
                                              disabled={!isEditInfo}
                                              dateFormat="dd/MM/yyyy"
                                              onChange={(e) =>
                                                handleEmployChange(index, e)
                                              }
                                            /> */}
                                            <input
                                              type="date"
                                              className="form-control"
                                              id={fDate}
                                              aria-describedby="textHelp"
                                              value={moment
                                                .utc(inputField.from)
                                                .format("yyyy-MM-DD")}
                                              // value="From"
                                              name="from"
                                              placeholder="From"
                                              // max={max}
                                              onChange={(e) =>
                                                handleEmployChange(index, e)
                                              }
                                            />
                                            {/* <span>
                                            <FaCalendar />
                                          </span> */}
                                          </div>
                                          <div className="invalid-feedback mt-1">
                                            {employerErrors.length - 1 >=
                                              index &&
                                              employerErrors[index].errorfrom}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="dateFormat">
                                          <div className="form-group">
                                            <input
                                              type="date"
                                              className="form-control"
                                              id={tDate}
                                              aria-describedby="textHelp"
                                              // value="From"
                                              value={moment
                                                .utc(inputField.to)
                                                .format("yyyy-MM-DD")}
                                              name="to"
                                              placeholder="TO"
                                              // max={max}
                                              onChange={(e) =>
                                                handleEmployChange(index, e)
                                              }
                                            />
                                            {/* <span>
                                            <FaCalendar />
                                          </span> */}
                                          </div>
                                          {/* <div className="invalid-feedback mt-1">
                                            {employerErrors.length - 1 >=
                                              index &&
                                              employerErrors[index].errorfrom}
                                          </div> */}
                                          <div className="invalid-feedback mt-1">
                                            {employerErrors.length - 1 >=
                                              index &&
                                              employerErrors[index].errorto}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        <span
                                          className="closeBtn"
                                          onClick={(e) =>
                                            removeEmployerRow(index)
                                          }
                                        >
                                          <FaTimes />
                                        </span>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="identityBox ">
                    <div className="row">
                      <div className="col-12">
                        <div className="identificationDocument">
                          <div className="row">
                            <div className="col-5 col-md-3">
                              <h5 className="security_reg">
                                Security License Number
                                {/* <span> 
                               <FaInfoCircle />
                                </span>  */}
                                {/* <CustomWidthTooltip title={longText}>
                                  <Button sx={{ m: 1 }}>
                                    {" "}
                                    <FaInfoCircle />
                                  </Button>
                                </CustomWidthTooltip> */}
                                <ClickAwayListener
                                  onClickAway={handleTooltipClose}
                                >
                                  <div>
                                    <Tooltip
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open}
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                      title="We recommend you place your Security license/ID on a flat surface
                                       when taking the photo and crop out any background images prior to uploading."
                                    >
                                      <span
                                        onClick={handleTooltipOpen}
                                        style={{ padding: "10px" }}
                                      >
                                        {" "}
                                        <FaInfoCircle
                                          style={{ color: "black" }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>
                                </ClickAwayListener>
                              </h5>
                              <h5 className="security_reg">
                                Security License Upload
                              </h5>
                              <h5 className="security_reg">
                                Security License Expiry Date
                              </h5>
                            </div>
                            <div className="col-7 col-md-9">
                              <div className="identityTitle">
                                <div className="identiBox securityBox">
                                  <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="exampleInputtext"
                                              aria-describedby="textHelp"
                                              value={
                                                guard.isLicense
                                                  ? guard.licenseNumber
                                                  : ""
                                              }
                                              placeholder="Security License No."
                                              disabled={
                                                guard.isLicense ? true : false
                                              }
                                            />
                                            <span>
                                              <FaUserAlt />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <input
                                              type="file"
                                              className="form-control"
                                              htmlFor="exampleInputtext"
                                              aria-describedby="textHelp"
                                              name="licenseFile"
                                              value=""
                                              placeholder="Security License File"
                                              onChange={(e) =>
                                                handleLicenseFile(e)
                                              }
                                              accept="image/*"
                                            />{" "}
                                            <span>
                                              <FaPaperclip />
                                            </span>
                                          </div>
                                          {errorSecurityRegistrationImage ? (
                                            <div className="invalid-feedback">
                                              Please upload security
                                              registration image
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div className="col-md-12">
                                          <div className="dateFormat">
                                            <div className="form-group">
                                              <DatePicker
                                                renderCustomHeader={({
                                                  date,
                                                  changeYear,
                                                  changeMonth,
                                                  decreaseMonth,
                                                  increaseMonth,
                                                  prevMonthButtonDisabled,
                                                  nextMonthButtonDisabled,
                                                }) => (
                                                  <div
                                                    style={{
                                                      margin: 10,
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <button
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        decreaseMonth();
                                                      }}
                                                      disabled={
                                                        prevMonthButtonDisabled
                                                      }
                                                    >
                                                      {"<"}{" "}
                                                    </button>
                                                    <select
                                                      value={getYear(date)}
                                                      onChange={({
                                                        target: { value },
                                                      }) => changeYear(value)}
                                                    >
                                                      {years.map((option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      ))}
                                                    </select>

                                                    <select
                                                      value={
                                                        months[getMonth(date)]
                                                      }
                                                      onChange={({
                                                        target: { value },
                                                      }) =>
                                                        changeMonth(
                                                          months.indexOf(value)
                                                        )
                                                      }
                                                    >
                                                      {months.map((option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      ))}
                                                    </select>

                                                    <button
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        increaseMonth();
                                                      }}
                                                      disabled={
                                                        nextMonthButtonDisabled
                                                      }
                                                    >
                                                      {" "}
                                                      {">"}
                                                    </button>
                                                  </div>
                                                )}
                                                className="form-control"
                                                placeholderText="dd/mm/yyyy"
                                                selected={
                                                  securityRegistrationExpiryDate
                                                }
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(e) =>
                                                  _secDateHandle(e)
                                                }
                                              />
                                              {/* <input
                                                type="date"
                                                className="form-control"
                                                aria-describedby="textHelp"
                                                // value="From"
                                                name="securityRegistrationExpiryDate"
                                                placeholder="Security License Expiry"
                                                // max={max}
                                                value={
                                                  securityRegistrationExpiryDate
                                                }
                                                min={min}
                                                onChange={(e) =>
                                                  _secDateHandle(e.target.value)
                                                }
                                              /> */}
                                              <span>
                                                <FaCalendar />
                                              </span>
                                            </div>
                                            {errorSecurityRegistrationExpiryDate ? (
                                              <div className="invalid-feedback">
                                                Please enter security
                                                registration expiry date
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-8">
                                      <ul className="doc_upload">
                                        <li>
                                          <div className="imgBox">
                                            {licensePreviewUrl ? (
                                              <img
                                                src={licensePreviewUrl}
                                                className="img-fluid"
                                              />
                                            ) : (
                                              <img
                                                src={DocsImg}
                                                className="img-fluid"
                                              />
                                            )}
                                          </div>
                                          <div className="content">
                                            <h6>Security License No.</h6>
                                            <span>{guard.licenseNumber}</span>
                                            <br />
                                            <span>
                                              {moment
                                                .utc(
                                                  securityRegistrationExpiryDate
                                                )
                                                .format("DD-MM-yyyy")}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loginStatus !== "loggedin" && (
                    <div className="identityBox borderT">
                      <div className="identificationDocument">
                        <div className="row">
                          <div className="col-12">
                            <div className="identityTitle mt-0 mt-md-3 mb-md-2">
                              <h5>Identification</h5>
                              <div className="editDetail">
                                <a
                                  className="btn addBtn"
                                  type="button"
                                  onClick={handleAddDocumentFields}
                                >
                                  <span>
                                    <FaPlusCircle />
                                  </span>
                                  Add
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {documentFields.map((identificationField, i) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-12">
                                  <div className="identiBox" key={i}>
                                    <div className="row w-100">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="idType"
                                            value={identificationField.idType}
                                            defaultValue="Select Identification Type"
                                            onChange={(e) => handleChange(i, e)}
                                          >
                                            <option selected value="">
                                              Select Identification Type
                                            </option>
                                            <option value="Drivers license">
                                              Drivers license
                                            </option>
                                            {/* <option value="Social insurance number">
                                            Social insurance number
                                          </option> */}
                                            <option value="Permanent resident card">
                                              Permanent resident card
                                            </option>
                                            <option value="Other">Other</option>
                                            <option value="Canadian passport">
                                              Canadian passport
                                            </option>
                                          </select>
                                          <span>
                                            <FaCaretDown />
                                          </span>
                                        </div>
                                        <div className="invalid-feedback mt-1">
                                          {documentErrors.length - 1 >= i &&
                                            documentErrors[i].errorIdType}
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputtext"
                                            aria-describedby="textHelp"
                                            onChange={(e) => handleChange(i, e)}
                                            name="idNumber"
                                            value={identificationField.idNumber}
                                            maxLength={15}
                                            placeholder="Identification No."
                                          />
                                        </div>
                                        <div className="invalid-feedback mt-1">
                                          {documentErrors.length - 1 >= i &&
                                            documentErrors[i].errorIdNumber}
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label
                                            className="update_files"
                                            htmlFor={`file` + i}
                                            style={{
                                              minHeight: "40px",
                                              padding: "5px 0px",
                                            }}
                                          >
                                            <input
                                              type="file"
                                              className="form-control d-none"
                                              aria-describedby="textHelp"
                                              name="idProof"
                                              id={`file` + i}
                                              placeholder="Upload Identification"
                                              onChange={(e) =>
                                                handleChange(i, e)
                                              }
                                              accept="image/*"
                                            />
                                            {identificationField?.fileName
                                              ? identificationField?.fileName
                                              : "No file chosen"}
                                            <span>
                                              <FaPaperclip />
                                            </span>
                                          </label>
                                        </div>
                                        <div className="invalid-feedback mt-1">
                                          {documentErrors.length - 1 >= i &&
                                            documentErrors[i].errorFileName}
                                        </div>
                                      </div>
                                    </div>
                                    {documentFields &&
                                    documentFields.length > 1 ? (
                                      <span
                                        className="closeBtn"
                                        onClick={(e) =>
                                          removeIdentificationRow(i, `file` + i)
                                        }
                                      >
                                        <FaTimes />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-md-3">
                                  <div className="identity_docs">
                                    <div className="content">
                                      <h6>{identificationField.idType}</h6>
                                      <span>
                                        {identificationField.idNumber}
                                      </span>
                                    </div>
                                    <div className="imgBox">
                                      {/* <img src={DocsImg} className="img-fluid" /> */}
                                      {/* <img
                                      src={localStorage.getItem(`file${i}`)}
                                      className="img-fluid"
                                    /> */}
                                      {identificationField.idProof !== "" ? (
                                        <img
                                          src={
                                            API_URL +
                                            "/media/preview?filename=" +
                                            identificationField.idProof
                                          }
                                          className="img-fluid"
                                          alt="idProof"
                                        />
                                      ) : identificationField.tempPreview !==
                                        "" ? (
                                        <img
                                          src={identificationField.tempPreview}
                                          className="img-fluid"
                                          alt="tempPreview"
                                        />
                                      ) : (
                                        <img
                                          src={DocsImg}
                                          className="img-fluid"
                                          alt="default"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div className="nextBtn">
                    <button onClick={(e) => handleSave(e)} disabled={isLoading}>
                      Save & Proceed{" "}
                      <span>
                        {isLoading ? (
                          <Oval
                            color="white"
                            height={20}
                            width={20}
                            // className="Loadingstyle"
                          />
                        ) : (
                          <FaArrowRight />
                        )}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Registration;
