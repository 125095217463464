import React, { useEffect, useState } from "react";
import Navbar from "../../Pages/Header/Navbar";
import {
  FaCalendar,
  FaClock,
  FaDownload,
  FaFile,
  FaCaretDown,
} from "react-icons/fa";
import "./withdraw.scss";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { myEarnings } from "../../utils/request";
import moment from "moment";
import Pagination from "react-js-pagination";

const Withdraw = () => {
  const [pageNo, setPageNo] = useState(1);
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [earnings, setEarnings] = useState([]);
  const [earningsList, setEarningsList] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    } else {
      getMyearnings();
    }
  }, []);
  // Function for get earning list
  const getMyearnings = async () => {
    const reqData = {
      searchBy: "",
      limit: 10,
      page: pageNo,
      from: from,
      to: to,
    };
    setIsLoading(true);
    const res = await myEarnings(reqData);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/guard/login`);
    }
    //console.log(res);
    if (res?.data?.code == 200) {
      setIsLoading(false);
      setEarnings(res?.data?.data);
      setEarningsList(res?.data?.data?.earnings?.docs);
      settotalItemsCount(res?.data?.data?.earnings?.total);
    } else {
      setIsLoading(false);
    }
  };
  // Function for handle page change
  const handlePageChange = (pageNumber) => {
    //console.log(pageNo);
    setPageNo(pageNo);
    // pageNo = pageNumber;
    getMyearnings();
  };
  // Function for filter earning list
  const paymentStatus = (pval) => {
    //console.log(pval);
    const isCredit = true;
    const isDebit = true;
    const isBankDeposit = true;
    if (pval == "Credit") {
      const data = earningsList.filter((res) => {
        return res.isCredit == true;
      });
      getMyearnings();
      setEarningsList(data);
    } else if (pval == "Debit") {
      const data = earningsList.filter((res) => {
        return res.isDebit == true;
      });
      setEarningsList(data);
    } else if (pval == "Withdrawn") {
      const data = earningsList.filter((res) => {
        return res.isBankDeposit == true;
      });
      setEarningsList(data);
    }
  };
  // Function for date filter
  const fromData = (fval) => {
    //console.log(moment.utc(fval).format(""));
    setFrom(fval);
    const data = earningsList.filter((res) => {
      return (
        res.createdAt >= moment.utc(from).format() &&
        res.createdAt <= moment.utc(to).format()
      );
    });
    setEarningsList(data);
  };

  const toData = (tval) => {
    //console.log(tval);
    setTo(tval);
    const data = earningsList.filter((res) => {
      return res.createdAt == moment.utc(tval).format();
    });
    setEarningsList(data);
  };

  return (
    <>
      <Navbar />
      <section className="withdrawBox">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="content">
                <div className="withdrawInner">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="title">
                        <h5>
                          Total Earning :{" "}
                          <span>
                            {/* <sub>$</sub>  */}
                            {earnings?.guardWallet?.totalAmount == undefined
                              ? "$" + 0
                              : "$" +
                                earnings?.guardWallet?.totalAmount?.toFixed(
                                  2
                                )}{" "}
                          </span>
                        </h5>
                        <span>|</span>
                        <h5>
                          In Hand Earning :{" "}
                          <span>
                            {/* <sub>$</sub>{" "} */}
                            {earnings?.guardWallet?.totalWithdrawalAmout ==
                            undefined
                              ? "$" + 0
                              : "$" +
                                earnings?.guardWallet?.totalWithdrawalAmout?.toFixed(
                                  2
                                )}{" "}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="transaction_box">
                  <div className="row justify-content-center">
                    <div className="col-md-4">
                      <div className="form-group">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            paymentStatus(e.target.value);
                          }}
                        >
                          {/* <option selected>Select</option> */}
                          <option value="Debit">Debit</option>
                          <option value="Withdrawn">Withdrawn</option>
                          <option value="Credit" selected>
                            Credit
                          </option>
                        </select>
                        <span>
                          <FaCaretDown />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <div className="table-responsive border-1">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Job Title</th>
                              <th>Time / Date</th>
                              <th>Status</th>
                              <th>Transaction</th>
                            </tr>
                          </thead>

                          {isLoading && (
                            <tbody>
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan={6}>
                                  Loading...
                                </td>
                              </tr>
                            </tbody>
                          )}
                          {!isLoading && (
                            <tbody>
                              {earningsList?.length !== 0 ? (
                                earningsList?.map((res, i) => {
                                  return (
                                    <tr>
                                      <td>{res?.jobId?.jobTitle}</td>
                                      <td>
                                        {moment
                                          .utc(res?.createdAt)
                                          .format("DD-MM-YYYY")}
                                      </td>
                                      <td>
                                        {res?.isCredit == true
                                          ? "Credit"
                                          : res?.isDebit == true
                                          ? "Debit"
                                          : res?.isBankDeposit == true
                                          ? "Withdrawl"
                                          : ""}
                                      </td>
                                      <td
                                        className={
                                          res?.isCredit
                                            ? "recive_text"
                                            : res?.isDebit
                                            ? "instant_text"
                                            : ""
                                        }
                                      >
                                        ${res?.amount}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={6}
                                  >
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    {earnings?.earnings?.docs.length !== 0 && (
                      <div className="mt-2">
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade withdrawModal"
        id="withdraw_amount"
        tabIndex="-1"
        aria-labelledby="withdraw_amountLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="withdraw_amountLabel">
                Your widthdraw request has been submitted. we will process it
                within 24 Hrs
              </h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn submitBtn"
                data-bs-dismiss="modal"
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
