import React, { useEffect, useState } from "react";
import "./termsAndCondition.scss";
import WelcomeHeader from "../../Pages/Wel_Header/WelcomeHeader";
import WelcomeFooter from "../../Pages/Wel_Footer/WelcomeFooter";
import { getStaticContent } from "../../utils/request";
// import { useParams, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import SubscribeSection from "../../shared/subscribe";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';

const TermsAndCondition = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [description, setDescription] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (token) {
    getTermsAndCondition();
  }, []);
  // Function for get T&C data
  const getTermsAndCondition = async () => {
    const response = await getStaticContent({ type: "terms" });
    if (response.data.code === 200) {
      setDescription(response.data.data.description);
    }
  };

  return (
    <>
      <WelcomeHeader />
      <section className="term_Banner">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="work">
                <h1>Terms And Conditions</h1>
	  			<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Terms And Conditions</Breadcrumb.Item>
				</Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="term-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="para-A">
                <p
                  className="cls-maxheight-scroll"
                  dangerouslySetInnerHTML={{ __html: parse(description) }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SubscribeSection />
      <WelcomeFooter />
    </>
  );
};

export default TermsAndCondition;
