import React, { useEffect, useState } from "react";
import {
  FaArrowRight,
  FaMapMarkerAlt,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaCoins,
  FaClock,
  FaStar,
  FaEdit,
  FaUserAlt,
  FaAngleRight,
} from "react-icons/fa";
import Profile from "../../images/userPlace.png";
import { Link } from "react-router-dom";
import "./MyProfile.scss";
import { useParams, useNavigate } from "react-router-dom";
import {
  getGuardProfile,
  getMyJobs,
  myEarnings,
  getParticularGuardJobDetail,
} from "../../utils/request";
import { API_URL } from "../../utils/constants";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Rating } from "react-simple-star-rating";

const MyProfile = () => {
  const cookie = new Cookies();
  // const token = cookie.get("token");
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [guardOtherInfo, setGuardOtherInfo] = useState({});
  const [guard, setGuard] = useState({});
  const [myJobList, setMyJobList] = useState([]);
  const [earnings, setEarnings] = useState([]);
  const [earningsList, setEarningsList] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  // const [ratingValue, setRatingValue] = useState(0);
  var pageNo = 1;

  useEffect(() => {
    if (token) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
      getGuardProfilef();
      getMyJob();
      getMyearnings();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  const handleRating = () => {
    // setRatingValue(2.5);
  };
  // Function for get profile data
  const getGuardProfilef = async () => {
    setIsLoading(true);
    const response = await getGuardProfile(id);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    // //console.log("guard profile response", response);
    if (response?.data?.code === 200) {
      setIsLoading(false);
      setGuardOtherInfo(response.data.data);
      // setRatingValue(response.data.data?.rating);
      setTimeout(() => {
        handleRating();
      }, 2000);
      setGuard(response?.data?.data?.guard);
      cookie.set("profileImage", response?.data?.data?.guard?.profileImage);
      localStorage.setItem(
        "profileImage",
        response?.data?.data?.guard?.profileImage
      );
      cookie.set(
        "securityRegistrationDocument",
        response?.data?.data?.guard?.securityRegistrationDocument
      );
      localStorage.setItem(
        "securityRegistrationDocument",
        response?.data?.data?.guard?.securityRegistrationDocument
      );
    } else {
      setIsLoading(false);
    }
  };
  // Function for get earnings
  const getMyearnings = async () => {
    const reqData = {
      searchBy: "",
      limit: 3,
      page: pageNo,
      from: from,
      to: to,
    };
    const res = await myEarnings(reqData);
    //console.log(res);
    if (res?.data?.code == 200) {
      setEarnings(res?.data?.data);
      setEarningsList(res?.data?.data?.earnings?.docs);
      settotalItemsCount(res?.data?.data?.earnings?.total);
    }
  };
  // Function for get guard job
  const getMyJob = async () => {
    const requestedData = {
      searchBy: "",
      limit: 3,
      page: 1,
      isComplete: 1,
    };
    const response = await getMyJobs(requestedData);
    //console.log(response);
    if (response?.data?.code === 200) {
      setMyJobList(response?.data?.data?.docs);
      settotalItemsCount(response?.data?.data?.total);
    }
  };
  // Function for get job details
  const getJobDetails = async (jd) => {
    //console.log(jd);
    const jdata = {
      jobId: jd?.jobId,
      userId: jd?.userId,
      shiftId: jd?.shiftId,
      lat: latitude,
      long: longitude,
    };
    const res = await getParticularGuardJobDetail(jdata);
    //console.log(res);
    if (res?.data?.code === 200) {
      const user = res?.data?.data;
      navigate(`/jobDetail`, { state: { user } });
    }
  };
  // Function for filter
  const filterData = (val) => {
    if (val === "All") {
      getMyearnings();
      return;
    }

    if (val === "isCredit") {
      getMyearnings();
      const data = earningsList.filter((res) => {
        return res.isCredit == true;
      });
      setEarningsList(data);
      return;
    }

    if (val === "isWithdrawn") {
      // getMyearnings();
      const data = earningsList.filter((res) => {
        return res.isWithdrawn == true;
      });
      setEarningsList(data);
      return;
    }
  };

  return (
    <>
      <div>
        <Modal
          open={isLoading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Navbar />
      {!isLoading && (
        <section className="MyProfie">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="profile">
                  <div className="row align-items-center">
                    <div className="col-6 col-md-6">
                      <h4>My Profile</h4>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="editProBtn">
                        <button
                          onClick={() =>
                            navigate(`/guard/editProfile`, { state: { guard } })
                          }
                        >
                          <span>
                            <FaEdit />
                          </span>
                          Edit Profile
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flexBox">
                    <div
                      className="left"
                      style={{ borderRight: "1px solid #b7b7b7" }}
                    >
                      <div className="box">
                        <div className="imgBox">
                          {guard?.profileImage !== "" ? (
                            <img
                              src={
                                API_URL +
                                "/media/preview?filename=" +
                                guard?.profileImage
                              }
                              width={40}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/images/Profile.png";
                              }}
                            />
                          ) : (
                            <img src={Profile} />
                          )}
                        </div>

                        <div className="contentBox">
                          <h6>
                            <FaUser />{" "}
                            {guard?.firstName == undefined
                              ? ""
                              : guard?.firstName + " "}{" "}
                            {+guard?.lastName == undefined
                              ? ""
                              : guard?.lastName}
                          </h6>

                          <ul>
                            <li>
                              <FaEnvelope /> {guard?.email}
                            </li>
                            <li>
                              <FaPhone /> {guard?.mobileNumber}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="btnBox">
                        <Link to="/guard/documents" className="docBtn">
                          My Documents
                        </Link>
                        <button
                          className="accBtn"
                          onClick={() =>
                            navigate(`/guard/myAccount`, { state: { guard } })
                          }
                        >
                          My Account
                        </button>
                        <button
                          className="accBtn mt-2"
                          onClick={() => navigate(`/emp_details/${id}`)}
                        >
                          Employment Details
                        </button>
                      </div>
                    </div>
                    <div className="right">
                      <div className="flexInner">
                        <div className="left">
                          <p>Total Earning</p>
                          <span className="coins">
                            <FaCoins /> $
                            {guardOtherInfo?.totalEarning?.toFixed(2)}
                          </span>
                        </div>
                        <div className="right">
                          <p>In Hand Earning</p>
                          <span className="coins">
                            <FaCoins /> ${guardOtherInfo?.eWallet?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="flexInner">
                        <div className="left">
                          <p>Total Hours Worked</p>
                          <span className="clockIcon">
                            <FaClock />
                            {guardOtherInfo?.hoursWorked} Hrs
                          </span>
                        </div>
                        <div className="right">
                          <Link to="/jobRating">
                            My Rating <FaArrowRight />
                          </Link>
                          <div className="starRate">
                            <Rating
                              onClick={handleRating}
                              initialValue={guardOtherInfo?.rating}
                              allowHalfIcon
                              readonly
                            />
                          </div>
                          <span className="count">
                            (
                            {guardOtherInfo?.rating
                              ? guardOtherInfo?.rating
                              : 0}
                            )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="allJob">
                  <div className="topBox">
                    <h4>
                      All Jobs <span>({totalItemsCount})</span>
                    </h4>
                    <Link to="/all-job">
                      View All <FaArrowRight />
                    </Link>
                  </div>
                  {myJobList.length !== 0 ? (
                    myJobList.slice(0, 3).map((user, index) => {
                      return (
                        <button
                          style={{ width: "100%" }}
                          onClick={() => {
                            getJobDetails(user);
                          }}
                        >
                          <div className="box">
                            <div className="job_title_box">
                              <h5>{user?.jobTitle}</h5>
                              <p>
                                <span>Status:</span>{" "}
                                {user?.status == 0
                                  ? "Awaiting approval"
                                  : user?.status == 1
                                  ? "Approved"
                                  : user?.status == 2
                                  ? "De-Activate(Reject)"
                                  : user?.status == 3
                                  ? "Cancel"
                                  : user?.status == 4
                                  ? "Ongoing"
                                  : user?.status == 5
                                  ? "Closed"
                                  : user?.status == 6
                                  ? "Upcoming"
                                  : user?.status == 7
                                  ? "Previous"
                                  : "N/A"}
                              </p>
                              <span>
                                <FaAngleRight />
                              </span>
                            </div>
                            <p>
                              <FaMapMarkerAlt />{" "}
                              {user?.locationId.locationName &&
                                user?.locationId.locationName}
                            </p>
                          </div>
                        </button>
                      );
                    })
                  ) : (
                    <h6>No job found</h6>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="myEarning">
                  <div className="topBox">
                    <h4>
                      My Earning{" "}
                      <span>(${earnings?.earnings?.total?.toFixed(2)})</span>
                    </h4>
                    <Link to="/withdraw">
                      View All <FaArrowRight />
                    </Link>
                  </div>

                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={(e) => {
                          filterData("All");
                        }}
                      >
                        {/* All Jobs */}
                        All
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={(e) => {
                          filterData("isCredit");
                        }}
                      >
                        {/* Upcoming */}
                        Credit
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={(e) => {
                          filterData("isWithdrawn");
                        }}
                      >
                        Withdarwn
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    {earningsList?.length !== 0 ? (
                      earningsList.map((elist, i) => {
                        return (
                          <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="box">
                              <h5>
                                {elist?.jobId?.jobTitle}{" "}
                                <span>${elist?.amount?.toFixed(2)}</span>
                              </h5>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h6>No Data Found</h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MyProfile;
