import React, { useState, useEffect } from "react";
import Navbar from "../../Pages/Header/Navbar";
import { FaStar } from "react-icons/fa";
// import { Link } from "react-router-dom";
import "./all_job.scss";
import {
  newJobList,
  applyJobByGuard,
  getMyJobs,
  getParticularGuardJobDetail,
} from "../../utils/request";
import moment from "moment";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
// require("bootstrap/less/bootstrap.less");

const AllJob = () => {
  var pageNo = 1;
  const navigate = useNavigate();
  const [jobList, setJobList] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  var length = 0;
  var temp = 0;
  let jobsList = {};
  const [showPerPage, setShowPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const cookie = new Cookies();
  const token = cookie.get("token");

  useEffect(() => {
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Effect hook to fetch the initial job list when the page loads or when the page number changes
  useEffect(() => {
    getJobList(); // Call the function to fetch job list
  }, [page]); // The effect depends on the 'page' variable

  // Function to handle page change for pagination
  const handlePageChange = (pageNumber) => {
    pageNo = pageNumber; // Update the page number
    getJobList(); // Call the function to fetch job list with the updated page number
  };

  // Function to fetch the list of jobs
  const getJobList = async () => {
    const requestedData = {
      sortBy: "asc",
      limit: 10,
      page: pageNo, // Use the current page number
    };
    const response = await getMyJobs(requestedData); // Call the API to get job list

    // Handle response based on its status code
    if (response?.data?.code === 401) {
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      settotalItemsCount(response.data.data.total);
      jobsList = response.data.data.docs; // Store the list of jobs
      setJobList(response.data.data.docs); // Set the state with the list of jobs
      length = jobsList.length; // Update the length of the jobs list
    } else {
      // Handle other cases if needed
      return;
    }
    return; // Return if necessary
  };

  // Function to get details of a particular job and navigate to its detail page
  const getJobDetails = async (jd) => {
    const jdata = {
      jobId: jd?.jobId,
      userId: jd?.userId,
      shiftId: jd?.shiftId,
    };
    const res = await getParticularGuardJobDetail(jdata); // Call the API to get job details

    // Handle response based on its status code
    if (res?.data?.code === 200) {
      const user = res?.data?.data;
      navigate(`/jobDetail`, { state: { user } }); // Navigate to the job detail page with user data
    }
  };

  // Function to apply for a job by a guard
  const aplyJobByGuard = async (item, index) => {
    // Create a data object with necessary information
    const requestedData = {
      userId: item.userId,
      jobId: item.userId, // Double-check if this should be 'item.jobId'
      shiftId: item.userId, // Double-check if this should be 'item.shiftId'
      isApply: 1, // Indicates that the guard is applying for the job
    };

    // Check if the guard has not already applied for the job
    if (item.isAppliedStatus === false) {
      const response = await applyJobByGuard(requestedData); // Call the API to apply for the job

      // Handle response based on its status code
      if (response.data.code === 200) {
        alert("Successfully applied for this job!"); // Show success message
      }
    } else {
      alert("You already applied for this job!"); // Show message indicating previous application
    }
  };

  return (
    <>
      <Navbar />
      <section className="all_job">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="detailsBox">
                <div className="rate_title">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <h4>All Jobs</h4>
                    </div>
                  </div>
                </div>
                <div></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive border-1">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Job Title</th>
                            <th>Location</th>
                            <th className="w-10">Date</th>
                            <th className="w-10">Start Time</th>
                            <th className="w-10">End Time</th>
                            <th className="w-10">Ratings</th>
                            <th className="w-5">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList?.length !== 0 ? (
                            jobList.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.jobTitle}</td>
                                  <td>{item.locationId.locationName}</td>
                                  <td>
                                    {moment
                                      .utc(
                                        item?.jobDate == undefined
                                          ? item?.startTime
                                          : item?.jobDate
                                      )
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>
                                    {moment
                                      .utc(item.startTime)
                                      .format("hh:mm a")}
                                  </td>
                                  <td>
                                    {moment.utc(item.endTime).format("hh:mm a")}
                                  </td>
                                  <td>
                                    {/* <i className={rating<=0.5+index ? 'fas fa-star-half-alt' : rating <= 1+index ? 'fas fa-star' : 'far fa-star'}></i> */}
                                    {item.clientRating == 0 ? (
                                      "--"
                                    ) : (
                                      <div className="starRate">
                                        {new Array(item.clientRating)
                                          .fill(null)
                                          .map((i) => (
                                            <FaStar />
                                          ))}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => getJobDetails(item)}
                                      className="view_btn"
                                    >
                                      View More
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan={6}>
                                No data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {jobList.length !== 0 && (
                  <div className="mt-2">
                    <Pagination
                      color="primary"
                      activePage={pageNo}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItemsCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllJob;
