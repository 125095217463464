import React, { useState } from "react";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import img404 from "../../images/404.png";
import { Link } from "react-router-dom";
import "./page_404.scss";

const Page404 = () => {
  return (
    <>
      <Header_inner />
      <section className="page_404">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="regForm">
                <div>
                  <img src={img404} className="img-fluid" />
                </div>
                <h3>Oops! This Page Is Not Found.</h3>
                <p>The requested page does not exist.</p>
                <div className="nextBtn">
                  <a href="/">
                    <i class="fa-solid fa-house"></i>
                    <span>Back to Home</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Page404;
