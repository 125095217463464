import React, { useState, useEffect } from "react";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import mapIcon from "../../images/map.png";
import mapImg from "../../images/mapImg.png";
import NoDataImg from "../../images/no_data_found.png";
import {
  FaArrowRight,
  FaCalendar,
  FaSearch,
  FaMapMarkerAlt,
  FaStar,
  FaBriefcase,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./e_note.scss";
import {
  getENoteAccToJob,
  getENotebyId,
  API_GetAllJobsList,
  getParticularGuardJobDetail,
} from "../../utils/request";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { S3PREVIEW_URL } from "../../utils/constants";
import Map from "../Map/Map";

const ENote = () => {
  const navigate = useNavigate();
  const cookie = new Cookies();
  const token = cookie.get("token");
  const [enotes, setEnotes] = useState([]);
  const [enoteId, setEnoteId] = useState([0]);
  const [enoteDetails, setEnoteDetails] = useState([0]);
  const [title, setTitle] = useState("");
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [enotepageNo, setEnotePageNo] = useState(1);
  const [totalHrs, setTotalHrs] = useState(0);
  const [smShow, setSmShow] = useState(false);
  const [guardData, setGuardData] = useState({});
  const [experience, setExperience] = useState("");
  const [daysInExp, setDaysInExp] = useState("");
  const [open, setOpen] = useState(false);
  const [totalEnoteItemsCount, setTotalEnoteItemsCount] = useState(0);
  const [isJobDetails, setIsJobDetails] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobid, setJobId] = useState([0]);
  const [jobDetails, setjobDetails] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  useEffect(() => {
    if (token) {
      // geteNoteListAccToJob();
      callGetAllJobsList();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Function to fetch eNote list according to a job
  const geteNoteListAccToJob = async (edata) => {
    const data = {
      jobId: "", // Set jobId as needed
      pageNumber: pageNo,
    };
    setIsLoading(true);
    const response = await getENoteAccToJob(data);

    // Check if the response code is 200
    if (response.data.code === 200) {
      setIsLoading(false);
      setEnotes(response.data.data.docs);
      setEnoteId(response?.data?.data?.docs[0]?.id);
      getENotesbyId(response?.data?.data?.docs[0]?.id);
      setTotalItemsCount(response?.data?.data?.total);
    } else {
      setIsLoading(false);
    }
  };

  // Function to handle pagination change
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    // Call the function to fetch eNote list with the updated page number
    geteNoteListAccToJob();
  };

  // Function to handle selection of an eNote
  const selectedEnote = (id, name) => {
    setEnoteId(id);
    setTitle(name);
    getENotesbyId(id);
  };

  // Function to get details of a specific eNote by ID
  const getENotesbyId = async (id) => {
    if (id !== undefined) {
      const response = await getENotebyId(id);
      // Check if the response code is 200
      if (response?.data?.code === 200) {
        setEnoteDetails(response.data.data);
      }
    }
  };

  // Function For get all job list
  async function callGetAllJobsList() {
    const data = {
      pageNumber: pageNo,
    };
    setIsLoading(true);
    const res = await API_GetAllJobsList(data);
    if (res.data.code === 200) {
      setIsLoading(false);
      setJobs(res.data.data.docs);
      // setjobDetails(res.data.data.docs[0]);
      callGetJobDetails(res?.data?.data?.docs[0]);
      callGetENote(res?.data?.data?.docs[0]?.jobId, enotepageNo);
      setJobId(res?.data?.data?.docs[0]?.jobId);
      setTotalItemsCount(res.data.data.total);
    } else if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/guard/login`);
    } else {
      setIsLoading(false);
      toast.error(res.message);
    }
  }

  // Function for get job details by job id
  async function callGetJobDetails(data) {
    setIsJobDetails(true);
    const requestedData = {
      jobId: data?.jobId,
      userId: data?.userId,
      shiftId: data?.shiftId,
    };
    const res = await getParticularGuardJobDetail(requestedData);
    if (res.data.code === 200) {
      var total = 0;
      setIsJobDetails(false);
      res?.data?.data?.shifts?.map((res) => {
        total += res.totalShiftHours;
        setTotalHrs(total);
        return res;
      });
      setjobDetails(res.data.data);
      setLat(jobDetails?.location?.location?.coordinates[0]);
      setLong(jobDetails?.location?.location?.coordinates[1]);
      //console.log("lat", jobDetails?.location?.location?.coordinates[0]);
      //console.log("long", jobDetails?.location?.location?.coordinates[1]);
      //console.log(jobDetails);
    } else {
      setIsJobDetails(false);
      toast.error(res.message);
    }
  }

  // Function to set job ID and retrieve job details and eNotes
  const setJobsid = (i, data) => {
    callGetJobDetails(data); // Fetch job details
    callGetENote(i, enotepageNo); // Fetch eNotes
    setJobId(i); // Set the selected job ID
  };

  // Function to fetch eNotes according to a job and page
  async function callGetENote(id, page) {
    const data = {
      jobId: id,
      pageNumber: page,
    };
    setIsLoading(true);
    const res = await getENoteAccToJob(data);

    // Check if the response code is 200
    if (res.data.code === 200) {
      setIsLoading(false);
      setEnotes(res.data.data.docs);
      setTotalEnoteItemsCount(res?.data?.data?.total);
    }
  }

  // Function to handle pagination change for eNotes
  const handleEnotePageChange = (pageNumber) => {
    setEnotePageNo(pageNumber);
    callGetENote(jobid, pageNumber);
  };

  // Function to generate audio URL based on audio filename
  const playAudio = (audio) => {
    return `${S3PREVIEW_URL}${audio}`;
  };

  return (
    <>
      <div>
        <Modal
          open={isLoading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Navbar />
      {!isLoading && (
        <section className="all_awrded_job">
          <div className="container">
            <div className="row mb-2">
              <div className="col-md-12"></div>
            </div>
            {jobs.length > 0 ? (
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-12">
                  <div className="row justify-content-center">
                    <div className="col-md-3">
                      <div className="left_sidebar">
                        <h5 style={{ marginBottom: "0" }}>Enotes</h5>
                        {jobs.map((res, i) => {
                          return (
                            <div className="nav_box">
                              <div
                                className="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                              >
                                <button
                                  // className="nav-link active"
                                  class={
                                    "nav-link" +
                                    (jobid === res.jobId ? " active" : "")
                                  }
                                  id="first-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#first"
                                  type="button"
                                  role="tab"
                                  key={i}
                                  aria-controls="first"
                                  aria-selected="true"
                                  onClick={(e) => {
                                    setJobsid(res?.jobId, res);
                                  }}
                                >
                                  {res.jobTitle}
                                  <span>
                                    <i className="fa-solid fa-location-dot"></i>{" "}
                                    {res.locationId?.locationName}
                                  </span>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {jobs.length > 0 && (
                        <div className="mt-2">
                          <Pagination
                            color="primary"
                            activePage={pageNo}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-5">
                      {isJobDetails && (
                        <div
                          class="text-center"
                          style={{
                            position: "absolute",
                            left: "45%",
                            padding: "15px",
                            top: "50%",
                          }}
                        >
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                      <div className="content">
                        <div
                          className="tab-content total_count_box"
                          id="v-pills-tabContent"
                        >
                          <div
                            className={
                              "tab-pane fade" +
                              (jobid.length !== 0 ? "show active" : "")
                            }
                            id="first"
                            role="tabpanel"
                            aria-labelledby="first-tab"
                          >
                            <h5 className="awrded_job_title">
                              {jobDetails?.jobTitle}{" "}
                            </h5>
                            <p className="adderess">
                              {jobDetails?.location?.locationName}
                            </p>
                            <div className="desc">
                              <div className="flexBox">
                                <p>
                                  <span>Status</span> :{" "}
                                  {jobDetails?.jobStatus == 0
                                    ? "Awaiting Approval"
                                    : jobDetails?.jobStatus == 1
                                    ? "Approved"
                                    : jobDetails?.jobStatus == 2
                                    ? "De-Activate(Reject)"
                                    : jobDetails?.jobStatus == 3
                                    ? "Cancel"
                                    : jobDetails?.jobStatus == 4
                                    ? "Ongoing"
                                    : jobDetails?.jobStatus == 5
                                    ? "Closed"
                                    : jobDetails?.jobStatus == 6
                                    ? "Upcoming"
                                    : jobDetails?.jobStatus == 7
                                    ? "Previous"
                                    : "N/A"}
                                </p>
                                {jobDetails.jobType !== 3 ? (
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p>
                                        <span>Job Start Date</span> :{" "}
                                        {moment
                                          .utc(jobDetails?.startTime)
                                          .format("DD-MM-YYYY")}
                                      </p>
                                    </div>
                                    <div className="col-md-6">
                                      <p>
                                        <span>Job End Date</span> :{" "}
                                        {moment
                                          .utc(jobDetails?.endTime)
                                          .format("DD-MM-YYYY")}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        <span>Job Date</span> :{" "}
                                        {jobDetails.customDates.map(
                                          (date) =>
                                            moment
                                              .utc(date)
                                              .format("DD-MM-YYYY") + " , "
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-md-6">
                                    <p>
                                      <span>Transpotation</span> :{" "}
                                      {jobDetails?.isVehicleRequired
                                        ? "Own Vehicle"
                                        : "Doesn't matter"}
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p>
                                      <span>Dress Code</span> :{" "}
                                      {jobDetails?.dressCode == 1
                                        ? "HV Vest"
                                        : jobDetails?.dressCode == 2
                                        ? "Casual"
                                        : jobDetails?.dressCode == 3
                                        ? "Suited"
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                <p>
                                  <span>Job Description</span> :{" "}
                                  {jobDetails?.description}
                                </p>
                              </div>
                            </div>

                            {/* AIzaSyCprWnsG8o_V4tmIo-EsgyvgEkhO3pouTc */}
                            <div className="mapBox">
                              <Map
                                lat={
                                  jobDetails?.location?.location?.coordinates[1]
                                }
                                long={
                                  jobDetails?.location?.location?.coordinates[0]
                                }
                                name={jobDetails?.location?.locationName}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="enote_shift_rBox">
                        {enotes?.length > 0 ? (
                          enotes?.map((enote) => {
                            return (
                              <div className="reach_box">
                                <h5>
                                  {enote?.guardId?.firstName +
                                    " " +
                                    enote?.guardId?.lastName}
                                </h5>
                                <h6>{enote?.title}</h6>
                                <p>{enote?.description}</p>
                                {enote?.file !== undefined && (
                                  <>
                                    <a href={playAudio(enote?.file)} download>
                                      Download Audio
                                    </a>
                                  </>
                                )}
                                <p>
                                  {moment(enote?.createdAt).format(
                                    "DD-MM-YYYY hh:mm, A"
                                  )}
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <div className="not_data_found">
                            <h5>No Enotes Found</h5>
                          </div>
                        )}
                      </div>
                      {enotes.length > 0 && (
                        <div className="mt-2">
                          <Pagination
                            color="primary"
                            activePage={enotepageNo}
                            itemsCountPerPage={10}
                            totalItemsCount={totalEnoteItemsCount}
                            pageRangeDisplayed={5}
                            onChange={(e) => handleEnotePageChange(e)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="nodata_img">
                    <img
                      src={NoDataImg}
                      className="img-fluid"
                      alt="No Data Found"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default ENote;
