import React, { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { guardAgreement, getStaticContent } from "../../utils/request";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import "./terms_use.scss";
import parse from "html-react-parser";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const TermsUse = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const { id } = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");

  useEffect(() => {
    getTermsAndCondition();
  }, [id]);
  // Function for get T&C
  const getTermsAndCondition = async () => {
    const response = await getStaticContent({ type: "terms" });
    if (response.data.code === 200) {
      setDescription(response.data.data.description);
    }
  };
  // Function for get Guard agreement
  const handleAgreement = async (event) => {
    event.preventDefault();
    const requestData = {
      id: id,
      isTermsOfUse: true,
    };
    const updatedResponse = await guardAgreement(requestData);
    if (updatedResponse.data.code === 200) {
      navigate(`/guard-agreement/${updatedResponse?.data?.data?.id}`);
    }
  };

  return (
    <>
      <Header_inner />
      <section className="terms_use">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="privacyTitle">
                  <div className="row align-items-lg-center">
                    <div className="col-md-12">
                      <h4>Terms & Conditions</h4>
                    </div>
                  </div>
                </div>
                <div className="notifyBox">
                  <p
                    className="cls-maxheight-scroll"
                    dangerouslySetInnerHTML={{ __html: parse(description) }}
                  ></p>
                  <div className="time">
                    <a onClick={(e) => handleAgreement(e)}>
                      Agree & Continue <FaArrowRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsUse;
