import React from "react";
import { FaCopyright } from "react-icons/fa";
import "./footer.scss";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="footerBox">
                <p>
                  <FaCopyright /> Copyright@{new Date().getFullYear()} |
                  GoGuard.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
