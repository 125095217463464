import React, { useEffect, useState } from "react";
import "./faq.scss";
import WelcomeHeader from "../../Pages/Wel_Header/WelcomeHeader";
import WelcomeFooter from "../../Pages/Wel_Footer/WelcomeFooter";
import { useParams, useNavigate } from "react-router-dom";
import { getFAQContent } from "../../utils/request";
import { toast } from "react-toastify";
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';

const Faq = () => {
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [faqList, setFAQList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getFAQ();
    // setToken(sessionStorage.getItem("token"))
  }, []);

  // Function for get FAQ
  const getFAQ = async () => {
    const response = await getFAQContent();
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      setFAQList(response.data.data);
    }
  };

  return (
    <>
      <WelcomeHeader />
      <section className="faqBanner">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="work">
                <h1>Frequently Asked Questions</h1>
				<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>FAQ</Breadcrumb.Item>
				</Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq-page" className="faq-section">
        <div className="container">
          <div className="row justify-content-center">
            {faqList.map((item, i) => {
              return (
                <>
                  <div className="col-md-12" key={i}>
                    <div className="part-b">
                      <h3>{item.title}</h3>
                      <div class="accordion accordion-flush">
                        {item.questionAnswers.map((qa, j) => {
                          var keyA = "#flush-collapseOne" + i + j;
                          var key = "flush-collapseOne" + i + j;
                          return (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={keyA}
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    {qa.question}
                                  </button>
                                </h2>
                                <div
                                  id={key}
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingOne"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">{qa.answer}</div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      <section className="subscribe">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <p>
                Stay in touch to get updates on our launch events and offers.
              </p>
            </div>
            <div className="col-md-3">
              <div className="subBtn">
                <a href="#">Subscribe</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WelcomeFooter />
    </>
  );
};

export default Faq;
