import React, { useState, useEffect } from "react";
// import Profile from "../../mages/userPlace.png";
import Profile from "../../images/userPlace.png";

import {
  FaArrowRight,
  FaArrowLeft,
  FaUserCircle,
  FaCamera,
  FaUserAlt,
  FaEnvelope,
  FaBirthdayCake,
  FaTimes,
  FaPen,
  FaCalendar,
  FaPhone,
  FaIdCard,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Navbar from "../../Pages/Header/Navbar";
import "./profile.scss";
import moment from "moment";
import { updateGuard } from "../../utils/request";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { API_URL } from "../../utils/constants";

const ProfilePic = () => {
  const cookie = new Cookies();
  const guardId = cookie.get("guardId");
  const navigate = useNavigate();
  const profileData = useLocation();
  const [firstName, setFirstName] = useState(
    profileData?.state.guard.firstName
  );
  const [middleName, setMiddleName] = useState(
    profileData?.state.guard.middleName
  );
  const [lastName, setLastName] = useState(profileData?.state.guard.lastName);
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameValidError, setFirstNameValidError] = useState(false);
  const [middleNameError, setMiddleNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameValidError, setLastNameValidError] = useState(false);

  const [email, setEmail] = useState(profileData?.state.guard.email);
  const [licenceno, setLicenceNo] = useState(
    profileData?.state.guard.licenseNumber
  );

  const [licenceImg, setLicenceImg] = useState("");
  const [LicencePreviewUrl, setLicencePreviewUrl] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailIsRequiredError, setEmailIsRequiredlError] = useState(false);
  const [phone, setPhone] = useState(profileData?.state.guard.mobileNumber);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneValidError, setPhoneValidError] = useState(false);
  const [dob, setDOB] = useState(profileData?.state.guard.dob);
  const [dobError, setdobError] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [documentNoError, setDocumentNoError] = useState(false);

  const [securityRegistrationExpiryDate, setSecurityRegistrationExpiryDate] =
    useState("");
  const [
    errorSecurityRegistrationExpiryDate,
    setErrorSecurityRegistrationExpiryDate,
  ] = useState(false);

  useEffect(async () => {
    document.getElementById("expiryDatenew").value = moment
      .utc(profileData?.state.guard?.securityRegistrationExpiryDate)
      .format("yyyy-MM-DD");
    setSecurityRegistrationExpiryDate(
      moment
        .utc(profileData?.state.guard?.securityRegistrationExpiryDate)
        .format("yyyy-MM-DD")
    );
    //console.log("guard profileData", profileData);
    setImagePreviewUrl(profileData?.state.guard.profileImage);
    //console.log("checl URL ---", API_URL + "/media/preview?filename=");
    if (imagePreviewUrl !== undefined || imagePreviewUrl !== "") {
      setImagePreviewUrl(
        `${
          API_URL +
          "/media/preview?filename=" +
          profileData?.state.guard.profileImage
        }`
      );
      // setProfilePhoto( `${profileData?.state.guard.profileImage}`)
    }
    setLicencePreviewUrl(profileData?.state.guard.securityRegistrationDocument);
    if (LicencePreviewUrl !== undefined || LicencePreviewUrl !== "") {
      setLicencePreviewUrl(
        `${
          API_URL +
          "/media/preview?filename=" +
          profileData?.state.guard.securityRegistrationDocument
        }`
      );
      // setLicenceImg(`${profileData?.state.guard.securityRegistrationDocument}`)
    }
  }, []);
  // Functions for validation
  const handleFirstName = (value) => {
    setFirstName(value);
    const regex = /^[a-zA-Z]{2,}$/;
    if (firstName && regex.test(value) === false) {
      setFirstNameValidError(true);
    } else if (value === "") {
      setFirstNameError(true);
      setFirstNameValidError(false);
    } else {
      setFirstNameError(false);
      setFirstNameValidError(false);
    }
  };

  const handleLicenceNo = (value) => {
    const regex = /^\d{6,10}$/;

    if (regex.test(value) === false) {
      if (value.length < 11) {
        setLicenceNo(value);
      }
      setDocumentNoError(true);
    } else {
      setLicenceNo(value);
      setDocumentNoError(false);
    }
  };

  const _secDateHandle = (value) => {
    //console.log(value);
    if (value === "") {
      setErrorSecurityRegistrationExpiryDate(true);
    } else {
      setErrorSecurityRegistrationExpiryDate(false);
      setSecurityRegistrationExpiryDate(value);
    }
  };

  const handleMiddleName = (str) => {
    const regex = /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/;
    setMiddleName(str);
    if (str && regex.test(str) === false) {
      setMiddleNameError(true);
    } else {
      setMiddleNameError(false);
    }
  };

  const handleLastName = (value) => {
    setLastName(value);
    const regex = /^[a-zA-Z]{2,}$/;
    if (regex.test(value) === false) {
      setLastNameValidError(true);
    } else if (value === "") {
      setLastNameError(true);
      setLastNameValidError(false);
    } else {
      setLastNameError(false);
      setLastNameValidError(false);
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,3})*$/;
    if (regex.test(value) === false) {
      setEmailError(true);
      setEmailIsRequiredlError(false);
    } else {
      setEmailError(false);
      setEmailIsRequiredlError(false);
    }
  };

  const handleMobileChange = (value) => {
    setPhone(value);
    const regex = /^[0-9]*$/;
    if (regex.test(value) === false) {
      setPhoneValidError(true);
      setPhoneError(false);
    } else if (value.length < 10) {
      setPhoneValidError(true);
      setPhoneError(false);
    } else {
      setPhoneValidError(false);
      setPhoneError(false);
    }
  };

  const handleCheckDOB = (value) => {
    setDOB(value);
    var today = new Date();
    var birthDate = new Date(value);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age >= 18) {
      setdobError(false);
    } else {
      setdobError(true);
    }
  };
  // Function for handle profile photo change
  const handleProfilePhoto = async (e) => {
    //console.log("handle profile photo", e);
    e.preventDefault();

    const allowedBytes = 2 * 1024 * 1024;
    let file = e.target.files[0];
    // const size = e.target.files[0].size / 1048576;

    let allImages = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/tiff",
      "image/bpg",
      "image/webp",
    ];

    if (allImages.indexOf(file?.type) === -1) {
      toast.warning("File type is not allowed");
      return;
    }

    if (e.target.files[0].size > allowedBytes) {
      alert("Size is more than 2 MB");
      setProfilePhoto(true);
      return;
    }
    setProfilePhoto(false);

    let reader = new FileReader();
    // let file = e.target.files[0];

    reader.onloadend = () => {
      setProfilePhoto(file);
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };
  // Function for handle licence photo change
  const handleLicencePhoto = async (e) => {
    //console.log("handle profile photo", e);
    e.preventDefault();
    const allowedBytes = 2 * 1024 * 1024;
    let file = e.target.files[0];
    // const size = e.target.files[0].size / 1048576;

    let allImages = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/tiff",
      "image/bpg",
      "image/webp",
    ];

    if (allImages.indexOf(file?.type) === -1) {
      toast.warning("File type is not allowed");
      return;
    }
    if (e.target.files[0].size > allowedBytes) {
      alert("Size is more than 2 MB");
      setLicenceImg(true);
      return;
    }
    setLicenceImg(false);

    let reader = new FileReader();
    // let file = e.target.files[0];

    reader.onloadend = () => {
      setLicenceImg(file);
      setLicencePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };
  // Function for Update Profile
  const handleUpdateProfile = async (event) => {
    event.preventDefault();
    const re =
      /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,3})*$/;
    var phoneno = /^[0-9]*$/;
    const nameRegex = /^[a-zA-Z-. ]+$/;
    if (email === "" || !email) {
      setEmailIsRequiredlError(true);
      setEmailError(false);
    } else if (re.test(email) === false) {
      setEmailError(true);
      setEmailIsRequiredlError(false);
      event.stopPropagation();
    }

    if (firstName === "" || !firstName) {
      setFirstNameError(true);
      setFirstNameValidError(false);
      // return;
    } else if (nameRegex.test(firstName) === false) {
      setFirstNameValidError(true);
      setFirstNameError(false);
      // return
    }

    if (lastName === "" || !lastName) {
      setLastNameError(true);
      setLastNameValidError(false);
      // return;
    } else if (nameRegex.test(lastName) === false) {
      setLastNameValidError(true);
      setLastNameError(false);
      // return
    }

    if (phone && !phoneno.test(phone)) {
      setPhoneValidError(true);
      setPhoneError(false);
      // return;
    }
    if (phone === "") {
      setPhoneError(true);
      // return;
    }
    if (phone && !phoneno.test(phone)) {
      setPhoneValidError(true);
      setPhoneError(false);
      return;
    }

    const regex = /^\d{6,10}$/;

    if (licenceno === "") {
      setDocumentNoError(true);
      return;
    }
    if (licenceno && !regex.test(licenceno)) {
      setDocumentNoError(true);
      // setPhoneError(false);
      return;
    }

    if (
      securityRegistrationExpiryDate === "" ||
      securityRegistrationExpiryDate === "Invalid date"
    ) {
      setErrorSecurityRegistrationExpiryDate(true);
      return;
    } else {
      setErrorSecurityRegistrationExpiryDate(false);
    }

    if (email && firstName && lastName && phone && dob) {
      setEmailError(false);
      setPhoneError(false);
      const id = profileData?.state?.guard?.id;
      const fdata = new FormData();
      fdata.append("firstName", firstName);
      fdata.append("middleName", middleName);
      fdata.append("lastName", lastName);
      fdata.append("email", email);
      fdata.append("mobileNumber", phone);
      fdata.append("dob", dob);
      //console.log("profilePhoto====", profilePhoto);
      if (profilePhoto) {
        fdata.append("profileImage", profilePhoto);
      }
      if (licenceImg) {
        fdata.append("securityRegistrationDocument", licenceImg);
      }

      //console.log(profilePhoto, licenceImg, "===========================pf");
      fdata.append(
        "securityRegistrationExpiryDate",
        securityRegistrationExpiryDate
      );
      fdata.append("licenseNumber", licenceno);
      fdata.append("profileUpdate", "second");
      setIsLoading(true);
      await updateGuard(guardId, fdata).then((response) => {
        //console.log(response);
        setIsLoading(false);
        if (response?.data?.code === 401) {
          // setIsloading(false);
          toast.error(response?.data?.message);
          navigate(`/guard/login`);
        }
        if (response.status === 200) {
          const { guard, tokens } = response.data.data;
          document.getElementById("fname").value = "";
          document.getElementById("mname").value = "";
          document.getElementById("lname").value = "";
          document.getElementById("email").value = "";
          document.getElementById("mobno").value = "";
          document.getElementById("dob").value = "";
          document.getElementById("licenceNo").value = "";

          navigate(`/guard/myprofile/${response?.data?.data?.id}`);
          toast.success(response?.data?.message);
        } else {
          setIsLoading(false);
          toast.error("Something went wrong!");
        }
      });
    }
  };
  return (
    <>
      <Navbar />
      <section className="profilePic">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="otpForm">
                <div className="title">
                  <div className="backArrow">
                    <Link to={`/guard/myprofile/${guardId}`}>
                      <FaTimes />
                    </Link>
                  </div>
                  <div className="heading">
                    <h1>Edit Profile</h1>
                  </div>
                </div>
                <form>
                  <div className="avatar">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        {/* <input type="file" className="form-control profile-select"
                          onChange={(e) => handleProfilePhoto(e)}
                          htmlFor="exampleInputtext" aria-describedby="textHelp" name="profilePhoto" accept="image/*" /> */}
                        <input
                          type="file"
                          id="uploadPhoto"
                          className="form-control profile-select d-none"
                          htmlFor="exampleInputtext"
                          aria-describedby="textHelp"
                          name="profilePhoto"
                          // capture="environment"
                          onChange={(e) => handleProfilePhoto(e)}
                          accept="image/*"
                        />
                        <label htmlFor="uploadPhoto" className="profileImg">
                          <img
                            src={
                              imagePreviewUrl === undefined ||
                              imagePreviewUrl === ""
                                ? Profile
                                : imagePreviewUrl
                            }
                            style={{
                              width: 88,
                              height: 88,
                              borderRadius: 44,
                              overflow: "hidden",
                            }}
                          />
                          <div className="icon">
                            <div className="camIcon">
                              <label htmlFor="uploadPhoto">
                                <FaPen />
                              </label>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="fname"
                          placeholder="First Name"
                          aria-label="default input example"
                          onChange={(e) => handleFirstName(e.target.value)}
                          // defaultValue={profileData?.state.guard.firstName}
                          value={firstName}
                        />
                        <span>
                          <FaUserAlt />
                        </span>
                      </div>
                      {firstNameValidError === true ? (
                        <div className="invalid-feedback">
                          Please enter valid first name
                        </div>
                      ) : (
                        ""
                      )}
                      {firstNameError === true ? (
                        <div className="invalid-feedback">
                          First name is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="mname"
                          placeholder="Middle Name"
                          aria-label="default input example"
                          onChange={(e) => handleMiddleName(e.target.value)}
                          value={middleName}
                        />
                        <span>
                          <FaUserAlt />
                        </span>
                      </div>
                      {middleNameError === true ? (
                        <div className="invalid-feedback">
                          Please enter valid middle name.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="lname"
                          placeholder="Last Name"
                          aria-label="default input example"
                          onChange={(e) => handleLastName(e.target.value)}
                          value={lastName}
                        />
                        <span>
                          <FaUserAlt />
                        </span>
                      </div>
                      {lastNameValidError === true ? (
                        <div className="invalid-feedback">
                          Please enter valid last name
                        </div>
                      ) : (
                        ""
                      )}
                      {lastNameError === true ? (
                        <div className="invalid-feedback">
                          Last name is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="email"
                          placeholder="example@gmail.com"
                          aria-label="default input example"
                          onChange={(e) => handleEmailChange(e.target.value)}
                          value={email}
                          readOnly
                        />
                        <span>
                          <FaEnvelope />
                        </span>
                      </div>
                      {emailError && (
                        <div className="invalid-feedback">
                          Please enter valid email
                        </div>
                      )}
                      {emailIsRequiredError && (
                        <div className="invalid-feedback">
                          Email is required
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="mobno"
                          placeholder="Mob. No"
                          aria-label="default input example"
                          onChange={(e) => handleMobileChange(e.target.value)}
                          value={phone}
                          readOnly
                        />
                        <span>
                          <FaPhone />
                        </span>
                      </div>
                      {phoneValidError ? (
                        <div className="invalid-feedback">
                          Please enter valid mobile number
                        </div>
                      ) : (
                        ""
                      )}
                      {phoneError ? (
                        <div className="invalid-feedback">
                          Mobile number is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="date"
                          className={
                            dobError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="dob"
                          aria-describedby="textHelp"
                          placeholder="D.O.B"
                          value={moment.utc(dob).format("yyyy-MM-DD")}
                          onChange={(e) => handleCheckDOB(e.target.value)}
                          required
                        />
                        {/* <input
                          className="form-control"
                          type="text"
                          placeholder="31/12/2021"
                          aria-label="default input example"
                        /> */}
                        <span>
                          <FaBirthdayCake />
                        </span>
                      </div>
                      {dobError && (
                        <div className="invalid-feedback">
                          Please valid date of birth
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exp">Licence No</label> */}
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="License No."
                          value={licenceno}
                          id="licenceNo"
                          className="form-control"
                          // readOnly
                          onChange={(e) => handleLicenceNo(e.target.value)}
                        />
                        <span>
                          <FaIdCard />
                        </span>
                      </div>
                      {documentNoError ? (
                        <div className="invalid-feedback">
                          Please enter valid Document Number
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exp">Licence Expiry Date</label> */}
                      <div className="form-group">
                        <input
                          type="date"
                          min={moment.utc(Date.now()).format("yyyy-MM-DD")}
                          className={
                            dobError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="expiryDatenew"
                          aria-describedby="textHelp"
                          placeholder="Expiry Date"
                          onChange={(e) => _secDateHandle(e.target.value)}
                          required
                        />
                        {/* <input
                          className="form-control"
                          type="text"
                          placeholder="31/12/2021"
                          aria-label="default input example"
                        /> */}
                        <span>
                          <FaCalendar />
                        </span>
                      </div>
                      {errorSecurityRegistrationExpiryDate ? (
                        <div className="invalid-feedback">
                          Please enter security registration expiry date
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="avatar">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          {/* <label for="exp">Licence Image</label> */}
                          <div className="license_upload_imgBox">
                            <input
                              type="file"
                              id="uploadPhotoLicenec"
                              className="form-control profile-select d-none"
                              htmlFor="exampleInputtext"
                              aria-describedby="textHelp"
                              name="licencePhoto"
                              // capture="environment"
                              onChange={(e) => handleLicencePhoto(e)}
                              accept="image/*"
                            />
                            <label
                              htmlFor="uploadPhotoLicenec"
                              className="profileImg"
                            >
                              <img
                                src={
                                  LicencePreviewUrl === undefined ||
                                  LicencePreviewUrl === ""
                                    ? Profile
                                    : LicencePreviewUrl
                                }
                                style={{
                                  width: 88,
                                  height: 88,
                                  borderRadius: 44,
                                  overflow: "hidden",
                                }}
                              />
                              <div className="icon">
                                <div className="camIcon">
                                  <label htmlFor="uploadPhotoLicenec">
                                    <FaPen />
                                  </label>
                                </div>
                              </div>
                            </label>
                            {/* <img src={licenceImg} className="img-fluid" /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="agreeBtn">
                        <button
                          type="button"
                          onClick={(e) =>
                            isLoading ? "" : handleUpdateProfile(e)
                          }
                        >
                          {isLoading ? "Loading..." : "Update"}
                        </button>
                        {/* <Link to="/myprofile">Update</Link> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfilePic;
