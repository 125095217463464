import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
// import Slider from "@mui/material/Slider";
// import AllJob from "../AllJob/AllJob";
// import mapIcon from "../../images/map.png";
// import mapImg from "../../images/mapImg.png";
// import { FaInfoCircle, FaStar, FaCalendar, FaStarHalf, FaArrowRight } from "react-icons/fa";
import "./job_range.scss";
import Navbar from "../../Pages/Header/Navbar";
// import Footer from "../../Pages/Footer/Footer";
import { getAllJobs, getSpecialityList } from "../../utils/request";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Pagination from "react-js-pagination";
import moment from "moment";
import InputRange from "react-input-range";
import { Rating } from "react-simple-star-rating";

function valuetext(value) {
  return `${value}°C`;
}

const JobRange = () => {
  const [ratingValue, setRatingValue] = useState(0);
  const [transpotation, setTranspotation] = useState("");
  const [vestSize, setVestSize] = useState("");
  const [shiftType, setShiftType] = useState("");
  const [jobType, setJobType] = useState("");
  const [dressCode, setDressCode] = useState("");
  const navigate = useNavigate();
  const cookie = new Cookies();
  const token = cookie.get("token");
  const guardInfo = JSON.parse(localStorage.getItem("guardInfo"));
  const [value, setValue] = useState([20, 37]);
  const [jobList, setJobList] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [specialityItem, setSpecialityItem] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  let [isClicked, setIsClicked] = useState(false);
  const [isTravelTo, setTravelTo] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (token) {
      getSpecialityLists();
      getAllJob(1);
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Function for get speciality list
  const getSpecialityLists = async () => {
    const response = await getSpecialityList();
    if (response?.data?.code === 200) {
      setSpecialityList(response.data.data);
    }
  };

  const handleRatingNew = () => {
    // setRatingValue(2.5);
  };
  //console.log(isTravelTo, "isTravelTo");
  // Function for get job list
  const getAllJob = async (pageNo) => {
    // //console.log("i am called");
    const requestData = {
      sortBy: "",
      limit: 10,
      page: pageNo,
      price: 1,
      distanceFromBase: isTravelTo,
      minRange: 1,
      maxRange: 1,
      searchBy: "",
      range: "",
      speciality: specialityItem,
      rating: ratingValue,
      shifts: shiftType,
      jobs: jobType,
      transportation: transpotation,
      dressCode: dressCode,
      liveLong: isTravelTo === 0 ? "" : guardInfo?.lng,
      liveLat: isTravelTo === 0 ? "" : guardInfo?.lat,
      status: "",
    };
    setIsLoading(true);
    const response = await getAllJobs(requestData);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    //console.log(response);
    if (response?.data?.code === 200) {
      setTimeout(() => {
        handleRatingNew();
      }, 2000);
      setIsLoading(false);
      settotalItemsCount(response?.data?.data?.total);
      setJobList(response?.data?.data?.docs);
    } else {
      setIsLoading(false);
    }
  };

  // Handle Values
  const handleSpecialityItem = (newValue) => {
    specialityItem.push(newValue);
    //console.log(specialityItem);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const onTranspotationChanged = (v) => {
    //console.log(v, "vv");
    setTranspotation(v);
  };

  // Handle Page Change function
  const handlePageChange = (pageNumber) => {
    // //console.log(pageNo);
    setPageNo(pageNumber);
    // pageNo = pageNumber;
    getAllJob(pageNumber);
  };
  // Handle Values
  const getFilterJob = () => {
    //console.log("i am called");
  };

  const handleRating = (rate) => {
    //console.log(rate / 20);
    setRatingValue(rate / 20);
  };

  const vestChanges = (v) => {
    setVestSize(v);
  };

  const shiftChanges = (v) => {
    setShiftType(v);
  };

  const jobChanges = (v) => {
    setJobType(v);
  };

  const dressCodeChanges = (v) => {
    setDressCode(v);
  };
  // Clear Filter Function
  const clearFilter = () => {
    setTravelTo(0);
    setSpecialityItem([]);
    setRatingValue(0);
    setShiftType("");
    setJobType("");
    setTranspotation("");
    setDressCode("");
    setVestSize("");
  };

  return (
    <>
      <Navbar />
      <section className="job_range">
        <div className="container">
          <div className="row align-items-center mb-2">
            {/* <div className="col-6">
              <h4>Filters</h4>
            </div>    */}
            <div className="col-12">
              <a
                className="btn apply_togglebtn"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={() => {
                  setIsClicked((isClicked = !isClicked));
                }}
              >
                {isClicked ? "Hide Filters" : "Show Filters"}
              </a>
            </div>
          </div>

          <div
            className={isClicked ? "collapse show" : "collapse"}
            id="collapseExample"
          >
            <div className="row mb-2">
              <div className="col-md-12">
                <h4>Filters</h4>
              </div>
            </div>
            <div className="travel_filter">
              <div className="row mb-3">
                <div className="col-md-6 mt-3 mt-md-0">
                  <div className="Box travel_box">
                    <h6>I am willing to travel</h6>
                    <br />
                    <Box sx={{ width: 400 }}>
                      <InputRange
                        maxValue={75}
                        minValue={0}
                        formatLabel={(value) => `${value} km`}
                        // disabled
                        value={isTravelTo}
                        onChange={(e) => setTravelTo(e)}
                      />
                    </Box>
                  </div>
                </div>
                {/* <div className="col-md-4 mt-3 mb-3 mt-md-0 mb-md-0"> */}

                <div className="col-md-6 mt-3 mt-md-0">
                  <div className="Box">
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <h6>Shifts Type:</h6>
                      </div>
                      <div className="col-md-9">
                        <ul>
                          <li>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                checked={shiftType === "1"}
                                value="1"
                                name="shiftType"
                                type="checkbox"
                                onChange={(e) => shiftChanges(e.target.value)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Morning
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={shiftType === "2"}
                                value="2"
                                name="shiftType"
                                onChange={(e) => shiftChanges(e.target.value)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Afternoon
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={shiftType === "3"}
                                value="3"
                                name="shiftType"
                                onChange={(e) => shiftChanges(e.target.value)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Evening
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>

            <div className="row mb-3 ">
              <div className="col-md-6 mt-3 mt-md-0">
                <div className="Box">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <h6>Jobs:</h6>
                    </div>
                    <div className="col-md-9">
                      <ul>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={jobType === "all"}
                              value="all"
                              name="jobType"
                              onChange={(e) => {
                                jobChanges(e.target.value);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              All
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={jobType === "1"}
                              value="1"
                              name="jobType"
                              onChange={(e) => {
                                jobChanges(e.target.value);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Available
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={jobType === "2"}
                              value="2"
                              name="jobType"
                              onChange={(e) => {
                                jobChanges(e.target.value);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Applied
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-3 mt-md-0">
                <div className="Box">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <h6>Dress Code:</h6>
                    </div>
                    <div className="col-md-9">
                      <div className="dressCode">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="1"
                            name="dressCode"
                            checked={dressCode === "1"}
                            onChange={(e) => {
                              dressCodeChanges(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            HV Vest
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="2"
                            name="dressCode"
                            checked={dressCode === "2"}
                            onChange={(e) => {
                              dressCodeChanges(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Casual
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="3"
                            name="dressCode"
                            checked={dressCode === "3"}
                            onChange={(e) => {
                              dressCodeChanges(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            Suited
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6 mt-3 mt-md-0"></div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="Box">
                  <h6>Speciality:</h6>
                  <div className="row">
                    {specialityList.map((item, index) => {
                      return (
                        <div className="col-md-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={item.id}
                              name={item.name}
                              onChange={() => handleSpecialityItem(item?.id)}
                              // defaultChecked={airports}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              {item.name}
                              {/* <a href="#">
                                <FaInfoCircle />
                              </a> */}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="searchJob_table">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="detailsBox">
                  <div className="rate_title">
                    <div className="row align-items-center">
                      <div className="col-6">
                        <h4>All Jobs</h4>
                      </div>
                      <div className="col-6">
                        <div className="fltr">
                          {isTravelTo !== 0 ||
                          transpotation !== "" ||
                          vestSize !== "" ||
                          shiftType !== "" ||
                          jobType !== "" ||
                          dressCode !== "" ||
                          ratingValue !== 0 ||
                          specialityItem.length !== 0 ? (
                            <button
                              className="filter_btn"
                              type="button"
                              onClick={(e) => {
                                // //console.log(e);
                                clearFilter();
                              }}
                            >
                              Clear Filters
                            </button>
                          ) : null}

                          {isTravelTo !== 0 ||
                          transpotation !== "" ||
                          vestSize !== "" ||
                          shiftType !== "" ||
                          jobType !== "" ||
                          dressCode !== "" ||
                          ratingValue !== 0 ||
                          specialityItem.length !== 0 ? (
                            <button
                              className="filter_btn"
                              type="button"
                              onClick={(e) => {
                                // //console.log(e);
                                getAllJob(1);
                              }}
                            >
                              Apply Filters
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive border-1">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Job Title</th>
                              <th>Location</th>
                              <th className="w-10">Date</th>
                              <th className="w-10">Start Time</th>
                              <th className="w-10">End Time</th>
                              <th className="w-10">Ratings</th>
                              <th className="w-5">Action</th>
                            </tr>
                          </thead>
                          {isLoading && (
                            <tbody>
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan={6}>
                                  Loading...
                                </td>
                              </tr>
                            </tbody>
                          )}

                          {!isLoading && (
                            <tbody>
                              {jobList?.length !== 0 ? (
                                jobList?.map((item, i) => {
                                  return (
                                    <>
                                      <tr key={i}>
                                        <td>{item?.jobTitle}</td>
                                        <td>
                                          {item?.locationId?.locationName}
                                        </td>
                                        <td>
                                          {moment
                                            .utc(item?.jobDate)
                                            .format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                          {moment
                                            .utc(item?.startTime)
                                            .format("h:mm, A")}
                                        </td>
                                        <td>
                                          {moment
                                            .utc(item?.endTime)
                                            .format("h:mm, A")}
                                        </td>
                                        <td>
                                          <div className="starRate">
                                            <Rating
                                              // ratingValue={item?.clientRating}
                                              onClick={handleRatingNew}
                                              initialValue={item?.clientRating}
                                              allowHalfIcon
                                              readonly
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <button
                                            onClick={() =>
                                              navigate(`/jobPage`, {
                                                state: { item },
                                              })
                                            }
                                            className="view_btn"
                                          >
                                            View More
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={6}
                                  >
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                  {jobList.length !== 0 && (
                    <div className="mt-2">
                      <Pagination
                        color="primary"
                        activePage={pageNo}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <AllJob /> */}
      </section>

      <div
        className="modal fade jobApply"
        id="jobApply"
        tabIndex="-1"
        aria-labelledby="jobDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                You have successfully applied for Job
              </h5>
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn submitBtn"
                data-bs-dismiss="modal"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobRange;
// 625519b7f27f1d4820ac651e
