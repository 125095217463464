import React, { useState, useEffect } from "react";
import Pics3 from "../../images/imge placeholder.png";
import {
  FaCamera,
  FaCaretDown,
  FaPaperclip,
  FaPlusCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./report_injury.scss";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import {
  createReportInjury,
  getInjuryReason,
  getJobList,
  getAllJobsDropDown,
} from "../../utils/request";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const ReportInjury = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [injuryList, setInjuryList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileNameError, setFileNameError] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [jobId, setJobId] = useState("");
  const [jobIdError, setJobIdError] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(Pics3);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (token) {
      getReasonList();
      getJobLists();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);
  // Functions for handle validation
  const handleDescriptionChange = (value) => {
    setDescription(value);
    if (value === "") {
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }
  };

  // const handleFileChange = (value) => {
  //   setFileName(value);
  //   if (value === "") {
  //     setFileNameError(true);
  //   } else {
  //     setFileNameError(false);
  //   }
  // };

  const handleTitle = (e) => {
    setTitle(e);
    if (e === "") {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
  };
  // Function for handle file change
  const handleFileChange = (e) => {
    e.preventDefault();
    //console.log(e.target.files.length);

    let reader = new FileReader();
    let file = e.target.files[0];
    const fdata = [];
    fdata.push(e.target.files[0]);
    setFileName(fdata);
    //console.log(file);
    reader.onloadend = () => {
      // //console.log(reader.result);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    if (file.length === 0) {
      setFileNameError(true);
    } else {
      setFileNameError(false);
    }
  };
  const handleJobId = (e) => {
    setJobId(e);
  };
  // Function for create injury
  const createInjury = async () => {
    if (jobId === "") {
      setJobIdError(true);
      setTitleError(false);
      setDescriptionError(false);
      setFileNameError(false);
    } else if (title === "") {
      setJobIdError(false);
      setTitleError(true);
      setDescriptionError(false);
      setFileNameError(false);
    } else if (description === "") {
      setJobIdError(false);
      setTitleError(false);
      setDescriptionError(true);
      setFileNameError(false);
    } else if (fileName === "") {
      setJobIdError(false);
      setTitleError(false);
      setDescriptionError(false);
      setFileNameError(true);
    } else {
      var bodyFormData = new FormData();

      bodyFormData.append("jobId", jobId);
      bodyFormData.append("title", title);
      bodyFormData.append("description", description);
      for (var i = 0; i < fileName.length; i++) {
        // body.append("images", this.file[i]);
        bodyFormData.append("file", fileName[i]);
      }
      setIsLoading(true);
      const response = await createReportInjury(bodyFormData);
      setIsLoading(false);
      //console.log(response);
      if (response?.data?.code === 401) {
        // setIsloading(false);
        toast.error(response?.data?.message);
        navigate(`/guard/login`);
      }
      if (response.data.code === 200) {
        setJobId("");
        setTitle("");
        setDescription("");
        setFileName("");
        navigate(`/previousInjury`);
      }
    }
  };
  // Function for get injury list
  const getReasonList = async () => {
    const response = await getInjuryReason();
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      setInjuryList(response.data.data);
    }
  };
  // Function for get job list
  const getJobLists = async () => {
    const data = {
      jobStatus: 2,
    };
    const response = await getAllJobsDropDown(data);
    //console.log("getJobLists", response);
    if (response?.data?.code === 200) {
      setJobList(response?.data?.data.docs);
    }
  };

  // MyJob (laga di static data se,,, lekin ye nhi pata ui par kha lgani h)
  // Apply Leave (laga di static data se,,, lekin ye nhi pata ui par kha lgani h)
  // E-Note (laga di static data se,,, lekin ye nhi pata ui par kha lgani h)
  // Report Injury (laga di bs check karni h ek baar) thik h bs job list ni aari bs...

  // Alert (pata nhi kuch is baare m)
  // History (mili nhi api)
  // My Earning (mili nhi api)
  // Cancel Job (pata nhi konsi api lagegi)

  // Support (dekhta hu)
  // Job Start (dekhta hu)
  // Notification (dekhta hu)
  // My Account (update account wali to laga di h,,, my account ki api ka pata nhi konsi h)

  return (
    <>
      <Navbar />
      <section className="report_injury">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6">
              <div className="content">
                <form>
                  <div className="title">
                    <div className="heading">
                      <h1>Report Injury</h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <select
                          onChange={(e) => handleJobId(e.target.value)}
                          className="form-control"
                          defaultValue=""
                        >
                          <option selected>Select Job</option>
                          {jobList?.map((user, index) => {
                            return (
                              <option value={user?.jobId?.id}>
                                {user?.jobId?.jobTitle}
                              </option>
                            );
                          })}
                          {/* <option value="1">Select All</option>
                          <option value="2">One</option>
                          <option value="3">Two</option>
                          <option value="4">Three</option> */}
                        </select>
                        <span>
                          <FaCaretDown />
                        </span>
                      </div>
                      {jobIdError ? (
                        <div className="invalid-feedback">
                          Please select title
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <select
                          onClick={(e) => handleTitle(e.target.value)}
                          className="form-control"
                          defaultValue=""
                        >
                          <option selected value="Injured During Mob Attack">
                            Injured During Mob Attack
                          </option>
                          {injuryList.map((user, index) => {
                            return (
                              <option value={user.name}>{user.name}</option>
                            );
                          })}
                          {/* <option value="Select All">Select All</option>
                          <option value="One">One</option>
                          <option value="Two">Two</option>
                          <option value="Three">Three</option> */}
                        </select>
                        <span>
                          <FaCaretDown />
                        </span>
                      </div>
                      {titleError ? (
                        <div className="invalid-feedback">
                          Please select title
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control textArea"
                      rows="4"
                      placeholder="Description"
                      id="floatingTextarea"
                      value={description}
                      onChange={(e) => handleDescriptionChange(e.target.value)}
                    ></textarea>
                  </div>
                  {descriptionError ? (
                    <div className="invalid-feedback">
                      Please enter description
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group attachInput">
                    <div className="form-control">
                      <span>Attach Photo</span>
                    </div>
                    <label htmlFor="uploadAttach" className="attachIcon">
                      <FaPaperclip />
                    </label>
                    <input
                      // value={fileName}
                      onChange={(e) => handleFileChange(e)}
                      type="file"
                      id="uploadAttach"
                      multiple
                      className="d-none"
                      accept="image/*"
                    />
                    <ul>
                      <li>
                        <img src={imagePreviewUrl} className="img-fluid" />{" "}
                        {/* <a href="#">x</a> */}
                      </li>
                    </ul>
                  </div>
                  {fileNameError ? (
                    <div className="invalid-feedback">Please select file</div>
                  ) : (
                    ""
                  )}
                  <div className="btnBox">
                    <button type="button" disabled={isLoading}>
                      <a
                        type="button"
                        onClick={() => (isLoading ? "" : createInjury())}
                      >
                        {isLoading ? "Please Wait..." : "Submit"}
                      </a>
                    </button>{" "}
                    <Link to="/previousInjury">Previous Injuries</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportInjury;
