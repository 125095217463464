import React, { useEffect } from "react";
import './ontario.scss';
import WelcomeHeader from '../../Pages/Wel_Header/WelcomeHeader';
import WelcomeFooter from '../../Pages/Wel_Footer/WelcomeFooter';
import OntarioSer from '../../images/how_work/ontario_service.png';
// import CusImg1 from '../../images/how_work/client-1.png';
// import CusImg2 from '../../images/how_work/client-2.png';
// import CusImg3 from '../../images/how_work/client-3.png';
// import CusImg4 from '../../images/how_work/client-4.png';
import { FaCaretRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import GuardsignupSection from "../../shared/guardSignup";
import ontario_service from "../../images/welcomePage/ontario-service.png";
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';

const Ontario = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <WelcomeHeader />
            <section className="banner-ontario">
                <div className="container">
                    <div className='workBox'>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-12">
                                <div className="work">
                                    <h1><strong>GoGuard Ontario</strong></h1>
									<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
										<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
										<Breadcrumb.Item active>Ontario</Breadcrumb.Item>
									</Breadcrumb>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="airpoSec">
                <div className="workGuard">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                    <h2><strong>GoGuard At Ontario</strong></h2>
                                    <p>GoGuard is a registered security service provider legally authorized to provide security services in Ontario Canada. We are an online security provider who provide guards for a wide range of security needs. We offer security services to Airports, Banks, Car Lots, Events, Strikes, Estate, Loss, Residential, Protection, Private & VIP’s. Our security guards are licensed and trained to protect you and your property with utmost care, confidentiality and commitment.</p>
                                    <p>If you are an individual, a small business or a large corporation looking to hire security guards in Ontario, Canada, we can offer you technology backed security services. Our services enable you to get a better insight on your security detail and you are always apprised of events happening around you and your assets.</p>
                                    <p>Get in touch with us to discuss your security requirements today!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
		{/*<section className="ontarioSec">
                <div className="workGuard">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="imgBox">
                                    <img src={OntarioSer} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content">
                                    <h2>Service <strong>Areas In Ontario</strong></h2>
                                    <p>GoGuard offers on demand security guards in major cities of Ontario. We plan to expand and offer our services across Ontario in the coming months. Currently, we are serving the following regions.</p>
                                    <ul>
                                        <li><p><FaCaretRight /> Toronto & nearby regions</p></li>
                                        <li><p><FaCaretRight /> Ottawa	 & nearby regions</p></li>
                                        <li><p><FaCaretRight /> Mississauga & nearby regions</p></li>
                                        <li><p><FaCaretRight /> Brampton & nearby regions</p></li>
                                        <li><p><FaCaretRight /> Hamilton & nearby regions</p></li>
                                        <li><p><FaCaretRight /> London & nearby regions</p></li>
                                        <li><p><FaCaretRight /> Markham & nearby regions</p></li>
                                        <li><p><FaCaretRight /> Vaughan & nearby regions</p></li>
                                        <li><p><FaCaretRight /> Kitchener & nearby regions</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}
			<section class="service-areas py-6">
			<div class="container">
			<div class="row">
			<div class="col-lg-5 mb-4 mb-lg-0">
			<img src={ontario_service} alt="Services Areas In Ontario" class="img-fluid rounded-3" width="593px" height="359px" />
			</div>
			<div class="col-lg-7">
			<h2><strong>Services Areas</strong> In Ontario</h2>
			<p class="sec-sub-heading">GoGuard offers on demand security guards in major cities of Ontario.</p>
			<p>We plan to expand and offer our services across Ontario in the coming months. Currently, we are serving the following regions.</p>
			<ul class="list-unstyled d-flex flex-wrap">
			<li>Toronto</li>
			<li>Ottawa</li>          
			<li>Mississauga</li>
			<li>Brampton</li>             
			<li>Hamilton</li>                
			<li>London</li>
			<li>Markham</li>
			<li>Vaughan</li>
			<li>Kitchener</li>
			</ul>
			</div>
			</div>
			</div>
			</section>
            <GuardsignupSection />
            <WelcomeFooter />
        </>
    )
}

export default Ontario;
