import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaClock } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./injury_detail.scss";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

import Navbar from "../../Pages/Header/Navbar";
import { getInjuryDetail } from "../../utils/request";
import JobDetail from "../JobDetail/JobDetail";
import { API_URL } from "../../utils/constants";

const InjuryDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cookie = new Cookies();
  const token = cookie.get("token");
  const [injuryDetail, setInjuryDetail] = useState("");

  useEffect(() => {
    if (token) {
      getInjury(id);
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Function to get injury
  const getInjury = async (inid) => {
    const response = await getInjuryDetail(inid);
    //console.log(response);
    if (response?.data?.code === 200) {
      setInjuryDetail(response?.data?.data);
    }
  };

  return (
    <>
      <Navbar />
      <section className="injury_details">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="content">
                <div className="contentBox">
                  <form>
                    <div className="title">
                      <div className="backArrow">
                        <Link to="/previousInjury">
                          <FaArrowLeft />
                        </Link>
                      </div>
                      <div className="heading">
                        <h1>Injury Detail</h1>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            value={injuryDetail?.title}
                            placeholder="Injury Title"
                            aria-label="default input example"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            value={injuryDetail?.jobId?.jobTitle}
                            placeholder="Mall Security Job"
                            aria-label="default input example"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control timeInput"
                            id="exampleInputtext"
                            value={injuryDetail?.createdAt}
                            aria-describedby="textHelp"
                            placeholder="01:20 PM,12/11/2021"
                            readOnly
                          />
                          <span className="mapIcon">
                            <FaClock />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="descBox">
                        <h6 className="descPara">Description</h6>
                        <p>{JobDetail?.description}</p>
                        <h6 className="injury_title">Injury Pics</h6>
                        <div className="pics_box">
                          {injuryDetail?.files?.map((res, i) => {
                            return (
                              <p>
                                <img
                                  src={
                                    API_URL + "/media/preview?filename=" + res
                                  }
                                  alt="img"
                                  className="img-fluid"
                                />
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {injuryDetail?.response?.map((r, i) => {
                        return (
                          <div class="descBox reponse">
                            <h6 className="descPara">{r?.title}</h6>
                            <p>{r?.description}</p>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InjuryDetail;
