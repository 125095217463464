import React, { useState } from "react";
import "../Component/termsAndCondition/termsAndCondition.scss";
import { newsLetterAPI } from "../utils/reachUsRequest";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
// import $ from "jquery";

const SubscribeSection = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isEmailBlankError, setIsEmailBlankError] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Function for handle email validation
  const handleEmailChange = (value) => {
    setEmail(value);
    if (value === "") {
      setIsEmailBlankError(true);
      setEmailError(false);
      return false;
    }
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (regex.test(value) === false) {
      setEmailError(true);
      setIsEmailBlankError(false);
      return true;
    } else {
      setEmailError(false);
      setIsEmailBlankError(false);
      return true;
    }
  };
  // Function for subscribe newsletter
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleEmailChange(email)) {
      var reqObj = {
        email: email,
      };
      setIsLoading(true);
      await newsLetterAPI(reqObj).then((response) => {
        setIsLoading(false);
        if (response.data.code === 200) {
          toast.success(response.data.message, "green");
          setEmail("");
          setSmShow(false);
        } else {
          setIsLoading(false);
          toast.error(response.data.message, "red");
        }
      });
    }
  };
  return (
    <>
      <section className="subscribe">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <p>
                Stay in touch to get updates on our launch events and offers.
              </p>
            </div>
            <div className="col-md-3">
              <div className="subBtn">
                <a onClick={() => setSmShow(true)}>Subscribe</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            Join our newsletter
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              className={
                emailError ? "form-control is-invalid" : "form-control"
              }
              type="email"
              placeholder="Enter you email"
              value={email}
              aria-label="default input example"
              onChange={(e) => handleEmailChange(e.target.value)}
              required
            />
            {emailError && (
              <div className="invalid-feedback">Please enter valid email</div>
            )}
            {isEmailBlankError && (
              <div className="invalid-feedback mt-1">Email is required</div>
            )}
          </div>
          {/* <div className="modal-footer">
                        <button type="button" className="btn submitBtn" onClick={(e) => handleSubmit(e)}>SUBSCRIBE</button>
                    </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn submitBtnbtn"
            onClick={(e) =>
              emailError || isEmailBlankError || isLoading
                ? ""
                : handleSubmit(e)
            }
          >
            {isLoading ? "Please Wait..." : "SUBSCRIBE"}
          </button>
        </Modal.Footer>
      </Modal>
      {/* <div className="modal jobDetail" id="jobDetail" tabIndex="-1" aria-labelledby="jobDetailLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header mb-4">
                            <h5 className="modal-title" id="exampleModalLabel">Join our newsletter</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <input
                                    className={
                                        emailError
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    type="email"
                                    placeholder="Enter you email"
                                    value={email}
                                    aria-label="default input example"
                                    onChange={(e) => handleEmailChange(e.target.value)}
                                    required />
                                {emailError && (
                                    <div className="invalid-feedback">
                                        Please enter valid email
                                    </div>
                                )}
                                {isEmailBlankError && (
                                    <div className="invalid-feedback mt-1">
                                        Email is required
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn submitBtn" onClick={(e) => handleSubmit(e)}>SUBSCRIBE</button>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default SubscribeSection;
