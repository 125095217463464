import React, { useEffect } from "react";
import './aboutus.scss';
import '../../css/style.css';
import WelcomeHeader from '../../Pages/Wel_Header/WelcomeHeader';
import WelcomeFooter from '../../Pages/Wel_Footer/WelcomeFooter';
import SubscribeSection from '../../shared/subscribe';
import go_guard_icon from '../../images/go-guard-icon.png';
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <WelcomeHeader />
            <section className="aboutBanner">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="work">
                                <h1>About Us</h1>
								<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
								<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
								<Breadcrumb.Item active>About Us</Breadcrumb.Item>
								</Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="inner-intro text-center py-6">
			<div class="container">
			<h2>Fulfiling All <strong>Your Safety Requirements &amp; Needs</strong></h2>
			<p>Fed up with the tiresome process of hiring security which takes hours of form filling and you still need to wait for days to get security in place? Tired of the huge agency retainers and costs which increase your security costs? We have a solution to all your worries. Imagine a platform that allows you to register as a client and hire guards in a few clicks and get a guard in just 18 hours of notice or even sooner. GoGuard is an exclusively online security platform that allows you to hire security guards with a few clicks.</p>
			</div>
			</section>
			<section class="about-statement py-6">
<div class="container">
<h2 class="mb-5"><strong>Mission</strong> Statement</h2>
<div class="row align-items-center">
<div class="col-md-9">
<ul class="list-unstyled mb-0">
<li>
<h3>Communication</h3>
<p>GoGuard provides you with a mobile app to hire, track and communicate with your security team in a few clicks. Get instant updates, send notifications, and instant messages to your security team, and get quick messages from them on the mobile app. Leverage technology to improve your security with GoGuard – i.e. live tracking, instant messages, and SOS alerts. GoGuard makes the most of the technology to enhance your security experience while bringing down costs.</p>
</li>
<li>
<h3>Confidence</h3>
<p>We are a fully licensed, insured, and bonded personal security guard service, starting operations primarily in Ontario, Canada with a vision to expand in the future. We provide security services to our customers using revolutionary mobile apps that take your security to the next level.</p>
</li>
</ul>
</div>
<div class="col-md-3 text-center d-none d-md-block">
<img src={go_guard_icon} alt="Go Guard" class="img-fluid" />
</div>
</div>
</div>
</section>
            <SubscribeSection />
            <WelcomeFooter />
        </>
    )
}

export default AboutUs;
