import React, { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import moment from "moment";
import {
  FaUserAlt,
  FaBirthdayCake,
  FaArrowRight,
  FaArrowLeft,
  FaEnvelope,
  FaPhone,
  FaLock,
  FaEye,
  FaEyeSlash,
  // FaTimes,
  // FaCheck,
} from "react-icons/fa";
import Cookies from "universal-cookie";
import LogoIcon from "../Logo/Logo";
import { Link } from "react-router-dom";
import "./signup.scss";
import { signUp } from "../../utils/request";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";

const Signup = () => {
  const navigate = useNavigate();
  // const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [dob, setDOB] = useState(
  //   moment.utc().subtract(18, "years").format("yyyy-MM-DD")
  // );
  const [dob, setDOB] = useState("");
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLicensed, setIsLicensed] = useState();
  let [licenseNumber, setLicenseNumber] = useState("");
  let [licenseNumberError, setlicenseNumberError] = useState(false);
  let [licenseNumberValidError, setlicenseNumberValidError] = useState(false);
  const [error, setError] = useState(false);
  const [isError, setIsError] = useState("");
  const [fNameError, setfNameError] = useState(false);
  const [fNameValidError, setfNameValidError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [lNameValidError, setlNameValidError] = useState(false);
  const [mNameError, setmNameError] = useState(false);
  const [dobError, setdobError] = useState(false);
  const [dobReqError, setdobReqError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneValidError, setPhoneValidError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailIsRequiredError, setEmaiIsRequiredlError] = useState(false);
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [cPasswordError, setcPasswordError] = useState(false);
  const [validated, setValidated] = useState(false);

  const [match, setMatch] = useState(false);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowCPassword, setIsShowCPassword] = useState(false);
  const [allowNullLicence, setAllowNullLicence] = useState(false);

  const formRef = useRef();
  const [basicToken, setbasicToken] = useState(
    "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
  );

  const years = range(1900, 3000, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    passwordFunction();
    // handleFirstName(firstName);
    // handleLastName(lastName);
    handlePasswordChange(password);
  }, [
    numberValid,
    uppercaseValid,
    charNumberValid,
    specialCharValid,
    fNameError,
    fNameValidError,
    lastName,
    lNameError,
    lNameValidError,
    password,
    passwordIsRequiredError,
  ]);

  const cookie = new Cookies();
  cookie.set("session", basicToken);
  // Functions for handle validatio
  const handleLicense = (islicense) => {
    if (islicense == "yes") {
      setIsLicensed(true);
      setAllowNullLicence(false);
    } else if (islicense == "no") {
      licenseNumber = "";
      document.getElementById("licenceno").value = "";
      setAllowNullLicence(true);
      handleLicenseNumber("");
      licenseNumberError = false;
      setlicenseNumberError(false);
      licenseNumberValidError = false;
      setlicenseNumberValidError(false);
      setIsLicensed(false);
      // setlicenseNumberError(false);
    }
  };

  const handleCheckDOB = (value) => {
    if (value === "") {
      setdobReqError(true);
      setDOB(value);
    } else {
      setdobReqError(false);
    }
    //console.log("value:118 ", value);

    var today = new Date();
    var birthDate = new Date(value);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age >= 18) {
      setdobError(false);
      setDOB(value);
    } else {
      setdobError(true);
      setDOB(value);
    }
  };

  const handleConfirmPass = (e) => {
    setConfirmPassword(e.target.value);
    if (password !== e.target.value) {
      setcPasswordError(true);
    } else {
      setcPasswordError(false);
    }
  };
  // Function for signup
  const handleSignUp = async (event) => {
    //console.log(isLicensed);
    event.preventDefault();
    setdobReqError(true);
    if (dob === "") {
      setdobReqError(true);
    } else {
      setdobReqError(false);
    }

    const re =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    var phoneno = /^[0-9]*$/;
    const nameRegex = /^[a-zA-Z]{2,}$/;

    if (email === "" || !email) {
      setEmaiIsRequiredlError(true);
      setEmailError(false);
    } else if (!re.test(email)) {
      setEmailError(true);
      setEmaiIsRequiredlError(false);
      event.stopPropagation();
      // return;
    }

    if (firstName === "") {
      setfNameError(true);
      setfNameValidError(false);
      // return;
    } else if (!nameRegex.test(firstName)) {
      setfNameValidError(true);
      setfNameError(false);
      // return;
    } else {
      setfNameError(false);
      setfNameValidError(false);
    }

    if (lastName === "" || !lastName) {
      setlNameError(true);
      setlNameValidError(false);
      // return;
    } else if (nameRegex.test(lastName) === false) {
      setlNameValidError(true);
      setlNameError(false);
      // return
    }

    if (phone && !phoneno.test(phone)) {
      setPhoneValidError(true);
      setPhoneError(false);
      // return;
    }
    if (phone === "") {
      setPhoneError(true);
      // return;
    }
    if (phone && !phoneno.test(phone)) {
      setPhoneValidError(true);
      setPhoneError(false);
      // return;
    }
    if (password === "") {
      setPasswordIsRequiredError(true);
    }
    if (password !== confirmPassword) {
      setcPasswordError(true);
      // return;
    }

    if (isLicensed === undefined) {
      return;
    }

    if ((isLicensed && licenseNumber === "") || !licenseNumber) {
      setlicenseNumberError(true);
    }

    if (isLicensed && licenseNumber === "") {
      return;
    }

    if (dob) {
      var today = new Date();
      var birthDate = new Date(dob);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age >= 18) {
        setdobError(false);
      } else {
        setdobError(true);
        return;
      }
    }
    if (
      !fNameError &&
      !fNameValidError &&
      !mNameError &&
      !lNameError &&
      !lNameValidError &&
      !dobError &&
      !dobReqError &&
      !phoneError &&
      !phoneValidError &&
      !emailError &&
      !emailIsRequiredError &&
      !passwordError &&
      !passwordIsRequiredError &&
      !cPasswordError &&
      (isLicensed || !isLicensed) &&
      email &&
      password &&
      firstName &&
      lastName &&
      phone &&
      dob &&
      confirmPassword
    ) {
      setcPasswordError(false);
      setEmailError(false);
      setPhoneError(false);

      if (allowNullLicence) {
        setLicenseNumber("");
      }

      const requestData = {
        email: email,
        password: password,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        mobileNumber: phone,
        dob: dob,
        isLicense: isLicensed,
        licenseNumber: licenseNumber,
        deviceToken: "web",
        deviceId: "web",
        deviceType: 3,
      };
      //console.log(requestData);
      setIsLoading(true);
      await signUp(requestData).then((response) => {
        // setIsLoading(false);
        if (Object.values(response).length > 0) {
          if (response.data !== undefined) {
            if (response.data.code == 200) {
              setIsLoading(false);
              if (licenseNumber === "") {
                navigate(`/thankYou?isLicense=` + "blank");
                // navigate(`/guard/myprofile/${response.data.data.guard.id}`);
              } else {
                navigate(`/privacy/${response.data.data.guard.id}`);
              }
            } else {
              setError(true);
              setIsError(response.data.message);
              setIsLoading(false);
            }
          } else {
            setError(true);
            setIsError(response.error);
            setIsLoading(false);
          }
        } else {
          setError(true);
          setIsError(response.data.message);
          setIsLoading(false);
        }
      });
    }
  };
  // Functions for handle validation
  const handleEmailChange = (value) => {
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    if (regex.test(value) === false) {
      setEmailError(true);
      setEmaiIsRequiredlError(false);
    } else {
      setEmailError(false);
      setEmaiIsRequiredlError(false);
      setemail(value);
    }
  };

  const handleFirstName = (value) => {
    setFirstName(value);
    const regex = /^[a-zA-Z]{2,}$/;
    if (firstName && regex.test(value) === false) {
      setfNameValidError(true);
    } else if (value === "") {
      setfNameError(true);
      setfNameValidError(false);
    } else {
      setfNameError(false);
      setfNameValidError(false);
    }
  };

  const mname = (str) => {
    const regex = /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/;
    setMiddleName(str);
    if (str && regex.test(str) === false) {
      setmNameError(true);
    } else {
      setmNameError(false);
    }
  };

  const handleLastName = (value) => {
    setLastName(value);
    const regex = /^[a-zA-Z]{2,}$/;
    if (regex.test(value) === false) {
      setlNameValidError(true);
    } else if (value === "") {
      setlNameError(true);
      setlNameValidError(false);
    } else {
      setlNameError(false);
      setlNameValidError(false);
    }
  };

  const handleLicenseNumber = (value) => {
    if (value === "") {
      setlicenseNumberError(true);
      setAllowNullLicence(true);
      setlicenseNumberValidError(false);
      setLicenseNumber("");
      return;
    }

    if (allowNullLicence === false) {
      setlicenseNumberError(false);
    }
    // setAllowNullLicence(false)
    //console.log(value);
    const regex = /^[0-9]+$/;
    if (regex.test(value) === false) {
      setlicenseNumberValidError(true);
      setlicenseNumberError(false);
      return;
    } else if (value === "") {
      setlicenseNumberError(true);
      setlicenseNumberValidError(false);
      return;
    } else if (value.length < 6) {
      setlicenseNumberValidError(true);
      setlicenseNumberError(false);
      return;
    } else {
      setlNameError(false);
      setlicenseNumberValidError(false);
      setLicenseNumber(value);
    }
  };

  const handleMobileChange = (value) => {
    setPhone(value);
    const regex = /^[0-9]*$/;
    if (regex.test(value) === false) {
      setPhoneValidError(true);
      setPhoneError(false);
    } else if (value.length < 10) {
      setPhoneValidError(true);
      setPhoneError(false);
    } else {
      setPhoneValidError(false);
      setPhoneError(false);
    }
  };

  // Check the length of the input
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  };

  const passwordFunction = () => {
    if (numberValid && uppercaseValid && charNumberValid && specialCharValid) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    if (password === "") {
      // setPasswordIsRequiredError(true);
      setPasswordError(false);
      setNumberValid(true);
      setUppercaseValid(true);
      setSpecialCharValid(true);
      setCharNumberValid(true);
    } else {
      checkPasswordLength(value);
      checkSpecialCharacters(value);
      checkUppercase(value);
      checkNumber(value);
      passwordFunction();
      setPasswordIsRequiredError(false);
    }
  };
  // Functions for toggle password
  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };
  const handleShowCPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowCPassword(true);
    } else {
      setIsShowCPassword(false);
    }
  };

  return (
    <>
      <Header_inner />
      <section className="signupPage">
        <div className="container">
          {/* <LogoIcon /> */}
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6 mx-auto">
              <div className="signupForm login-form">
                <form
                  ref={formRef}
                  validated={validated}
                  className="needs-validation"
                >
                  <div className="signUpBox">
                    <div className="row align-items-lg-center">
                      <div className="col-md-12 text-center">
                        {/* <Link to="/guard/login" className="backBtn">
                          <FaArrowLeft />
                        </Link> */}
                        <h3>Sign Up</h3>
                        {error ? (
                          <div className="alert alert-danger mt-2 mb-0">
                            {isError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputtext"
                          aria-describedby="textHelp"
                          maxLength={64}
                          placeholder="First Name"
                          onChange={(e) => handleFirstName(e.target.value)}
                          // onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                        <span>
                          <FaUserAlt />
                        </span>
                      </div>
                      {fNameValidError === true ? (
                        <div className="invalid-feedback">
                          Please enter valid first name
                        </div>
                      ) : (
                        ""
                      )}
                      {fNameError === true ? (
                        <div className="invalid-feedback">
                          First name is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className={
                            mNameError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          maxLength={64}
                          id="exampleInputtext"
                          aria-describedby="textHelp"
                          placeholder="Middle Name"
                          onChange={(e) => mname(e.target.value)}
                        />
                        <span>
                          <FaUserAlt />
                        </span>
                      </div>
                      {mNameError === true ? (
                        <div className="invalid-feedback">
                          Please enter valid middle name.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className={"form-control"}
                          id="exampleInputtext"
                          maxLength={64}
                          aria-describedby="textHelp"
                          placeholder="Last Name"
                          onChange={(e) => handleLastName(e.target.value)}
                          // onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                        <span>
                          <FaUserAlt />
                        </span>
                      </div>
                      {lNameValidError == true ? (
                        <div className="invalid-feedback">
                          Please enter valid last name
                        </div>
                      ) : (
                        ""
                      )}
                      {lNameError == true ? (
                        <div className="invalid-feedback">
                          Last name is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="form-group">
                        <DatePicker
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  decreaseMonth();
                                }}
                                disabled={prevMonthButtonDisabled}
                              >
                                {"<"}{" "}
                              </button>
                              <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  increaseMonth();
                                }}
                                disabled={nextMonthButtonDisabled}
                              >
                                {" "}
                                {">"}
                              </button>
                            </div>
                          )}
                          className={
                            dobError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholderText="dd/mm/yyyy"
                          selected={dob}
                          dateFormat="dd/MM/yyyy"
                          onChange={(e) => handleCheckDOB(e)}
                        />
                        {/* <DatePicker
                          className={
                            dobError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholderText="dd/mm/yyyy"
                          selected={dob}
                          dateFormat="dd/MM/yyyy"
                          onChange={(e) => handleCheckDOB(e)}
                          required
                        /> */}
                        {/* <input
                          type="text"
                          max="2099-12-12"
                          className={
                            dobError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="exampleInputtext"
                          aria-describedby="textHelp"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "date")}
                          placeholder="dd-mm-yyyy"
                          // value={dob}
                          onChange={(e) => handleCheckDOB(e.target.value)}
                          required
                        /> */}
                        <span>
                          <FaBirthdayCake />
                        </span>
                      </div>
                      {dobError && (
                        <div className="invalid-feedback">
                          Please enter valid date of birth
                        </div>
                      )}
                      {dobReqError && (
                        <div className="invalid-feedback">
                          Please select date of birth
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputnumber"
                          aria-describedby="numberHelp"
                          placeholder="Mobile Number"
                          maxLength={10}
                          // onChange={(e) => setPhone(e.target.value)}
                          onChange={(e) => handleMobileChange(e.target.value)}
                          required
                        />
                        <span>
                          <FaPhone />
                        </span>
                      </div>
                      {phoneValidError ? (
                        <div className="invalid-feedback">
                          Please enter valid mobile number
                        </div>
                      ) : (
                        ""
                      )}
                      {phoneError ? (
                        <div className="invalid-feedback">
                          Mobile number is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputemail"
                          aria-describedby="emailHelp"
                          placeholder="Email Address"
                          maxLength={180}
                          // onChange={(e) => setemail(e.target.value)}
                          onChange={(e) => handleEmailChange(e.target.value)}
                          required
                        />
                        <span>
                          <FaEnvelope />
                        </span>
                      </div>
                      {emailError && (
                        <div className="invalid-feedback">
                          Please enter valid email
                        </div>
                      )}
                      {emailIsRequiredError && (
                        <div className="invalid-feedback">
                          Email is required
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="form-group">
                        <input
                          type={!isShowPassword ? "password" : "text"}
                          className="form-control"
                          id="exampleInputpassword"
                          aria-describedby="passwordHelp"
                          placeholder="Password"
                          // onChange={(e) => setPassword(e.target.value)}
                          onChange={(event) =>
                            handlePasswordChange(event.target.value)
                          }
                          required
                        />
                        <span>
                          {!isShowPassword ? (
                            <FaEye
                              onClick={(e) => handleShowPassword(e, "show")}
                            />
                          ) : (
                            <FaEyeSlash
                              onClick={(e) => handleShowPassword(e, "hide")}
                            />
                          )}
                        </span>
                      </div>

                      {passwordError ||
                      !charNumberValid ||
                      !specialCharValid ||
                      !uppercaseValid ||
                      !numberValid ? (
                        <div className="invalid-feedback">
                          Password at least 8 characters in length.
                          <br />
                          Lowercase letters (a-z)
                          <br />
                          Uppercase letters (A-Z)
                          <br />
                          Numbers (0-9)
                          <br />
                          Special characters ($@$!%*?&) <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {passwordIsRequiredError ? (
                        <div className="invalid-feedback">
                          Password is required
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <div className="validation">
                        <div className="validator">
                          {charNumberValid ? <FaCheck className="success" /> : <FaTimes className="error" />}
                          <p className="validation-item">8-20 characters</p>
                        </div>
                        <div className="validator">
                          {specialCharValid ? <FaCheck className="success" /> : <FaTimes className="error" />}
                          <p className="validation-item">1 special character</p>
                        </div>
                        <div className="validator">
                          {uppercaseValid ? <FaCheck className="success" /> : <FaTimes className="error" />}
                          <p className="validation-item">1 uppercase letter</p>
                        </div>
                        <div className="validator">
                          {numberValid ? <FaCheck className="success" /> : <FaTimes className="error" />}
                          <p className="validation-item">1 number</p>
                        </div>
                        <div className="validator">
                          {match ? <FaCheck className="success" /> : <FaTimes className="error" />}
                          {!match ? <p className="validation-item">Confirm Password not match with new password</p> : <p className="validation-item">Confirm Password matched</p>}
                        </div>
                      </div> */}
                    </div>
                    <div className="mb-3">
                      <div className="form-group">
                        <input
                          type={!isShowCPassword ? "password" : "text"}
                          className="form-control"
                          id="exampleInputconfirm"
                          aria-describedby="confirmHelp"
                          placeholder="Confirm Password"
                          onChange={(e) => handleConfirmPass(e)}
                          required
                        />
                        <span>
                          {!isShowCPassword ? (
                            <FaEye
                              onClick={(e) => handleShowCPassword(e, "show")}
                            />
                          ) : (
                            <FaEyeSlash
                              onClick={(e) => handleShowCPassword(e, "hide")}
                            />
                          )}
                        </span>
                      </div>
                      {cPasswordError && (
                        <div className="invalid-feedback">
                          Password mismatch
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <h5>
                        Are you licensed to work as a Security Guard in Ontario?
                      </h5>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-6 col-md-3 mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1Checked"
                          value="option1"
                          onChange={(e) => handleLicense("yes")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1Checked"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option1"
                          onChange={(e) => handleLicense("no")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-6 col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="licenceno"
                            // value={licenseNumber}
                            aria-describedby="numberHelp"
                            placeholder="Security Guard License No."
                            maxLength={10}
                            // onChange={(e) => setLicenseNumber(e.target.value)}
                            onChange={(e) =>
                              handleLicenseNumber(e.target.value)
                            }
                            disabled={!isLicensed}
                          />
                        </div>
                        {licenseNumberError && !allowNullLicence ? (
                          <div className="invalid-feedback">
                            Security guard License Number is required
                          </div>
                        ) : (
                          ""
                        )}
                        {licenseNumberValidError ? (
                          <div className="invalid-feedback">
                            Enter valid security guard license number
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="nextBtn">
                        <a class="btn btn-black w-100" onClick={(e) => (isLoading ? "" : handleSignUp(e))}>
                          {isLoading ? "Please wait..." : "Next"}{" "}
                          <span>
                            {isLoading ? (
                              <Oval
                                color="white"
                                height={20}
                                width={20}
                                className="Loadingstyle"
                              />
                            ) : (
                              <FaArrowRight />
                            )}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="alredy_account">
                        <span>Already Have An Account?</span>
                        {/* <a href="#">LogIn</a> */}
                        <Link to="/guard/login">Login</Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
