import React, { useEffect, useState } from "react";
import LogoIcon from "../Logo/Logo";
import Google from "../../images/google.png";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import Cookies from "universal-cookie";
import {
  FaArrowRight,
  FaFacebookF,
  FaGoogle,
  FaEnvelope,
  FaLock,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
// import { login } from "../../actions/auth";
import { login, loginWithFacebook } from "../../utils/request";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Oval } from "react-loader-spinner";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  apiKey,
  appId,
  authDomain,
  googleclientIdKey,
  measurementId,
  messagingSenderId,
  projectId,
  storageBucket,
  vapidKey,
} from "../../utils/constants";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter valid email";
  }
  if (!values.password) {
    errors.password = "Please enter the Password";
  }
  return errors;
};

const Login = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  var socialId = "";
  const [email, setEmail] = useState("");
  // const [socialId, setSocialId] = useState("");
  const [emailIsRequiredError, setEmailIsRequiredlError] = useState(false);
  const [emailValidError, setEmailValidError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordIsRequiredError, setPasswordIsRequiredlError] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [basicToken, setbasicToken] = useState(
    "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
  );
  const [notToken, setNotToken] = useState("");

  const cookie = new Cookies();
  cookie.set("session", basicToken);

  var firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId,
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  // Function for get fcm toket
  const fetchToken = (setTokenFound) => {
    return getToken(messaging, {
      vapidKey: vapidKey,
    })
      .then((currentToken) => {
        if (currentToken) {
          //console.log("current token for client: ", currentToken);
          setNotToken(currentToken);
          // setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          // console.log(
          //   "No registration token available. Request permission to generate one."
          // );
          // setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        //console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  };

  useEffect(() => {
    // Check if Geolocation is supported by the browser
    // console.log('call========if')
    if (navigator.geolocation) {
      // Retrieve the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          //console.log("position======", position);
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          localStorage.setItem("latitude", position.coords.latitude);
          localStorage.setItem("longitude", position.coords.longitude);
        },
        (error) => {
          console.log("Error getting current location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  const navigate = useNavigate();
  useEffect(() => {
    fetchToken();
    onMessageListener();
    if (localStorage.getItem("user-info")) {
      navigate("/add");
    }
  }, []);
  // Function for manual login
  const loginSubmit = async (e) => {
    cookie.set("password", password);
    e.preventDefault();
    if (email !== "" && password !== "") {
      let requestData = {
        deviceToken: notToken,
        deviceId: "web",
        deviceType: 3,
        email: email,
        password: password,
      };
      setIsLoading(true);
      await login(requestData)
        .then((response) => {
          //console.log("login response---------------", response);
          if (response.data.code !== 200) {
            setIsLoading(false);
            setError(response.data.message);
            setIsError(true);
            setTimeout(() => {
              setError("");
              setIsError(false);
            }, 5000);
          } else {
            setIsError(false);
            const { guard, tokens } = response.data.data;
            const cookie = new Cookies();
            if (tokens && tokens.refresh && tokens.refresh.token) {
              localStorage.setItem("token", tokens?.refresh?.token);
              localStorage.setItem("session", tokens?.refresh?.token);
              cookie.set("token", tokens.refresh.token);
              cookie.set("session", tokens.refresh.token);
            }
            //console.log(guard);
            localStorage.setItem("guardId", guard.id);
            cookie.set("guardId", guard.id);
            localStorage.setItem("guardInfo", JSON.stringify(guard));
            cookie.set("guardInfo", guard);
            if (!guard.isLicense) {
              navigate(`/thankYou?isLicense=` + "full");
              return;
            }

            if (guard && guard.isPrivacyAgreement === false) {
              navigate(`/privacy/${guard.id}`);
            } else if (
              guard &&
              guard.isPrivacyAgreement &&
              guard.isTermsOfUse === false
            ) {
              navigate(`/termsUse/${guard.id}`);
            } else if (
              guard &&
              guard.isPrivacyAgreement &&
              guard.isIndependentContractor === false
            ) {
              navigate(`/guard-agreement/${guard.id}`);
            } else if (
              guard &&
              guard.isPrivacyAgreement &&
              guard.isTermsOfUse &&
              guard.isVerifyOtp === false
            ) {
              navigate(`/guard/otp/${guard.id}`);
            } else if (
              guard &&
              guard.identityDocuments &&
              guard.identityDocuments.length === 0
            ) {
              navigate(`/guard/registration/${guard.id}`);
            } else if (
              guard &&
              guard.identityDocuments &&
              guard.identityDocuments.length !== 0 &&
              guard.workSpecialities.length === 0
            ) {
              navigate(`/work-profile/${guard.id}?isShow=with_out_login`);
            } else if (
              guard &&
              guard.identityDocuments.length !== 0 &&
              guard.workSpecialities.length !== 0 &&
              guard.isLicense &&
              guard.status !== 0
            ) {
              // navigate(`/thankYou?isLicense=` + "full");
              navigate(`/guard/myprofile/${guard.id}`);
            } else {
              navigate(`/thankYou?isLicense=` + "full");
            }
            // if (
            //   guard &&
            //   guard.isVerifyOtp === true &&
            //   guard.isPrivacyAgreement === true &&
            //   guard.isTermsOfUse === true &&
            //   guard.identityDocuments.length !== 0 &&
            //   guard.status !== 0
            // ) {
            //   // navigate(`/thankYou?isLicense=` + "full");
            //   navigate(`/guard/myprofile/${guard.id}`);
            // } else {
            //   navigate(`/thankYou?isLicense=` + "full");
            // }

            // cookie.set("session", guard);
            // navigate(`/guard/myprofile/${guard.id}`);
          }
        })
        .catch((err) => {
          setIsError(true);
          if (err.response) {
            setError(err.response.data.userMessage);
          }
          setTimeout(() => {
            setError("");
            setIsError(false);
          }, 5000);
        });
    } else {
      // setIsError(true);
      // setError("Please Enter Valid Credentials");
      // setTimeout(() => {
      //   setError("");
      //   setIsError(false);
      // }, 5000);
      if (email === "" || !email) {
        setEmailIsRequiredlError(true);
      }
      if (password === "" || !password) {
        setPasswordIsRequiredlError(true);
      }
    }
  };
  // Function for facebook login
  const responseFacebook = async (response) => {
    //console.log(response);
    let requestData = {
      deviceToken: notToken,
      deviceId: "web",
      deviceType: 3,
      email: response.email,
      socialId: response.id,
      socialType: 1,
    };
    await loginWithFacebook(requestData)
      .then((apiResponse) => {
        if (apiResponse.data.code !== 200) {
          setError(apiResponse.data.message);
          setIsError(true);
          setTimeout(() => {
            setError("");
            setIsError(false);
          }, 5000);
        } else {
          setIsError(false);
          const { guard, tokens } = response.data.data;
          const cookie = new Cookies();
          if (tokens && tokens.refresh && tokens.refresh.token) {
            cookie.set("token", tokens.refresh.token);
            localStorage.setItem("token", tokens.refresh.token);
            cookie.set("session", tokens.refresh.token);
            localStorage.setItem("session", tokens.refresh.token);
          }
          cookie.set("guardId", guard.id);
          localStorage.setItem("guardId", guard.id);
          if (!guard.isLicense) {
            navigate(`/thankYou?isLicense=` + "full");
            return;
          }

          if (guard && guard.isPrivacyAgreement === false) {
            navigate(`/privacy/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse === false
          ) {
            navigate(`/termsUse/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isIndependentContractor === false
          ) {
            navigate(`/guard-agreement/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse &&
            guard.isVerifyOtp === false
          ) {
            navigate(`/guard/otp/${guard.id}`);
          } else if (
            guard &&
            guard.identityDocuments &&
            guard.identityDocuments.length === 0
          ) {
            navigate(`/guard/registration/${guard.id}`);
          } else {
            navigate(`/thankYou?isLicense=` + "full");
          }
          if (
            guard &&
            guard.isVerifyOtp &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse &&
            guard.identityDocuments.length !== 0 &&
            guard.isLicense &&
            guard.status !== 0
          ) {
            navigate(`/guard/registration/${guard.id}`);
          } else {
            navigate(`/thankYou?isLicense=` + "full");
          }
        }
      })
      .catch((err) => {
        setIsError(true);
        if (err.response) {
          setError(err.response.data.userMessage);
        }
        setTimeout(() => {
          setError("");
          setIsError(false);
        }, 5000);
      });
  };

  const componentClicked = (response) => {
    //console.log("componentClicked", response);
  };

  const socialLogin = async (requestData) => {
    // // e.preventDefault();
    // let requestData = {
    //   deviceToken: "web",
    //   deviceId: "web",
    //   deviceType: 3,
    //   email: email,
    //   socialId: socialId,
    //   socialType: 1,
    // };
    // //console.log(requestData);
    await loginWithFacebook(requestData)
      .then((response) => {
        //console.log(response);
        if (response.data.code === 200) {
          setIsError(false);

          const { guard, tokens } = response.data.data;
          const cookie = new Cookies();
          if (tokens && tokens.refresh && tokens.refresh.token) {
            cookie.set("token", tokens.refresh.token);
            localStorage.setItem("token", tokens.refresh.token);
            cookie.set("session", tokens.refresh.token);
            localStorage.setItem("session", tokens.refresh.token);
          }
          cookie.set("guardId", guard.id);
          localStorage.setItem("guardId", guard.id);
          cookie.set("guardInfo", guard);
          localStorage.setItem("guardInfo", JSON.stringify(guard));

          if (!guard.isLicense) {
            navigate(`/thankYou?isLicense=` + "full");
            return;
          }

          if (guard && guard.isPrivacyAgreement === false) {
            navigate(`/privacy/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse === false
          ) {
            navigate(`/termsUse/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isIndependentContractor === false
          ) {
            navigate(`/guard-agreement/${guard.id}`);
          } else if (
            guard &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse &&
            guard.isVerifyOtp === false
          ) {
            navigate(`/guard/otp/${guard.id}`);
          } else if (
            guard &&
            guard.identityDocuments &&
            guard.identityDocuments.length === 0
          ) {
            navigate(`/guard/registration/${guard.id}`);
          } else if (
            guard &&
            guard.identityDocuments &&
            guard.identityDocuments.length !== 0 &&
            guard.workSpecialities.length === 0
          ) {
            navigate(`work-profile/${guard.id}?isShow=with_out_login`);
          } else {
            navigate(`/thankYou?isLicense=` + "full");
          }
          if (
            guard &&
            guard.isVerifyOtp &&
            guard.isPrivacyAgreement &&
            guard.isTermsOfUse &&
            guard.identityDocuments.length !== 0 &&
            guard.workSpecialities.length !== 0 &&
            guard.isLicense === true &&
            guard.status !== 0
          ) {
            //console.log("i am called");
            navigate(`/guard/myprofile/${guard.id}`);
          } else {
            navigate(`/thankYou?isLicense=` + "full");
          }
        } else {
          setError(response.data.message);
          setIsError(true);
          setTimeout(() => {
            setError("");
            setIsError(false);
          }, 5000);
        }
      })
      .catch((err) => {
        setIsError(true);
        if (err.response) {
          setError(err.response.data.userMessage);
        }
        setTimeout(() => {
          setError("");
          setIsError(false);
        }, 5000);
      });
  };
  // Validation functions
  const handleEmailChange = (value) => {
    setEmail(value);
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    // const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value === "") {
      setEmailValidError(false);
      setEmailIsRequiredlError(true);
    } else if (regex.test(value) === false) {
      setEmailValidError(true);
      setEmailIsRequiredlError(false);
    } else {
      setEmailValidError(false);
      setEmailIsRequiredlError(false);
    }
  };

  const handlePassword = (value) => {
    setPassword(value);
    if (value === "") {
      setPasswordIsRequiredlError(true);
    } else {
      setPasswordIsRequiredlError(false);
    }
  };

  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };
  // Function for google login
  const responseGoogle = (response) => {
    //console.log(response);
    let requestData = {
      deviceToken: "web",
      deviceId: "web",
      deviceType: 3,
      email: response?.profileObj?.email,
      socialId: response?.profileObj?.googleId,
      socialType: 2,
    };
    //console.log(requestData);
    if (
      response?.profileObj?.email !== undefined &&
      response?.profileObj?.googleId !== undefined
    ) {
      socialLogin(requestData);
    }
    // if (response?.profileObj?.googleId && response?.profileObj.email) {
    //   // setSocialId(response.profileObj.googleId);
    //   socialId = response.profileObj.googleId;
    //   setEmail(response.profileObj.email);
    // }
  };

  return (
    <>
      {/* <form onSubmit={handleSubmit} > */}
      <Header_inner />
      <section className="loginPage">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-6 mx-auto">
              <div className="login-form training-form loginForm">
                <div className="row">
                  <form>
                      <div className="login_with_pass">
                        {iserror ? (
                          <div className="alert alert-danger">{error}</div>
                        ) : null}
                        <div className="mb-3 form-group">
                          <input
                            type="email"
                            className="form-control"
                            onChange={(e) => handleEmailChange(e.target.value)}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Email Address"
                            required
                          />
                          <span>
                            <FaEnvelope />
                          </span>
                        </div>
                        {emailIsRequiredError && (
                          <div className="invalid-feedback">
                            Email is required
                          </div>
                        )}
                        {emailValidError && (
                          <div className="invalid-feedback">
                            Please enter valid email
                          </div>
                        )}
                        <div className="mb-3 form-group">
                          <input
                            type={!isShowPassword ? "password" : "text"}
                            onChange={(e) => handlePassword(e.target.value)}
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="Password"
                            required
                          />
                          <span>
                            {!isShowPassword ? (
                              <FaEye
                                onClick={(e) => handleShowPassword(e, "show")}
                              />
                            ) : (
                              <FaEyeSlash
                                onClick={(e) => handleShowPassword(e, "hide")}
                              />
                            )}
                          </span>
                        </div>
                        {passwordIsRequiredError && (
                          <div className="invalid-feedback">
                            Password is required
                          </div>
                        )}
                        <div className="forgot-password">
                          <Link to="/guard/forgot-password">
                            Forgot Password?
                          </Link>
                        </div>
                        <div className="loginBtn">
                          <button
							class="btn btn-black w-100"
                            disabled={isLoading}
                            type="button"
                            onClick={(e) => loginSubmit(e)}
                          >
                            {isLoading ? "Loading..." : "Login"}{" "}
                            {isLoading ? (
                              ""
                            ) : (
                              <FaArrowRight className="arrowRight" />
                            )}
                          </button>
                          {/* <LoginForm onSubmit={props => this.doLogin(props)} {...this.props} loading={this.state.loading} passwordType={this.state.passwordType} toggleShowPassword={this.toggleShowPassword.bind(this)} /> */}
                        </div>
                      </div>
                    </form>
					<p class="text-center my-4">Or</p>
					<h3>Login with Social Media</h3>
					<ul class="list-unstyled d-flex flex-wrap my-5">
					<li><div className="fbBtn">
                        <FacebookLogin
                          className="facebookButton"
                          cssClass="button"
                          textButton="Login With Facebook"
                          appId="705391504029824" //APP ID NOT CREATED YET
                          fields="name,email,picture"
                          icon={
                            <span>
                              <FaFacebookF />
                            </span>
                          }
                          callback={responseFacebook}
                        />
                        {/* <button type="button">
                          {" "}
                          <span>
                            <FaFacebookF />
                          </span>
                          Login With Facebook
                        </button> */}
                      </div></li>
					<li><div className="googleBtn">
                        {/* <button type="button" onClick={(e) => socialLogin(e)}>
                          {" "}
                          <span>
                            <img src={Google} className="img-fluid" />
                          </span>{" "}
                          LogIn With Google
                        </button> */}
                        <GoogleLogin
                          className="googleButton"
                          clientId={googleclientIdKey} //CLIENTID NOT CREATED YET
                          buttonText="Login With Google"
                          cssClass="button"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                        />
                      </div></li>
					</ul>
					<label class="text-center w-100">Don't Have An Account?{" "}
                        <Link to="/guard/signup" className="signupBtn">
                          Sign Up
                        </Link></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </form> */}
    </>
  );
};

export default Login;
