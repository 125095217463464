// import { Row } from 'antd';
import React, { useEffect, useState } from "react";
import { FaCalendar, FaPaperclip } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./addDoc.scss";
import Cookies from "universal-cookie";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import moment from "moment";
import { uploadDocument } from "../../utils/request";
import { toast } from "react-toastify";

const AddDocu = () => {
  const navigate = useNavigate();
  const [documentNo, setDocumentNo] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [fileName, setFileName] = useState("");
  const [expireDate, setExpireDate] = useState(
    moment.utc().format("yyyy-MM-DD")
  );
  const [documentTypeError, setDocumentTypeError] = useState(false);
  const [documentNoError, setDocumentNoError] = useState(false);
  const [licenseNoError, setLicenseNoError] = useState(false);
  const [expireDateError, setExpireDateError] = useState(false);
  const [fileNameError, setFileNameError] = useState(false);
  const cookie = new Cookies();
  const token = cookie.get("token");

  // Effect hook to check if the user is authenticated with a valid token
  useEffect(() => {
    // If token is not present, show a warning toast and navigate to guard login page
    if (!token) {
      toast.warning("Your session has expired. Please log in again.", "yellow");
      navigate(`/guard/login`);
    }
  }, []);

  // Function to add a new document
  const addDocument = async () => {
    const cookie = new Cookies();
    const guardId = cookie.get("guardId"); // Get guard ID from cookie

    // Create FormData to send document data
    var bodyFormData = new FormData();
    bodyFormData.append("documentType", documentType);
    bodyFormData.append("documentNumber", documentNo);
    bodyFormData.append("file", fileName);
    bodyFormData.append("guardId", guardId);
    bodyFormData.append("expiryDate", expireDate);

    // Call the uploadDocument function with the FormData
    const response = await uploadDocument(bodyFormData);

    // Handle response based on its status code
    if (response?.data?.code === 401) {
      // Unauthorized response, show error toast and navigate to guard login page
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    // Handle other possible responses if needed
  };

  // Validation Functions
  const handleDocumentType = (value) => {
    //console.log("handleDocumentType", JSON.stringify(value));
    setDocumentType(value);
    if (value === "") {
      setDocumentTypeError(true);
    } else {
      setDocumentTypeError(false);
    }
  };

  const handleDocumentChange = (value) => {
    setDocumentNo(value);
    const regex = /^\d{5}$/;
    if (regex.test(value) === false) {
      setDocumentNoError(true);
    } else {
      setDocumentNoError(false);
    }
  };

  const handleLicenseChange = (value) => {
    setLicenseNo(value);
    const regex = /^\d{5}$/;
    if (regex.test(value) === false) {
      setLicenseNoError(true);
    } else {
      setLicenseNoError(false);
    }
  };

  const handleExpiryDate = (value) => {
    setExpireDate(value);
    var today = new Date();
    var expireDate = new Date(value);
    var day = expireDate.getDay() - today.getDay();
    var m = today.getMonth() - expireDate.getMonth();

    // //console.log('call age---', age);
    if (day > 0) {
      setExpireDateError(false);
    } else {
      setExpireDateError(true);
    }
  };

  const handleFileChange = (value) => {
    setFileName(value);
    if (value === "") {
      setFileNameError(true);
    } else {
      setFileNameError(false);
    }
  };

  const handleChange = (e) => {
    //console.log("e", e);
  };
  return (
    <>
      <Navbar />
      <section className="add_docu">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="content">
                <form>
                  <div className="title">
                    {/* <div className="backArrow"><Link to="/guard/documents"><FaArrowLeft/></Link></div> */}
                    <div className="heading">
                      <h1>Add Documents</h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <select
                          class="form-select"
                          onChange={(e) => handleDocumentType(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option selected>Document Type</option>
                          <option value="Drivers license">
                            Drivers license
                          </option>
                          <option value="Permanent resident card">
                            Permanent resident card
                          </option>
                          <option value="Other">Other</option>
                          <option value="Canadian passport">
                            Canadian passport
                          </option>
                        </select>
                      </div>
                      {documentTypeError ? (
                        <div className="invalid-feedback">
                          Please select document type
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className={
                            documentNoError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="number"
                          placeholder="Document No."
                          aria-label="default input example"
                          value={documentNo}
                          onChange={(e) => handleDocumentChange(e.target.value)}
                        />
                      </div>
                      {documentNoError ? (
                        <div className="invalid-feedback">
                          Please enter valid Document Number
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className={
                            licenseNoError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="number"
                          placeholder="License No."
                          aria-label="default input example"
                          value={licenseNo}
                          onChange={(e) => handleLicenseChange(e.target.value)}
                        />
                      </div>
                      {licenseNoError ? (
                        <div className="invalid-feedback">
                          Please enter valid License Number
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="date"
                          className={
                            expireDateError
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="exampleInputtext"
                          aria-describedby="textHelp"
                          placeholder="Expiry Date"
                          value={expireDate}
                          onChange={(e) => handleExpiryDate(e.target.value)}
                          required
                        />
                        {/* <input className="form-control" type="text" placeholder="Expiry Date" aria-label="default input example" /> */}
                        <FaCalendar className="attachIcon" />
                      </div>
                      {expireDateError ? (
                        <div className="invalid-feedback">
                          Please enter valid Expiry Date
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-control">
                          <span>Attach File</span>
                        </div>
                        <label htmlFor="uploadAttach" className="attachIcon">
                          <FaPaperclip />
                        </label>
                        <input
                          type="file"
                          id="uploadAttach"
                          className="d-none"
                          value={fileName}
                          onChange={(e) => handleFileChange(e.target.value)}
                        />
                      </div>
                      {fileNameError ? (
                        <div className="invalid-feedback">
                          Please select file
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="addMore">
                    <button
                      onClick={() => addDocument()}
                      type="submit"
                      className="submitBtn"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AddDocu;
