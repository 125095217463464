import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaPaperclip,
  FaPlusCircle,
  FaCaretDown,
} from "react-icons/fa";
import Docs from "../../images/docs.png";
import Navbar from "../../Pages/Header/Navbar";
import Pics3 from "../../images/imge placeholder.png";
import { Link } from "react-router-dom";
import "./report_issue.scss";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import {
  addTicket,
  getAllJobsDropDown,
  getTicketList,
} from "../../utils/request";
import Pagination from "react-js-pagination";
import moment from "moment";
import { API_URL } from "../../utils/constants";
import ImageUploading from "react-images-uploading";
import { flexbox } from "@mui/system";

const ReportIssue = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [jobList, setJobList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [jobId, setJobId] = useState("");
  const [jobIdError, setJobIdError] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [tickettype, setTicketType] = useState("");
  const [tickettypeError, setTicketTypeError] = useState(false);
  const [desc, setDesc] = useState("");
  const [descError, setDescError] = useState(false);
  const [img, setImg] = useState([]);
  const [imgError, setImgError] = useState(false);
  let [imagePreviewUrl, setImagePreviewUrl] = useState(Pics3);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItemsCount, settotalItemsCount] = useState(0);

  const [images, setImages] = useState([]);

  var pageNo = 1;
  var status = 0;

  useEffect(async () => {
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    } else {
      getJobLists();
      getAllTickets();
    }
  }, []);
  // Function for create ticket
  const createTicket = async () => {
    setJobIdError(true);
    setTitleError(true);
    setDescError(true);
    setImgError(true);
    if (ticketType === "") {
      setTicketTypeError(true);
      return;
    } else {
      setTicketTypeError(false);
    }

    if (jobId === "") {
      setJobIdError(true);
      return;
    } else {
      setJobIdError(false);
    }

    if (title === "") {
      setTitleError(true);
      return;
    } else {
      setTitleError(false);
    }
    if (desc === "") {
      setDescError(true);
      return;
    } else {
      setDescError(false);
    }
    if (img.length < 1) {
      setImgError(true);
      return;
    } else {
      setImgError(false);
    }

    var bodyFormData = new FormData();
    bodyFormData.append("jobId", jobId);
    bodyFormData.append("ticketTitle", title);
    bodyFormData.append("ticketType", tickettype);
    bodyFormData.append("description", desc);
    for (var i = 0; i < img.length; i++) {
      bodyFormData.append("images", img[i]);
    }
    setIsLoading(true);
    const res = await addTicket(bodyFormData);
    setIsLoading(false);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/guard/login`);
    }
    if (res.data.code == 200) {
      setJobId("");
      document.getElementById("tid").value = "";
      setTicketType("");
      document.getElementById("ttype").value = "0";
      setDescError("");
      document.getElementById("tdesc").value = "";
      setTitle("");
      document.getElementById("ttitle").value = "";
      setImg("");
      // document.getElementById("uploadAttach").value = null;
      setImagePreviewUrl(Pics3);
      images.splice(0, images.length);
      setImg([]);
      getAllTickets();
      toast.success(res?.data?.message);
    }
  };
  // Function for get all tickets list
  const getAllTickets = async () => {
    const data = {
      limit: 10,
      page: pageNo,
    };

    const res = await getTicketList(data);
    //console.log(res);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/guard/login`);
    }
    if (res?.data.code == 200) {
      setTicketList(res?.data?.data?.docs);
      settotalItemsCount(res?.data?.data?.total);
    }
  };
  // Function for handle page change
  const handlePageChange = (pageNumber) => {
    //console.log(pageNo);
    pageNo = pageNumber;
    getAllTickets();
  };
  // Function for get job list
  const getJobLists = async () => {
    const data = {
      jobStatus: status,
    };
    const response = await getAllJobsDropDown(data);
    //console.log("getJobLists", response);
    if (response.data.code === 200) {
      setJobList(response?.data?.data?.docs);
    }
  };
  // Function for filter job list according to ticket type
  const ticketType = (val) => {
    //console.log(val);
    if (val === "") {
      setTicketTypeError(true);
      return;
    } else {
      setTicketTypeError(false);
      setTicketType(val);
      // getJobLists();
      if (val === "0") {
        //console.log("val = 0");
        status = 0;
        getJobLists();
      }
      if (val === "1") {
        //console.log("val = 1");
        status = 2;
        getJobLists();
      }
      if (val === "2") {
        //console.log("val = 2");
        status = 1;
        getJobLists();
      }
    }
  };
  // Function for handle validation
  const selectedJob = (val) => {
    //console.log(val);
    if (val === "") {
      setJobIdError(true);
      return;
    } else {
      setJobIdError(false);
      setJobId(val);
    }
  };

  const selectedTitle = (val) => {
    //console.log(val);
    if (val === "") {
      setTitleError(true);
      return;
    } else {
      setTitleError(false);
      setTitle(val);
    }
  };

  const selectedDesc = (val) => {
    //console.log(val);
    if (val === "") {
      setDescError(true);
      return;
    } else {
      setDescError(false);
      setDesc(val);
    }
  };

  //
  const maxNumber = 69;
  // Function for handle image photo change
  const onChange = (imageList, addUpdateIndex) => {
    let imageCollection = [];
    // data for submit
    //console.log(imageList, addUpdateIndex);
    while (img.length > 0) {
      img.pop();
    }
    for (let i = 0; i < imageList.length; i++) {
      img?.push(imageList[i].file);
    }
    //console.log("number of images 233: ", img);
    setImages(imageList);

    if (img.length < 1) {
      setImgError(true);
      return;
    } else {
      setImgError(false);
    }

    // setImg(imageCollection);
  };

  //
  // Function for handle file change
  const handleFileChange = (e) => {
    e.preventDefault();
    //console.log(e.target.files.length);
    let reader = new FileReader();
    let file = e.target.files[0];
    setImg(file);
    reader.onloadend = () => {
      // //console.log(reader.result);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    if (file.length === 0) {
      setImgError(true);
    } else {
      setImgError(false);
    }
  };

  return (
    <>
      <Navbar />
      <section className="report_PreviousJob">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="heading">
                  <h1>Report Issue</h1>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="ttype"
                        onClick={(e) => {
                          ticketType(e.target.value);
                        }}
                      >
                        {/* <option selected>Select Ticket Type</option> */}
                        <option value="0" selected>
                          Current Job
                        </option>
                        <option value="1">Previous Job</option>
                        <option value="2">Upcoming Job</option>
                        <option value="3">Profile Update</option>
                        <option value="4">Payout/Change payout</option>
                        <option value="5">Others</option>
                      </select>
                      <span className="attachIcon">
                        <FaCaretDown />
                      </span>
                      {tickettypeError ? (
                        <div className="invalid-feedback">
                          Please select ticket type
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="tid"
                        onClick={(e) => {
                          selectedJob(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          Select Job
                        </option>
                        {jobList?.length !== 0 ? (
                          jobList?.map((r, i) => {
                            return (
                              <option value={r?.jobId?.id}>
                                {r?.jobId?.jobTitle}
                              </option>
                            );
                          })
                        ) : (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}
                        {/*                         
                        <option value="2">One</option>
                        <option value="3">Two</option>
                        <option value="4">Three</option> */}
                      </select>
                      <span className="attachIcon">
                        <FaCaretDown />
                      </span>
                      {jobIdError ? (
                        <div className="invalid-feedback">
                          Please select job
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="ttitle"
                        name="tickettitle"
                        placeholder="Ticket Title"
                        onKeyUp={(e) => {
                          selectedTitle(e.target.value);
                        }}
                      />
                      {titleError ? (
                        <div className="invalid-feedback">
                          Title is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <select className="form-control" defaultValue="">
                        <option selected>Select Issue</option>
                        <option value="1">Select All</option>
                        <option value="2">One</option>
                        <option value="3">Two</option>
                        <option value="4">Three</option>
                      </select>
                      <span className="attachIcon">
                        <FaCaretDown />
                      </span>
                    </div>
                  </div> */}
                  <div className="col-md-8">
                    <div className="form-group">
                      <textarea
                        className="form-control textArea"
                        rows="3"
                        placeholder="Description"
                        id="tdesc"
                        onKeyUp={(e) => {
                          selectedDesc(e.target.value);
                        }}
                      ></textarea>
                      {descError ? (
                        <div className="invalid-feedback">
                          Description is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group attachInput">
                      {/* <div className="form-control">
                        <span>Attach Photo</span>
                      </div> */}
                      <label
                        htmlFor="uploadAttach"
                        className="attach_input_icon"
                      >
                        <span>Attach Photo</span>
                        <FaPaperclip />
                      </label>
                      {/* <input
                        type="file"
                        id="uploadAttach"
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e)}
                      /> */}

                      <div>
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={["jpg", "png", "webp", "gif", "jpeg"]}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper">
                              <button
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                className="Drop_images"
                              >
                                Click or Drop here
                              </button>
                              &nbsp;
                              <button
                                onClick={onImageRemoveAll}
                                className="remove_images"
                              >
                                Remove all images
                              </button>
                              <div className="upload_img_box">
                                {imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <img
                                      src={image.data_url}
                                      alt=""
                                      width="100"
                                    />
                                    <div className="image-item__btn-wrapper">
                                      {/* <button className="edit_btn" onClick={() => onImageUpdate(index)}><i className="fas fa-pen"></i></button> */}
                                      <button
                                        className="trash_btn"
                                        onClick={() => onImageRemove(index)}
                                      >
                                        <i class="fa-solid fa-circle-xmark"></i>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>

                      <ul>
                        <li>
                          {/* <img src={imagePreviewUrl} className="img-fluid" />{" "}
                          <a href="">x</a> */}
                        </li>
                      </ul>
                      {imgError ? (
                        <div className="invalid-feedback">
                          Please select image
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="addMore">
                  <button
                    type="button"
                    disabled={isLoading}
                    className="submitBtn"
                    onClick={() => createTicket()}
                  >
                    {isLoading ? "Please wait..." : "Submit Details"}
                  </button>
                  {/* <Link to="/myprofile" className="submitBtn">
                    Submit Details
                  </Link> */}
                </div>
              </div>
              <div className="ticket_box">
                <h6>Previous Tickets</h6>
                <div className="table-responsive border-1">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Ticket Title</th>
                        <th>Job Title</th>
                        <th>Date</th>
                        <th>Attachment</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* {ticketList?.length === 0 && (
                        <tr>
                          <td style={{ textAlign: "center" }} colSpan={6}>
                            No data found
                          </td>
                        </tr>
                      )} */}
                      {ticketList.length !== 0 ? (
                        ticketList.map((res, i) => {
                          return (
                            <tr>
                              <td>{res?.ticketTitle}</td>
                              <td>{res?.jobId?.jobTitle}</td>
                              <td>
                                {moment
                                  .utc(res?.ticketDate)
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td>
                                <div className="attach_docs">
                                  {res?.images?.length !== 0
                                    ? res?.images?.map((im, id) => {
                                        return (
                                          <img
                                            src={
                                              API_URL +
                                              "/media/preview?filename=" +
                                              im
                                            }
                                            className="img-fluid"
                                          />
                                        );
                                      })
                                    : "No Image"}

                                  {/* <span>Docs_file.doc</span> */}
                                </div>
                              </td>
                              <td>
                                <a
                                  type="button"
                                  onClick={(e) => {
                                    navigate(`/ticketDetail/${res?.id}`);
                                  }}
                                >
                                  View More
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td style={{ textAlign: "center" }} colSpan={6}>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {ticketList.length !== 0 && (
                  <div className="mt-2">
                    <Pagination
                      color="primary"
                      activePage={pageNo}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItemsCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportIssue;
