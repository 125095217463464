import React, { useState, useEffect } from "react";
import {
  FaArrowLeft,
  FaClock,
  FaMapMarkerAlt,
  FaPaperclip,
  FaPlusCircle,
  FaStar,
  FaCalendar,
  FaFile,
  FaBriefcase,
} from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./job_rating.scss";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import { getRating, getRatingDetails } from "../../utils/request";
import moment from "moment";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import NoDataImg from "../../images/no_data_found.png";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Rating } from "react-simple-star-rating";

const JobRating = () => {
  const item = useLocation();
  const navigate = useNavigate();
  const cookie = new Cookies();
  const token = cookie.get("token");
  const [rateDetails, setRateDetails] = useState([]);
  const [details, setDetails] = useState({});
  const [defaultData, setDefaultData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [activeId, setActiveId] = useState();

  useEffect(async () => {
    if (token) {
      rating();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
    //   //console.log("rateDetails[0]",JSON.stringify(rateDetails[0]))
  }, []);

  const handleRating = () => {
    // setRatingValue(2.5);
  };
  // Function for get rating
  const rating = async () => {
    setIsLoading(true);
    const response = await getRating();

    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    //console.log(response);
    if (response?.data?.code === 200) {
      setTimeout(() => {
        handleRating();
      }, 2000);
      setIsLoading(false);
      setRateDetails(response?.data?.data?.docs);
      setDefaultData(response?.data?.data?.docs[0]);
      getRatingById(response?.data?.data?.docs[0]?.id);
      setActiveId(response?.data?.data?.docs[0]?.id);
    } else {
      setIsLoading(false);
      toast.error(response?.data?.message);
    }
  };
  // Function for get rating by id
  const getRatingById = async (id) => {
    const response = await getRatingDetails(id);
    //console.log("details", response);
    if (response?.data?.code === 200) {
      setDetails(response?.data?.data);
    } else {
      toast.error(response?.data?.message);
    }
  };
  // Handle Rating Details On Click
  const setValue = (item) => {
    setActiveId(item.id);
    //console.log("getRating response", item);
    getRatingById(item.id);
  };

  return (
    <>
      <div>
        <Modal
          open={isLoading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Navbar />
      {!isLoading && (
        <section className="job_rating">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12">
                {rateDetails?.length !== 0 ? (
                  <div className="row justify-content-center">
                    <div className="col-md-5 col-lg-4 col-xl-3">
                      <div className="left_sidebar">
                        <h5>My Ratings</h5>
                        <div class="nav_box">
                          <div
                            class="nav flex-column nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            {rateDetails?.map((item, index) => {
                              return (
                                <button
                                  class={
                                    "nav-link" +
                                    (activeId === item?.id ? " active" : "")
                                  }
                                  id="first-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#first"
                                  onClick={() => setValue(item)}
                                  type="button"
                                  role="tab"
                                  aria-controls="first"
                                  aria-selected="false"
                                >
                                  {item?.jobId?.jobTitle}
                                  <span>
                                    <FaMapMarkerAlt />
                                    {item?.jobId?.locationId?.locationName}
                                  </span>
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-7 col-xl-7">
                      <div className="content">
                        <div class="tab-content" id="v-pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="first"
                            role="tabpanel"
                            aria-labelledby="first-tab"
                          >
                            <div className="rating_box">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4>Rating Detail</h4>
                                </div>
                                <div className="col-md-6">
                                  <p className="date_para">
                                    <FaCalendar />{" "}
                                    {details.createdAt &&
                                      moment
                                        .utc(details?.createdAt)
                                        .format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className="title_box">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        disabled={true}
                                        className="form-control"
                                        type="text"
                                        placeholder="Job Title"
                                        aria-label="default input example"
                                        value={details?.jobId?.jobTitle}
                                      />
                                      <span>
                                        <FaBriefcase />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        disabled={true}
                                        className="form-control"
                                        value={
                                          details.createdAt &&
                                          moment
                                            .utc(details?.createdAt)
                                            .format("DD/MM/YYYY")
                                        }
                                        type="text"
                                        placeholder="Date"
                                        aria-label="default input example"
                                      />
                                      <span>
                                        <FaCalendar />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        disabled={true}
                                        className="form-control"
                                        value={
                                          details?.jobId?.locationId
                                            ?.locationName
                                        }
                                        type="text"
                                        placeholder="Job Location"
                                        aria-label="default input example"
                                      />
                                      <span>
                                        <FaMapMarkerAlt />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    {/* {details?.clientRating == 0 ? ( */}
                                    <div className="starRate_box">
                                      <h6>Job Rating</h6>
                                      <b style={{ margin: "0 8px" }}>:</b>
                                      <div className="starRate">
                                        <Rating
                                          // onClick={handleRating}
                                          // ratingValue={ratingValue}
                                          initialValue={details?.rating}
                                          allowHalfIcon
                                          readonly
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {details?.shiftId?.endReasonId && (
                                    <div className="col-md-12 mb-3">
                                      <div className="reason_box">
                                        <h6>
                                          {details?.shiftId?.endReasonId?.name}
                                        </h6>
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-md-12 mb-3">
                                    <div className="reason_box">
                                      <h6>Description</h6>

                                      <p>{details?.shiftId?.shiftEndComment}</p>
                                    </div>
                                  </div>
                                  {details?.rating < 3 && (
                                    <div className="col-md-12">
                                      <div className="reason_box">
                                        <h6>Reason For Minimum Job Rating</h6>
                                        <p>
                                          {details?.description === "" ||
                                          details?.description === undefined
                                            ? "No Data"
                                            : details?.description}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {/* {details?.rating < 3 && (
                                    <div className="col-md-12">
                                      <div className="reason_box">
                                        <h6>Reason For Minimum Job Rating</h6>
                                        <p>
                                          {details?.description === "" ||
                                          details?.description === undefined
                                            ? "No Reason Found"
                                            : details?.description}
                                        </p>
                                      </div>
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* <h6>No Data</h6> */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="nodata_img">
                          <img
                            src={NoDataImg}
                            className="img-fluid"
                            alt="No Data Found"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default JobRating;
