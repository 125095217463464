import React, { useEffect, useState } from "react";
import LogoIcon from "../Logo/Logo";
// import Google from "../../images/google.png";
import Cookies from "universal-cookie";
import { FaArrowRight, FaLock, FaTimes, FaCheck, FaEye } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./reset-password.scss";
import { resetPasswordAPI } from "../../utils/request";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [match, setMatch] = useState(false);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [issuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  const [basicToken, setbasicToken] = useState(
    "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
  );

  const cookie = new Cookies();
  cookie.set("session", basicToken);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      navigate("/add");
    }
  }, []);
  // Function for handle reset password
  const passwordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (charNumberValid && specialCharValid && uppercaseValid && numberValid) {
      let requestData = {
        password: newPassword,
      };
      await resetPasswordAPI(requestData, searchParams.get("token"))
        .then((response) => {
          if (response.data.code !== 200) {
            setError(response.data.message);
            setIsError(true);
            setTimeout(() => {
              setError("");
              setIsError(false);
            }, 5000);
          } else if (response.data.code === 401) {
            setError(response.data.message);
            setIsError(true);
            setTimeout(() => {
              setError("");
              setIsError(false);
            }, 2000);
          } else {
            setIsError(false);
            setIsSuccess(true);
            setSuccessMsg(response.data.message);
            setTimeout(() => {
              setIsSuccess(false);
              setSuccessMsg("");
              navigate(`/guard/login`);
            }, 2000);
          }
        })
        .catch((err) => {
          setIsError(true);
          if (err.response) {
            setError(err.response.data.userMessage);
          }
          setTimeout(() => {
            setError("");
            setIsError(false);
          }, 5000);
        });
    } else {
      setIsError(true);
      setError("Please enter password");
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  };

  // Check the length of the input
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  };
  // Function for handle validation
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
    checkPasswordLength(event.target.value);
    checkSpecialCharacters(event.target.value);
    checkUppercase(event.target.value);
    checkNumber(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    //console.log("new password value", JSON.stringify(newPassword));
    // //console.log(
    //   "confirm new password value",
    //   JSON.stringify(event.target.value)
    // );
    setConfirmPassword(event.target.value);
    if (newPassword !== event.target.value) {
      setMatch(false);
    } else {
      setMatch(true);
    }
    // comparePassword(event.target.value);
  };

  const comparePassword = (event) => {
    if (newPassword === confirmPassword) {
      setMatch(false);
    } else {
      setMatch(true);
    }
  };

  return (
    <>
      <section className="resetPasswordPage">
        <div className="container">
          <LogoIcon />
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="loginForm">
                <form>
                  <h4>Reset Password</h4>
                  {iserror ? (
                    <div className="alert alert-danger">{error}</div>
                  ) : null}
                  {issuccess ? (
                    <div className="alert alert-success">{successMsg}</div>
                  ) : null}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      // onChange={(e) => setNewPassword(e.target.value)}
                      onChange={(event) => handlePasswordChange(event)}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="New Password"
                      required
                    />
                    <span>
                      <FaEye />
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      // onChange={(e) => setConfirmPassword(e.target.value)}
                      onChange={(event) => handleConfirmPasswordChange(event)}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Confirm Password"
                      // onBlur={comparePassword}
                      required
                    />
                    <span>
                      <FaEye />
                    </span>
                  </div>
                  <div className="validation">
                    <div className="validator">
                      {charNumberValid ? (
                        <FaCheck className="success" />
                      ) : (
                        <FaTimes className="error" />
                      )}
                      <p className="validation-item">8-20 characters</p>
                    </div>
                    <div className="validator">
                      {specialCharValid ? (
                        <FaCheck className="success" />
                      ) : (
                        <FaTimes className="error" />
                      )}
                      <p className="validation-item">1 special character</p>
                    </div>
                    <div className="validator">
                      {uppercaseValid ? (
                        <FaCheck className="success" />
                      ) : (
                        <FaTimes className="error" />
                      )}
                      <p className="validation-item">1 uppercase letter</p>
                    </div>
                    <div className="validator">
                      {numberValid ? (
                        <FaCheck className="success" />
                      ) : (
                        <FaTimes className="error" />
                      )}
                      <p className="validation-item">1 number</p>
                    </div>
                    <div className="validator">
                      {match ? (
                        <FaCheck className="success" />
                      ) : (
                        <FaTimes className="error" />
                      )}
                      {!match ? (
                        <p className="validation-item">
                          Confirm Password not match with new password
                        </p>
                      ) : (
                        <p className="validation-item">
                          Password confirmation successful{" "}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="loginBtn">
                    <a
                      type="button"
                      onClick={(e) => (!match ? "" : passwordSubmit(e))}
                      disabled
                    >
                      Submit <FaArrowRight className="arrowRight" />
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </form> */}
    </>
  );
};

export default ResetPassword;
