import React, { useState, useEffect } from "react";
import "./how_work.scss";
// import { FaChevronRight } from 'react-icons/fa';
import WelcomeHeader from "../../Pages/Wel_Header/WelcomeHeader";
import WelcomeFooter from "../../Pages/Wel_Footer/WelcomeFooter";
import Line from "../../images/how_work/line-resize.png";
import Shape2 from "../../images/how_work/Shape2copy .png";
import BootomLine from "../../images/how_work/b_line.png";
import SignUpimg from "../../images/how_work/SignUp.png";
import Review_img from "../../images/how_work/ReviewProfile.png";
import Reg from "../../images/how_work/regis.png";
import Reg1 from "../../images/how_work/doc_pdf.png";
import Noti from "../../images/how_work/mob.png";
import Message from "../../images/how_work/message.png";
import Appl from "../../images/how_work/applic.png";
import Assign_job from "../../images/how_work/assign_job.png";
import App_track from "../../images/how_work/app_track.png";
import Getcont_img from "../../images/how_work/Getconnected.png";
import MobLine from "../../images/how_work/mob_merger_line.png";
import GetMatch_img from "../../images/how_work/GetMatched.png";
import SubscribeSection from "../../shared/subscribe";
import { getHowItWorksFAQ } from "../../utils/request";
import how_works_img from '../../images/how-it-work.png';
import Breadcrumb from '../../../node_modules/react-bootstrap/Breadcrumb';

const HowWork = () => {
  const [guardFaq, setGuardFaq] = useState([]);
  const [cusFaq, setCusFaq] = useState([]);

  useEffect(() => {
    getFAQ();
  }, []);

  // Function to get how it works data
  const getFAQ = async () => {
    const res = await getHowItWorksFAQ();
    //console.log(res);
    var fdata = [];
    if (res.data.code === 200) {
      fdata = res?.data?.data;
    }
    const gFaq = fdata.filter((r) => {
      return r.type == 1;
    });
    //console.log(gFaq);
    setGuardFaq(gFaq);
    //console.log(guardFaq);

    const cFaq = fdata.filter((r) => {
      return r.type == 2;
    });
    //console.log(cFaq);
    setCusFaq(cFaq);
  };

  return (
    <>
      <WelcomeHeader />
      <section className="banner-work">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="work">
                <h1>How It Works?</h1>
	  			<Breadcrumb className="breadcrumb d-flex justify-content-center mt-2 mb-0">
				<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>How It Works?</Breadcrumb.Item>
				</Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </section>
<article id="how-works">
      <section class="py-6">
<div class="container">
<div class="text-center mb-5">
<h2><strong>Our</strong> Working Process</h2>
<p>Hiring a security professional with GoGuard is a seamless process. With us, you don’t need to browse through hundreds of security guard profiles to select the perfect one for your needs; Here is our security on-demand basic steps:</p>
</div>
<div class="row">
<div class="col-lg-4 text-lg-end d-flex flex-column justify-content-between">
<div class="d-flex count-left">
<div class="how-graph flex-grow-1 me-lg-4 ms-4 ms-lg-0 order-2 order-lg-1">
<h3>06. Job Start / Ends</h3>
<p>Customers Use Technology to Track, Report, and Manage Their Engagement for Better Transparency.</p>
</div>
<div class="flex-shrink-0 order-1 order-lg-2">
<span class="gg-icon start-icon"></span>
</div>
</div>
<div class="d-flex count-left">
<div class="how-graph flex-grow-1 me-lg-4 ms-4 ms-lg-0 order-2 order-lg-1">
<h3>05. Job is Assigned</h3>
<p>Customer is Notified</p>
</div>
<div class="flex-shrink-0 order-1 order-lg-2">
<span class="gg-icon assign-icon"></span>
</div>
</div>
<div class="d-flex count-left">
<div class="how-graph flex-grow-1 me-lg-4 ms-4 ms-lg-0 order-2 order-lg-1">
<h3>04. Job Match</h3>
<p>A GoGuard Applies to a Job</p>
</div>
<div class="flex-shrink-0 order-1 order-lg-2">
<span class="gg-icon job-icon"></span>
</div>
</div>
</div>
<div class="col-lg-4 text-center d-none d-lg-block">
<img src={how_works_img} class="img-fluid" alt="How it works" />
</div>
<div class="col-lg-4 text-start d-flex flex-column justify-content-between">
<div class="d-flex count-right">
<div class="flex-shrink-0">
<span class="gg-icon register-icon"></span>
</div>
<div class="how-graph flex-grow-1 ms-4">
<h3>01. Register</h3>
<p>Register on GoGuard</p>
</div>
</div>	
<div class="d-flex count-right">
<div class="flex-shrink-0">
<span class="gg-icon post-icon"></span>
</div>
<div class="how-graph flex-grow-1 ms-4">
<h3>02. Post Job</h3>
<p>Customer Posts a Job on GoGuard</p>
</div>
</div>
<div class="d-flex count-right">
<div class="flex-shrink-0">
<span class="gg-icon notification-icon"></span>
</div>
<div class="how-graph flex-grow-1 ms-4">
<h3>03. Notification</h3>
<p>App Sends Notification to all Matching Guards</p>
</div>
</div>
</div>
</div>
</div>
</section>
<section class="py-6 inner-login-sec">
<div class="container">
<ul class="list-unstyled login-sec-content px-3 py-4 p-lg-5 d-flex justify-content-center flex-wrap">
<li class="d-flex">
<a href="/guard/signup">
<div class="flex-shrink-0">
<i class="gg-icon apply-icon"></i>
</div>
</a>
<a href="/guard/signup">
<div class="flex-grow-1 ms-3">
<h3>01. Apply</h3>
<p>Guard Applies to Work as a GoGuard</p>
</div>
</a>
</li>
<li class="d-flex">
<a href="/guard/signup" class="dinline-block">
<div class="flex-shrink-0">
<i class="gg-icon register-icon"></i>
</div>
</a>
<a href="/guard/signup" class="dinline-block">
<div class="flex-grow-1 ms-3">
<h3>02. Register</h3>
<p>Guard is Registered to Work after a Rigorous Vetting Process</p>
</div>
</a>
</li>
</ul>
</div>
</section>
</article>
      <WelcomeFooter />
    </>
  );
};

export default HowWork;
