// import { Row } from "antd";
import React, { useEffect, useState } from "react";
import Navbar from "../../Pages/Header/Navbar";
import {
  FaArrowLeft,
  // FaPaperclip,
  FaClock,
  // FaPaperPlane,
} from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import PdfIcon from "../../images/pdf.png";
import "./ticket_detail.scss";
import { getTicketDetail, addTicket } from "../../utils/request";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { API_URL } from "../../utils/constants";

const TicketDetail = () => {
  const { id } = useParams();

  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [ticketDetails, setTicketDetails] = useState("");

  useEffect(() => {
    if (token) {
      getTicketDetails(id);
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
    // addTickets();
  }, []);

  // Function for get ticket details by ticket id
  const getTicketDetails = async (id) => {
    const response = await getTicketDetail(id);
    //console.log("getTicketDetails response", response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      setTicketDetails(response?.data?.data);
    }
  };
  return (
    <>
      <Navbar />
      <section className="ticket_detail">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="content">
                <div className="contentBox">
                  <form>
                    <div className="title">
                      <div className="backArrow">
                        <Link to="/reportIssue">
                          <FaArrowLeft />
                        </Link>
                      </div>
                      <div className="heading">
                        <h1>Ticket Detail</h1>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            value={ticketDetails?.ticketTitle}
                            placeholder="Security Job Issue"
                            aria-label="default input example"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            value={ticketDetails?.jobId?.jobTitle}
                            placeholder="Mall Security Job"
                            aria-label="default input example"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control timeInput"
                            id="exampleInputtext"
                            value={ticketDetails?.ticketDate}
                            aria-describedby="textHelp"
                            placeholder="01:20 PM,12/11/2021"
                            readOnly
                          />
                          <span className="mapIcon">
                            <FaClock />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="descBox">
                        {/* <h6>Client's Behaviour Not Good</h6> */}
                        <h6 className="descPara">Description</h6>
                        <p>{ticketDetails?.description}</p>
                        {ticketDetails?.images?.map((im, i) => {
                          return (
                            <p>
                              <img
                                src={API_URL + "/media/preview?filename=" + im}
                                alt={im}
                                className="img-fluid"
                              />{" "}
                              <span>{im}</span>
                            </p>
                          );
                        })}
                      </div>
                    </div>
                    {ticketDetails?.response?.length !== 0
                      ? ticketDetails?.response?.map((r, index) => {
                          return (
                            <>
                              <div className="col-md-12">
                                <div class="descBox reponse">
                                  <h6 className="descPara">{r?.response}</h6>

                                  {/* <p>{r?.description}</p> */}
                                </div>
                              </div>
                              <br />
                            </>
                          );
                        })
                      : ""}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TicketDetail;
