import React, { useEffect, useState } from "react";
import {
  FaEdit,
  FaTrash,
  FaPlusCircle,
  FaPaperclip,
  FaFile,
  FaCalendar,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Docs from "../../images/docs.png";
import "./UpdateDocument.scss";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import Cookies from "universal-cookie";
import {
  updateDocument,
  uploadDocument,
  getDocumentList,
  deleteListItem,
  detailListItem,
} from "../../utils/request";
import moment from "moment";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const UpdateDocument = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [documentNo, setDocumentNo] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [expireDate, setExpireDate] = useState();
  const [documentNoError, setDocumentNoError] = useState(false);
  const [documentTypeError, setDocumentTypeError] = useState(false);
  const [licenseNoError, setLicenseNoError] = useState(false);
  const [expireDateError, setExpireDateError] = useState(false);
  const [fileNameError, setFileNameError] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [addDocument, setAddDocument] = useState(0);
  let [isLoading, setIsLoading] = useState(false);
  //console.log(id);

  var pageNo = 1;

  const cookie = new Cookies();
  const token = cookie.get("token");
  const guardId = cookie.get("guardId");

  React.useEffect(() => {
    //console.log(id);
    if (token) {
      getDocumentLists();
      detailListItems(id);
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);
  // Function for get documents list
  async function getDocumentLists() {
    const requestedData = {
      limit: 10,
      guardId: guardId,
      page: pageNo,
    };
    const response = await getDocumentList(requestedData);
    //console.log(response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response?.data?.code === 200) {
      setTotalItemsCount(response.data.data.total);
      setDocumentList(response.data.data.docs);
    }
  }
  // This function displays a confirmation dialog to delete a document
  const deleteList = async (did) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove this document?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteListItems(did); // If user confirms, call the deleteListItems function
      }
    });
  };

  // This function is currently empty and not used
  const getDocById = (docId) => {
    // You can add functionality here to retrieve document details by its ID
  };

  // This function deletes a document from the list and updates the UI accordingly
  const deleteListItems = async (did) => {
    const response = await deleteListItem(did); // Call an API to delete the document

    // Handle response status codes
    if (response?.data?.code === 401) {
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    } else if (response.data.code === 200) {
      toast.success(response.data.message, "green");
      const showList = documentList.filter((ele) => ele.id !== did);
      setDocumentList(showList); // Update the document list in the UI
    } else {
      toast.error(response.data.message, "red");
      setIsError(true);
      setError(response.data.message);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  // This function retrieves details of a document and updates UI fields
  const detailListItems = async (docid) => {
    const response = await detailListItem(docid);
    //console.log(response);
    if (response.data.code === 200) {
      setDocumentType(response?.data?.data?.documentType);
      document.getElementById("doctype").value =
        response?.data?.data?.documentType;
      setDocumentNo(response?.data?.data?.documentNumber);
      document.getElementById("docNo").value =
        response?.data?.data?.documentNumber;
      //console.log("dfgdfg", response?.data?.data?.expiryDate);
      if (
        response?.data?.data?.expiryDate !== undefined &&
        response?.data?.data?.expiryDate !== null
      ) {
        setExpireDate(
          moment.utc(response?.data?.data?.expiryDate).format("yyyy-MM-DD")
        );
        document.getElementById("expdate").value = moment
          .utc(response?.data?.data?.expiryDate)
          .format("yyyy-MM-DD");
      }
      // document.getElementById("expdate").value = moment.utc(
      //   response?.data?.data?.expiryDate
      // ).format("yyyy-MM-DD");
      setFileName(response?.data?.data?.file);
      // value[index][e.target.name] = e.target.files[0].name;
      // document.getElementById("fileName").name = response?.data?.data?.file;
      // document.getElementById(
      //   "fileName"
      // ).value = `${response?.data?.data?.file}`;
    } else {
      toast.error(response.data.message, "red");
      setIsError(true);
      setError(response.data.message);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };
  // Function for handle page change
  const handlePageChange = (pageNumber) => {
    pageNo = pageNumber;
    getDocumentLists();
  };

  // Functions for validation
  const handleDocumentType = (value) => {
    //console.log("handleDocumentType", JSON.stringify(value));
    if (value === "") {
      toast.warn("Please Select Document Type");
      setDocumentTypeError(true);
      return;
    } else {
      setDocumentTypeError(false);
      setDocumentType(value);
    }
  };

  const handleDocumentChange = (value) => {
    if (value === "") {
      setDocumentNoError(true);
      return;
    }
    const regex = /^\d{15}$/;
    if (regex.test(value) === false) {
      setDocumentNoError(true);
    } else {
      setDocumentNo(value);
      setDocumentNoError(false);
    }
  };

  const handleLicenseChange = (value) => {
    setLicenseNo(value);
    const regex = /^\d{7}$/;
    if (regex.test(value) === false) {
      setLicenseNoError(true);
    } else {
      setLicenseNoError(false);
    }
  };

  const handleExpiryDate = (value) => {
    setExpireDate(value);
  };

  // Function for handle file change
  const handleFileChange = (e) => {
    e.preventDefault();

    const allowedBytes = 2 * 1024 * 1024;
    if (e.target.files[0].size > allowedBytes) {
      alert("Size is more than 2 MB");
      // setIsFile(true)
      return;
    }
    // setIsFile(false)

    let reader = new FileReader();
    let fileData = e.target.files[0];
    if (
      fileData.type !== "image/jpeg" &&
      fileData.type !== "image/png" &&
      fileData.type !== "image/jpg" &&
      fileData.type !== "image/webp"
    ) {
      toast.warning("Please select valid image file");
    } else {
      setFile(fileData);
      setFileName(fileData.name);
      //console.log(fileData);
    }
  };
  // Function for handle update document
  const updateDocuments = async () => {
    const f = documentList.filter((res) => {
      return res.documentType === documentType && res.id !== id;
    });
    const df = documentList.filter((res) => {
      return res.documentNumber === documentNo && res.id !== id;
    });
    // //console.log(f);
    //console.log(df);
    if (f.length >= 1) {
      toast.warn("Same type of identification proof is already saved");
      return;
    }

    if (df.length >= 1) {
      toast.warn("Same document no is already saved");
      return;
    }

    const cookie = new Cookies();
    const guardId = cookie.get("guardId");
    var bodyFormData = new FormData();

    if (documentType === "") {
      toast.warning("Please Select Document Type");
      return;
    } else if (documentNo === "") {
      toast.warning("Document no is required");
    }
    // else if (expireDate === "") {
    //   toast.warning("Please select expiry date");
    // }
    else if (fileName === "") {
      toast.warning("Please select file");
    } else {
      bodyFormData.append("documentType", documentType);
      bodyFormData.append("documentNumber", documentNo);
      bodyFormData.append("file", file === "" ? fileName : file);
      bodyFormData.append("guardId", guardId);
      if (expireDate !== undefined) {
        bodyFormData.append("expiryDate", expireDate);
      }
      setIsLoading(true);
      const response = await updateDocument(id, bodyFormData);
      setIsLoading(false);
      if (response?.data?.code === 401) {
        // setIsloading(false);
        toast.error(response?.data?.message);
        navigate(`/guard/login`);
      }
      if (response?.data?.code === 200) {
        setAddDocument(addDocument + 1);
        document.getElementById("doctype").value = "";
        setDocumentType("");
        document.getElementById("docNo").value = "";
        setDocumentNo("");
        document.getElementById("expdate").value = "";
        setExpireDate("");
        document.getElementById("fileName").value = "";
        setFileName("");
        navigate(`/guard/documents`);
        toast.success(response?.data?.message);
      }
    }
  };

  return (
    <>
      <Navbar />
      <section className="update_document">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="myDocBox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="topBox">
                      <h4>My Documents</h4>
                      <div className="editDetail">
                        <a
                          className="btn addBtn"
                          type="button"
                          onClick={(e) => {
                            navigate(`/guard/documents`);
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            <i class="fa-solid fa-arrow-left"></i>
                          </span>
                          Back
                        </a>
                      </div>
                    </div>
                    {iserror ? (
                      <div className="alert alert-danger">{error}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  {/* <div className='col-md-3'>
                    <div className="form-group">
                      <input className="form-control" type="text" placeholder="Document Title" aria-label="default input example"
                      />
                      <span>
                        <FaFile />
                      </span>
                    </div>
                  </div> */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <select
                        class="form-select"
                        // value={documentType}
                        id="doctype"
                        onChange={(e) => handleDocumentType(e.target.value)}
                        aria-label="Default select example"
                      >
                        <option selected value="">
                          Document Type
                        </option>
                        <option value="Drivers license">Drivers license</option>
                        <option value="Permanent resident card">
                          Permanent resident card
                        </option>
                        <option value="Other">Other</option>
                        <option value="Canadian passport">
                          Canadian passport
                        </option>
                      </select>
                    </div>
                    {documentTypeError ? (
                      <div className="invalid-feedback">
                        Please select document type
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <input
                        // value={documentNo}
                        id="docNo"
                        onChange={(e) => handleDocumentChange(e.target.value)}
                        className={
                          documentNoError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        type="text"
                        maxLength={15}
                        placeholder="Document No."
                        aria-label="default input example"
                      />
                      <span>
                        <FaFile />
                      </span>
                    </div>
                    {documentNoError ? (
                      <div className="invalid-feedback">
                        Please enter valid Document Number
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <input
                        type="date"
                        className="form-control"
                        id="expdate"
                        aria-describedby="textHelp"
                        placeholder="Expiry Date"
                        // value={expireDate}
                        onChange={(e) => handleExpiryDate(e.target.value)}
                        required
                      />
                      <span>
                        <FaCalendar />
                      </span>
                    </div>
                    {/* {expireDateError ? (
                      <div className="invalid-feedback">
                        Please select expiry date
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="update_files">
                        <input
                          type="file"
                          className="form-control d-none"
                          htmlFor="exampleInputtext"
                          aria-describedby="textHelp"
                          name="fileName"
                          // value={identificationField.fileName}
                          id="fileName"
                          accept="image/*"
                          placeholder="Upload Identification"
                          onChange={(e) => handleFileChange(e)}
                        />
                        <small>{fileName ? fileName : "No file chosen"}</small>
                        {/* {identityFile.length === 0
                                ? item?.state?.item?.file
                                : ""} */}
                        <span>
                          <i className="fas fa-paperclip"></i>
                        </span>
                      </label>
                    </div>
                    {fileNameError && (
                      <div className="invalid-feedback">Please select file</div>
                    )}
                  </div>

                  <div className="col-md-7">
                    <div className="addDocs_btn">
                      <a
                        type="button"
                        onClick={() => (isLoading ? "" : updateDocuments())}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Update Document"}{" "}
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="uploded_docs">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Uploaded Documents</h5>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive border-1">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Document Type</th>
                            <th>Document No.</th>
                            <th>Expiry</th>
                            <th>Attachment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documentList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.documentType}</td>
                                <td>{item.documentNumber}</td>
                                <td>
                                  {item.expiryDate
                                    ? moment.utc(item.expiryDate).format(
                                        "yyyy-MM-DD"
                                      )
                                    : "--"}
                                </td>
                                <td>
                                  <div className="attach_docs">
                                    <img
                                      src={`https://go-guard.omnisttechhub.com/v1/media/preview?filename=${item?.file}`}
                                      className="img-fluid"
                                    />
                                    <span>{item.file}</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="action_btn">
                                    <a
                                      type="button"
                                      onClick={() => deleteList(item.id)}
                                      className="del_btn"
                                    >
                                      <FaTrash />
                                    </a>
                                    <a
                                      type="button"
                                      onClick={() => {
                                        navigate(`/guard/documents/${item.id}`);
                                        // detailListItems(item.id)
                                      }}
                                      className="edit_btn"
                                    >
                                      <FaEdit />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          {documentList.length == 0 ? (
                            <tr>
                              <td colSpan={5} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-2">
                      {documentList.length != 0 && (
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateDocument;
