import React, { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import { Link } from "react-router-dom";
import "./privacy.scss";
import { useParams, useNavigate } from "react-router-dom";
import { guardAgreement, getStaticContent } from "../../utils/request";
// import DOMPurify from "dompurify";
import parse from "html-react-parser";

const Privacy = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");

  useEffect(() => {
    getPrivacyPolicy();
    // setToken(sessionStorage.getItem("token"))
  }, [id]);
  // Function for get privacy policy
  const getPrivacyPolicy = async () => {
    const response = await getStaticContent({ type: "privacy" });
    if (response.data.code === 200) {
      setDescription(response.data.data.description);
    }
  };
  // Function for get guard agreement
  const handleAgreement = async (event) => {
    event.preventDefault();
    const requestData = {
      id: id,
      isPrivacyAgreement: true,
    };
    const updatedResponse = await guardAgreement(requestData);
    if (updatedResponse.data.code === 200) {
      navigate(`/termsUse/${id}`);
    }
  };

  return (
    <>
      <Header_inner />
      <section className="privacy_policy">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="privacyTitle">
                  <div className="row align-items-lg-center">
                    <div className="col-md-12">
                      <h4>Privacy Policy</h4>
                    </div>
                  </div>
                </div>
                <div className="notifyBox">
                  <p
                    className="cls-maxheight-scroll"
                    dangerouslySetInnerHTML={{ __html: parse(description) }}
                  ></p>
                  <div className="time">
                    <Link
                      to={`/termsUse/${id}`}
                      onClick={(e) => handleAgreement(e)}
                    >
                      Agree & Continue <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
