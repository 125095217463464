import React, { useEffect } from 'react';
import mapIcon from '../../images/map.png';
import mapImg from '../../images/mapImg.png';
import { FaStar, FaCalendar, FaStarHalf, FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './job_detail2.scss';
import Navbar from '../../Pages/Header/Navbar';
import Footer from '../../Pages/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const JobDetail2 = () => {
    const navigate = useNavigate();
    const cookie = new Cookies();
    const token = cookie.get("token");

    useEffect(() => {
        if (!token) {
            toast.warning(
              "your session has been expired ...kindly login again.",
              "yellow"
            );
            navigate(`/guard/login`);
          } 
        }, []);

    return (
        <>
            <Navbar />
            <section className='job_detail2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='arrow_icon'>
                                <Link to="/guard/documents"><FaArrowLeft /> </Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-md-7'>
                            <div className='mainBox'>
                                <div className='detailBox'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="title">
                                                <div className="heading"><h1>Job Detail</h1></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <h5>Mall Security Job</h5>
                                        <p className='adderess'>123,street Name, City Name</p>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='lBox'>
                                            <p className='para'>Tart fruitcake brownie chupa chups candy jujubes biscuit. Oat cake biscuit donut jelly-o toffee croissant croissant. Bear claw tootsie roll pudding chocolate bar shortbread donut cookie. Halvah chocolate cake bonbon caramels cake.</p>
                                            <div className='row align-items-center'>
                                                <div className='col-md-6'>
                                                    <p className='calendar_para'><FaCalendar /> 12/09/2021</p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='starDate'>
                                                        <p className='star'><span>Job Rating</span>
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStarHalf />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='rBox'>
                                                <div className='imgBox'>
                                                    <img src={mapImg} className='img-fluid' alt="map Image" />
                                                </div>
                                            </div>
                                            <div className='desc'>
                                                <div className='flexBox'>
                                                    <p><span>Start Time</span> : 09:30 AM</p>
                                                    <p><span>End Time</span> : 06:30 PM</p>
                                                    <p><span>Job Type</span> : One-Time</p>
                                                    <p><span>Transport</span> : Own Vehicle</p>
                                                </div>
                                                <div className='flexBox spaceRight'>
                                                    <p><span>Hourly Rate</span> : $8/hr</p>
                                                    <p><span>Speciality</span> : Mall Security</p>
                                                    <p><span>Tot.Hours</span> : 8 Hrs</p>
                                                    <p><span>Dress Code</span> : Formal</p>
                                                </div>
                                            </div>
                                            <div className='earningBox'>
                                                <div className='totEarn'>
                                                    <h6>Total Earning</h6>
                                                    <p>$400</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='shiftBox'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='imgBtn'>
                                            <a href="#" className='enote_btn'>E Notes</a>
                                            <a href="#">Support</a>
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='borderBox'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='dateBox'>
                                                        <h6>Date</h6>
                                                        <p>10/Nov/2021</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='startBox'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <h6>Start Time</h6>
                                                        <p>10:30 AM</p>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <h6>End Time</h6>
                                                        <p>07:30 AM</p>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <h6>Rate</h6>
                                                        <p>$10/hr</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='borderBox'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='dateBox'>
                                                        <h6>Date</h6>
                                                        <p>10/Nov/2021</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='startBox'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <h6>Start Time</h6>
                                                        <p>10:30 AM</p>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <h6>End Time</h6>
                                                        <p>07:30 AM</p>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <h6>Rate</h6>
                                                        <p>$10/hr</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='borderBox'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='dateBox'>
                                                        <h6>Date</h6>
                                                        <p>10/Nov/2021</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='startBox'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <h6>Start Time</h6>
                                                        <p>10:30 AM</p>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <h6>End Time</h6>
                                                        <p>07:30 AM</p>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <h6>Rate</h6>
                                                        <p>$10/hr</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JobDetail2;
