import React, { useState, useEffect } from "react";
import Docs from "../../images/docs.png";
// import { FaArrowLeft, FaClock, FaMapMarkerAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./previous_injury.scss";
import Navbar from "../../Pages/Header/Navbar";
import { getPreviousInjury, getInjuryDetail } from "../../utils/request";
import moment from "moment";
import Pagination from "react-js-pagination";
import { API_URL } from "../../utils/constants";
import { toast } from "react-toastify";

const PreviousInjury = () => {
  const navigate = useNavigate();
  var pageNo = 1;
  const [previousInjury, setPreviousInjury] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  var injuryLength = 0;

  useEffect(() => {
    getInjury();
  }, []);
  // Function for get previous injury
  const getInjury = async () => {
    const response = await getPreviousInjury();
    //console.log(response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      setPreviousInjury(response.data.data.docs);
      injuryLength = response.data.data.total;
      settotalItemsCount(response.data.data.total);
    }
  };
  // Function for handle page change
  const handlePageChange = (pageNumber) => {
    //console.log(pageNo);
    pageNo = pageNumber;
    getInjury();
  };
  // Function for go to the injury details page
  const getReportInjuryDetail = async (item) => {
    navigate(`/injuryDetail/` + item?.id);
  };

  return (
    <>
      <Navbar />
      <section className="previous_injury">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="ticket_box">
                <h6>Previous Injuries</h6>

                {/* {injuryLength > 0 ?  */}
                <div className="table-responsive border-1">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Injury Title</th>
                        <th>Injury Description</th>
                        <th>Date</th>
                        <th>Attachment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {previousInjury?.map((item, index) => {
                        return (
                          <tr>
                            <td>{item?.title}</td>
                            <td> {item?.description}</td>
                            <td>
                              {moment.utc(item?.date).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <div className="attach_docs">
                                {item?.files?.map((item, index) => {
                                  return (
                                    <img
                                      src={
                                        API_URL +
                                        "/media/preview?filename=" +
                                        item
                                      }
                                      className="img-fluid"
                                      key={index}
                                    ></img>
                                  );
                                })}
                                {/* <img src={Docs} className="img-fluid" />
                                        <img src={Docs} className="img-fluid" />
                                        <img src={Docs} className="img-fluid" /> */}
                              </div>
                            </td>
                            <td>
                              <a
                                type="button"
                                onClick={() => getReportInjuryDetail(item)}
                              >
                                View More
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                      {previousInjury.length === 0 && (
                        <tr>
                          <td style={{ textAlign: "center" }} colSpan={6}>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* : <p>Previous Injuries not found</p> } */}
              </div>
              {previousInjury.length !== 0 && (
                <div className="mt-2">
                  <Pagination
                    color="primary"
                    activePage={pageNo}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PreviousInjury;
