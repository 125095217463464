import React, { useState, useEffect } from "react";
import Navbar from "../../Pages/Header/Navbar";
import mapIcon from "../../images/map.png";
import mapImg from "../../images/mapImg.png";
import {
  FaStar,
  FaCalendar,
  FaStarHalf,
  FaArrowLeft,
  FaTimesCircle,
} from "react-icons/fa";
import "./job_page.scss";
import {
  applyJobByGuard,
  getParticularGuardJobDetail,
  endJobReasonsList,
} from "../../utils/request";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Map from "../Map/Map";

const JobPage = () => {
  const item = useLocation();
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [jobDetails, setJobDetails] = useState({});
  const [jobReasons, setJobReasons] = useState([]);
  const [applied, setApplied] = useState(false);
  const [allowApply, setAllowApply] = useState(false);
  const [registered, setRegistredAs] = useState({});
  const [indvidual, setIndividual] = useState({});

  //console.log(jobDetails, "jobDetails");
  // Effect to fetch the user's current geolocation
  useEffect(() => {
    // Check if Geolocation is supported by the browser
    if (navigator.geolocation) {
      // Retrieve the current position using Geolocation API
      navigator.geolocation.getCurrentPosition(
        // Success callback function with the received position
        (position) => {
          // Extract latitude and longitude from the position object
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Update the state with the retrieved latitude and longitude
          setLatitude(latitude);
          setLongitude(longitude);
        },
        // Error callback function
        (error) => {
          console.log("Error getting current location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []); // Empty dependency array ensures that this effect runs only once on component mount

  useEffect(async () => {
    //console.log(item?.state);
    if (token) {
      cookie.set("jobId", item?.state?.item?.jobId);
      cookie.set("userId", item?.state?.item?.userId);
      cookie.set("shiftId", item?.state?.item?.shiftId);
      await getParticularGuardJobDetails();
      await endJobReasonsLists();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
    // //console.log("jobDetails value in useEffect ", JSON.stringify(jobDetails))
  }, []);

  // Function for get job details
  const getParticularGuardJobDetails = async () => {
    //console.log("");
    const requestedData = {
      jobId: item?.state?.item?.jobId,
      userId: item?.state?.item?.userId,
      shiftId: item?.state?.item?.shiftId,
      lat: latitude ? latitude : localStorage.getItem("latitude"),
      long: longitude ? longitude : localStorage.getItem("longitude"),
    };
    const response = await getParticularGuardJobDetail(requestedData);
    //console.log("getParticularGuardJobDetails response", response);
    if (response.data.code === 200) {
      setJobDetails(response.data.data);
      if (response?.data?.data?.isAppliedStatus) {
        setApplied(true);
      }
      if (response?.data?.data?.applyGuardIds.length >= 5) {
        setAllowApply(true);
      } else {
        setAllowApply(false);
      }
      if (
        response?.data?.data?.user?.registerAs === "Incorporation" ||
        response?.data?.data?.user?.registerAs === "Business"
      ) {
        setRegistredAs(response?.data?.data?.user);
        //console.log("setting=============");
      } else {
        setIndividual(response?.data?.data?.user);
      }
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  };
  // Get Job End reason list
  const endJobReasonsLists = async () => {
    const response = await endJobReasonsList();
    // //console.log("endJobReasonsLists response", JSON.stringify(response))
    if (response.data.code === 200) {
      setJobReasons(response.data.data);
    }
  };
  // Function for apply job
  const aplyJobByGuard = async (e, stDate) => {
    e.preventDefault();
    let a = moment().toDate().getTime();
    let b = moment(stDate).toDate().getTime();

    if (a > b) {
      toast.warning(
        "You can not apply for this job due to job time passed away"
      );
      return;
    }
    // //console.log("apply for guard",JSON.stringify(jobDetails),JSON.stringify(jobDetails.userId),JSON.stringify(jobDetails.jobId),JSON.stringify(jobDetails.shiftId))
    const requestedData = {
      userId: item.state.item.jobId,
      jobId: jobDetails.jobId,
      shiftId: jobDetails.shiftId,
      isApply: 1,
    };
    const response = await applyJobByGuard(requestedData);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response?.data?.code === 200) {
      getParticularGuardJobDetails();
      toast.success(response?.data?.message);
      // alert("Successfully applied for this job!");
    } else if (response?.data?.code === 404) {
      setApplied(true);
      toast.warning(response?.data?.message);
    }
    // // }
    else {
      //   toast.warning("You already applied for this job!");
      toast.warning(response?.data?.message);
    }
  };

  return (
    <>
      <Navbar />
      <section className="job_page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="arrow_icon">
                <a type="button" onClick={(e) => navigate("/search-job")}>
                  <FaArrowLeft />
                </a>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="mainBox">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <h4>Job Page</h4>
                  </div>
                  <div className="col-md-6">
                    <div className="date_title">
                      <p>
                        <FaCalendar />{" "}
                        {moment
                          .utc(item?.state?.item?.jobDate)
                          .format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="lBox">
                      <h5>{item?.state?.item?.jobTitle}</h5>
                      <p className="adderess">
                        <img
                          src={mapIcon}
                          className="img-fluid"
                          alt="mapIcon"
                        />
                        {item?.state?.item?.locationId?.locationName}{" "}
                      </p>
                      <p style={{ color: "#4a4a4a" }}>
                        <h5>Description:</h5> {item?.state?.item?.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <div className="rBox">
                      <Map
                        lat={item?.state?.item?.locationId?.lat}
                        long={item?.state?.item?.locationId?.long}
                        name={item?.state?.item?.locationId?.locationName}
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    {item?.state?.item?.clientRating == 0 ? (
                      ""
                    ) : (
                      <div className="starRate">
                        <p className="star">
                          <span>Job Rating</span>
                          {new Array(item?.state?.item?.clientRating)
                            .fill(null)
                            .map(() => (
                              <FaStar />
                            ))}
                        </p>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="desc">
                          <div className="flexBox">
                            {Object.values(registered).length > 0 && (
                              <strong>Business Name:</strong>
                            )}{" "}
                            {Object.values(registered).length > 0 &&
                              registered.businessDetails[0].businessName}
                            {Object.values(indvidual).length > 0 && (
                              <strong>Customer Name:</strong>
                            )}{" "}
                            {Object.values(indvidual).length > 0 &&
                              indvidual.firstName + " " + indvidual.lastName}
                            <p>
                              <span>Start Time</span> :{" "}
                              {moment
                                .utc(item?.state?.item?.startTime)
                                .format("h:mm, A")}
                            </p>
                            <p>
                              <span>End Time</span> :{" "}
                              {moment
                                .utc(item?.state?.item?.endTime)
                                .format("h:mm, A")}
                            </p>
                            <p>
                              <span>Job Type</span> :{" "}
                              {item?.state?.item?.jobType == 1
                                ? "One Day Job"
                                : item?.state?.item?.jobType == 2
                                ? "Long Term Job"
                                : item?.state?.item?.jobType == 3
                                ? "customDates"
                                : ""}
                            </p>
                            <p>
                              <span>Transport</span> :{" "}
                              {jobDetails?.transportation
                                ? "Public Vehicle"
                                : "Doesn't Matter"}
                            </p>
                            <p>
                              <span>Status</span> :{" "}
                              {jobDetails?.jobStatus == 0
                                ? "Awaiting Approval"
                                : jobDetails?.jobStatus == 1
                                ? "Approved"
                                : jobDetails?.jobStatus == 2
                                ? "De-Activate(Reject)"
                                : jobDetails?.jobStatus == 3
                                ? "Cancel"
                                : jobDetails?.jobStatus == 4
                                ? "Ongoing"
                                : jobDetails?.jobStatus == 5
                                ? "Closed"
                                : jobDetails?.jobStatus == 6
                                ? "Upcoming"
                                : jobDetails?.jobStatus == 7
                                ? "Previous"
                                : "N/A"}
                            </p>
                          </div>
                          <div className="flexBox spaceRight">
                            <p>
                              <span>Hourly Rate</span> : $
                              {item?.state?.item?.hourlyRate}
                            </p>
                            <p>
                              <span>Speciality</span> :{" "}
                              {item?.state?.item?.speciality?.name}
                            </p>
                            <p>
                              <span>Tot.Hours</span> :{" "}
                              {item?.state?.item?.shiftHrs}
                            </p>
                            <p>
                              <span>Dress Code</span> :{" "}
                              {item?.state?.item?.dressCode === 1
                                ? "HV Vest"
                                : item?.state?.item?.dressCode === 2
                                ? "Causal"
                                : "Suited"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="imgBtn">
                      <a
                        type="button"
                        onClick={(e) => {
                          applied
                            ? toast.warning("Already Applied")
                            : allowApply
                            ? toast.warning("5 Guards Already Applied")
                            : aplyJobByGuard(e, item?.state?.item?.startTime);
                        }}
                        className="detailBtn"
                        data-bs-target="#applyJob"
                      >
                        {" "}
                        {applied ? "Already Applied" : "Apply Now"}
                      </a>
                    </div>
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade applyJob"
        id="applyJob"
        tabIndex="-1"
        aria-labelledby="applyJobLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="applyJobLabel">
                You successfully applied for the job
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <FaTimesCircle />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <h6></h6>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn submitBtn"
                data-bs-dismiss="modal"
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade cancelDetails"
        id="cancelDetails"
        tabIndex="-1"
        aria-labelledby="cancelDetailsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="cancelDetailsLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <FaTimesCircle />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Cancellation Reason</option>
                      {jobReasons.map((item, index) => {
                        return <option value={index}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control textArea"
                      rows="4"
                      id="validationTextarea"
                      placeholder="Add Comment"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn submitBtn"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobPage;
