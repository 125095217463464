import React from "react";
import "./welcome_header.scss";
import Logo from "../../images/welcomePage/Logo_2.png";
import { FaBars, FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const WelcomeHeader = () => {
  return (
    <>
      <section className="main_header">
	  {/*<section className="topHeader">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <a target="_blank" href="https://www.facebook.com/Guardondemand">
                    <FaFacebook />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/goguard.inc/" className="twitterLink">
                    <FaInstagram />
                  </a>
                  <a target="_blank" href="https://www.linkedin.com/in/goguardinc-2305/">
                    <FaLinkedin />
                  </a>
                  <div className="loginBtn">
                    <Link to="/select-navigation/login">LogIn</Link>
                  </div>
                  <div className="loginBtn">
                    <Link to="/select-navigation/signup">Sign up</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
        <header className="welcomeHeader">
          <nav
            className="navbar navbar-expand-lg navbar-light" id="navbar_fixed">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img src={Logo} className="img-fluid" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
                <FaBars />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  {/*<li className="nav-item">
                    <a className="nav-link" href="/">
                       aria-current="page" href="/" 
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/how-it-work">
                      How It Works?
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/training">
                      Training
                    </Link>
                  </li>
                  <li className="nav-item">
                                      <Link className="nav-link" to="/">Blog</Link>
                                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact_us">
                      Contact Us
                    </Link>
                  </li>*/}
	  			<li class="nav-item dropdown">
				  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
				  <ul class="dropdown-menu">
					<li><Link class="dropdown-item" to="/our-speciality/bank">Banks</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/car-lots">Car Lots</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/concierge">Concierge</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/loss-prevention">Loss Prevention</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/protests">Protests</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/access-control">Access Control</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/events">Events</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/residential">Residential</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/construction">Construction</Link></li>
					<li><Link class="dropdown-item" to="/our-speciality/vip">VIP</Link></li>
				  </ul>
				</li>
	  			<li className="nav-item">
                    <Link className="nav-link" to="/guard/signup">Register as a Professional</Link>
                  </li>
	  			 <li className="nav-item">
                    <a className="nav-link" href="https://customer.go-guard.com/signup">Log In / Sign Up</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </section>
    </>
  );
};

export default WelcomeHeader;
