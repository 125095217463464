import React, { useEffect, useState } from "react";
import {
  FaArrowRight,
  FaEnvelope,
  FaPhone,
  FaBirthdayCake,
  FaPen,
  FaArrowLeft,
} from "react-icons/fa";
import LogoIcon from "../Logo/Logo";
import UserImg from "../../images/user.png";
import PdfImg from "../../images/pdfImg.png";
import { Link } from "react-router-dom";
import "./Review.scss";
import { useParams, useNavigate } from "react-router-dom";
import { getGuardProfile } from "../../utils/request";
import moment from "moment";
import { API_URL } from "../../utils/constants";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const Review = () => {
  const { id } = useParams();
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [guard, setGuard] = useState({});
  const [employd, setEmployD] = useState([]);
  const [identity, setIdentity] = useState([]);

  useEffect(async () => {
    if (token) {
      await getGuardProfilef();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);
  // Function for get guard profile
  const getGuardProfilef = async () => {
    const response = await getGuardProfile(id);
    if (response.data.code === 200) {
      setGuard(response.data.data.guard);
      setEmployD(response.data.data.guard.employmentDetails);
      setIdentity(response.data.data.guard.identityDocuments);
    }
  };
  return (
    <>
      <section className="reviewPage">
        <div className="container">
          <LogoIcon />
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="reviewBox">
                <div className="heading">
                  <h1>Registration Review</h1>
                </div>
                <div className="row">
                  <div className="profileBox col-md-6">
                    <div className="flexBox">
                      <div className="left">
                        <h2>{guard.firstName + " " + guard.lastName}</h2>
                        <p>
                          {" "}
                          <span>
                            <FaEnvelope />
                          </span>{" "}
                          {guard.email}
                        </p>
                        <p>
                          {" "}
                          <span>
                            <FaPhone />
                          </span>{" "}
                          {guard.mobileNumber}
                        </p>
                        <p>
                          {" "}
                          <span>
                            <FaBirthdayCake />
                          </span>{" "}
                          {moment.utc(guard.dob).format("Do-MMM-YYYY")}
                        </p>
                      </div>
                      <div className="right">
                        <div className="imgBox">
                          <img src={UserImg} />
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      {/* <button className="editProfile">
                      <span>
                        <FaPen />
                      </span>{" "}
                      Edit Profile
                    </button> */}
                    </div>
                  </div>

                  <div className="profileBox col-md-6">
                    <div className="flexBox">
                      <div className="left">
                        <p className="bold"> Security License No. </p>
                        <p> {guard.licenseNumber}</p>
                        {/* <button className="editProfile">
                        <span>
                          <FaPen />
                        </span>{" "}
                        Edit Details
                      </button> */}
                      </div>
                      <div className="right">
                        <div className="imgBox">
                          <img src={PdfImg} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h5>Identity Proof</h5>
                <div className="row">
                  {identity &&
                    identity.map((item, i) => {
                      return (
                        <div className="listBox col-md-6" key={i}>
                          <div className="flexBox">
                            <div className="left">
                              <p className="bold"> {item.idType} </p>
                              <p> {item.idNumber}</p>
                              {/* <button className="editProfile">
                            <span>
                              <FaPen />
                            </span>{" "}
                            Edit Details
                          </button> */}
                            </div>
                            <div className="right">
                              <div className="imgBox">
                                <img
                                  src={
                                    API_URL +
                                    "/media/preview?filename=" +
                                    item.idProof
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <h5 className="mt">Employment History</h5>
                <div className="row">
                  {employd.length > 0 &&
                    employd?.map((item, i) => {
                      return (
                        <div className="history col-6">
                          <ul className="flexBox">
                            <li>
                              <h5>Project</h5>
                              <p>{item?.projectName}</p>
                            </li>
                            <li>
                              <h5>Employer </h5>
                              <p>{item?.employerName}</p>
                            </li>
                            <li>
                              <h5>Speciality</h5>
                              <p>Building Security</p>
                            </li>
                            <li>
                              <h5>Start Date </h5>
                              <p>
                                {moment.utc(item?.to).format("Do-MMM-YYYY")}
                              </p>
                            </li>
                            <li>
                              <h5>End Date </h5>
                              <p>
                                {moment.utc(item?.from).format("Do-MMM-YYYY")}
                              </p>
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                </div>
                <div className="text-center">
                  <Link
                    to={`/work-profile/${id}?isShow=with_login`}
                    className="submit"
                  >
                    {" "}
                    Submit{" "}
                    <span>
                      <FaArrowRight />
                    </span>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-5">
              <div className="backBtn">
                <Link to="">
                  <span>
                    <FaArrowLeft />
                  </span>{" "}
                  Previous Step
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Review;
