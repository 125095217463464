import React, { useState } from "react";
import '../css/style.css';
import security_guard from '../images/security-guard.png';

// import $ from "jquery";
const GuardsignupSection = () => {
    return (
        <>
		<section class="jobs-near-sec py-6">
		<div class="container">
		<div class="jobs-near-sec-in">
		<div class="row align-items-end">
		<div class="col-md-4 text-center d-none d-md-block">
		<img src={security_guard} class="img-fluid" width="307px" height="280px" />
		</div>
		<div class="col-md-8 p-4">
		<h2><strong>Security Guard Jobs Near You.</strong></h2>
		<p class="sec-sub-heading mb-3">Join GoGuard with a simple signup for a fulfilling as well as rewarding career. </p>
		<a href="/guard/signup" class="btn btn-black">Sign Up</a>
		</div>
		</div>
		</div>
		</div>
		</section>
    </>
  );
};
export default GuardsignupSection;
