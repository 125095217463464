import Cookies from "universal-cookie";
import axios from "axios";
import { API_URL } from "./constants";
var basicToken = "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1";
const cookie = new Cookies();
// const session = cookie.get("token");
// var request = {};

// const createRequest = (method) => {
//     return (url, options)=> {
//         const cookie = new Cookies();
//         const session = cookie.get("session");
//         session && (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);
//         return axios[method](`${API_URL}${url}`, options);
//     }
// }

// var methods = ["get", "post", "put", "delete"];

// methods.every(method=>{
//     request[method]= createRequest(method);
//     return true;
// })

// export default request;

export async function login(requestData) {
  axios.defaults.headers.common["Authorization"] = basicToken;

  var response = await axios.post(`${API_URL}/guard/auth/login`, requestData);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function loginWithFacebook(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  session.split(" ")[0] === "Basic"
    ? (axios.defaults.headers.common["Authorization"] = `${session}`)
    : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);
  var response = await axios.post(
    `${API_URL}/guard/auth/social-login`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function forgotPasswordAPI(requestData) {
  axios.defaults.headers.common["Authorization"] = basicToken;

  var response = await axios.post(
    `${API_URL}/guard/auth/forgot-password`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function resetPasswordAPI(requestData, token) {
  const cookie = new Cookies();
  const session = cookie.get("session");

  session.split(" ")[0] === "Basic"
    ? (axios.defaults.headers.common["Authorization"] = `${session}`)
    : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

  var response = await axios.post(
    `${API_URL}/guard/auth/reset-password?token=` + token,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function changePassword(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");

  session.split(" ")[0] === "Basic"
    ? (axios.defaults.headers.common["Authorization"] = `${session}`)
    : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

  var response = await axios.post(
    `${API_URL}/guard/change-password`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function signUp(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("session");

    session.split(" ")[0] === "Basic"
      ? (axios.defaults.headers.common["Authorization"] = `${session}`)
      : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

    var response = await axios.post(
      `${API_URL}/guard/auth/register`,
      requestData
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function guardAgreement(requestData) {
  try {
    axios.defaults.headers.common["Authorization"] = basicToken;

    var response = await axios.post(
      `${API_URL}/guard/auth/guardAgreement/${requestData.id}`,
      requestData
    );
    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getGuardProfile(requestData) {
  try {
    // const cookie = new Cookies();
    // const session = localStorage.getItem("token");
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(`${API_URL}/guard/profile/${requestData}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getAllJobs(requestData) {
  try {
    // const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.post(`${API_URL}/job/guard/list`, requestData);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getAllJobsDropDown(data) {
  try {
    // const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/job/guard/job-history?page=${1}&sortBy=asc&limit=${20}&isComplete=${
        data.jobStatus
      }`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function logout() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("session");
    session.split(" ")[0] === "Basic"
      ? (axios.defaults.headers.common["Authorization"] = `${session}`)
      : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);
    var response = await axios.post(`${API_URL}/guard/auth/logout`, {
      refreshToken: session,
    });
    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function updateWorkProfile(requestData) {
  try {
    // const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.post(
      `${API_URL}/guard/profile/${requestData.id}`,
      requestData
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function resendOtp(requestData) {
  try {
    axios.defaults.headers.common["Authorization"] = basicToken;

    var response = await axios.get(`${API_URL}/guard/otp/${requestData.id}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function verifyOtp(requestData) {
  try {
    axios.defaults.headers.common["Authorization"] = basicToken;

    var response = await axios.patch(
      `${API_URL}/guard/otp/${requestData.id}`,
      requestData
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function uploadDocument(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.post(`${API_URL}/guard-document`, requestData);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function updateDocument(id, requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    // const guardId = cookie.get("guardId");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.patch(
      `${API_URL}/guard-document/${id}`,
      requestData
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getDocumentList(requestedData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/guard-document?limit=${requestedData.limit}&guardId=${requestedData.guardId}&page=${requestedData.page}&searchBy=`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function deleteListItem(id) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.delete(`${API_URL}/guard-document/${id}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function detailListItem(id) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(`${API_URL}/guard-document/${id}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function detaiAlllListItem(idArry) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.post(
      `${API_URL}/guard-document/delete/multiple`,
      idArry
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getSpecialityList() {
  try {
    // const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(`${API_URL}/speciality/guard/list`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function updateGuard(id, requestData) {
  try {
    // const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.patch(
      `${API_URL}/guard/auth/update-guard/${id}`,
      requestData
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getStaticContent(requestData) {
  try {
    axios.defaults.headers.common["Authorization"] = basicToken;

    var response = await axios.get(
      `${API_URL}/static/pages/${requestData.type}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getFAQContent() {
  try {
    axios.defaults.headers.common["Authorization"] = basicToken;

    var response = await axios.get(`${API_URL}/faq/home/list`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function createENote(formData) {
  try {
    // const cookie = new Cookies();
    const session = cookie.get("session");

    session.split(" ")[0] === "Basic"
      ? (axios.defaults.headers.common["Authorization"] = `${session}`)
      : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

    var response = await axios.post(`${API_URL}/e-note`, formData);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getENoteAccToJob(formData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("session");

    session.split(" ")[0] === "Basic"
      ? (axios.defaults.headers.common["Authorization"] = `${session}`)
      : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

    var response = await axios.get(
      `${API_URL}/e-note?jobId=${formData.jobId}&searchBy=&limit=10&page=${formData.pageNumber}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function API_GetAllJobsList(formData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("session");

    session.split(" ")[0] === "Basic"
      ? (axios.defaults.headers.common["Authorization"] = `${session}`)
      : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

    var response = await axios.get(
      `${API_URL}/job/guard/myjobs?searchBy=&limit=10&page=${formData.pageNumber}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getENotebyId(id) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("session");

    session.split(" ")[0] === "Basic"
      ? (axios.defaults.headers.common["Authorization"] = `${session}`)
      : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

    var response = await axios.get(`${API_URL}/e-note/${id}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getRating() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("session");

    session.split(" ")[0] === "Basic"
      ? (axios.defaults.headers.common["Authorization"] = `${session}`)
      : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

    var response = await axios.get(`${API_URL}/guards/rating`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getRatingDetails(id) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("session");

    session.split(" ")[0] === "Basic"
      ? (axios.defaults.headers.common["Authorization"] = `${session}`)
      : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

    var response = await axios.get(`${API_URL}/guards/rating/${id}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function newJob() {
  // const cookie = new Cookies();
  const session = cookie.get("session");

  session.split(" ")[0] === "Basic"
    ? (axios.defaults.headers.common["Authorization"] = `${session}`)
    : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

  var response = await axios.post(`${API_URL}/job`);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function newJobList(requestedData) {
  // const cookie = new Cookies();
  const session = cookie.get("session");

  session.split(" ")[0] === "Basic"
    ? (axios.defaults.headers.common["Authorization"] = `${session}`)
    : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);

  var response = await axios.post(`${API_URL}/job/guard/list`, requestedData);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function updateProfile(requestData) {
  try {
    const cookie = new Cookies();
    // const session = cookie.get("session");
    const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = basicToken;
    // session.split(" ")[0] === "Basic"
    //   ? (axios.defaults.headers.common["Authorization"] = `${session}`)
    //   : (axios.defaults.headers.common["Authorization"] = `Bearer ${session}`);
    var response = await axios.patch(
      `${API_URL}/guard/auth/updateProfile/${guardId}`,
      requestData
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getPreviousInjury() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/report-injury?limit=10&page=1&searchBy`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getInjuryReason() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(`${API_URL}/injury-type/guard/list`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getJobList() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/job/guard/myjobs?searchBy=&limit=10&page=1&isComplete=`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getReportInjury() {
  try {
    // const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/report-injury/61e940ba716b5a76ead864cc`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getInjuryDetail(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    // const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(`${API_URL}/report-injury/${requestData}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function applyJobByGuard(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.post(
    `${API_URL}/apply-job/request-for-job`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function allApplyJobByAdmin() {
  const cookie = new Cookies();
  const session = cookie.get("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.get(`${API_URL}/apply-job/findJobByAdmin`);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function applyJobByAdmin(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  const guardId = cookie.get("guardId");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.get(
    `${API_URL}/apply-job/${guardId}`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function deleteApplyJobByAdmin() {
  const cookie = new Cookies();
  const session = cookie.get("session");
  const guardId = cookie.get("guardId");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.delete(`${API_URL}/apply-job/${guardId}`);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function getParticularGuardJob() {
  const cookie = new Cookies();
  const session = cookie.get("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.get(`${API_URL}/apply-job/findJobByGuard`);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function getParticularGuardJobDescription(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.post(`${API_URL}/job/jobDescription`, requestData);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function getParticularGuardJobDetail(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.post(`${API_URL}/job/jobDetail`, requestData);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function breakReasonsList() {
  const cookie = new Cookies();
  const session = cookie.get("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.get(`${API_URL}/break-reason/guard/list`);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function takeBreak(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.post(`${API_URL}/take-break`, requestData);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function endBreak(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  const guardId = cookie.get("guardId");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.patch(
    `${API_URL}/take-break/${guardId}`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function endTakeBreak(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  const guardId = cookie.get("guardId");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.patch(
    `${API_URL}/take-break/${guardId}`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function getMyJobs(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/job/guard/myjobs?searchBy=&limit=${requestData.limit}&page=${requestData.page}&isComplete=${requestData.isComplete}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function updateApplyJobByAdmin(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  // const guardId = cookie.get("guardId");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.patch(
    `${API_URL}/apply-job/625519b7f27f1d4820ac651e`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function updateMyAccount(requestData) {
  const cookie = new Cookies();
  const session = cookie.get("session");
  const guardId = cookie.get("guardId");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.post(
    `${API_URL}/guard/profile/${guardId}`,
    requestData
  );
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function applyLeave(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.post(`${API_URL}/apply-leave`, requestData);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getLeaveList(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/apply-leave?searchBy=&limit=${requestData.limit}&page=${requestData.page}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function deleteAppliedLeave() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.delete(`${API_URL}/apply-leave/${guardId}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function createReportInjury(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.post(`${API_URL}/report-injury`, requestData);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function updateNotificationType(requestedData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.post(
      `${API_URL}/guard/profile/${guardId}`,
      requestedData
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getNotificationList(requestedData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/notification/guard/list?searchBy=&limit=${requestedData.limit}&page=${requestedData.page}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getNotificationDetail() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(`${API_URL}/notification/guards/${guardId}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function deleteNotification() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.delete(
      `${API_URL}/notification/guards/${guardId}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function readNotification() {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    const guardId = cookie.get("guardId");
    //console.log("guardId", guardId);
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.patch(
      `${API_URL}/notification/guards/${guardId}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function endJobReasonsList() {
  const cookie = new Cookies();
  const session = cookie.get("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  var response = await axios.get(`${API_URL}/end-job-reason/guard/list`);
  if (response) {
    return response;
  } else {
    return [];
  }
}

export async function getTicketList(req) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/guards/ticket?searchBy=&limit=${req.limit}&page=${req.page}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getTicketDetail(id) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    // const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(`${API_URL}/guards/ticket/${id}`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function addTicket(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    // const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.post(`${API_URL}/guards/ticket`, requestData);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function myEarnings(requestData) {
  try {
    const cookie = new Cookies();
    const session = cookie.get("token");
    const guardId = cookie.get("guardId");

    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    var response = await axios.get(
      `${API_URL}/guard/myearning/${guardId}?searchBy=${requestData.searchBy}&limit=${requestData.limit}&page=${requestData.page}&from=${requestData.from}&to=${requestData.to}`
    );

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}

export async function getHowItWorksFAQ() {
  try {
    axios.defaults.headers.common["Authorization"] = basicToken;

    var response = await axios.get(`${API_URL}/how-it-work-faq/home/list`);

    if (response) {
      return response;
    } else {
      return {};
    }
  } catch (error) {
    return { error: error.message };
  }
}
