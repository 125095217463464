import React, { useEffect, useState } from "react";
import mapIcon from "../../images/map.png";
import mapImg from "../../images/mapImg.png";
import { FaAngleDown, FaStar, FaStarHalf } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./job_history.scss";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import { getMyJobs } from "../../utils/request";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const JobHistory = () => {
  const navigate = useNavigate();
  const cookie = new Cookies();
  const token = cookie.get("token");
  const [isComplete, setIsComplete] = useState(1);
  const [myJobList, setMyJobList] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [pageRange, setPageRange] = useState(0);
  const [status, setStatus] = useState("");
  const [pageNo, setPageNo] = useState(1);
  // var pageNo = 1;

  useEffect(() => {
    if (token) {
      getMyJob(1, "");
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Function for get job history
  const getMyJob = async (pageNo, status) => {
    const requestedData = {
      searchBy: "",
      limit: 10,
      page: pageNo,
      isComplete: status,
    };
    const response = await getMyJobs(requestedData);
    // console.log("getMyJob response", response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response?.data?.code === 200) {
      // console.log(response?.data?.data?.docs);
      setMyJobList(response?.data?.data?.docs);
      setTotalItemsCount(response?.data?.data?.total);
      setPageRange(response?.data?.data?.pages);
    }
  };
  // Funtion for filter job history
  const handleJobType = (value) => {
    // if (value === "") {
    setStatus(value);
    getMyJob(1, value);
  };
  // Funtion for page change handler
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    // pageNo = pageNumber;
    getMyJob(pageNumber, status);
  };

  return (
    <>
      <Navbar />
      <section className="job_history">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="content">
                <div className="title">
                  <div className="row align-items-center">
                    <div className="col-6 col-md-9">
                      <h1>Job History</h1>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <select
                          onChange={(e) => handleJobType(e.target.value)}
                          className="form-control"
                        >
                          <option selected value="">
                            All Jobs
                          </option>
                          {/* <option value="0">Awaiting Approval</option> */}
                          <option value="1">Approved</option>
                          {/* <option value="2">De-Activate(Reject)</option> */}
                          {/* <option value="3">Canceled</option> */}
                          <option value="4">Ongoing</option>
                          <option value="5">Closed</option>
                          <option value="6">Upcoming</option>
                          <option value="7">Previous</option>
                        </select>
                        <span>
                          <FaAngleDown />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="historyBox">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive border-1">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Job Title</th>
                              <th>Location</th>
                              <th>Tot.Earning</th>
                              <th>Rate</th>
                              <th>Speciality</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {myJobList?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item?.jobTitle}</td>
                                  <td>{item?.locationId?.locationName}</td>
                                  <td>
                                    {item?.totalEarning[0]?.credit
                                      ? "$" + item?.totalEarning[0]?.credit
                                      : "$0"}
                                  </td>
                                  <td>${item?.hourlyRate}/hr</td>
                                  <td>{item?.speciality?.name}</td>
                                  <td>
                                    {item?.status == 0
                                      ? "Awaiting Approval"
                                      : item?.status == 1
                                      ? "Approved"
                                      : item?.status == 2
                                      ? "De-Activate(Reject)"
                                      : item?.status == 3
                                      ? "Cancel"
                                      : item?.status == 4
                                      ? "Ongoing"
                                      : item?.status == 5
                                      ? "Closed"
                                      : item?.status == 6
                                      ? "Upcoming"
                                      : item?.status == 7
                                      ? "Previous"
                                      : "N/A"}
                                  </td>
                                </tr>
                              );
                            })}
                            {myJobList?.length === 0 && (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan={6}>
                                  No data found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="mt-2">
                        {myJobList?.length > 0 && (
                          <Pagination
                            color="primary"
                            activePage={pageNo}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={pageRange}
                            onChange={(e) => handlePageChange(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobHistory;
