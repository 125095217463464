import React, { useEffect, useState } from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
// import { Link } from "react-router-dom";
// import LogoIcon from "../Logo/Logo";
import "./otp.scss";
import { resendOtp, verifyOtp } from "../../utils/request";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Oval } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { toast } from "react-toastify";

const OTP = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isIndividualDocument, setIsIndividualDocument] = useState(false);
  const [isSubmitAllDocument, setIsSubmitAllDocument] = useState(false);
  const [isPromotional, setIsPromotional] = useState(false);
  // const [isIndependentContractor, setIsIndependentContractor] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [otp, setOtp] = useState("");
  const [allCheck, setAllCheck] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  // Function for resend OTP
  const handleOtp = async () => {
    const res = await resendOtp({ id: id });
    if (res?.data?.code === 200) {
      setIsResend(true);
      setIsVerified(false);
      setTimeout(() => {
        setIsResend(false);
      }, 5000);
    }
  };
  // Function for verify OTP
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (
      isSubmitAllDocument &&
      isIndividualDocument &&
      // isIndependentContractor &&
      otp !== ""
    ) {
      const reqData = {
        otp: otp,
        isIndividualDocument: isIndividualDocument,
        isSubmitAllDocument: isSubmitAllDocument,
        isPromotional: isPromotional,
        // isIndependentContractor: isIndependentContractor,
        id: id,
      };
      const res = await verifyOtp(reqData);
      setIsLoading(true);
      if (res?.data?.code === 200) {
        setIsVerified(true);
        setAllCheck(false);
        setIsResend(false);
        const cookie = new Cookies();
        cookie.set("token", res?.data?.data?.tokens?.refresh?.token);
        cookie.set("guardId", res?.data?.data?.guard?.id);
        cookie.set("guardInfo", res?.data?.data?.guard);
        // sessionStorage.setItem("token", res.data.data.tokens.refresh.token)
        // navigate()
        setIsLoading(false);
        navigate(`/guard/registration/${id}`);
        toast.success(res?.data?.message);
      } else {
        setIsLoading(false);
        setInvalidOtp(true);
      }
    } else {
      setAllCheck(true);
      setIsLoading(false);
      setTimeout(() => {
        setAllCheck(false);
      }, 5000);
    }
  };
  // Function for OTP Validation
  const handleCheckOtp = (value) => {
    const pattern = /[0-9]/;
    setOtp(value);
    if (pattern.test(value) === false) {
      setInvalidOtp(true);
    } else {
      setInvalidOtp(false);
      setAllCheck(false);
    }
  };

  return (
    <>
      <Header_inner />
      <section className="otpPage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="otpForm">
                <form>
                  <h4>Terms Of Use</h4>
                  {allCheck && (
                    <div className="alert alert-danger" role="alert">
                      Please agree to all terms and conditions to register with
                      us.
                    </div>
                  )}
                  {isVerified && (
                    <div className="alert alert-success" role="alert">
                      OTP Verified!
                    </div>
                  )}
                  {isResend && (
                    <div className="alert alert-warning" role="alert">
                      OTP re-sent to your given email and mobile.
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked1"
                          onChange={(e) =>
                            setIsSubmitAllDocument(e.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked1"
                        >
                          I confirm to submit all documents to work as a GoGuard
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked2"
                          onChange={(e) =>
                            setIsIndividualDocument(e.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked2"
                        >
                          {/* I am an individual not an agency. I am not affiliated
                          with any agency or working on any agency’s behalf. */}
                          I am an individual, not an agency.
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={isPromotional}
                          id="flexCheckChecked3"
                          onChange={(e) => setIsPromotional(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked3"
                        >
                          By clicking this box I agree to receive periodic
                          promotional emails from GoGuard.
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <p className="confirm_para">
                        By entering the OTP I am digitally signing an agreement
                        to work as a GoGuard agreeing to all policies and
                        independent contractor agreements.
                      </p>
                      <div className="row justify-content-center">
                        <div className="col-md-5">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputtext"
                              maxLength="4"
                              aria-describedby="textHelp"
                              placeholder="Enter OTP"
                              onChange={(e) => handleCheckOtp(e.target.value)}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  handleVerifyOtp(event);
                                }
                              }}
                            />
                            <div className="agreeBtn">
                              <a
                                type="button"
                                onClick={(e) => handleVerifyOtp(e)}
                              >
                                Continue{" "}
                                <span>
                                  {isLoading ? (
                                    <Oval
                                      color="white"
                                      height={20}
                                      width={20}
                                      className="Loadingstyle"
                                    />
                                  ) : (
                                    <FaArrowRight />
                                  )}
                                </span>
                              </a>
                            </div>
                          </div>
                          {invalidOtp == true ? (
                            <div className="invalid-feedback">
                              Please enter valid otp
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <div className="resendBtn" onClick={handleOtp}>
                                <a type="button">Resend OTP?</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row justify-content-center mt-4">
                    <div className="col-md-12">
                      
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OTP;
