import React, { useEffect } from 'react';
import {FaArrowRight,FaUserAlt,FaEnvelope,FaPhone,FaInfoCircle,FaPlus,FaMapMarkerAlt} from "react-icons/fa";
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom';
import Slider from '@mui/material/Slider';
import LogoIcon from '../Logo/Logo';
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import './work_inner.scss';
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const marks = [
    {
      value: 0,
      label: '10 Kms',
    },
    {
      value: 37,
      label: '37 Kms',
    },
    {
      value: 100,
      label: '100 Kms',
    },
  ];
  function valuetext(value) {
    return `${value}°C`;
  }
const WorkProfileInner = () => {

    const cookie = new Cookies();
    const token = cookie.get("token");
    const navigate = useNavigate();

    useEffect( async () => {
        if (!token) {
            toast.warning(
              "your session has been expired ...kindly login again.",
              "yellow"
            );
            navigate(`/guard/login`);
          } 
    },[])

  return (
    <>
    <Header_inner />
      <section className="workInner">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-md-10">
                      <div className="userBox">
                            <div className="row">
                                <div className="col-md-6 col-lg-5">
                                    <div className="mapBox">
                                        <h4>Work Profile</h4>
                                        <div className="profileBox">
                                            <div className="imgBox">
                                                <span><FaUserAlt /></span>
                                            </div>
                                            <div className="content">
                                                <h4>John Doe</h4>
                                                <p><FaEnvelope />Johnd123@gmail.com</p>
                                                <p><FaPhone />0123456789</p>
                                            </div>
                                        </div>
                                        <div className='update_box'>
                                            <h5>My Base Location</h5>
                                            <a href="#" className='updateLoc'> <FaMapMarkerAlt /> Update</a>
                                        </div>
                                        <div className="mapNav">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width={'100%'} height={'135px'} frameborder={'0'} style={{color: "red"}}></iframe>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                    type="text"
                                                    className="form-control"
                                                    aria-describedby="textHelp"
                                                    placeholder="City"
                                                    />
                                                    <span>
                                                    <FaMapMarkerAlt />
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                    type="text"
                                                    className="form-control"
                                                    aria-describedby="textHelp"
                                                    placeholder="Province"
                                                    />
                                                    <span>
                                                    <FaMapMarkerAlt />
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input
                                                    type="text"x
                                                    className="form-control"
                                                    aria-describedby="textHelp"
                                                    placeholder="Address"
                                                    />
                                                    <span>
                                                    <FaMapMarkerAlt />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='range_box'>
                                                <p>I am willing to travel</p>
                                                <Box>
                                                    <Slider
                                                        aria-label="Custom marks"
                                                        defaultValue={37}
                                                        getAriaValueText={valuetext}
                                                        step={1}
                                                        valueLabelDisplay="auto"
                                                        marks={marks}
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-7">
                                    <div className="otpForm">
                                        <form>
                                            <div className="workProfileForm">
                                                <p>I wish to work on job with specialities</p>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Airports
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Banks
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Car lots
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Events
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Strikes & Unrest
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Estate Protection
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Loss Prevention
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Residential 
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Close Protection
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Private Investigation
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                VIP Protection
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                Other
                                                            <a href="#"><FaInfoCircle /></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="transport borderB">
                                                <p>Choose Preferred Reflective Vest Size</p>
                                                <div className="row justify-content-center">
                                                    <div className="col-md-4 col-lg-4">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault"> Medium
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault"> Large
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault"> XL
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="transport">
                                                <p>How will you commute transport?</p>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-5 col-xl-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault"> Public Transport
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-xl-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault"> Own Vehicle
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="nextBtn">
                                        <Link to="/myprofile">Submit<span><FaArrowRight /></span></Link>
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default WorkProfileInner;
