import React from 'react';
import {FaArrowRight,FaArrowLeft,FaInfoCircle} from "react-icons/fa";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import LogoIcon from '../Logo/Logo';
import './work_distance.scss';


  const marks = [
    {
      value: 0,
      label: '10 Km',
    },
    {
      value: 37,
      label: '37 Km',
    },
    {
      value: 100,
      label: '100 Km',
    },
  ];
  
  function valuetext(value) {
    return `${value}°C`;
  }
const WorkDistance = () => {
  return (
    <>
      <section className="workDistance">
          <div className="container">
          <LogoIcon />
              <div className="row justify-content-center">
                  <div className="col-md-7 col-lg-6">
                      <div className="otpForm">
                          <form>
                                <h4>Work Profile</h4>
                                <p>Maximum distance you can travel to work</p>
                                <Box>
                                    <Slider
                                        aria-label="Custom marks"
                                        defaultValue={37}
                                        getAriaValueText={valuetext}
                                        step={1}
                                        valueLabelDisplay="auto"
                                        marks={marks}/>
                                </Box>
                                <div className="transport">
                                    <p>How will you commute transport?</p>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-5 col-xl-6">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault"> Public Transport
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-xl-6">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault"> My Vehicle to go to work.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="transport">
                                    <p>Choose Preferred Reflective Vest Size</p>
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault"> Medium
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault"> Large
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault"> XL
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="nextBtn">
                                            <button type="submit">Submit<span><FaArrowRight /></span></button>
                                        </div>
                                    </div>
                                </div>
                          </form>
                      </div>
                  </div>
              </div>
              <div className="row justify-content-center mt-4">
                  <div className="col-md-5">
                      <div className="preBtn">
                          <button type="button"><span><FaArrowLeft /></span> Previous Step</button>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default WorkDistance;
