import React, { useEffect, useState } from "react";
import { FaArrowRight, FaClock } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./notification.scss";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import {
  getNotificationList,
  getNotificationDetail,
  deleteNotification,
  readNotification,
} from "../../utils/request";
import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import moment from "moment";

const Notification = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (token) {
      getNotificationLists();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);
  // Function for get notification list
  const getNotificationLists = async () => {
    const requestedData = {
      limit: 10,
      page: pageNo,
    };
    const response = await getNotificationList(requestedData);
    //console.log("getNotificationList response", response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      setTotalItemsCount(response.data.data.total);
      setNotificationList(response.data.data.docs);
    }
  };
  // Function for handle page change
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    // pageNo = pageNumber;
    getNotificationLists();
  };

  return (
    <>
      <Navbar />
      <section className="notification_Alert">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="content">
                <h4>Notification Alerts</h4>

                {notificationList?.map((item, index) => {
                  return (
                    <div className="notifyBox">
                      <h5>{item?.title}</h5>
                      <p>{item?.description}</p>
                      <div className="time">
                        <span>
                          {moment
                            .utc(item?.createdAt)
                            .format("DD-MM-YYYY h:mm, A")}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-2">
                {notificationList.length != 0 && (
                  <Pagination
                    color="primary"
                    activePage={pageNo}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Notification;
