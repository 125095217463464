import React, { useEffect, useState } from "react";
import PdfIcon from "../../images/pdf.png";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./previous_ticket.scss";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import { getTicketList } from "../../utils/request";
import moment from "moment";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const PreviousTicket = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [ticketList, setTicketList] = useState([]);

  useEffect(() => {
    if (token) {
      getTicketLists();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);
  // Function for get ticket list
  const getTicketLists = async () => {
    const response = await getTicketList();
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    //console.log("getTicketLists response", JSON.stringify(response))
    if (response.data.code === 200) {
      setTicketList(response.data.data.docs);
    } else {
      return;
    }
    return;
  };
  return (
    <>
      <Navbar />
      <section className="previous_ticket">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title">
                      <div className="backArrow">
                        <Link to="/support">
                          <FaArrowLeft />
                        </Link>
                      </div>
                      <div className="heading">
                        <h1>Previous Tickets</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {ticketList.map((item, index) => {
                    return (
                      <div className="col-md-4">
                        <Link to="/ticketDetail">
                          <div className="descBox">
                            <h6>{item.ticketTitle}</h6>
                            <p className="totEarn">
                              <span>Ticket Date :</span>{" "}
                              {moment.utc(item.ticketDate).format("DD/MM/YYYY")}
                            </p>
                            <p className="totEarn">
                              <span>Job Title :</span> {item.jobId.jobTitle}
                            </p>
                            <p className="pdfFile">
                              <img
                                src={PdfIcon}
                                alt="PdfImage"
                                className="img-fluid"
                              />
                              <span>Report File.pdf</span>
                            </p>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PreviousTicket;
