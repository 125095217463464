import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaClock, FaPause, FaCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./e_notes.scss";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import { createENote } from "../../utils/request";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const ENote = () => {
  const cookie = new Cookies();
  const session = cookie.get("session");
  const jobid = cookie.get("jobId");
  const token = cookie.get("token");

  const navigate = useNavigate();
  const [jobId, setJobId] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [eventTime, setEventTime] = useState(moment.utc().format("HH:mm:ss"));
  const [type, setType] = useState(1);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [file, setFile] = useState("");

  useEffect(() => {
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Validation Functions
  const handleTitle = (value) => {
    setTitle(value);
    if (value === "") {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
  };
  const handleEventTime = (value) => {
    setEventTime(value);
  };

  const handleDescription = (value) => {
    setDescription(value);
    if (value === "") {
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }
  };

  // Function for create enotes
  const createENotes = async () => {
    if (title === "") {
      setTitleError(true);
    } else if (description === "") {
      setDescriptionError(true);
    } else {
      var bodyFormData = new FormData();

      bodyFormData.append("jobId", jobid);
      bodyFormData.append("title", title);
      bodyFormData.append("eventTime", eventTime);
      bodyFormData.append("type", type);
      bodyFormData.append("description", description);
      bodyFormData.append("file", file);
      const response = await createENote(bodyFormData);
      if (response.data.code === 200) {
        navigate(`/onJob`);
      }
    }
  };

  return (
    <>
      <Navbar />
      <section className="e_notes">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6">
              <div className="content">
                <form>
                  <div className="title">
                    <div className="backArrow">
                      <Link to="/jobDetail">
                        <FaArrowLeft />
                      </Link>
                    </div>
                    <div className="heading">
                      <h1>E-Notes Entry</h1>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      onChange={(e) => handleTitle(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Title"
                      aria-label="default input example"
                    />
                  </div>
                  {titleError && (
                    <div className="invalid-feedback">Title is required</div>
                  )}
                  <div className="form-group">
                    <input
                      onChange={(e) => handleEventTime(e.target.value)}
                      type="time"
                      className="form-control"
                      placeholder="HH-MM-SS"
                      aria-label="default input example"
                      value={eventTime}
                    />
                    {/* <FaClock /> */}
                  </div>
                </form>
                <div className="tabBox">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={(e) => setType(1)}
                        className="nav-link active"
                        id="pills-text-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-text"
                        type="button"
                        role="tab"
                        aria-controls="pills-text"
                        aria-selected="true"
                      >
                        Text
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setType(2)}
                        className="nav-link"
                        id="pills-audio-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-audio"
                        type="button"
                        role="tab"
                        aria-controls="pills-audio"
                        aria-selected="false"
                      >
                        Audio
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-text"
                      role="tabpanel"
                      aria-labelledby="pills-text-tab"
                    >
                      <div className="form-group">
                        <textarea
                          onChange={(e) => handleDescription(e.target.value)}
                          className="form-control"
                          rows="8"
                          placeholder="Description"
                          id=""
                        ></textarea>
                      </div>
                      {descriptionError && (
                        <div className="invalid-feedback">
                          Description is required
                        </div>
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-audio"
                      role="tabpanel"
                      aria-labelledby="pills-profile-audio"
                    >
                      <div className="audioBox">
                        <div className="imgBox">sdfs</div>
                        <div className="voiceBox">
                          <a href="#">
                            <FaPause />
                          </a>
                          <a href="#" className="redCircle">
                            <FaCircle />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addMore">
                  <button onClick={() => createENotes()} className="submitBtn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ENote;
