import React, { useEffect, useState } from "react";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import Navbar from "../../Pages/Header/Navbar";
import {
  FaPlusCircle,
  FaTimes,
  FaCaretDown,
  FaArrowRight,
} from "react-icons/fa";
import { getSpecialityList, getGuardProfile } from "../../utils/request";
import "./empl_details.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";

const Empl_Details = () => {
  const navigate = useNavigate();
  const cookie = new Cookies();
  const { id } = useParams();
  const guardId = cookie.get("guardId");
  const token = cookie.get("token");

  const [emp, setEmp] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [specialityList, setSpecialityList] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [guardOtherInfo, setGuardOtherInfo] = useState({});
  const [guard, setGuard] = useState({});
  var pageNo = 1;

  useEffect(() => {
    getSpecial();
    getGuardProfilef();
    //console.log(id);
    // setEmp(guard?.state?.guard);

    // setSpeciality(guard?.state?.guard?.workSpecialities[0]._id);
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);

  // Function For get guard profile
  const getGuardProfilef = async () => {
    setIsLoading(true);
    const response = await getGuardProfile(id);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    // //console.log("guard profile response", response);
    if (response?.data?.code === 200) {
      setIsLoading(false);
      setEmp(response?.data?.data?.guard);
      settotalItemsCount(
        response?.data?.data?.guard?.employmentDetails?.length
      );
    } else {
      setIsLoading(false);
    }
  };

  // Function for get speciality list
  const getSpecial = async () => {
    const guardListRes = await getSpecialityList();
    if (guardListRes?.data?.code === 200) {
      setSpecialityList(guardListRes.data.data);
    }
  };

  // Handle Page Change FUnction
  const handlePageChange = (pageNumber) => {
    //console.log(pageNo);
    pageNo = pageNumber;
  };
  return (
    <>
      <Navbar />
      <section className="emp_details">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="regForm">
                <div className="row">
                  <div className="col-md-12">
                    <div className="identityTitle">
                      <h5 className="previous_emp_title">Previous Employer</h5>
                      <div className="editDetail">
                        {emp?.employmentDetails?.length === 0 ? (
                          <button
                            className="btn add"
                            type="button"
                            onClick={(e) => {
                              navigate(
                                `/guard/registration/${guardId}?status=loggedin`
                              );
                            }}
                          >
                            <span style={{ marginRight: "8px" }}>
                              <i class="fa-solid fa-plus"></i>
                            </span>
                            Add
                          </button>
                        ) : (
                          <button
                            className="btn add"
                            type="button"
                            onClick={(e) => {
                              navigate(
                                `/guard/registration/${guardId}?status=loggedin`
                              );
                            }}
                          >
                            <span style={{ marginRight: "8px" }}>
                              <i class="fa-solid fa-edit"></i>
                            </span>
                            Edit
                          </button>
                        )}

                        <button
                          className="btn add"
                          type="button"
                          onClick={(e) => {
                            navigate(`/guard/myprofile/${guardId}`);
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            <i class="fa-solid fa-arrow-left"></i>
                          </span>
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="identificationDocument mt-2">
                      <div className="table-responsive border-1">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Employer Name</th>
                              <th>Position Held</th>
                              <th>Speciality</th>
                              <th>From Date</th>
                              <th>To Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {emp?.employmentDetails?.length !== 0 ? (
                              emp?.employmentDetails?.map((res, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Employer Name"
                                          value={res?.employerName}
                                          readOnly
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="GoGuard"
                                          value={res?.projectName}
                                          readOnly
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group">
                                        <select
                                          class="form-select"
                                          aria-label="Default select example"
                                          value={speciality}
                                          disabled
                                        >
                                          {res?.speciality === undefined && (
                                            <option value="">
                                              Select Speciality
                                            </option>
                                          )}

                                          <option
                                            value={res?.speciality?._id}
                                            selected
                                          >
                                            {res?.speciality?.name}
                                          </option>
                                          {/* {specialityList.map((item, i) => {
                                            return (
                                              <>
                                                <option
                                                  selected={
                                                    res?.speciality?._id ===
                                                    item.id
                                                  }
                                                  key={i}
                                                  value={item.id}
                                                  // selected
                                                >
                                                  {item.name}
                                                </option>
                                              </>
                                            );
                                          })} */}
                                        </select>
                                        <span>
                                          <FaCaretDown />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="dateFormat">
                                        <div className="form-group">
                                          <input
                                            type="date"
                                            className="form-control"
                                            placeholder="From"
                                            value={moment
                                              .utc(res?.from)
                                              .format("yyyy-MM-DD")}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="dateFormat">
                                        <div className="form-group">
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={moment
                                              .utc(res?.to)
                                              .format("yyyy-MM-DD")}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    {/* <td className="text-center">
                                        <span className="closeBtn">
                                          <FaTimes />
                                        </span>
                                      </td> */}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan={6}>
                                  No data found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {emp?.employmentDetails?.length !== 0 && (
                    <div className="mt-2">
                      <Pagination
                        color="primary"
                        activePage={pageNo}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                      />
                    </div>
                  )}
                  {/* <div className="col-md-12">
                    <div className="nextBtn">
                      <button>
                        Submit
                        <span>
                          <FaArrowRight />
                        </span>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Empl_Details;
