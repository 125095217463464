import React, { useEffect } from "react";
import "./training.scss";
import WelcomeHeader from "../../Pages/Wel_Header/WelcomeHeader";
import WelcomeFooter from "../../Pages/Wel_Footer/WelcomeFooter";
import Train_Img_1 from "../../images/how_work/train_img1.png";
import Train_Img_2 from "../../images/how_work/train_img2.png";
import { FaCaretRight } from "react-icons/fa";
import ReachUSForm from "../../shared/reachUs";
import ServiceGuard from "../../images/service-guard.png";

const Training = () => {
  return (
    <>
      <WelcomeHeader />
      <section className="banner-training">
        <div className="container">
          <div className="workBox">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12">
                <div className="work">
                  <h1>GoGuard Training Program</h1>
                  <p>Get Trained and Certified by our Training Partners!!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contactForm">
        <div className="container">
          <div className="row align-items-center">
            <div class="col-md-6 col-lg-7 order-2 order-md-1 left-box">
			<h2>Register And <strong>Get Trained!!</strong></h2>
			<p class="sec-sub-heading">Looking to get trained as a security guard, Register Now!!</p>
			<p>Register your interest to train as a security guard and our training team will connect you with our training partners. We have partnered with best guard training agencies to provide you with the best training courses which help you quickly qualify and get your licensed security guard registration.</p>
			<div class="d-inline-flex taining-contact">
			<div class="flex-shrink-0">
			<i class="gg-icon envelope-icon"></i>
			</div>
			<div class="flex-grow-1 ms-3">
			<h3>Contact Us Via</h3>
				<label class="mb-2">Email: <a href="mailto:support@go-guard.com">support@go-guard.com</a></label>
			</div>
			</div>
			</div>
            <ReachUSForm pageType="Training" />
          </div>
        </div>
      </section>
        <section class="inner-loc-benefit py-6">
		  <div class="container">
		  <h2><strong>Why Get Trained</strong> with GoGuard?</h2>
		  <div class="row">
		  <div class="col-md-6">
		  <p class="sec-sub-heading">GoGuards has empanelled the best security guard training agencies in Ontario. Our partnership gives you the following advantages</p>
		  <img src={ServiceGuard} alt="Service Guard" class="img-fluid app-mobile-img rounded-3" width="652px" height="233px" />
		  </div>
		  <div class="col-md-6">
		  <ul class="list-unstyled">
		  <li class="d-flex">
		  <div class="flex-shrink-0">
		  <i class="gg-icon experts-icon"></i>
		  </div>
		  <div class="flex-grow-1 ms-3">
		  <h3>Training By Experts</h3>
		  <p>Get trained by industry experts. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
		  </div>
		  </li>
		  <li class="d-flex">
		  <div class="flex-shrink-0">
		  <i class="gg-icon license-icon"></i>
		  </div>
		  <div class="flex-grow-1 ms-3">
		  <h3>License</h3>
		  <p>Learn how to quickly qualify complete the training and get license. Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
		  </div>
		  </li>
		  <li class="d-flex">
		  <div class="flex-shrink-0">
		  <i class="gg-icon find-icon"></i>
		  </div>
		  <div class="flex-grow-1 ms-3">
		  <h3>Find Work</h3>
		  <p>Find work as a GoGuard once we update your license details.Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium.</p>
		  </div>
		  </li>
		  </ul>
		  </div>
		  </div>
		  </div>
		  </section>

      {/* <section className="trainingSec employee">
        <div className="workGuard">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="content">
                  <h3>Employment Linked Program </h3>
                  <p>
                    GoGuard is an equal opportunity platform which lets you
                    apply for jobs that match your skills and experience. Our
                    platform will empower you to find hundreds of security jobs
                    and if you apply relentlessly, you will find a great job
                    that matches your skills and expectations. Join the program
                    to build your career as a security professional now!!
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="imgBox">
                  <img src={Train_Img_2} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="scholarship">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3>Scholarship Program</h3>
              <p>
                GoGuard has partnered with security agencies which offer us the
                privilege to recommend candidates who need financial assistance
                to get their security guard license. We offer scholarships after
                carefully reviewing candidates’ financial situation and various
                other factors. These scholarships are offered as both partial
                assistance and full scholarships to deserving candidates.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <WelcomeFooter />
    </>
  );
};

export default Training;
