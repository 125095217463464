import React from "react";
import "./welcome_footer.scss";
import FbIcon from "../../images/welcomePage/facebook.png";
import TwIcon from "../../images/welcomePage/insta.png";
import LiIcon from "../../images/welcomePage/linkedin.png";
import Logo from "../../images/welcomePage/Logo_2.png";
import { Link } from "react-router-dom";

const WelcomeFooter = () => {
  return (
    <>
      <footer className="footer">
	  	
	  
	  <div class="container">
		<div class="row">
		<div class="col-md-4 col-lg-4 col-xl-3">
	  <img src={Logo} class="img-fluid img-fluid mb-md-5 mb-3" width="203px" height="51px" />
		<p>Hire reliable security solutions</p>
		</div>
		<div class="col-md-3 col-lg-3 col-xl-2">
		<h4>Quick Links</h4>
		<ul class="mb-4 mb-md-0 list-unstyled quick-links">
		<li><Link to="/ontario">GoGuard Ontario</Link></li>
		<li><Link to="/about-us">About Us</Link></li>
		<li><Link to="/how-it-work">How It Works?</Link></li>
		<li><Link to="/faq">FAQ</Link></li>
		<li><Link to="/contact_us">Contact Us</Link></li>
		<li><Link to="/guard/login">LogIn as Guard</Link></li>
		</ul>
		</div>
		<div class="col-md-5 col-lg-5 col-xl-4">
		<h4>Specialities</h4>
		<ul class="mb-4 mb-md-0 list-unstyled quick-links specs">
		<li><Link to="/our-speciality/bank">Banks</Link></li>
		<li><Link to="/our-speciality/car-lots">Car Lots</Link></li>
		<li><Link to="/our-speciality/concierge">Concierge</Link></li>
		<li><Link to="/our-speciality/loss-prevention">Loss Prevention</Link></li>
		<li><Link to="/our-speciality/protests">Protests</Link></li>
		<li><Link to="/our-speciality/access-control">Access Control</Link></li>
		<li><Link to="/our-speciality/events">Events</Link></li>
		<li><Link to="/our-speciality/residential">Residential</Link></li>
		<li><Link to="/our-speciality/construction">Construction</Link></li>
		<li><Link to="/our-speciality/vip">VIP</Link></li>
		</ul>
		</div>
		<div class="col-md-12 col-lg-3">
		<address>GoGuard Inc.<br />285 Taunton Road East,<br />Suite 4423 Oshawa, ON, L1G 3V2</address>
		<ul class="list-unstyled footer-social mb-0 d-flex">
		<li><a href="https://www.facebook.com/Guardondemand" target="_blank"><i class="gg-icon fb-icon"></i></a></li>
		<li><a href="https://www.instagram.com/goguard.inc/" target="_blank"><i class="gg-icon instagram-icon"></i></a></li>
		<li><a href="https://www.linkedin.com/in/goguardinc-2305/" target="_blank"><i class="gg-icon linkedin-icon"></i></a></li>
		</ul>
		</div>
		</div>
		</div>

        <div className="footer-btm">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-between flex-wrap">
	  			<div class="footer-btm-left">
                <i className="fas fa-copyright"></i> copyright@ {new Date().getFullYear()} <Link to="/">Go-Guard.com</Link>
	  			</div>
	  			<div class="footer-btm-right">
				<ul class="list-unstyled mb-0 d-flex justify-content-center">
	  			<li><Link to="/terms-and-condition">Terms And Conditions</Link></li>
              	<li><Link to="/privacy-policy">Privacy Policy</Link></li>
				</ul>
				</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default WelcomeFooter;
