import React, { useEffect, useState } from "react";
import { FaMapMarkerAlt, FaStar, FaStarHalf, FaCalendar } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./rating.scss";
import Navbar from "../../Pages/Header/Navbar";
import Footer from "../../Pages/Footer/Footer";
import { getRating } from "../../utils/request";
import moment from "moment";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const MyRating = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  const [rateDetails, setRateDetails] = useState([]);

  useEffect(() => {
    if (token) {
      rating();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, []);
  // Function for get rating
  const rating = async () => {
    const response = await getRating();
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    //console.log("getRating response", JSON.stringify(response))
    if (response.data.code === 200) {
      setRateDetails(response.data.data.docs);
    } else {
      return;
    }
    return;
  };

  return (
    <>
      <Navbar />
      <section className="my_Rating">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="rateBox">
                <div className="row">
                  <div className="col-md-12">
                    <h5>My Ratings</h5>
                  </div>
                </div>
                <div className="row">
                  {rateDetails.map((item, index) => {
                    return (
                      <div className="col-md-6 col-lg-3">
                        <button
                          onClick={() =>
                            navigate(`/jobRating`, { state: { item } })
                          }
                        >
                          <div className="flexBox">
                            <h6>{item.jobId.jobTitle}</h6>
                            <p className="mapText">
                              <FaMapMarkerAlt />
                              {item.jobId.locationId.locationName}
                            </p>
                            <p className="starRate">
                              Job Rating
                              <FaStar style={{ marginLeft: "6px" }} />
                              <FaStar />
                              <FaStar />
                              <FaStarHalf />
                            </p>
                            <p className="date">
                              <FaCalendar className="dateIcon" />
                              {moment.utc(item.createdAt).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyRating;
