import React, { useState } from "react";
import {
  FaEdit,
  FaTrash,
  FaPaperclip,
  FaFile,
  FaCalendar,
} from "react-icons/fa";

import "./MyDocument.scss";
import Navbar from "../../Pages/Header/Navbar";
import Cookies from "universal-cookie";
import {
  uploadDocument,
  getDocumentList,
  deleteListItem,
  detaiAlllListItem,
} from "../../utils/request";
import moment from "moment";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL } from "../../utils/constants";

const MyDocument = () => {
  const navigate = useNavigate();
  const [documentNo, setDocumentNo] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [expireDate, setExpireDate] = useState(
    moment.utc().format("yyyy-MM-DD")
  );
  const [documentNoError, setDocumentNoError] = useState(false);
  const [documentTypeError, setDocumentTypeError] = useState(false);
  const [fileNameError, setFileNameError] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [addDocument, setAddDocument] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  let [isLoading, setIsLoading] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const cookie = new Cookies();
  const token = cookie.get("token");
  const guardId = cookie.get("guardId");

  React.useEffect(() => {
    if (token) {
      getDocumentLists();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/guard/login`);
    }
  }, [addDocument]);
  // Function for get document list
  async function getDocumentLists() {
    const requestedData = {
      limit: 10,
      guardId: guardId,
      page: pageNo,
    };
    const response = await getDocumentList(requestedData);
    //console.log(response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response?.data?.code === 200) {
      setTotalItemsCount(response.data.data.total);
      setDocumentList(response.data.data.docs);
    }
  }
  // Function for delete warning
  const deleteList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do yo want to remove this document?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteListItems(id);
      }
    });
  };
  // Function for delete document
  const deleteListItems = async (id) => {
    const response = await deleteListItem(id);
    //console.log("del", response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/guard/login`);
    }
    if (response.data.code === 200) {
      setDocumentList([]);
      getDocumentLists();
      toast.success(response.data.message, "green");
    } else {
      toast.error(response.data.message, "red");
      setIsError(true);
      setError(response.data.message);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };
  // Function for page change
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    getDocumentLists();
  };
  // Functions for validation
  const handleDocumentType = (value) => {
    //console.log("handleDocumentType", JSON.stringify(value));
    if (value === "") {
      toast.warn("Please Select Document Type");
      setDocumentTypeError(true);
      return;
    } else {
      setDocumentTypeError(false);
      setDocumentType(value);
    }
  };

  const handleDocumentChange = (value) => {
    if (value === "") {
      setDocumentNoError(true);
      return;
    }
    const regex = /^\d{2,15}$/;

    if (regex.test(value) === false) {
      setDocumentNoError(true);
    } else {
      setDocumentNo(value);
      setDocumentNoError(false);
    }
  };

  const handleExpiryDate = (value) => {
    setExpireDate(value);
  };
  // Function for file change
  const handleFileChange = (e) => {
    e.preventDefault();
    const allowedBytes = 2 * 1024 * 1024;
    let file = e.target.files[0];
    const size = e.target.files[0].size / 1048576;

    let allImages = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/tiff",
      "image/bpg",
      "image/webp",
    ];

    if (allImages.indexOf(file?.type) === -1) {
      toast.warning("File type is not allowed");
      return;
    }
    if (e.target.files[0].size > allowedBytes) {
      alert("Size is more than 2 MB");
      setIsFile(true);
      return;
    }
    setIsFile(false);

    let reader = new FileReader();
    // let file = e.target.files[0];
    setFileName(file);
    //console.log(fileName);

    reader.readAsDataURL(file);
  };
  // Function for add document
  const uploadDocuments = async () => {
    if (documentList.length === 4) {
      toast.warn("Max 4 documents allowed");
      return;
    }
    const f = documentList.filter((res) => {
      return res.documentType === documentType;
    });
    const df = documentList.filter((res) => {
      return res.documentNumber === documentNo;
    });

    //console.log(f);
    //console.log(df);
    if (f.length === 1) {
      toast.warn("Same type of identification proof is already saved");
      return;
    }

    if (df.length === 1) {
      toast.warn("Same document no is already saved");
      return;
    }
    const cookie = new Cookies();
    const guardId = cookie.get("guardId");
    var bodyFormData = new FormData();

    if (documentType === "") {
      toast.warning("Please Select Document Type");
      return;
    } else if (documentNo === "") {
      toast.warning("Document no is required");
    } else if (expireDate === "") {
      toast.warning("Please select expiry date");
    } else if (fileName === "") {
      toast.warning("Please select file");
    } else {
      bodyFormData.append("documentType", documentType);
      bodyFormData.append("documentNumber", documentNo);
      bodyFormData.append("file", fileName);
      bodyFormData.append("guardId", guardId);
      bodyFormData.append("expiryDate", expireDate);
      setIsLoading(true);
      const response = await uploadDocument(bodyFormData);
      setIsLoading(false);

      if (response.data.code === 200) {
        setDocumentList([]);
        setAddDocument(addDocument + 1);
        document.getElementById("doctype").value = "";
        setDocumentType("");
        document.getElementById("docNo").value = "";
        setDocumentNo("");
        document.getElementById("expdate").value = "";
        setExpireDate("");
        document.getElementById("fileName").value = "";
        setFileName("");
      }
    }
  };
  // Function for select all document
  const handleSelectAll = (e) => {
    const { id, checked } = e.target;
    //console.log(id, checked, "test");
    setIsCheckAll(!isCheckAll);
    let allValue = documentList.map((li) => {
      return {
        documentType: li.documentType,
        documentNumber: li.documentNumber,
        file: li.file,
        id: li.id,
        isCheck: checked ? true : false,
      };
    });
    //console.log(allValue, "allValue");
    setDocumentList(allValue);
  };
  // Function for select document
  const handleChecked = (e) => {
    const { id, checked } = e.target;
    //console.log(id, checked, "vvv");
    let docList = documentList.map((item) => {
      if (item.id === id) {
        return {
          documentType: item.documentType,
          documentNumber: item.documentNumber,
          file: item.file,
          id: item.id,
          isCheck: checked,
        };
      } else {
        return item;
      }
    });
    setDocumentList(docList);
    //console.log(isCheckAll, "isCheckAll");
  };
  // Function for delete all document warning
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteApiCall(id);
      }
    });
  };
  // Function for delete all document
  const handleDeleteApiCall = async () => {
    let emptyArry = [];
    documentList.map((i) => {
      if (i.isCheck) {
        emptyArry.push(i.id);
      }
    });
    //console.log(emptyArry, "emptyArry");
    //console.log(token, "token");
    const response = await detaiAlllListItem({ documentIds: emptyArry });
    //console.log(response, "resss");
    if (response.data.code === 200) {
      toast.success(response.data.message, "green");
      const showList = documentList.filter((ele) => ele.isCheck === false);
      //console.log(showList, "showList");
      setDocumentList(showList);
      setIsLoading(false);
    } else {
      toast.error(response.data.message, "red");
      setIsError(true);
      setError(response.data.message);
    }
    getDocumentLists();

    //console.log(response, emptyArry, "");
  };

  return (
    <>
      <Navbar />
      <section className="myDoc">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="myDocBox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="topBox">
                      <h4>My Documents</h4>
                      <div className="editDetail">
                        <a
                          className="btn addBtn"
                          type="button"
                          onClick={(e) => {
                            navigate(`/guard/myprofile/${guardId}`);
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            <i class="fa-solid fa-arrow-left"></i>
                          </span>
                          Back
                        </a>
                      </div>
                    </div>
                    {iserror ? (
                      <div className="alert alert-danger">{error}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <select
                        className="form-select"
                        id="doctype"
                        defaultValue=""
                        onChange={(e) => handleDocumentType(e.target.value)}
                        aria-label="Default select example"
                      >
                        <option selected value="" disabled>
                          Document Type
                        </option>
                        <option value="Drivers license">Drivers license</option>
                        <option value="Permanent resident card">
                          Permanent resident card
                        </option>
                        <option value="Other">Other</option>
                        <option value="Canadian passport">
                          Canadian passport
                        </option>
                      </select>
                    </div>
                    {documentTypeError ? (
                      <div className="invalid-feedback">
                        Please select document type
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <input
                        id="docNo"
                        onChange={(e) => handleDocumentChange(e.target.value)}
                        className={
                          documentNoError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        type="text"
                        minLength={1}
                        maxLength={15}
                        placeholder="Document No."
                        aria-label="default input example"
                      />
                      <span>
                        <FaFile />
                      </span>
                    </div>
                    {documentNoError ? (
                      <div className="invalid-feedback">
                        Please enter valid Document Number
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <input
                        type="date"
                        max="2099-12-12"
                        className="form-control"
                        id="expdate"
                        aria-describedby="textHelp"
                        placeholder="Expiry Date"
                        onChange={(e) => handleExpiryDate(e.target.value)}
                        required
                      />
                      <span>
                        <FaCalendar />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      {isFile ? (
                        <input
                          type="file"
                          className="form-control"
                          htmlFor="exampleInputtext"
                          aria-describedby="textHelp"
                          name="fileName"
                          id="fileName"
                          placeholder="Upload Identification"
                          value=""
                          onChange={(e) => handleFileChange(e)}
                          accept="image/*"
                        />
                      ) : (
                        <input
                          type="file"
                          className="form-control"
                          htmlFor="exampleInputtext"
                          aria-describedby="textHelp"
                          name="fileName"
                          id="fileName"
                          placeholder="Upload Identification"
                          onChange={(e) => handleFileChange(e)}
                          accept="image/*"
                        />
                      )}
                      <span>
                        <FaPaperclip />
                      </span>
                    </div>
                    {fileNameError ? (
                      <div className="invalid-feedback">Please select file</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-7">
                    <div className="addDocs_btn">
                      <a
                        type="button"
                        onClick={() => (isLoading ? "" : uploadDocuments())}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Add Document"}{" "}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="uploded_docs">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Uploaded Documents</h5>
                    </div>
                    <div className="addDocs_btn">
                      {documentList.filter((i) => i?.isCheck === true).length >
                      0 ? (
                        <a
                          type="button"
                          onClick={(e) => handleDelete(e)}
                          style={{ background: "red" }}
                        >
                          Delete
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive border-1">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                id="selectAll"
                                onChange={handleSelectAll}
                                checked={
                                  documentList.filter(
                                    (i) => i?.isCheck !== true
                                  ).length < 1
                                }
                              />
                            </th>
                            <th>Document Type</th>
                            <th>Document No.</th>
                            <th>Expiry</th>
                            <th>Attachment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documentList.map((item, id, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    key={id}
                                    id={item.id}
                                    onChange={(e) => handleChecked(e)}
                                    checked={item?.isCheck}
                                  />
                                </td>
                                <td>{item.documentType}</td>
                                <td>{item.documentNumber}</td>
                                <td>
                                  {item.expiryDate
                                    ? moment
                                        .utc(item.expiryDate)
                                        .format("yyyy-MM-DD")
                                    : "--"}
                                </td>
                                <td>
                                  <div className="attach_docs">
                                    <img
                                      src={`${API_URL}/media/preview?filename=${item?.file}`}
                                      className="img-fluid"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="action_btn">
                                    <a
                                      type="button"
                                      onClick={() => deleteList(item.id)}
                                      className="del_btn"
                                    >
                                      <FaTrash />
                                    </a>
                                    <a
                                      type="button"
                                      onClick={() => {
                                        navigate(`/guard/documents/${item.id}`);
                                      }}
                                      className="edit_btn"
                                    >
                                      <FaEdit />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          {documentList.length == 0 ? (
                            <tr>
                              <td colSpan={5} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-2">
                      {documentList.length != 0 && (
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyDocument;
